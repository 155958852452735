// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import {
  HouseholdRequestStatus
} from '../../models';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';
import {
  EMPTY_STRING
} from '../../global';

// #endregion

// #region interfaces

interface PostDeadlineWarningDialogProps {
  handleClose: () => void;
  requestStatus: HouseholdRequestStatus;
  show: boolean;
}

// #endregion


const PostDeadlineWarningDialog: React.FC<PostDeadlineWarningDialogProps> = (props) => {

  const { isMobile } = useResponsive();

  // #region Styles
  // #endregion

  // #region Methods

  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
    >
      <DialogTitle>Post Deadline Request Restrictions</DialogTitle>
      <DialogContent>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>{`A post deadline request can only include the ${props.requestStatus.postDeadline ? props.requestStatus.postDeadline.arrivalDate : EMPTY_STRING}-${props.requestStatus.postDeadline ? props.requestStatus.postDeadline.departureDate : EMPTY_STRING} weekend/holiday and adjoining weekdays. It can not include a future weekend/holiday.`}</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Change the departure date before the next weekend/holiday. Typically, the departure date must be no later than Friday following the weekend/holiday being requested.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default PostDeadlineWarningDialog;