// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// #endregion

const Starting: React.FC = () => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      margin: theme.spacing(5, 0, 0, 0)
    },
    message: {
      margin: theme.spacing(4, 0, 0, 0)
    },
    progress: {
      margin: theme.spacing(4, 0, 0, 0)
    }
  };

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item justifyContent="center">
        <img src="images/msc_logo_150x150.jpg" width="150" height="150" alt="Montclair Ski Club" />
      </Grid>
      <Grid container item justifyContent="center">
        <Typography gutterBottom variant="h5">Montclair Ski Club</Typography>
      </Grid>
      <Grid container item justifyContent="center" >
        <Typography variant="body1" color="textSecondary">Since 1947</Typography>
      </Grid>
      <Grid container item justifyContent="center" sx={styles.message} >
        <Typography variant="body1" paragraph color="textPrimary">Initialzing the MSC website...</Typography>
      </Grid>
      <Grid container item justifyContent="center" sx={styles.progress} >
        <Grid item xs={6}>
          <LinearProgress />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Starting;

