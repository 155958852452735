// #region Imports

import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the React PDF Viewer styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Material-UI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import {
  API_URL,
  EMPTY_STRING
} from '../global';

import {
  GDFile,
  HTTPHeaders
} from "../interfaces";

// Auth0 user hooks
import { useAuth0User } from '../hooks/UseAuth0User';

// #endregion

// #region Interfaces

interface PDFViewerProps {
  file: GDFile | undefined;
}

// #endregion

const PDFViewer: React.FC<PDFViewerProps> = (props) => {

  // Auth0 hooks
  const { getAccessToken } = useAuth0User();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [errors, setErrors] = React.useState<boolean>(false);
  const [fileUrl, setFileUrl] = React.useState<string>(EMPTY_STRING);
  const [open, setOpen] = React.useState<boolean>(false);

  // #region Styles
  // #endregion

  // #region Methods

  /*
 * Get file BLOB for PDF viwer
 */
  const getFileContents = async (id: string, name: string) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/resources/download/${id}`);

    const params = new URLSearchParams();
    params.append('name', `${name}`);

    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('File contents was not retrieved');
        }
        return response.blob()
      })
      .then((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
      })
      .catch(err => {
        setErrors(err);
      });

  }

  // #endregion

  // #region Event Handlers

  /*
   * Handle when dialog closes
   */
  const handleClose = () => {
    setFileUrl(EMPTY_STRING);
  }

  React.useEffect(() => {
    // Open dialog when there is a file
    setOpen(fileUrl ? true : false);
  }, [fileUrl]);

  React.useEffect(() => {
    // If file present get contents
    if (props.file) {
      getFileContents(props.file.id, props.file.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file]);

  // #endregion

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
        <DialogContent>
          {fileUrl &&
            <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
          }
        </DialogContent>
      </Dialog>
    </Worker >
  );
}

export default PDFViewer;

