// #region imports

import React from 'react';

// Material-UI
import Typography from '@mui/material/Typography';

import {
  MembersFetchActionType
} from '../global';

// #endregion


// #region Interfaces

interface MembershipHelpProps {
  actionType: MembersFetchActionType;
  searchText?: string;
}

// #endregion

const MembershipHelp: React.FC<MembershipHelpProps> = (props) => {

  // #region Styles
  // #endregion

  return (
    <>
      {props.actionType === MembersFetchActionType.Filter &&
        <Typography variant="body1">{`No members found for selected filter(s)`}</Typography>
      }
      {props.actionType === MembersFetchActionType.Search &&
        <Typography variant="body1">{`No members found whose first or last name starts with "${props.searchText}". Try fewer characters to narrow the search, then gradually add additional characters.`}</Typography>
      }
    </>
  );

}

export default MembershipHelp;