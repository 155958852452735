// #region Imports

import React from 'react';

// Material-UI
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import { amber, blueGrey, green, red } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import Tooltip from '@mui/material/Tooltip';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import CancelledSignup from './CancelledSignup';
import MyLodgeUseHelp from './MyLodgeUseHelp';
import MyLodgeUseHelpDialog from './MyLodgeUseHelpDialog';
import PastSignup from './PastSignup';
import RequestEdit from './RequestEdit';

import {
  HouseholdSignups,
  SignupHousehold,
  SignupHouseholdFilter
} from '../../models';

import {
  HTTPHeaders
} from '../../interfaces';

import {
  API_URL,
  EMPTY_STRING,
  NULL_ID,
  SignupStatusID
} from '../../global';

// Redux
import { useSelector } from "react-redux";

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';
// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';
// Signup related dates hooks
import { useSignupDates } from '../../hooks/UseSignupDates';

// #endregion

const NODEID_CANCELLED = 'cancelled';
const NODEID_CURRENT = 'current';
const NODEID_FUTURE = 'future';
const NODEID_PAST = 'past';

enum LodgeUseMode {
  Edit = 1,
  Past = 2,
  Cancelled = 3,
  Summary = 99
}

const MyLodgeUse: React.FC = () => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();
  const { getRequestYearEndDate, getRequestYearStartDate } = useSignupDates();

  const [cancelledDrawer, setCancelledDrawer] = React.useState<boolean>(false);
  const [editDrawer, setEditDrawer] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState<boolean>(false);
  const [lodgeUseMode, setLodgeUseMode] = React.useState(LodgeUseMode.Summary);
  const [pastDrawer, setPastDrawer] = React.useState<boolean>(false);
  const [showWorking, setShowWorking] = React.useState<boolean>(false);
  const [signupID, setSignupID] = React.useState(NULL_ID);
  const [signups, setSignups] = React.useState<HouseholdSignups>(new HouseholdSignups());
  const [expandedNodes, setExpandedNodes] = React.useState<Array<string>>([]);

  // Redux
  const guestCancelled: boolean = useSelector((state: any) => state.signups.guestCancelled);
  const guestSaved: boolean = useSelector((state: any) => state.signups.guestSaved);
  const memberCancelled: boolean = useSelector((state: any) => state.signups.memberCancelled);
  const memberSaved: boolean = useSelector((state: any) => state.signups.memberSaved);
  const notSignedUpAdded: boolean = useSelector((state: any) => state.signups.notSignedUpAdded);
  const signupCancelled: boolean = useSelector((state: any) => state.signups.signupCancelled);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(1, 1, 1, 1)
    },
    confirmed: {
      color: green[500]
    },
    deined: {
      color: red[500]
    },
    drawer: {
      padding: theme.spacing(1, 1, 1, 1),
      flex: 1,
      height: '100%'
    },
    heading: {
      padding: theme.spacing(1, 0, 1, 0)
    },
    pending: {
      color: blueGrey[500]
    },
    requestsContainer: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    waiting: {
      padding: theme.spacing(2, 0, 2, 0)

    },
    waitlist: {
      color: amber[500]
    },
  };

  // #endregion

  // #region Methods

  /*
   * Fetch the household requests
   */
  const fetchSignups = async () => {

    setShowWorking(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // Always show current date forward
    const params = new URLSearchParams();

    // Filter to current member/user and request year
    params.append(`memberID`, `${memberID()}`);
    params.append(`startDate`, getRequestYearStartDate());
    params.append(`endDate`, getRequestYearEndDate());

    const url = new URL(`${API_URL}/v1/signups/household`);
    url.search = params.toString();

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setShowWorking(false);
          throw new Error('Household requests not retrieved');
        }
        return response.json()
      })
      .then((s: HouseholdSignups) => {
        setSignups(s);
        const exp: Array<string> = [];
        if (s.currentSignups.length > 0) {
          exp.push(NODEID_CURRENT);
        }
        if (s.futureSignups.length > 0) {
          exp.push(NODEID_FUTURE);
        }
        setExpandedNodes(exp);
        setShowWorking(false);
      }
      )
      .catch(err => {
        setErrors(err)
        setShowWorking(false);
      });
  }

  /*
   * Render status icon for request
   */
  const renderStatusIcon = (status: number) => {
    switch (status) {
      case SignupStatusID.Confirmed:
        return <Tooltip title="Confirmed">
          <CheckCircleIcon fontSize="medium" sx={styles.confirmed} />
        </Tooltip>;
        break;
      case SignupStatusID.Denied:
        return <Tooltip title="Denied">
          <BlockIcon fontSize="medium" sx={styles.deined} />
        </Tooltip>;
        break;
      case SignupStatusID.Pending:
        return <Tooltip title="Pending">
          <InfoIcon fontSize="medium" sx={styles.pending} />
        </Tooltip>;
        break;
      case SignupStatusID.Waitlist:
        return <Tooltip title="Waitlist">
          <PanToolIcon fontSize="medium" sx={styles.waitlist} />
        </Tooltip>;
        break;
      default:
    }
  }

  // #endregion

  // #region Event handlers

  /*
 * Handle closing of cancelled requests 
 */
  const handleCancelClose = () => {
    setCancelledDrawer(false);
  };

  /*
* Handle closing of request edit drawer
*/
  const handleEditClose = () => {
    setEditDrawer(false);
  };

  /*
 * Handler for help dialog window
 */
  const handleHelpClose = () => {
    setHelpOpen(false);
  }

  /*
   * Handle toggle of treeview node
   */
  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
  };

  /*
   * Handles action after selection of a signup
   */
  const handleNodeSelected = (signupID: number, mode: LodgeUseMode) => () => {

    setSignupID(signupID);

    if (isMobile) {
      switch (mode) {

        case LodgeUseMode.Cancelled:
          setCancelledDrawer(true);
          break;
        case LodgeUseMode.Edit:
          setEditDrawer(true);
          break;
        default:
      }

    } else {

    }
    setLodgeUseMode(mode);

  }

  /*
* Handle closing of past request drawer
*/
  const handlePastClose = () => {
    setPastDrawer(false);
  };

  React.useEffect(() => {
    fetchSignups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (guestCancelled) {
      fetchSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestCancelled]);

  React.useEffect(() => {
    if (guestSaved) {
      fetchSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestSaved]);

  React.useEffect(() => {
    if (memberCancelled) {
      fetchSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCancelled]);

  React.useEffect(() => {
    if (memberSaved) {
      fetchSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberSaved]);

  React.useEffect(() => {
    if (notSignedUpAdded) {
      fetchSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notSignedUpAdded]);

  React.useEffect(() => {
    if (signupCancelled) {
      setLodgeUseMode(LodgeUseMode.Summary);
      fetchSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupCancelled]);

  // #endregion

  return <>
    <Grid container spacing={2} sx={styles.root}>
      <Grid item xs={12} md={3} sx={!isMobile ? styles.requestsContainer : {}}>
        <Grid container item alignItems="center" sx={styles.heading}>
          <Grid item>
            <Typography variant="h6">My Requests</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="My Lodge Use help">
              <IconButton
                onClick={() => setHelpOpen(true)}
                size="large"
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container item>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expandedNodes}
            onNodeToggle={handleNodeToggle}
          >
            <TreeItem
              nodeId={NODEID_CURRENT}
              label={'Current (' + (signups.currentSignups && signups.currentSignups.length > 0 ? signups.currentSignups.length.toString() : 'None') + ')'}
            >
              {(signups.currentSignups && signups.currentSignups.length > 0) &&
                signups.currentSignups.map((signup: SignupHousehold) => (
                  <TreeItem
                    key={signup.signupID}
                    nodeId={signup.signupID.toString()}
                    label={`${signup.minArrivalDate} to ${signup.maxDepartureDate}`}
                    onClick={handleNodeSelected(signup.signupID, LodgeUseMode.Edit)}
                  />
                ))
              }
            </TreeItem>
            <TreeItem
              nodeId={NODEID_FUTURE}
              label={'Future (' + (signups.futureSignups && signups.futureSignups.length > 0 ? signups.futureSignups.length.toString() : 'None') + ')'}
            >
              {(signups.futureSignups && signups.futureSignups.length > 0) &&
                signups.futureSignups.map((signup: SignupHousehold) => (
                  <TreeItem
                    icon={renderStatusIcon(signup.signupStatusID)}
                    key={signup.signupID}
                    nodeId={signup.signupID.toString()}
                    label={signup.minArrivalDate + ' to ' + signup.maxDepartureDate}
                    onClick={handleNodeSelected(signup.signupID, LodgeUseMode.Edit)}
                  />
                ))
              }
            </TreeItem>
            <TreeItem
              nodeId={NODEID_PAST}
              label={'Past (' + (signups.pastSignups && signups.pastSignups.length > 0 ? signups.pastSignups.length.toString() : 'None') + ')'}
            >
              {(signups.pastSignups && signups.pastSignups.length > 0) &&
                signups.pastSignups.map((signup: SignupHousehold) => (
                  <TreeItem
                    key={signup.signupID}
                    nodeId={signup.signupID.toString()}
                    label={signup.minArrivalDate + ' to ' + signup.maxDepartureDate}
                    onClick={handleNodeSelected(signup.signupID, LodgeUseMode.Past)}
                  />
                ))
              }
            </TreeItem>
            <TreeItem
              nodeId={NODEID_CANCELLED}
              label={'Cancellations (' + (signups.cancelledSignups && signups.cancelledSignups.length > 0 ? signups.cancelledSignups.length.toString() : 'None') + ')'}
            >
              {(signups.cancelledSignups && signups.cancelledSignups.length > 0) &&
                signups.cancelledSignups.map((signup: SignupHousehold) => (
                  <TreeItem
                    key={signup.signupID}
                    nodeId={signup.signupID.toString()}
                    label={signup.minArrivalDate + ' to ' + signup.maxDepartureDate}
                    onClick={handleNodeSelected(signup.signupID, LodgeUseMode.Cancelled)}
                  />
                ))
              }
            </TreeItem>
          </TreeView>
        </Grid>
        {showWorking &&
          <Grid container item justifyContent="center" sx={styles.waiting}>
            <CircularProgress />
          </Grid>
        }
      </Grid>
      {!isMobile &&
        <Grid item md={9}>
          {(lodgeUseMode === LodgeUseMode.Cancelled) &&
            <CancelledSignup
              closeDrawer={handleCancelClose}
              signupID={signupID}
            />
          }
          {lodgeUseMode === LodgeUseMode.Edit &&
            <RequestEdit
              closeDrawer={handleEditClose}
              signupID={signupID}
            />
          }
          {lodgeUseMode === LodgeUseMode.Past &&
            <PastSignup
              closeDrawer={handleCancelClose}
              signupID={signupID}
            />
          }
          {(lodgeUseMode === LodgeUseMode.Summary) &&
            <MyLodgeUseHelp />
          }
        </Grid>
      }
    </Grid>
    <Drawer
      variant="temporary"
      anchor={'bottom'}
      open={cancelledDrawer}
      onClose={handleCancelClose}
      PaperProps={{ style: { height: '100%' } }}
    >
      <div style={styles.drawer}>
        {lodgeUseMode === LodgeUseMode.Cancelled &&
          <CancelledSignup
            closeDrawer={handleCancelClose}
            signupID={signupID}
          />}
      </div>
    </Drawer>
    <Drawer
      variant="temporary"
      anchor={'bottom'}
      open={editDrawer}
      onClose={handleEditClose}
      PaperProps={{ style: { height: '100%' } }}
    >
      <div style={styles.drawer}>
        <Grid item xs={12}>
          {(lodgeUseMode === LodgeUseMode.Cancelled) &&
            <CancelledSignup
              closeDrawer={handleCancelClose}
              signupID={signupID}
            />
          }
          {lodgeUseMode === LodgeUseMode.Edit &&
            <RequestEdit
              closeDrawer={handleEditClose}
              signupID={signupID}
            />
          }
          {lodgeUseMode === LodgeUseMode.Past &&
            <PastSignup
              closeDrawer={handleCancelClose}
              signupID={signupID}
            />
          }
          {(lodgeUseMode === LodgeUseMode.Summary) &&
            <MyLodgeUseHelp />
          }
        </Grid>
      </div>
    </Drawer>
    <Drawer
      variant="temporary"
      anchor={'bottom'}
      open={pastDrawer}
      onClose={handlePastClose}
      PaperProps={{ style: { height: '100%' } }}
    >
      <div style={styles.drawer}>
        {lodgeUseMode === LodgeUseMode.Past &&
          <PastSignup
            closeDrawer={handleCancelClose}
            signupID={signupID}
          />
        }
      </div>
    </Drawer>
    <MyLodgeUseHelpDialog
      show={helpOpen}
      handleClose={handleHelpClose}
    />

  </>;

}

export default MyLodgeUse;