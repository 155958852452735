// #region Imports

import React from 'react';

// Material-UI
import Grid from '@mui/material/Grid';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import MemberListAction from './MemberListAction';
import MemberListContactInfo from './MemberListContactInfo';
import MemberListHousehold from './MemberListHousehold';

import {
  HouseholdMode
} from '../global';

import {
  Member
} from '../interfaces';

// Responsive hooks
import { useResponsive } from '../hooks/UseResponsive';

// #endregion

// #region Interfaces

interface MemberListProps {
  handleHouseholdOpen: (id: number, view: HouseholdMode) => void;
  householdMemberIDs: Array<number>;
  members: Array<Member>;
}

// #endregion

const MemberList: React.FC<MemberListProps> = (props) => {

  const { isMobile } = useResponsive();

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1
      , borderTop: '1px solid #e0e0e0'
      , padding: theme.spacing(2, 0, 0, 0)
    },
    editIcon: {
      margin: theme.spacing(1, 0, 1, 0)
    },
    statusDisplay: {
      marginTop: theme.spacing(1)
    },
  };

  // #endregion

  // #region Methods

  const renderDesktopList = () => {

    return props.members.map((m: Member) => (
      <Grid container item spacing={0} key={m.memberID} sx={styles.root}>
        <Grid container item spacing={1}>
          <Grid item lg={1}>
            <MemberListAction handleHouseholdOpen={props.handleHouseholdOpen} householdMemberIDs={props.householdMemberIDs} member={m} />
          </Grid>
          <Grid item lg={3}>
            <MemberListContactInfo member={m} />
          </Grid>
          <Grid item lg={4}>
            <MemberListHousehold household={m.household} />
          </Grid>
        </Grid>
      </Grid>
    ))

  }

  const renderMobileList = () => {

    return props.members.map((m: Member) => (
      <Grid container item key={m.memberID} sx={styles.root}>
        <Grid container item spacing={1}>
          <Grid item sm={4}>
            <MemberListAction handleHouseholdOpen={props.handleHouseholdOpen} householdMemberIDs={props.householdMemberIDs} member={m} />
          </Grid>
          <Grid item sm={8}>
            <MemberListContactInfo member={m} />
            <MemberListHousehold household={m.household} />
          </Grid>
        </Grid>
      </Grid>
    ));

  }

  // #endregion

  // Return list in mobile or desktop format
  return (
    <Grid container item>
      {isMobile ? renderMobileList() : renderDesktopList()}
    </Grid>)
}

export default MemberList;
