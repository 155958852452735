// #region Imports

import React from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Components
import MemberAvatar from './MemberAvatar';

import {
  API_URL,
  NULL_ID
} from '../global';

import {
  HTTPHeaders
} from "../interfaces";

import {
  Member
} from '../models';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// #endregion

// #region Interfaces

interface HouseholdProps {
  memberSelected: (memberID: number) => void;
  memberID: number;
}

// #endregion

const HouseholdMembers: React.FC<HouseholdProps> = (props) => {

  const { getAccessToken } = useAuth0User();

  const [errors, setErrors] = React.useState(false);
  const [members, setMembers] = React.useState<Array<Member>>([]);
  // Store selected for intra-component functionality
  const [selectedMemberID, setSelectedMemberID] = React.useState<number>(-1);

  // #region Styles

  const styles = {
    selectedMember: {
      border: '1px solid black'
    },
    selectedTitle: {
      fontWeight: "bold"
    },
  };

  // #endregion

  // #region Methods

  const fetchHouseholdMembers = async (id: number) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/households/${id}/members`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Household members not retrieved');
        }
        return response.json()
      })
      .then(h => {
        setMembers(h.members);
      })
      .catch(err => setErrors(err));

  }

  // #endregion

  // #region Event handlers

  /*
   * Handler when a member is selected
   */
  const handleMemberSelected = (id: number) => {
    // Parent component
    props.memberSelected(id);
    setSelectedMemberID(id);
  }

  React.useEffect(() => {
    if (props.memberID !== NULL_ID) {
      fetchHouseholdMembers(props.memberID);
      setSelectedMemberID(props.memberID);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberID]);

  // #endregion

  return (
    <Grid container item sm={12} spacing={1}>
      {members.map((m: Member) => (
        <Grid item sm={2} key={m.memberID} sx={selectedMemberID === m.memberID ? styles.selectedMember : {}}>
          <Grid container item justifyContent="center">
            <IconButton onClick={() => handleMemberSelected(m.memberID)} size="large">
              <MemberAvatar member={m} />
            </IconButton>
          </Grid>
          <Grid container item justifyContent="center">
            <Typography variant="caption" sx={selectedMemberID === m.memberID ? styles.selectedTitle : {}}>{m.firstName}</Typography>
          </Grid>
        </Grid>
      ))
      }
    </Grid>
  );
}

export default HouseholdMembers;
