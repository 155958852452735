// #region imports

import React from 'react';

// Material-UI
import {
  Business
  , Home
  , PhoneIphone
} from '@mui/icons-material';

// Components
import {
  PhoneType
} from '../interfaces';

import {
  PhoneTypeID
} from '../global';

// #endregion

// #region interfaces

interface PhoneTypeIconProps {
  phoneType: PhoneType;
}

// #endregion

const PhoneTypeIcon: React.FC<PhoneTypeIconProps> = (props) => {

  // #region Methods

  /*
 * Icon for phone type
 */
  const phoneTypeIcon = () => {
    if (props.phoneType) {
      switch (props.phoneType.phoneTypeID) {
        case PhoneTypeID.Home:
          return <Home fontSize="small" />;
        case PhoneTypeID.Mobile:
          return <PhoneIphone fontSize="small" />;
        case PhoneTypeID.Work:
          return <Business fontSize="small" />
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  // #endregion

  return (
    phoneTypeIcon()
  );

}

export default PhoneTypeIcon;