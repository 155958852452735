// #region imports

import React from 'react';

// Material-UI
import Avatar from '@mui/material/Avatar';
import {
  amber
  , blue
  , blueGrey
  , brown
  , cyan
  , indigo
  , orange
  , purple
  , yellow
  , teal
} from '@mui/material/colors';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  MemberStatusType
} from '../global';

import {
  Member
} from '../interfaces';

// #endregion

// #region interfaces

interface MemberAvatarProps {
  member: Member;
}

// #endregion

const MemberAvatar: React.FC<MemberAvatarProps> = (props) => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    statusActive: {
      color: theme.palette.getContrastText(blue[500]),
      backgroundColor: blue[500],
    },
    statusApplicant: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: purple[500],
    },
    statusAssociate: {
      color: theme.palette.getContrastText(yellow[500]),
      backgroundColor: yellow[500],
    },
    statusChildOfActive: {
      color: theme.palette.getContrastText(blue[100]),
      backgroundColor: blue[100],
    },
    statusChildOfInactive: {
      color: theme.palette.getContrastText(teal[100]),
      backgroundColor: teal[100],
    },
    statusDeceased: {
      color: theme.palette.getContrastText(blueGrey[500]),
      backgroundColor: blueGrey[500],
    },
    statusHonorary: {
      color: theme.palette.getContrastText(indigo[500]),
      backgroundColor: indigo[500],
    },
    statusInactive: {
      color: theme.palette.getContrastText(teal[500]),
      backgroundColor: teal[500],
    },
    statusInactiveCouple: {
      color: theme.palette.getContrastText(teal[300]),
      backgroundColor: teal[500],
    },
    statusInactiveJunior: {
      color: theme.palette.getContrastText(teal[100]),
      backgroundColor: teal[100],
    },
    statusJunior: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
    },
    statusJuniorApplicant: {
      color: theme.palette.getContrastText(purple[100]),
      backgroundColor: purple[100],
    },
    statusLife: {
      color: theme.palette.getContrastText(amber[500]),
      backgroundColor: amber[500],
    },
    statusNonMember: {
      color: theme.palette.getContrastText(cyan[500]),
      backgroundColor: cyan[500],
    },
    statusPastMember: {
      color: theme.palette.getContrastText(brown[500]),
      backgroundColor: brown[500],
    },
    statusUnknown: {
      color: theme.palette.getContrastText(blueGrey[500]),
      backgroundColor: blueGrey[500],
    },
  };

  // #endregion

  // #region Methods

  /*
 * Returns avatar color for member status
 */
  const memberStatusColor = (status: number) => {

    switch (status) {
      case MemberStatusType.Active:
        return styles.statusActive;
      case MemberStatusType.Applicant:
        return styles.statusApplicant;
      case MemberStatusType.Associate:
        return styles.statusAssociate;
      case MemberStatusType.ChildofActive:
        return styles.statusChildOfActive;
      case MemberStatusType.ChildOfInactive:
        return styles.statusChildOfInactive;
      case MemberStatusType.Deceased:
        return styles.statusDeceased;
      case MemberStatusType.Honorary:
        return styles.statusHonorary;
      case MemberStatusType.Inactive:
        return styles.statusInactive;
      case MemberStatusType.InactiveCouple:
        return styles.statusInactiveCouple;
      case MemberStatusType.InactiveJunior:
        return styles.statusInactiveJunior;
      case MemberStatusType.Junior:
        return styles.statusJunior;
      case MemberStatusType.JuniorApplicant:
        return styles.statusJuniorApplicant;
      case MemberStatusType.Life:
        return styles.statusLife;
      case MemberStatusType.NonMember:
        return styles.statusNonMember;
      case MemberStatusType.PastMember:
        return styles.statusPastMember;
      default:
        return styles.statusUnknown;
    }
  }

  // #endregion

  // #region Event handlers
  // #endregion

  return (
    <Avatar sx={memberStatusColor(props.member.memberStatus.memberStatusID)}>{props.member.firstName.substr(0, 1) + props.member.lastName.substr(0, 1)}</Avatar>
  );

}

export default MemberAvatar;