// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import { amber, teal } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import {
  HouseholdRequestStatus
} from '../../models';

import {
  RequestMode
} from '../../global';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region interfaces

interface RequestModeOptionsProps {
  handleModeSelection: (mode: RequestMode) => void;
  requestStatus: HouseholdRequestStatus;
  nonweekend: boolean;
  offSeason: boolean;
  offSeasonStartDate: Date;
  postDeadline: boolean;
  weekend: boolean;
}

// #endregion


const RequestModeOptions: React.FC<RequestModeOptionsProps> = (props) => {

  const { dateFormatted, dateStringToLocalDateFormatted } = useDateFormatting();

  // #region Styles

  const theme = useTheme();
  const styles = {
    activeRequestsContainer: {
      margin: theme.spacing(1, 0, 1, 0),
    },
    infoText: {
      margin: theme.spacing(1, 0, 0, 0),
      width: '100%'
    },
    nonWeekendButton: {
      backgroundColor: teal[700]
    },
    optionContainer: {
      padding: theme.spacing(1, 0, 1, 0)
    },
    postDeadlineButton: {
      backgroundColor: amber[700]
    },
    postDeadlineContainer: {
      margin: theme.spacing(1, 0, 0, 0)
    },
    titleContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(1, 0, 2, 0),
      padding: theme.spacing(1, 0, 0, 0)
    }
  };

  // #endregion

  return (
    <>
      <Grid container>
        {(props.requestStatus.requestCount === props.requestStatus.maxRequests) &&
          <Grid container>
            <Grid container item>
              <Typography paragraph>{`Requests for ${props.requestStatus.maxRequests} priority periods (weekends/holidays) have been submitted. Once your next request stay is completed, a new request will automatically be available.`}</Typography>
            </Grid>
            <Grid container item>
              <Typography variant="caption" paragraph>Should you cancel one of your priority requests or be "bumped", a new request will be automatically available. Non-priority periods, e.g., mid-week, are not subject to the active request limit.</Typography>
            </Grid>
          </Grid>
        }
        {(props.requestStatus.requests.length > 0) &&
          <Grid container sx={styles.activeRequestsContainer}>
            <Grid container>
              <Typography variant="caption">{`Active Weekend/Holiday Requests (${props.requestStatus.requests.length}/${props.requestStatus.maxRequests})`}</Typography>
            </Grid>
            {props.requestStatus.requests.map(r => (
              <Grid container item spacing={2} key={r.signupID}>
                <Grid item>
                  <Typography variant="caption">{`#${r.referenceNumber}`}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">{`${r.minArrivalDate}-${r.maxDepartureDate}`}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">{`${r.priorityPeriods} Weekend/Holiday Request`}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        }
        {
          props.postDeadline &&
          <Grid container item sx={styles.postDeadlineContainer}>
            <Typography variant="caption">Post Deadline Request Available</Typography>
            <Typography variant="caption" paragraph>{`The ${dateStringToLocalDateFormatted(props.requestStatus.postDeadline.signupDeadline)} priority deadline for ${props.requestStatus.postDeadline.arrivalDate}-${props.requestStatus.postDeadline.departureDate} has passed. Post deadline requests can be submitted for available space first come, first serve, and will not count towards the active request limit.`}</Typography>
          </Grid>
        }
        <Grid container item sx={styles.titleContainer}>
          <Typography variant="body1">What type of lodge use request would you like to submit?</Typography>
        </Grid>
        {props.weekend &&
          <Grid item container sx={styles.optionContainer}>
            <Grid container item justifyContent="center">
              <Button variant="contained" onClick={() => props.handleModeSelection(RequestMode.WeekendHoliday)} color="primary">Weekend/Holiday</Button>
            </Grid>
            <Grid container item>
              <Typography variant="caption" sx={styles.infoText} textAlign="center">Can optionally include non-weekend/non-holiday nights</Typography>
            </Grid>
          </Grid>
        }
        {props.postDeadline &&
          <Grid item container sx={styles.optionContainer}>
            <Grid container item justifyContent="center">
              <Button variant="contained" onClick={() => props.handleModeSelection(RequestMode.PostDeadline)} sx={styles.postDeadlineButton}>Post-Priority Deadline</Button>
            </Grid>
            <Grid container item>
              <Typography variant="caption" sx={styles.infoText} textAlign="center">{`${props.requestStatus.postDeadline.arrivalDate}-${props.requestStatus.postDeadline.departureDate}`}</Typography>
            </Grid>
          </Grid>
        }
        {props.nonweekend &&
          <Grid item container sx={styles.optionContainer}>
            <Grid container item justifyContent="center">
              <Button variant="contained" onClick={() => props.handleModeSelection(RequestMode.NonWeekendHoliday)} sx={styles.nonWeekendButton}>Non-Weekend/Non-Holiday</Button>
            </Grid>
            <Grid container item textAlign="center">
              <Typography variant="caption" sx={styles.infoText}>Typically Sun-Thu nights</Typography>
            </Grid>
          </Grid>
        }
        {props.offSeason &&
          <Grid item container sx={styles.optionContainer}>
            <Grid container item justifyContent="center">
              <Button variant="outlined" onClick={() => props.handleModeSelection(RequestMode.OffSeason)} color="secondary">Off Season</Button>
            </Grid>
            <Grid container item textAlign="center">
              <Typography variant="caption" sx={styles.infoText}>{`Starting ${dateFormatted(props.offSeasonStartDate)}`}</Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    </>
  );
}

export default RequestModeOptions;