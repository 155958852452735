// #region Imports

import React from 'react';

// Material-UI
import AppBar from '@mui/material/AppBar';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import EmailQueueSummary from './EmailQueueSummary';

import {
  LodgeUseMemberRequest,
  LodgeUseStatusChange
} from '../../models';

import {
  LodgeUseAction
} from '../../global';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface PostDeadlineProps {
  refreshEmailQueue: boolean;
  handleStatusChange: (changes: LodgeUseStatusChange) => void;
  handleEmail: (ids: Array<number>) => void;
  requests: Array<LodgeUseMemberRequest>;
}

// #endregion

const PostDeadline: React.FC<PostDeadlineProps> = (props) => {

  const { dateFormatted, dateStringToLocalDateFormatted } = useDateFormatting();

  const [membersSelected, setMembersSelected] = React.useState<boolean>(false);
  const [statusType, setStatusType] = React.useState<LodgeUseAction>(LodgeUseAction.Confirm);
  const [postDeadlineSelected, setPostDeadlineSelected] = React.useState<Array<number>>([]);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    appBar: {
      margin: theme.spacing(0, 0, 1, 0)
    },
    gridContainer: {
      flexGrow: 1,
      height: 500
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    toolbarItem: {
      borderRight: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'referenceNumber',
        headerName: '#',
        width: 85,
        type: 'string',
        align: 'left',
      },
      {
        field: 'firstName',
        headerName: 'Name',
        width: 150,
        type: 'string',
        align: 'left',
        valueGetter: renderName
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 100,
        type: 'string',
        align: 'left',
      },
      {
        field: 'guestOf',
        headerName: 'Guest Of',
        width: 150,
        type: 'string',
        align: 'left',
        valueGetter: renderGuestOfName
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
        type: 'string',
        align: 'left'
      },
      {
        field: 'requestDate',
        headerName: 'Requested',
        width: 200,
        type: 'string',
        align: 'left',
        valueGetter: renderRequestDate
      },
      {
        field: 'waiverStatus',
        headerName: 'Waiver',
        width: 125,
        type: 'string',
        align: 'left'
      },
      {
        field: 'arrivalDate',
        headerName: 'Dates',
        width: 200,
        type: 'string',
        align: 'left',
        valueGetter: renderDates
      }
    ];

  }

  /*
* Overlay displayed when no data
*/
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No post deadline requests to display</div>
      </GridOverlay>
    );
  }

  /*
 * Render guest dates
 */
  const renderDates = (params: GridValueGetterParams) => {
    return `${dateFormatted(new Date(params.row.arrivalDate))}-${dateFormatted(new Date(params.row.departureDate))}`;
  }

  /*
   * Render guest of name
   */
  const renderGuestOfName = (params: GridValueGetterParams) => {
    return params.row.guestOf ? params.row.guestOf : 'n/a';
  }

  /*
   * Render guest name
   */
  const renderName = (params: GridValueGetterParams) => {
    return `${params.row.lastName}, ${params.row.firstName}-${params.row.gender}`;
  }

  /*
 * Render request date
 */
  const renderRequestDate = (params: GridValueGetterParams) => {
    return dateStringToLocalDateFormatted(params.row.requestDate);
  }

  // #endregion

  // #region Event handlers

  /*
   * Handle selected member and guest requests
   */
  const handleCheckboxSelected = (ids: Array<number>) => {
    setPostDeadlineSelected(ids);
    // Member ids > 0
    setMembersSelected(ids.filter(i => i > 0).length > 0);
  }

  /*
 * Handles email for members only
 */
  const handleEmail = () => {
    // Members id is > 0
    props.handleEmail(postDeadlineSelected.filter(i => i > 0));
  };

  /*
   * Handles status change
   */
  const handleStatusChange = () => {
    // Process selections
    const statusChange = new LodgeUseStatusChange();
    // Guest id is negative number so it can be differentiated
    const g = postDeadlineSelected.filter(g => g < 0).map(g => g * -1);
    statusChange.guestSignupIDs = (g.length > 0 ? g : []);
    // Members id is > 0
    const m = postDeadlineSelected.filter(i => i > 0);
    statusChange.memberSignupIDs = (m.length > 0 ? m : []);
    statusChange.signupStatusID = statusType;
    props.handleStatusChange(statusChange);

    // Clear selections
    setPostDeadlineSelected([]);
  };

  /*
   * Handles status type change
   */
  const handleStatusTypeChanged = (event: React.MouseEvent<HTMLElement>, action: LodgeUseAction) => {
    if (action !== null) {
      setStatusType(action);
    }
  };

  // #endregion

  return (
    <div style={styles.root}>
      <AppBar position="static" color="inherit" sx={styles.appBar}>
        <Toolbar>
          <ToggleButtonGroup
            disabled={props.requests.length === 0}
            value={statusType}
            exclusive
            onChange={handleStatusTypeChanged}
          >
            <ToggleButton value={LodgeUseAction.Pending}>
              <Tooltip title="Pending">
                <InfoIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Denied}>
              <Tooltip title="Deny">
                <BlockIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Waitlist}>
              <Tooltip title="Waitlist">
                <PanToolIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Confirm}>
              <Tooltip title="Confirm">
                <CheckCircleIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title="Approve the status change for selected individuals">
            <span>
              <IconButton
                disabled={(postDeadlineSelected.length === 0)}
                onClick={handleStatusChange}
                size="large"
                sx={styles.toolbarItem}
              >
                <PublishedWithChangesIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Send an email to the selected individuals">
            <span>
              <IconButton
                disabled={!membersSelected}
                onClick={handleEmail}
                size="large"
                sx={styles.toolbarItem}
              >
                <EmailIcon />
              </IconButton>
            </span>
          </Tooltip>
          <EmailQueueSummary refresh={props.refreshEmailQueue} />
        </Toolbar>
      </AppBar>
      <Box sx={styles.gridContainer}>
        <DataGrid
          checkboxSelection={true}
          columns={columns()}
          components={{
            NoRowsOverlay: noRowsOverlay
          }}
          density="compact"
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          getRowId={(row) => row.isGuest ? (row.id * -1) : row.id}
          hideFooter={true}
          rows={props.requests}
          onSelectionModelChange={(m: GridSelectionModel) => handleCheckboxSelected(m as Array<number>)}
          selectionModel={postDeadlineSelected}
        />
      </Box>
    </div >);

}

export default PostDeadline;