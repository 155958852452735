
import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Redux
import { useSelector } from "react-redux";

export interface AboutProps {
  open: boolean;
  onClose: () => void;
}
const About: React.FC<AboutProps> = (props) => {

  // Redux
  const copyright: string = useSelector((state: any) => state.app.copyright);
  const releaseDate: string = useSelector((state: any) => state.app.releaseDate);
  const version: string = useSelector((state: any) => state.app.version);

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={props.open} onClose={props.onClose}>
      <DialogTitle>About the MSC Website</DialogTitle>
      <DialogContent>
        <Grid container item>
          <Typography variant="body1" paragraph>{`Version ${version} (${releaseDate})`}</Typography>
          <Typography variant="body1" paragraph>The Montclair Ski Club website was created by the volunteers of the MSC website team.</Typography>
          <Typography variant="body1" paragraph>&copy;{copyright} Montclair Ski Club, Inc.</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default About;
