import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import FeedbackDetailDialog from './FeedbackDetailDialog'
import FeedbackFilter from './FeedbackFilter';
import FeedbackGrid from './FeedbackGrid'

// Redux
import { useDispatch, useSelector } from "react-redux";

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

import {
  HTTPHeaders
} from "../../interfaces";

import {
  DateRange,
  FeedbackItem
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  FeedbackView,
  NULL_ID,
  STANDARD_TIME_ZONE
} from '../../global';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

const Feedback: React.FC = () => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();
  const { timeStamp } = useDateFormatting();
  const dispatch = useDispatch();

  const [rows, setRows] = React.useState<Array<FeedbackItem>>([]);

  const [clearFlag, setClearFlag] = React.useState<boolean>(false);
  const [detailDialogOpen, setDetailDialogOpen] = React.useState<boolean>(false);
  const [feedback, setFeedback] = React.useState<FeedbackItem>(new FeedbackItem());
  const [feedbackView, setFeedbackView] = React.useState<FeedbackView>(FeedbackView.All);
  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [statusTime, setStatusTime] = React.useState<string>(EMPTY_STRING);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(1, 0, 1, 0),
    },
    filterContainer: {
      flexGrow: 1,
      padding: theme.spacing(2, 2, 2, 2),
    },
    gridContainer: {
      flexGrow: 1,
      height: 500
    },
  };

  // #endregion

  // #region Methods

  /*
* Clear feedback and filter
*/
  const clearFeedback = () => {
    setRows([]);
  }

  /*
 * Fetch feedback based upon filter
 */
  const fetchFeedback = async (filter: DateRange) => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // Always show current date forward
    const params = new URLSearchParams();
    const id = memberID();
    if (filter !== undefined) {
      params.append('memberID', id.toString());
      if (filter.startDate) {
        params.append('startDate', filter.startDate);
      }
      if (filter.endDate) {
        params.append('endDate', filter.endDate);
      }
    }

    const url = new URL(`${API_URL}/v1/admin/feedback`);
    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Feedback not retrieved');
        }
        return response.json()
      })
      .then((fb: Array<FeedbackItem>) => {
        setRows(fb);
        setStatusTime(timeStamp());
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setErrors(err);
      });
  }

  /*
* Requests based upon view
*/
  const filterFeedback = (): Array<FeedbackItem> => {

    switch (feedbackView) {
      case FeedbackView.All:
        return rows;
      case FeedbackView.BugReport:
        const a = rows.filter(f => f.feedbackType.feedbackTypeID == FeedbackView.BugReport);
        return a;
      case FeedbackView.FeatureRequest:
        const b = rows.filter(f => f.feedbackType.feedbackTypeID == FeedbackView.FeatureRequest);
        return b;
        return rows.filter(f => f.feedbackType.feedbackTypeID == FeedbackView.FeatureRequest);
      case FeedbackView.Question:
        return rows.filter(f => f.feedbackType.feedbackTypeID == FeedbackView.Question);
      case FeedbackView.Other:
        return rows.filter(f => f.feedbackType.feedbackTypeID == FeedbackView.Other);
      default:
        return rows;
    }

  }

  // #endregion

  // #region Event handlers

  /*
 * Handle clear of feedback components
 */
  const handleClear = () => {
    clearFeedback();
    setClearFlag(prevClear => !prevClear);
  }

  const handleDetailDialogClose = () => {
    setDetailDialogOpen(false);
  }

  /*
   * Handle changes to filter
   */
  const handleFilter = (filter: DateRange) => {
    if (filter.startDate && filter.endDate) {
      fetchFeedback(filter);
    }
  }

  /*
   * Handle refresh of feedback
   */
  const handleRefresh = (filter: DateRange) => {
    fetchFeedback(filter);
  }

  /*
 * Handle change in feedback view
 */
  const handleView = (view: FeedbackView) => {
    setFeedbackView(view);
  }

  const handleViewDetail = (id: number) => {
    const f = rows.find(f => f.feedbackID == id);
    if (f !== undefined) {
      setFeedback(f);
      setDetailDialogOpen(true);
    }
  }

  React.useEffect(() => {
    setFeedbackView(feedbackView);
  }, [feedbackView]);

  React.useEffect(() => {
    // Default to viewing all feedback
    setFeedbackView(FeedbackView.All);
  }, []);

  // #endregion

  return (
    <>
      <Box sx={styles.root}>
        <Grid container item spacing={1} sx={styles.filterContainer}>
          <Grid item xs={6}>
            <FeedbackFilter
              clearFilter={clearFlag}
              handleClear={handleClear}
              handleFilter={handleFilter}
              handleRefresh={handleRefresh}
              handleView={handleView}
            />
          </Grid>
        </Grid>
        <Grid container item sx={styles.gridContainer}>
          <FeedbackGrid
            handleViewDetail={handleViewDetail}
            isLoading={isLoading}
            rows={filterFeedback()}
            view={feedbackView}
          />
        </Grid>
      </Box>
      <FeedbackDetailDialog
        feedback={feedback}
        handleClose={handleDetailDialogClose}
        show={detailDialogOpen}
      />
    </>
  );

}

export default Feedback;