import {
  HOUSEHOLD_EDIT_SAVED,
  MEMBER_EDIT_SAVED,
  MEMBERSHIP_SEARCHTEXT_CLEAR,
  MEMBERSHIP_SEARCHTEXT_SET
} from "../ActionTypes";

const initialState = {
  householdEditSaved: false,
  memberEditSaved: false,
  searchText: null
};

const membership = (state: object = initialState, action: any) => {

  switch (action.type) {
    case HOUSEHOLD_EDIT_SAVED:
      return {
        ...state,
        householdEditSaved: action.payload
      };
    case MEMBER_EDIT_SAVED:
      return {
        ...state,
        memberEditSaved: action.payload
      };
    case MEMBERSHIP_SEARCHTEXT_CLEAR:
      return {
        ...state,
        searchText: null
      };
    case MEMBERSHIP_SEARCHTEXT_SET:
      return {
        ...state,
        searchText: action.payload
      };
    default: {
      return state;
    }
  }

}

export default membership;
