import { useSnackbar } from 'notistack';

// Auth0 hooks
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH0_CONFIG } from '../../Auth0Config';

import {
  AUTH0_MEMBERID_PROPERTY_NAME,
  AUTH0_ROLE_BETATESTER,
  AUTH0_ROLE_EMAILADMIN,
  AUTH0_ROLE_MEMBERSHIPADMIN,
  AUTH0_ROLES_PROPERTY_NAME,
  AUTH0_ROLE_SIGNUP_COORDINATOR,
  AUTH0_ROLE_SYSTEMADMIN,
  EMPTY_STRING,
  NULL_ID,
  AUTH0_ROLE_PASSADMIN
} from '../global';

interface UseAuth0UserInterface {
  getAccessToken: () => Promise<string>;
  isAuthenticated: boolean;
  isBetaTester: () => boolean;
  isEmailAdmin: () => boolean;
  isMembershipAdmin: () => boolean;
  isPassAdmin: ()=> boolean;
  isSignupCoordinator: () => boolean;
  isSystemAdmin: () => boolean;
  logout: () => void;
  memberID: () => number;
  roles: () => Array<string>;
  userName: () => string;
}

export const useAuth0User = (): UseAuth0UserInterface => {

  const { enqueueSnackbar } = useSnackbar();

  // Auth0 hooks
  const { getAccessTokenSilently, isAuthenticated, logout, user } = useAuth0();

  // #region Methods

  /*
 * Get access token for API
 */
  const getAccessToken = async () => {

    try {

      const token: string = await getAccessTokenSilently({
        audience: AUTH0_CONFIG.audience,
        redirect_uri: AUTH0_CONFIG.redirectUri
      });

      if ((typeof token !== 'undefined') && token) {
        return token;
      } else {
        enqueueSnackbar(`The authentication session has expired. Please login again.`,
          {
            preventDuplicate: true,
            variant: 'error'
          }
        );
        return EMPTY_STRING;
        //loginWithRedirect();
      }

    } catch (e) {
      enqueueSnackbar(`The authentication session has expired. Please login again.`,
        {
          preventDuplicate: true,
          variant: 'error'
        }
      );
      return EMPTY_STRING;
    }
  }

  /*
   * Get member ID of user
   * Use in component load
   */
  const memberID = (): number => {
    if (user) {
      return user[AUTH0_MEMBERID_PROPERTY_NAME] as number;
    } else {
      return NULL_ID;
    }
  }

  /*
 * Is user email admin?
 */
  const isBetaTester = (): boolean => {
    if (user) {
      const authRoles = user[AUTH0_ROLES_PROPERTY_NAME] as Array<string>;
      return authRoles.includes(AUTH0_ROLE_BETATESTER);
    } else {
      return false;
    }
  }

  /*
 * Is user email admin?
 */
  const isEmailAdmin = (): boolean => {
    if (user) {
      const authRoles = user[AUTH0_ROLES_PROPERTY_NAME] as Array<string>;
      return authRoles.includes(AUTH0_ROLE_EMAILADMIN);
    } else {
      return false;
    }
  }

  /*
 * Is user email admin?
 */
  const isMembershipAdmin = (): boolean => {
    if (user) {
      const authRoles = user[AUTH0_ROLES_PROPERTY_NAME] as Array<string>;
      return authRoles.includes(AUTH0_ROLE_MEMBERSHIPADMIN);
    } else {
      return false;
    }
  }

  /*
 * Is user transferable passes admin?
 */
  const isPassAdmin = (): boolean => {
    if (user) {
      const authRoles = user[AUTH0_ROLES_PROPERTY_NAME] as Array<string>;
      return authRoles.includes(AUTH0_ROLE_PASSADMIN);
    } else {
      return false;
    }
  }

  /*
 * Is user signup coordinator?
 */
  const isSignupCoordinator = (): boolean => {
    if (user) {
      const authRoles = user[AUTH0_ROLES_PROPERTY_NAME] as Array<string>;
      return authRoles.includes(AUTH0_ROLE_SIGNUP_COORDINATOR);
    } else {
      return false;
    }
  }

  /*
 * Is user system admin?
 */
  const isSystemAdmin = (): boolean => {
    if (user) {
      const authRoles = user[AUTH0_ROLES_PROPERTY_NAME] as Array<string>;
      return authRoles.includes(AUTH0_ROLE_SYSTEMADMIN);
    } else {
      return false;
    }
  }

  /*
* User roles
*/
  const roles = (): Array<string> => {
    if (user) {
      const authRoles = user[AUTH0_ROLES_PROPERTY_NAME] as Array<string>;
      return authRoles;
    } else {
      return [];
    }
  }

  /*
 * Auth0 user name
 */
  const userName = (): string => {
    if (user && user.name) {
      return user.name
    } else {
      return EMPTY_STRING;
    }
  }

  // #endregion

  return {
    getAccessToken,
    isAuthenticated,
    isBetaTester,
    isEmailAdmin,
    isMembershipAdmin,
    isPassAdmin,
    isSignupCoordinator,
    isSystemAdmin,
    logout,
    memberID,
    roles,
    userName
  };
}