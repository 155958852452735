// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region interfaces

interface RequestFormHelpDialogProps {
  handleClose: () => void;
  maxRequests: number;
  show: boolean;
}

// #endregion


const RequestFormHelpDialog: React.FC<RequestFormHelpDialogProps> = (props) => {

  const { isMobile } = useResponsive();

  // #region Styles
  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
    >
      <DialogTitle>New Lodge Use Requests</DialogTitle>
      <DialogContent>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>NOTE: Lodge stays are limited to 14 days.</Typography>
        <Typography variant="h6">Weekend/Holiday Requests & Limit</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Requests for up to {props.maxRequests} weekend/holidays can be active. When the limit is reached additional requests can be made for non-weekend/holidays only. An extended time period for the request could potentially span more than one weekend/holiday, e.g., two weekends. In this situation, each weekend/holiday counts towards the request limit.</Typography>
        <Typography variant="h6">Non-Weekend/Non-Holiday Requests</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>After the maximum weekend/holiday request limit has been reached, additional requests for non-weekend/non-holidays can be submitted which will not be counted towards the active weekend/holiday request limit.</Typography>
        <Typography variant="h6">Post Deadline Request</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>After the priority deadline (Sun 6 pm), requests can be submitted for the upcoming weekend first come, first serve, which will not count towards the active request limit.</Typography>
        <Typography variant="h6">Off-Season Request</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Off-season requests (5/1-11/30) can be made anytime during the year.</Typography>
        <Typography variant="h6">Members</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>By default all members in a household are selected for a new request. Deselect members if they are not to be included in the lodge use request. Members not included can be added later in My Lodge Use. Individuals living in the household that are not members, juniors, or children need to be entered as guests.</Typography>
        <Typography variant="h6">Guests</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Guests can be added to a signup request in My Lodge Use after creatng the request.</Typography>
        <Typography variant="h6" paragraph>Notes</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Some uses for Notes are indicating a work weekend, specifying no dinner, ask for a ride, offer a ride, or any other information. Notes are visible to all members.</Typography>
        <Typography variant="h6" paragraph>Transferable Passes</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>A maximum of two (2) transferable passes per day can be requested. Lodge managers have priority use of passes during weekends/holidays. All other times are on a first come, first served basis.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default RequestFormHelpDialog;