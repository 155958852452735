// #region imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { isValid as isDateValid } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  API_URL,
  EMPTY_STRING,
  NULL_DATETIME_STRING,
  NULL_DATETIME_TIMEZONE_STRING,
  NULL_ID
} from '../../global';

import {
  HTTPHeaders
} from "../../interfaces";

import {
  GuestSignup,
  WaiverStatus
} from '../../models';

import GuestTypeSelect from './GuestTypeSelect';
import WaiverStatusSelect from '../../shared/WaiverStatusSelect';

// Redux
import { useSelector } from "react-redux";

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// Signup date criteria
import { useSignupDates } from '../../hooks/UseSignupDates';

// #endregion

// #region Interfaces

interface GuestSignupEditProps {
  guestSignup: GuestSignup;
  handleClose: () => void;
  handleUpdate: (signup: GuestSignup) => void;
}

// #endregion

const GuestSignupEdit: React.FC<GuestSignupEditProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { enqueueSnackbar } = useSnackbar();
  const { dateFormatted } = useDateFormatting();
  const { calcMaxArrival, calcMaxDeparture, calcMinArrival, calcMinDeparture } = useSignupDates();
  const { dateStringToLocalDateFormatted } = useDateFormatting();

  const [errors, setErrors] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [signup, setSignup] = React.useState<GuestSignup>(new GuestSignup());
  const [maxArrival, setMaxArrival] = React.useState<string>(EMPTY_STRING);
  const [maxDeparture, setMaxDeparture] = React.useState<string>(EMPTY_STRING);
  const [minArrival, setMinArrival] = React.useState<string>(EMPTY_STRING);
  const [minDeparture, setMinDeparture] = React.useState<string>(EMPTY_STRING);
  const [waiverStatus, setWaiverStatus] = React.useState<Array<WaiverStatus>>([]);

  // Standardized dates from member in Redux
  const defaultDeparture: string = useSelector((state: any) => state.signups.maxDeparture);
  const defaultArrival: string = useSelector((state: any) => state.signups.minArrival);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
    actions: {
      margin: theme.spacing(3, 0, 2, 0)
    },
    rowSpacing: {
      margin: theme.spacing(2, 0, 2, 0)
    },
    requestDate: {
      margin: theme.spacing(2, 0, 0, 0)
    },
    updated: {
      margin: theme.spacing(2, 0, 0, 0)
    },
    updatedBy: {
      margin: theme.spacing(0)
    },
  };

  // #endregion

  // #region Methods

  /*
   * Add/update guest data in parent guest list
   */
  const updateGuestList = () => {
    props.handleUpdate(signup);
    setIsValid(false);
  }

  /*
   * Validate if all data is present
   */
  const validate = () => {

    if (
      (signup.arrivalDate !== NULL_DATETIME_STRING)
      && (signup.departureDate !== NULL_DATETIME_STRING)
      && (signup.firstName !== EMPTY_STRING)
      && (signup.lastName !== EMPTY_STRING)
      && (signup.guestType.guestTypeID !== NULL_ID)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  // #endregion

  // #region Event handlers

  /*
* Event handler for changes to arrival date
*/
  const handleArrivalDateChange = (date: Date | null) => {

    // Test valid date format
    if (!isDateValid(date)) {
      return;
    }

    const formattedDate = date ? dateFormatted(date) : EMPTY_STRING;

    if (formattedDate) {

      // Arrival must be today or greater
      const arrivalDate: Date = new Date(formattedDate);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (arrivalDate < today) {
        enqueueSnackbar(`Arrival date must be today or later`, { variant: 'warning' });
        return;
      }

      // Departure date exists
      if (signup.departureDate) {
        // Check if arrival >= departure date
        const arrivalDate: Date = new Date(formattedDate);
        const departureDate: Date = new Date(signup.departureDate);
        if (arrivalDate >= departureDate) {
          enqueueSnackbar(`Arrival date must be earlier than departure date`, { variant: 'warning' });
        } else {
          // Set new arrival date
          setSignup({
            ...signup,
            arrivalDate: formattedDate
          });
        }
      } else {
        // Departure date is empty, set +1
        const plusDate: Date = new Date(formattedDate);
        plusDate.setDate(plusDate.getDate() + 1);
        // Set new arrival and departure date
        setSignup({
          ...signup,
          arrivalDate: formattedDate,
          departureDate: plusDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
        });
      }
    } else {
      validate();
    }

  };

  /*
   * Event handler for changes to departure date
   */
  const handleDepartureDateChange = (date: Date | null) => {

    // Test valid date format
    if (!isDateValid(date)) {
      return;
    }

    const formattedDate = date ? dateFormatted(date) : EMPTY_STRING;

    if (formattedDate) {
      const departureDate: Date = new Date(formattedDate);

      // Departure must be tomorrow or greater
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const yesterday = new Date();
      yesterday.setHours(0, 0, 0, 0);
      yesterday.setDate(yesterday.getDate() - 1);

      if ((departureDate < today)) {
        enqueueSnackbar(`Departure date must be tomorrow or later`, { variant: 'warning' });
        return;
      }

      // Arrival date exists
      if (signup.arrivalDate) {
        // Check if arrival >= departure date
        const departureDate: Date = new Date(formattedDate);
        const arrivalDate: Date = new Date(signup.arrivalDate);
        if (departureDate <= arrivalDate) {
          enqueueSnackbar(`Departure date must be later than arrival date`, { variant: 'warning' });
        } else {
          // Set new departure date
          setSignup({
            ...signup,
            departureDate: formattedDate
          });
        }
      } else {
        // Arrival date is empty, set -1
        const minusDate: Date = new Date(formattedDate);
        minusDate.setDate(minusDate.getDate() - 1);
        setSignup({
          ...signup,
          arrivalDate: minusDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }),
          departureDate: formattedDate
        });
      }
    } else {
      validate();
    }

  };

  /*
   * Event handler for changes to guest first name
   */
  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignup({
      ...signup,
      firstName: event.target.value
    });
  }

  /*
   * Event handler for changes to guest type
   */
  const handleGuestTypeChange = (id: number, name: string) => {
    //// Clone guest type then change value
    const gt = { ...signup.guestType };
    gt.guestTypeID = id;
    gt.name = name;

    // Set cloned guest type
    setSignup({
      ...signup,
      guestType: gt
    });

  }

  /*
   * Event handler for changes to guest last name
   */
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignup({
      ...signup,
      lastName: event.target.value
    });
  }

  /*
   * Event handler for changes to priority guest
   */
  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSignup({
      ...signup,
      priorityGuest: event.target.checked
    });
  }

  React.useEffect(() => {
    // Load guest data
    if (!props.guestSignup.arrivalDate || !props.guestSignup.departureDate) {
      // Set default dates if not present
      const newSignup = { ...props.guestSignup };
      newSignup.arrivalDate = defaultArrival;
      newSignup.departureDate = defaultDeparture;
      setSignup(newSignup);
    } else {
      setSignup({ ...props.guestSignup });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.guestSignup]);

  React.useEffect(() => {
    if (signup.guestSignupID !== NULL_ID) {
      validate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup]);

  React.useEffect(() => {
    if (signup.departureDate) {
      setMaxArrival(calcMaxArrival(signup.departureDate));
      setMinArrival(calcMinArrival(signup.departureDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup.departureDate]);

  React.useEffect(() => {
    if (signup.arrivalDate) {
      setMaxDeparture(calcMaxDeparture(signup.arrivalDate));
      setMinDeparture(calcMinDeparture(signup.arrivalDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup.arrivalDate]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item alignItems="center">
        <Grid item>
          <IconButton onClick={props.handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid>
          <Typography variant="h6">Guest</Typography>
        </Grid>
      </Grid>
      <Grid container item sx={styles.rowSpacing}>
        <TextField
          label="First Name"
          value={signup.firstName}
          fullWidth
          required
          onChange={handleFirstNameChange}
          autoFocus
        />
      </Grid>
      <Grid container item sx={styles.rowSpacing}>
        <TextField
          label="Last Name"
          value={signup.lastName}
          fullWidth
          required
          onChange={handleLastNameChange}
        />
      </Grid>
      <Grid container item sx={styles.rowSpacing}>
        <GuestTypeSelect guestTypeID={signup.guestType.guestTypeID} guestTypeChanged={handleGuestTypeChange} />
      </Grid>
      <Grid container item sx={styles.rowSpacing}>
        <DatePicker
          disablePast
          inputFormat="MM/dd/yyyy"
          label="Arrival"
          minDate={minArrival ? new Date(minArrival) : undefined}
          maxDate={maxArrival ? new Date(maxArrival) : undefined}
          onChange={handleArrivalDateChange}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
          value={signup.arrivalDate}
          views={['month', 'day']}
        />
      </Grid>
      <Grid container item sx={styles.rowSpacing}>
        <DatePicker
          inputFormat="MM/dd/yyyy"
          label="Departure"
          minDate={minDeparture ? new Date(minDeparture) : undefined}
          maxDate={maxDeparture ? new Date(maxDeparture) : undefined}
          onChange={handleDepartureDateChange}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
          value={signup.departureDate}
          views={['month', 'day']}
        />
      </Grid>
      <Grid container item sx={styles.rowSpacing}>
        <FormControlLabel control={<Checkbox checked={signup.priorityGuest} onChange={handlePriorityChange} />} label="Priority Guest" />
      </Grid>
      <Grid container item justifyContent="center" sx={styles.actions}>
        <Tooltip title="Add or update guest in request">
          <span>
            <Button variant="contained" color="primary" disabled={isValid ? false : true} onClick={updateGuestList} >Save</Button>
          </span>
        </Tooltip>
      </Grid>
      {((signup.updated !== NULL_DATETIME_TIMEZONE_STRING) || signup.updatedBy || (signup.signupDate !== NULL_DATETIME_TIMEZONE_STRING)) &&
        <Divider />
      }
      {signup.updated !== NULL_DATETIME_TIMEZONE_STRING &&
        <Grid container item sx={styles.updated}>
          <Typography variant="overline">{`Updated: ${dateStringToLocalDateFormatted(signup.updated)}`}</Typography>
        </Grid>
      }
      {signup.updatedBy &&
        <Grid container item sx={styles.updatedBy}>
          <Typography variant="overline">{`By ${signup.updatedBy ? signup.updatedBy : 'n/a'}`}</Typography>
        </Grid>
      }
      {signup.signupDate !== NULL_DATETIME_TIMEZONE_STRING &&
        <Grid container item sx={styles.requestDate}>
          <Typography variant="overline">{`Requested: ${dateStringToLocalDateFormatted(signup.signupDate)}`}</Typography>
        </Grid>
      }
    </Box>
  );

}

export default GuestSignupEdit;