// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders
} from "../../interfaces";

import {
  FeedbackItem
} from '../../models';

import {
  API_URL,
  MATERIALUI_DIALOG_REASON_BACKDROPCLICK,
  MATERIALUI_DIALOG_REASON_ESCKEYDOWN,
  NULL_ID
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region interfaces

interface FeedbackDetailDialogProps {
  feedback: FeedbackItem;
  handleClose: () => void;
  show: boolean;
}

// #endregion

const FeedbackDetailDialog: React.FC<FeedbackDetailDialogProps> = (props) => {

  const { getAccessToken, memberID } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();

  const [errors, setErrors] = React.useState(false);
  //const [feedback, setFeedback] = React.useState<Feedback>(new Feedback());

  // #region Styles

  const theme = useTheme();
  const styles = {
    closeButton: {
      margin: theme.spacing(0, 1, 1, 0)
    },
    reported: {
      margin: theme.spacing(0, 0, 1, 0)
    },
    rowSpacing: {
      margin: theme.spacing(1, 0, 0, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
* Fetch feedback based upon filter
*/
  //const fetchFeedback = async () => {

  //  // Auth0 API token
  //  const accessToken = await getAccessToken();

  //  const headers: HTTPHeaders = {};
  //  headers['Content-Type'] = 'application/json';
  //  headers['Accept'] = 'application/json';
  //  headers['Authorization'] = `Bearer ${accessToken}`;

  //  const url = new URL(`${API_URL}/v1/admin/feedback/${props.feedbackID}`);

  //  await fetch(url.toString(), {
  //    method: 'GET'
  //    , cache: 'no-cache'
  //    , headers: headers
  //    , mode: 'cors'
  //  })
  //    .then((response) => {
  //      if (!response.ok) {
  //        throw new Error('Feedback not retrieved');
  //      }
  //      return response.json()
  //    })
  //    .then((fb: Feedback) => {
  //      setFeedback(fb);
  //    })
  //    .catch(err => {
  //      setErrors(err);
  //    });
  //}

  // #endregion

  // #region Event handlers

  /*
* Event handler for Material-UI onClose
*/
  const handleClose = (event: object, reason: string) => {
    if ((reason === MATERIALUI_DIALOG_REASON_BACKDROPCLICK) || (reason === MATERIALUI_DIALOG_REASON_ESCKEYDOWN)) {
      return;
    }
  };

  React.useEffect(() => {
  //  if (props.feedback.feedbackID !== NULL_ID) {
  //    fetchFeedback();
  //  }
  }, [props.feedback.feedbackID]);

  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>{props.feedback.title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item sx={styles.reported}>
            <Typography variant="body1">{`Reported: ${dateStringToLocalDateFormatted(props.feedback.reported)} by: ${props.feedback.reportedBy}`}</Typography>
          </Grid>
          <Grid container item>
            <Typography variant="button">Description</Typography>
          </Grid>
          <Grid container item>
            <Typography variant="body1"><span dangerouslySetInnerHTML={{ __html: props.feedback.description }}></span></Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="outlined" color="primary" sx={styles.closeButton}>Close</Button>
      </DialogActions>
    </Dialog >
  );

}

export default FeedbackDetailDialog;