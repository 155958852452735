// #region imports

import React from 'react';
import {
  addDays,
  differenceInCalendarDays,
  format,
  isValid as isDateValid,
  subDays
} from 'date-fns';

// Material-UI
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  DateRange
} from '../models';

import {
  DateFilterYear,
  EMPTY_STRING
} from '../global';

// Signup hooks
import { useSignupDates } from '../hooks/UseSignupDates';
import { useSystem } from '../hooks/UseSystem';

// #endregion

// #region interfaces

interface DateRangeFilterMobileProps {
  clearDateRange: boolean;
  dateRange: DateRange; // Set date range from parent
  disabled: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  filterYear: DateFilterYear;
  handleChange: (range: DateRange) => void;
  isArrivalDeparture: boolean; // Component labels
  maxInterval?: number;
}

// #endregion

const DateRangeFilterMobile: React.FC<DateRangeFilterMobileProps> = (props) => {

  const { getRequestYearEndDate, getRequestYearStartDate } = useSignupDates();
  const { getFiscalYearEndDate, getFiscalYearStartDate } = useSystem();

  const [maxEnd, setMaxEnd] = React.useState<string>(EMPTY_STRING);
  const [minEnd, setMinEnd] = React.useState<string>(EMPTY_STRING);
  const [maxStart, setMaxStart] = React.useState<string>(EMPTY_STRING);
  const [minStart, setMinStart] = React.useState<string>(EMPTY_STRING);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(0, 0, 0, 0),
    },
    datePicker: {
      width: 50
    }
  };

  // #endregion

  // #region Methods

  /*
*  Clear date range filter
*/
  const clearDateRange = (): void => {
    setDefaultMinMax();
  };

  /*
   * Set default min and max arrival/departure dates
   */
  const setDefaultMinMax = () => {
    if (props.filterYear === DateFilterYear.RequestYear) {
      setMinStart(getRequestYearStartDate());
      setMaxStart(getRequestYearEndDate());
      setMinEnd(getRequestYearStartDate());
      setMaxEnd(getRequestYearEndDate());
    } else {
      setMinStart(getFiscalYearStartDate());
      setMaxStart(getFiscalYearEndDate());
      setMinEnd(getFiscalYearStartDate());
      setMaxEnd(getFiscalYearEndDate());
    }
  }

  // #endregion

  // #region Event handlers

  /*
  * Handle change in end date
  */
  const handleEndDateChange = (date: Date | null): void => {

    // Test valid date format
    if (!isDateValid(date)) {
      // Resent max start date to default
      if (props.filterYear === DateFilterYear.RequestYear) {
        setMaxStart(getRequestYearEndDate());
      } else {
        setMaxStart(getFiscalYearEndDate());
      }
      return;
    } else if (date) {

      props.handleChange({
        ...props.dateRange,
        endDate: format(date, 'MM/dd/y')
      });

      // If selection interval is limited
      if (props.maxInterval) {
        const start = (props.filterYear === DateFilterYear.FiscalYear ? new Date(getFiscalYearStartDate()) : new Date(getRequestYearStartDate()));

        if (props.disablePast && (differenceInCalendarDays(start, date) > props.maxInterval)) {
          setMaxStart(format(start, 'MM/d/y'));
          setMinStart(format(start, 'MM/d/y'));
        } else {
          setMaxStart(format(subDays(date, 1), 'MM/dd/y'));
          setMinStart(format(subDays(date, props.maxInterval), 'MM/dd/y'));
        }
      }
    }

  };

  /*
   * Handle change in start date
   */
  const handleStartDateChange = (date: Date | null): void => {

    // Test valid date format
    if (!isDateValid(date)) {
      // Reset min end date to default
      if (props.filterYear === DateFilterYear.RequestYear) {
        setMinEnd(getRequestYearStartDate());
      } else {
        setMinEnd(getFiscalYearStartDate());
      }
      return;
    } else if (date) {

      props.handleChange({
        ...props.dateRange,
        startDate: format(date, 'MM/dd/y')
      });

      // If selection interval is limited
      if (props.maxInterval) {
        const today = new Date();

        // Selected date is today
        if (props.disableFuture && (differenceInCalendarDays(date, today) > 0)) {
          setMaxEnd(format(today, 'MM/d/y'));
          setMinEnd(format(today, 'MM/d/y'));
        } else {
          setMaxEnd(format(addDays(date, props.maxInterval), 'MM/dd/y'));
          setMinEnd(format(addDays(date, 1), 'MM/dd/y'));
        }
      }
    }

  };

  React.useEffect(() => {
    setDefaultMinMax();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.maxInterval
      && props.dateRange
      && (props.dateRange.startDate && isDateValid(new Date(props.dateRange.startDate)))
      && (props.dateRange.endDate && isDateValid(new Date(props.dateRange.endDate)))
    ) {

      // Set calendar min/max selection
      const start = new Date(props.dateRange.startDate);
      // Display night based so subtract 1 from end date
      const end = new Date(props.dateRange.endDate);

      setMaxStart(format(subDays(end, 1), 'MM/dd/y'));
      setMinStart(format(subDays(end, props.maxInterval), 'MM/dd/y'));
      setMaxEnd(format(addDays(start, props.maxInterval), 'MM/dd/y'));
      setMinEnd(format(addDays(start, 1), 'MM/dd/y'));
    }

  }, [props.dateRange.startDate, props.dateRange.endDate]);

  React.useEffect(() => {
    clearDateRange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clearDateRange]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <DatePicker
            disabled={props.disabled}
            disableFuture={props.disableFuture ? props.disableFuture : false}
            disablePast={props.disablePast ? props.disablePast : false}
            inputFormat="MM/dd/yyyy"
            label={`${props.isArrivalDeparture ? 'Arrive' : 'Start'}`}
            minDate={minStart ? new Date(minStart) : undefined}
            maxDate={maxStart ? new Date(maxStart) : undefined}
            onChange={handleStartDateChange}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
            value={props.dateRange.startDate === EMPTY_STRING ? null : new Date(props.dateRange.startDate)}
            views={['month', 'day']}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            defaultCalendarMonth={props.dateRange.startDate ? new Date(props.dateRange.startDate) : undefined}
            disabled={props.disabled}
            disableFuture={props.disableFuture ? props.disableFuture : false}
            disablePast={props.disablePast ? props.disablePast : false}
            inputFormat="MM/dd/yyyy"
            label={`${props.isArrivalDeparture ? 'Depart' : 'End'}`}
            minDate={minEnd ? new Date(minEnd) : undefined}
            maxDate={maxEnd ? new Date(maxEnd) : undefined}
            onChange={handleEndDateChange}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
            value={props.dateRange.endDate === EMPTY_STRING ? null : new Date(props.dateRange.endDate)}
            views={['month', 'day']}
          />
        </Grid>
      </Grid>
    </Box>
  );

}

export default DateRangeFilterMobile;