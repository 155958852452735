// #region imports

import React from 'react';

// Material-UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Redux
import { useSelector } from "react-redux";

// Components
import {
  GuestSignup,
  HTTPHeaders,
  MemberSignup
} from '../../interfaces';

import {
  API_URL,
  NULL_ID
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface SignupCancelledListProps {
  signupID: number;
}

// #endregion

const SignupsCancelledList: React.FC<SignupCancelledListProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();

  const [errors, setErrors] = React.useState<boolean>(false);
  const [guestSignupsCancelled, setGuestSignupsCancelled] = React.useState<Array<GuestSignup>>([]);
  const [memberSignupsCancelled, setMemberSignupsCancelled] = React.useState<Array<MemberSignup>>([]);

  // Redux
  const guestCancelled: boolean = useSelector((state: any) => state.signups.guestCancelled);
  const memberCancelled: boolean = useSelector((state: any) => state.signups.memberCancelled);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(0, 0, 1, 0)
    },
    sectionHeading: {
      margin: theme.spacing(2, 0, 1, 0)
    },
    sectionSubHeading: {
      margin: theme.spacing(1, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
  * Fetch member signups cancelled
  */
  const fetchMemberSignups = async () => {

    // Don't fetch for NULL_ID
    if (props.signupID === NULL_ID) {
      return;
    }

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/${props.signupID}/members/cancelled`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Member signups cancelled not retrieved');
        }
        return response.json()
      })
      .then((signups: Array<MemberSignup>) => {
        setMemberSignupsCancelled(signups);
      })
      .catch(err => setErrors(err));
  }

  /*
  * Fetch guest signups cancelled
  */
  const fetchGuestSignups = async () => {

    // Don't fetch for NULL_ID
    if (props.signupID == NULL_ID) {
      return;
    }

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/${props.signupID}/guests/cancelled`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Guest signups cancelled not retrieved');
        }
        return response.json()
      })
      .then((signups: Array<GuestSignup>) => {
        setGuestSignupsCancelled(signups);
      })
      .catch(err => setErrors(err));
  }

  // #endregion

  // #region Event handlers

  React.useEffect(() => {
    if (guestCancelled) {
      fetchGuestSignups();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestCancelled]);

  React.useEffect(() => {
    if (memberCancelled) {
      fetchMemberSignups();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCancelled]);

  React.useEffect(() => {
    fetchMemberSignups();
    fetchGuestSignups();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.signupID]);

  // #endregion

  return (
    <>
      {
        ((memberSignupsCancelled.length > 0) || (guestSignupsCancelled.length > 0)) &&
        <Grid container item sx={styles.sectionHeading}>
          <Typography variant="button">Cancellations</Typography>
        </Grid>
      }
      {
        memberSignupsCancelled.length > 0 &&
        <>
          <Typography variant="body1" sx={styles.sectionSubHeading}>Members</Typography>
          {memberSignupsCancelled.map((ms: MemberSignup, index: number) => (
            <Grid container item key={ms.memberSignupID} sx={styles.root}>
              <Grid item xs={6} md={2}>
                <Typography>{`${ms.firstName} ${ms.lastName}`}</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>{ms.memberStatus}</Typography>
              </Grid>
              <Grid item>
                <Typography>{`Cancelled: ${dateStringToLocalDateFormatted(ms.cancelDate)}`}</Typography>
              </Grid>
            </Grid>
          ))
          }
        </>
      }
      {
        guestSignupsCancelled.length > 0 &&
        <>
          <Typography variant="body1" sx={styles.sectionSubHeading}>Guests</Typography>
          {guestSignupsCancelled.map((gs: GuestSignup, index: number) => (
            <Grid container item key={gs.guestSignupID} sx={styles.root}>
              <Grid item xs={6} md={2}>
                <Typography>{`${gs.firstName} ${gs.lastName}`}</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>{gs.guestType ? gs.guestType.name : 'Unknown'}</Typography>
              </Grid>
              <Grid item>
                <Typography>{`Cancelled: ${dateStringToLocalDateFormatted(gs.cancelDate)}`}</Typography>
              </Grid>
            </Grid>
          ))
          }
        </>
      }
    </>
  )

}

export default SignupsCancelledList;