import {
  getYear,
  isValid as isDateValid
} from 'date-fns';

import {
  format,
  utcToZonedTime
} from 'date-fns-tz';

import {
  EMPTY_STRING,
  NULL_DATETIME_YEAR,
  STANDARD_TIME_ZONE
} from '../global';

interface UseDateFormattingInterface {
  dateFormatted: (date: Date, formatString?: string) => string;
  dateStringFormatted: (date: string, formatString?: string) => string;
  dateToLocalDate: (date: Date, useLocalTimeZone?: boolean) => Date | null;
  dateToLocalDateFormatted: (date: Date, formatString?: string, useLocalTimeZone?: boolean) => string;
  dateStringToLocalDateFormatted: (date: string, formatString?: string, useLocalTimeZone?: boolean) => string;
  isNullDate: (date: string) => boolean;
  timeStamp: (formatString?: string) => string;
}

export const useDateFormatting = (): UseDateFormattingInterface => {

  /*
 * Converts a date as a formatted string MM/dd/yyyy
 */
  const dateFormatted = (date: Date, formatString?: string): string => {
    if (!isDateValid(date)) {
      return EMPTY_STRING;
    }

    const f = formatString ? formatString : 'MM/dd/yyyy';
    return format(date, f);
  }

  /*
 * Converts a date as a formatted string MM/dd/yyyy
 */
  const dateStringFormatted = (date: string, formatString?: string): string => {
    if (!isDateValid(new Date(date))) {
      return EMPTY_STRING;
    }

    const f = formatString ? formatString : 'MM/dd/yyyy';
    return format(new Date(date), f);
  }

  /*
   * Converts a date to a date based upon the local timezone
   */
  const dateToLocalDate = (date: Date, useLocalTimeZone?: boolean): Date | null => {
    if (!isDateValid(date)) {
      return null;
    } else {
      const timeZone = useLocalTimeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : STANDARD_TIME_ZONE;
      return utcToZonedTime(date, timeZone);
    }
  }

  /*
   * Converts a date to the local timezone and returns it as a formatted string
   */
  const dateToLocalDateFormatted = (date: Date, formatString?: string, useLocalTimeZone?: boolean): string => {
    if (!isDateValid(date)) {
      return EMPTY_STRING;
    } else {
      const localDate = dateToLocalDate(date, useLocalTimeZone);
      if (localDate) {
        const timeZone = useLocalTimeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : STANDARD_TIME_ZONE;
        return format(localDate, (formatString ? formatString : 'MM/dd/yyyy HH:mm:ss zzz'), { timeZone: timeZone });
      } else {
        return EMPTY_STRING;
      }
    }
  }

  /*
   * Converts a date string to the local timezone and returns it as a formatted string
   */
  const dateStringToLocalDateFormatted = (date: string, formatString?: string, useLocalTimeZone?: boolean): string => {
    if (!isDateValid(new Date(date))) {
      return EMPTY_STRING;
    } else {
      return dateToLocalDateFormatted(new Date(date), formatString, useLocalTimeZone);
    }
  }

  /*
   * Determines if a date is NULL by checking the year
   */
  const isNullDate = (date: string): boolean => {
    if (!isDateValid(new Date(date))) {
      return false;
    }

    // Year 1 is NULL
    return (new Date(date).getUTCFullYear() == NULL_DATETIME_YEAR);
  }

  /*
   * Current time stamp
   */
  const timeStamp = (formatString?: string) => {
    return dateToLocalDateFormatted(new Date(), formatString ? formatString : EMPTY_STRING);
  }

  return { dateFormatted, dateStringFormatted, dateToLocalDate, dateToLocalDateFormatted, dateStringToLocalDateFormatted, isNullDate, timeStamp };
}