// #region imports

import React from 'react';
import {
  format,
  subDays
} from 'date-fns';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  DateRange,
} from '../../models';

import {
  DateFilterYear,
  FEEDBACK_FILTER_MAX_INTERVAL,
  FeedbackView,
} from '../../global';

import DateRangeFilter from '../../shared/DateRangeFilter';
// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// System hooks
import { useSystem } from '../../hooks/UseSystem';

// #endregion

// #region interfaces

interface FeedbackFilterProps {
  clearFilter: boolean;
  handleClear: () => void;
  handleFilter: (filter: DateRange) => void;
  handleRefresh: (filter: DateRange) => void;
  handleView: (view: FeedbackView) => void;
}

// #endregion

const FeedbackFilter: React.FC<FeedbackFilterProps> = (props) => {

  const { isMobile } = useResponsive();
  const { getFiscalYearEndDate, getFiscalYearStartDate } = useSystem();

  /*
 * Default date range of the last week
 */
  const defaultDateRange = () => {
    const d = new DateRange(format(subDays(new Date(), 7), 'MM/dd/yyyy'), format(new Date(), 'MM/dd/yyyy'));
    return d;
  }

  const [dateRange, setDateRange] = React.useState<DateRange>(defaultDateRange());
  const [disablefilter, setDisableFilter] = React.useState<boolean>(true);
  const [view, setView] = React.useState<FeedbackView>(FeedbackView.All);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    datesContainer: {
      padding: theme.spacing(2, 0, 1, 0),
    },
    selectWidth: {
      flexGrow: 1,
      minWidth: 150,
    },
    selectContainer: {
      padding: theme.spacing(2, 0, 0, 0),
    },
  };

  // #endregion

  // #region Methods

  /*
 * Clear filter
 */
  const clearFilter = () => {
    setDateRange(defaultDateRange());
    setView(FeedbackView.All);
  }

  // #endregion

  // #region Event handlers

  /*
 * Handle changes to date filter
 */
  const handleFilter = (filter: DateRange) => {
    setDateRange(filter);
  }

  /*
 * Handle refresh
 */
  const handleRefresh = () => {
    props.handleRefresh(dateRange);
  }

  /*
   * Handle change in requests view
   */
  const handleViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Convert value to number
    const view = Number(event.target.value); //FeedbackView;
    setView(view);
    props.handleView(view);
  }

  React.useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      props.handleFilter(dateRange);
      setDisableFilter(false);
    } else {
      setDisableFilter(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.startDate, dateRange.endDate]);

  React.useEffect(() => {
    clearFilter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clearFilter]);

  // #endregion

  return (
    <Box borderRight={1} sx={styles.root}>
      <Grid container item spacing={3} sx={styles.selectContainer}>
        <Grid item>
          <TextField
            select
            label='View'
            sx={styles.selectWidth}
            onChange={handleViewChange}
            value={view.toString()}
          >
            <MenuItem key={FeedbackView.All} value={FeedbackView.All}>All</MenuItem>
            <MenuItem key={FeedbackView.BugReport} value={FeedbackView.BugReport}>Bug Report</MenuItem>
            <MenuItem key={FeedbackView.FeatureRequest} value={FeedbackView.FeatureRequest}>Feature Request</MenuItem>
            <MenuItem key={FeedbackView.Question} value={FeedbackView.Question}>Question</MenuItem>
            <MenuItem key={FeedbackView.Other} value={FeedbackView.Other}>Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <Tooltip title="Clear filter and feedback">
            <span>
              <Button variant="outlined" color="primary" disabled={disablefilter} onClick={props.handleClear}>
                <ClearIcon />
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Refresh the feedback">
            <span>
              <Button variant="outlined" color="primary" disabled={disablefilter} onClick={handleRefresh}>
                <RefreshIcon />
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container item spacing={0} alignItems="center" sx={styles.datesContainer}>
        <DateRangeFilter
          clearDateRange={props.clearFilter}
          disabled={false}
          disableFuture={true}
          dateRange={dateRange}
          filterYear={DateFilterYear.FiscalYear}
          handleChange={handleFilter}
          isArrivalDeparture={false}
          maxInterval={FEEDBACK_FILTER_MAX_INTERVAL}
        />
      </Grid>
    </Box>
  );

}

export default FeedbackFilter;