import {
  HOUSEHOLD_EDIT_SAVED,
  MEMBER_EDIT_SAVED,
  MEMBERSHIP_SEARCHTEXT_CLEAR,
  MEMBERSHIP_SEARCHTEXT_SET
} from "../ActionTypes";

export const clearMembershipSearchText = (search: string) => ({
  type: MEMBERSHIP_SEARCHTEXT_CLEAR,
  payload: search
});

export const housedholdEditSavedReset = () => ({
  type: HOUSEHOLD_EDIT_SAVED,
  payload: false
});

export const memberEditSavedReset = () => ({
  type: MEMBER_EDIT_SAVED,
  payload: false
});

export const setMembershipSearchText = (search: string) => ({
  type: MEMBERSHIP_SEARCHTEXT_SET,
  payload: search
});

