// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// #endregion

const BetaAccess: React.FC = () => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      margin: theme.spacing(5, 0, 0, 0)
    },
    betaMessage: {
      margin: theme.spacing(4, 0, 0, 0)
    },
  };

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item justifyContent="center">
        <img src="images/msc_logo_150x150.jpg" width="150" height="150" alt="Montclair Ski Club" />
      </Grid>
      <Grid container item justifyContent="center">
        <Typography gutterBottom variant="h5">Montclair Ski Club</Typography>
      </Grid>
      <Grid container item justifyContent="center" >
        <Typography variant="body1" color="textSecondary">Since 1947</Typography>
      </Grid>
      <Grid container item justifyContent="center" sx={styles.betaMessage} >
        <Typography variant="body1" color="textPrimary">Only approved beta testers can access this site. Please contact the website team for access.</Typography>
      </Grid>
    </Box>
  );
}

export default BetaAccess;

