// #region imports

import React from 'react';

// Material-UI
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  ResourceBreadcrumb
} from '../interfaces';
import { Typography } from '@mui/material';

// #endregion

// #region interfaces

interface ResourceBreadcrumbsProps {
  breadcrumbSelected: (rbc: ResourceBreadcrumb) => void;
  resourceBreadcumbs: Array<ResourceBreadcrumb>;
}

// #endregion

const ResourceBreadcrumbs: React.FC<ResourceBreadcrumbsProps> = (props) => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      margin: theme.spacing(2, 0, 2, 2)
    }
  };

  // #endregion

  return (
    <>
      <Breadcrumbs sx={styles.root}>
        {
          props.resourceBreadcumbs.map((rbc: ResourceBreadcrumb, i: number) => {
            return i < (props.resourceBreadcumbs.length - 1) ?
              <Link key={rbc.id} onClick={() => props.breadcrumbSelected(rbc)}>
                {rbc.name}
              </Link>
              :
              <Typography key={rbc.id} color="textPrimary">{rbc.name}</Typography>
          })
        }
      </Breadcrumbs>
    </ >
  );

}

export default ResourceBreadcrumbs;