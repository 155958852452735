// #region imports

import React from 'react';

import { isValid as isDateValid } from 'date-fns';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  EMPTY_STRING,
  NULL_DATETIME_STRING,
  NULL_ID
} from '../../global';

import {
  MemberNotSignedUp
} from '../../models';

// Redux
import { useSelector } from "react-redux";

// UTC to local date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// Signup date criteria
import { useSignupDates } from '../../hooks/UseSignupDates';

// #endregion

// #region Interfaces

interface NotSignedUpEditProps {
  memberSignup: MemberNotSignedUp;
  handleClose: () => void;
  handleUpdate: (signup: MemberNotSignedUp) => void;
}

// #endregion

const NotSignedUpEdit: React.FC<NotSignedUpEditProps> = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const { dateFormatted } = useDateFormatting();

  const [isValid, setIsValid] = React.useState(false);
  const [signup, setSignup] = React.useState<MemberNotSignedUp>(new MemberNotSignedUp());
  const { calcMaxArrival, calcMaxDeparture, calcMinArrival, calcMinDeparture } = useSignupDates();

  const [maxArrival, setMaxArrival] = React.useState<string>(EMPTY_STRING);
  const [maxDeparture, setMaxDeparture] = React.useState<string>(EMPTY_STRING);
  const [minArrival, setMinArrival] = React.useState<string>(EMPTY_STRING);
  const [minDeparture, setMinDeparture] = React.useState<string>(EMPTY_STRING);

  // Standardized dates from member in Redux
  const defaultDeparture: string = useSelector((state: any) => state.signups.maxDeparture);
  const defaultArrival: string = useSelector((state: any) => state.signups.minArrival);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
    actions: {
      margin: theme.spacing(3, 0, 2, 0)
    },
    entryField: {
      margin: theme.spacing(2, 0, 2, 0)
    },
    memberInfo: {
      margin: theme.spacing(2, 0, 0, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
   * Add/update member data in parent member list
   */
  const updateMemberList = () => {
    props.handleUpdate(signup);
    setIsValid(false);
  }

  /*
   * Validate if all data is present
   */
  const validate = () => {

    if (
      (signup.arrivalDate !== NULL_DATETIME_STRING)
      && (signup.departureDate !== NULL_DATETIME_STRING)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  // #endregion

  // #region Event handlers

  /*
* Event handler for changes to arrival date
*/
  const handleArrivalDateChange = (date: Date | null) => {

    // Test valid date format
    if (!isDateValid(date)) {
      return;
    }

    const formattedDate = date ? dateFormatted(date) : EMPTY_STRING;

    if (formattedDate) {

      // Arrival must be today or greater
      const arrivalDate: Date = new Date(formattedDate);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (arrivalDate < today) {
        enqueueSnackbar(`Arrival date must be today or later`, { variant: 'warning' });
        return;
      }

      // Departure date exists
      if (signup.departureDate) {
        // Check if arrival >= departure date
        const arrivalDate: Date = new Date(formattedDate);
        const departureDate: Date = new Date(signup.departureDate);
        if (arrivalDate >= departureDate) {
          enqueueSnackbar(`Arrival date must be earlier than departure date`, { variant: 'warning' });
        } else {
          // Set new arrival date
          setSignup({
            ...signup
            , arrivalDate: formattedDate
          });
        }
      } else {
        // Departure date is empty, set +1
        const plusDate: Date = new Date(formattedDate);
        plusDate.setDate(plusDate.getDate() + 1);
        // Set new arrival and departure date
        setSignup({
          ...signup
          , arrivalDate: formattedDate
          , departureDate: plusDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
        });
      }
    } else {
      validate();
    }

  };

  /*
   * Event handler for changes to departure date
   */
  const handleDepartureDateChange = (date: Date | null) => {

    // Test valid date format
    if (!isDateValid(date)) {
      return;
    }

    const formattedDate = date ? dateFormatted(date) : EMPTY_STRING;

    if (formattedDate) {
      const departureDate: Date = new Date(formattedDate);

      // Departure must be tomorrow or greater
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const yesterday = new Date();
      yesterday.setHours(0, 0, 0, 0);
      yesterday.setDate(yesterday.getDate() - 1);
      if ((departureDate.getTime() === today.getTime()) || (departureDate <= yesterday)) {
        enqueueSnackbar(`Departure date must be tomorrow or later`, { variant: 'warning' });
        return;
      }

      // Arrival date exists
      if (signup.arrivalDate) {
        // Check if arrival >= departure date
        const departureDate: Date = new Date(formattedDate);
        const arrivalDate: Date = new Date(signup.arrivalDate);
        if (departureDate <= arrivalDate) {
          enqueueSnackbar(`Departure date must be later than arrival date`, { variant: 'warning' });
        } else {
          // Set new departure date
          setSignup({
            ...signup
            , departureDate: formattedDate
          });
        }
      } else {
        // Arrival date is empty, set -1
        const minusDate: Date = new Date(formattedDate);
        minusDate.setDate(minusDate.getDate() - 1);
        setSignup({
          ...signup
          , arrivalDate: minusDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
          , departureDate: formattedDate
        });
      }
    } else {
      validate();
    }

  };

  React.useEffect(() => {
    // Load membrer data and set default dates
    const newSignup = { ...props.memberSignup };
    newSignup.arrivalDate = defaultArrival;
    newSignup.departureDate = defaultDeparture;
    setSignup(newSignup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberSignup]);

  React.useEffect(() => {
    if (signup.memberID !== NULL_ID) {
      validate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup]);

  React.useEffect(() => {
    if (signup.departureDate) {
      setMaxArrival(calcMaxArrival(signup.departureDate));
      setMinArrival(calcMinArrival(signup.departureDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup.departureDate]);

  React.useEffect(() => {
    if (signup.arrivalDate) {
      setMaxDeparture(calcMaxDeparture(signup.arrivalDate));
      setMinDeparture(calcMinDeparture(signup.arrivalDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup.arrivalDate]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item alignItems="center">
        <Grid item>
          <IconButton onClick={props.handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid>
          <Typography variant="h6">Member Not Signed Up</Typography>
        </Grid>
      </Grid>
      <Grid container item sx={styles.memberInfo}>
        <Typography variant="body1">{`${signup.firstName} ${signup.lastName}`}</Typography>
      </Grid>
      <Grid container item>
        <Typography variant="overline">{signup.memberStatus}</Typography>
      </Grid>
      <Grid container item sx={styles.entryField}>
        <DatePicker
          disablePast
          inputFormat="MM/dd/yyyy"
          label="Arrival"
          minDate={minArrival ? new Date(minArrival) : undefined}
          maxDate={maxArrival ? new Date(maxArrival) : undefined}
          onChange={handleArrivalDateChange}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
          value={signup.arrivalDate}
          views={['month', 'day']}
        />
      </Grid>
      <Grid container item sx={styles.entryField}>
        <DatePicker
          disablePast
          inputFormat="MM/dd/yyyy"
          label="Departure"
          minDate={minDeparture ? new Date(minDeparture) : undefined}
          maxDate={maxDeparture ? new Date(maxDeparture) : undefined}
          onChange={handleDepartureDateChange}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
          value={signup.departureDate}
          views={['month', 'day']}
        />
      </Grid>
      <Grid container item justifyContent="center" sx={styles.actions}>
        <Tooltip title="Add or update member in request">
          <span>
            <Button variant="contained" color="primary" disabled={isValid ? false : true} onClick={updateMemberList} >Save</Button>
          </span>
        </Tooltip>
      </Grid>
    </Box>
  );

}

export default NotSignedUpEdit;