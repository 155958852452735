// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// #endregion

// #region interfaces

interface MemberEmailDialogProps {
  show: boolean;
  handleClose: () => void;
}

// #endregion

const MemberEditEmailChangedDialog: React.FC<MemberEmailDialogProps> = (props) => {

  // #region Styles
  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
    >
      <DialogTitle>Member Email Change</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>{`You made changes to your email. All communication will now be sent to the revised email address.`}</Typography>
        <Typography variant="h6" paragraph>{`IMPORTANT`}</Typography>
        <Typography variant="body1" paragraph>{`Your log in email address remains the same. This change to your personal email does not affect your login email.`}</Typography>
        <Typography variant="body1" paragraph>{`If you want to change your login emal please submit a request using the Feedback form in the top bar Account Options.`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default MemberEditEmailChangedDialog;