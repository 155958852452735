import React from 'react';

// #region imports

// Material-UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

const MyLodgeUseHelp: React.FC = () => {

  const { isMobile } = useResponsive();

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  };

  // #endregion

  return (
    <Box sx={styles.root}>
      <Typography variant="h6" paragraph gutterBottom>Overview</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>My Lodge Use contains all the current, future, past, and cancelled lodge use requests for your household. Each category can be expanded or collapsed to view the individual requests. Selecting an individual request will display the details. Requests for the current "Request Year" (12/1-11/30) are displayed.</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>Ski Season (12/1-4/30)</Typography>
      <ul>
        <li><Typography variant={isMobile ? 'body2' : 'body1'}>Weekend/Holiday - ski season weekends and holidays (w/ meals pending # of people)</Typography></li>
        <li><Typography variant={isMobile ? 'body2' : 'body1'}>Non-Weekend/Non-Holidays - typically Sun-Thu nights (no meals)</Typography></li>
        <li><Typography variant={isMobile ? 'body2' : 'body1'}>Post Deadline - The upcoming weekend/holiday after the Sunday 6 p.m. signup deadline</Typography></li>
      </ul>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>Off Season (5/1-11/30)</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>View the lodge fees in the Lodge folder in the Resources area.</Typography>
      <Typography variant="h6" paragraph gutterBottom>Current Requests</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>Current requests are those where the current date is within the arrival and departure date of the request, i.e., you are currently using the lodge.</Typography>
      <Typography variant="h6" paragraph gutterBottom>Future Requests</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>Future requests are upcoming requests. A future request can be edited to change arrival or departure dates for each individual, add guests, add household members not already in the request. In addition, one or more individuals in a request can be "cancelled", and the entire request can be cancelled.</Typography>
      <Typography variant="h6" paragraph gutterBottom>Past Requests</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>Past requests are those where the departure date in in the past. These requests can be viewed, but not changed.</Typography>
      <Typography variant="h6" paragraph gutterBottom>Cancelled Requests</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph gutterBottom>Cancelled requests are those where the entire request was cancelled. If one or more individuals in a request are cancelled they are listed in the Current, Future, or Past request.</Typography>
    </Box>
  )

  // #endregion

}

export default MyLodgeUseHelp;