// #region imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import RequestCounts from './RequestCounts';
import TransferablePasses from './TransferablePasses';
import Typography from '@mui/material/Typography';

import {
  EMPTY_STRING
} from '../../global';

// UTC to local date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

const SignupSummary: React.FC = (props) => {

  const { dateToLocalDateFormatted } = useDateFormatting();
  const { isMobile } = useResponsive();

  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [refreshTime, setRefreshTime] = React.useState<string>(EMPTY_STRING);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2, 2, 2, 2)
    },
    passesContainer: {
      flexGrow: 1,
      height: 600
    },
    requestsContainer: {
      flexGrow: 1,
      height: 600
    },
    statusContainer: {
      padding: theme.spacing(0, 0, 2, 0)
    }
  };

  // #endregion

  // #region Event handlers

  const handleRefresh = () => {
    setRefresh(refresh => !refresh);
    setRefreshTime(dateToLocalDateFormatted(new Date()));
  }

  React.useEffect(() => {
    setRefreshTime(dateToLocalDateFormatted(new Date()));
  }, []);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item spacing={2} justifyContent="center" alignItems="center" sx={styles.statusContainer}>
        <Grid item>
          <Typography>{`Status as of ${refreshTime}`}</Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={handleRefresh}>Refresh</Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {!isMobile &&
          <Grid item xs={6} sx={styles.requestsContainer}>
            <RequestCounts
              refresh={refresh}
            />
          </Grid>
        }
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid container sx={styles.passesContainer}>
            <TransferablePasses
              refresh={refresh}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )

}

export default SignupSummary;