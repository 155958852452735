// #region Imports

import React from 'react';

// Material-UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import {
  API_URL
  , EMPTY_STRING
} from '../global';

import {
  HTTPHeaders
} from "../interfaces";

import {
  Household
} from '../models';

import {
  HOUSEHOLD_EDIT_SAVED
} from "../../store/ActionTypes";

// Redux hooks
import { useDispatch } from 'react-redux';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// UTC to local date formatting
import { useDateFormatting } from '../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface HouseholdEditProps {
  memberID: number;
  handleHouseholdChanged: (changed: boolean) => void;
}

// #endregion

const HouseholdEdit: React.FC<HouseholdEditProps> = (props) => {

  const { getAccessToken, memberID } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();
  const { enqueueSnackbar } = useSnackbar();

  const [errors, setErrors] = React.useState(false);
  const [changed, setChanged] = React.useState<boolean>(false);
  const [household, setHousehold] = React.useState<Household>(new Household());

  // Redux
  const dispatch = useDispatch();

  // #region Styles

  const theme = useTheme();
  const styles = {
    button: {
      margin: theme.spacing(1, 1, 1, 1),
    },
    rowSpacer: {
      margin: theme.spacing(0, 0, 2, 0)
    },
    updated: {
      margin: theme.spacing(2, 0, 0, 2)
    }
  };

  // #endregion

  // #region Methods

  /*
* Fetch household and members
*/
  const fetchHousehold = async (id: number) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/households/${id}`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Household data was not retrieved');
        }
        return response.json()
      })
      .then((h: Household) => {
        setHousehold(
          {
            ...h
            , 'userID': memberID()
          });
      })
      .catch(err => setErrors(err));

  }

  const formatHousehold = () => {
    let formattedAddress = household?.address1 ? household.address1 : '';
    formattedAddress += formattedAddress && household?.city ? ` ${household?.city}` : '';
    formattedAddress += formattedAddress && household?.stateRegion ? ` ${household?.stateRegion}` : '';
    formattedAddress += formattedAddress && household?.postalCode ? ` ${household?.postalCode}` : '';
    formattedAddress += formattedAddress && household?.country ? ` ${household?.country}` : '';

    return formattedAddress;
  }

  /*
 * Cancel edits and reset
 */
  const cancel = () => {

    // Flag that data has changed
    setChanged(false);
    // Notify parent
    props.handleHouseholdChanged(false);

    fetchHousehold(props.memberID);
  }

  /*
 * Save household data
 */
  const save = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/households`, {
      method: 'POST'
      , body: JSON.stringify(household)
      , headers: headers
      , mode: 'cors'
    })
      .then(response => {
        if (response.ok) {
          enqueueSnackbar(`Changes for household saved`, { variant: 'success' });
          // Flag that data has changed
          setChanged(false);
          dispatch({ type: HOUSEHOLD_EDIT_SAVED, payload: true })
          // Notify parent
          props.handleHouseholdChanged(false);
        } else {
          enqueueSnackbar(`WARNING: Changes for household not saved`, { variant: 'warning' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Household was not saved, Error: ${error}`, { variant: 'error' });
      });

  }

  // #endregion

  // #region Event handlers

  /*
   * Handle changes to text field
   */
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setHousehold(
      {
        ...household
        , [event.target.name]: event.target.value
      }
    );

    if (!changed) {
      // Flag that data has changed
      setChanged(true);
      // Notify parent
      props.handleHouseholdChanged(true);
    }

  };

  React.useEffect(() => {
    if (props.memberID > 0) {
      fetchHousehold(props.memberID);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberID]);

  // #endregion

  return <>
    {household.isDeceased &&
      <Typography variant="body1" >No household information, all members deceased</Typography>
    }
    {!household.isDeceased &&
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography variant="body1" >{formatHousehold()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form autoComplete="off" noValidate>
            <Grid container item lg={12} sx={styles.rowSpacer}>
              <TextField
                type="text"
                label="Address"
                name="address1"
                fullWidth
                value={household.address1}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid container item lg={12} sx={styles.rowSpacer}>
              <TextField
                type="text"
                label="Address"
                name="address2"
                fullWidth
                value={household.address2}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid container item lg={12} sx={styles.rowSpacer}>
              <TextField
                type="text"
                label="City"
                name="city"
                fullWidth
                value={household.city}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid container item lg={12} sx={styles.rowSpacer}>
              <TextField
                type="text"
                label="State/Region"
                name="stateRegion"
                fullWidth
                value={household.stateRegion}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid container item lg={12} sx={styles.rowSpacer}>
              <TextField
                type="text"
                label="Postal Code"
                name="postalCode"
                fullWidth
                value={household.postalCode}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid container item lg={12} sx={styles.rowSpacer}>
              <TextField
                type="text"
                label="Country"
                name="country"
                fullWidth
                value={household.country}
                onChange={handleTextChange}
              />
            </Grid>
          </form>
        </AccordionDetails>
        <AccordionActions>
          <Grid container item lg={12} spacing={2} >
            <Grid item lg={6} justifyContent="center">
              <Tooltip title="Cancel member changes">
                <span>
                  <Button variant="outlined" color="primary" fullWidth disabled={!changed} onClick={cancel}>Cancel</Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item lg={6} justifyContent="center">
              <Tooltip title="Save member changes">
                <span>
                  <Button variant="contained" color="primary" fullWidth disabled={!changed} onClick={save}>Save</Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionActions>
        <Grid container item lg={12} sx={styles.updated}>
          <Typography variant="overline">Updated: {dateStringToLocalDateFormatted(household.updated)} By {household.updatedBy ? household.updatedBy : EMPTY_STRING}</Typography>
        </Grid>
      </Accordion>
    }
  </>;
}

export default HouseholdEdit;
