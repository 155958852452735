// #region imports

import React from 'react';
import { format } from 'date-fns';

// Material-UI
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams
} from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders
} from '../../interfaces';

import {
  SignupPast
} from '../../models';

import {
  API_URL,
  NULL_DATETIME_TIMEZONE_STRING,
  NULL_ID
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region Interfaces

interface PastSignupProps {
  closeDrawer: () => void;
  signupID: number;
}

// #endregion

const PastSignup: React.FC<PastSignupProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { dateStringToLocalDateFormatted, isNullDate } = useDateFormatting();
  const { isMobile } = useResponsive();

  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [signup, setSignup] = React.useState<SignupPast>(new SignupPast());

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(2, 2, 2, 2)
    },
    divider: {
      margin: theme.spacing(1, 0, 1, 0)
    },
    sectionHeading: {
      margin: theme.spacing(2, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const guestColumns = (): Array<GridColDef> => {
    return [
      {
        field: 'lastName',
        headerName: 'Name',
        width: 150,
        type: 'string',
        valueGetter: renderName
      }, {
        field: 'arrivalDate',
        headerName: 'Dates',
        width: 125,
        type: 'string',
        valueGetter: renderDates
      }, {
        field: 'guestType',
        headerName: 'Type',
        hide: isMobile,
        width: 150,
        type: 'string',
      }, {
        field: 'cancelDate',
        headerName: 'Cancelled',
        hide: isMobile,
        width: 200,
        type: 'string',
        valueGetter: renderCancelDate
      }];

  }

  /*
* Grid column definitions
*/
  const memberColumns = (): Array<GridColDef> => {
    return [
      {
        field: 'lastName',
        headerName: 'Name',
        width: 150,
        type: 'string',
        valueGetter: renderName
      }, {
        field: 'arrivalDate',
        headerName: 'Dates',
        width: 125,
        type: 'string',
        valueGetter: renderDates
      }, {
        field: 'memberStatus',
        headerName: 'Type',
        hide: isMobile,
        width: 150,
        type: 'string',
      }, {
        field: 'cancelDate',
        headerName: 'Cancelled',
        hide: isMobile,
        width: 200,
        type: 'string',
        valueGetter: renderCancelDate
      }];

  }

  /*
   * Fetch signup data
   */
  const fetchSignup = async () => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/past/${props.signupID}`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Past signups not retrieved');
        }
        return response.json()
      })
      .then((signup: SignupPast) => {
        setSignup(signup);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      }
      );
  }

  /*
  * Render cancel date
  */
  const renderCancelDate = (params: GridValueGetterParams) => {
    return isNullDate(params.row.cancelDate) ? '' : `${dateStringToLocalDateFormatted(params.row.cancelDate)}`;
  }

  /*
  * Render dates
  */
  const renderDates = (params: GridValueGetterParams) => {
    return `${format(new Date(params.row.arrivalDate), 'MM/dd')}-${format(new Date(params.row.departureDate), 'MM/dd')}`
  }

  /*
* Render member or guest name
*/
  const renderName = (params: GridValueGetterParams) => {
    return `${params.row.lastName}, ${params.row.firstName}`
  }

  // #endregion

  // #region Event handlers

  React.useEffect(() => {
    if ((props.signupID !== NULL_ID) && (signup.signupID !== props.signupID)) {
      fetchSignup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.signupID]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item alignItems="center">
        {isMobile &&
          <Grid item>
            <IconButton onClick={props.closeDrawer} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        }
        <Grid item>
          <Typography variant="h6">{`Past Request #${signup.referenceNumber}`}</Typography>
        </Grid>
      </Grid>
      {isMobile &&
        <Divider sx={styles.divider} />
      }
      <Grid container item>
        <Typography variant="body1" gutterBottom>{`${signup.minArrivalDate}-${signup.maxDepartureDate} Nights:${signup.nights}`}</Typography>
      </Grid>

      {signup.memberSignups.length > 0 &&
        <>
          <Typography variant="body1" sx={styles.sectionHeading}>Household Members</Typography>
          <DataGrid
            autoHeight
            columns={memberColumns()}
            density="compact"
            disableColumnSelector={true}
            disableSelectionOnClick={true}
            getRowId={(row) => row.memberSignupID} // Unique ID for each row
            hideFooter={true}
            loading={isLoading}
            rows={signup.memberSignups}
            sx={styles.root}
          />
        </>
      }

      {signup.guestSignups.length > 0 &&
        <>
          <Typography variant="body1" sx={styles.sectionHeading}>Guests</Typography>
          <DataGrid
            autoHeight
            columns={guestColumns()}
            density="compact"
            disableColumnSelector={true}
            disableSelectionOnClick={true}
            getRowId={(row) => row.guestSignupID} // Unique ID for each row
            hideFooter={true}
            loading={isLoading}
            rows={signup.guestSignups}
            sx={styles.root}
          />
        </>
      }
    </Box>
  )

}

export default PastSignup;