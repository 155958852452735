import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DialogContent } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Froala Editor
import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FeedbackTypeSelect from './FeedbackTypeSelect';

import {
  API_URL,
  NULL_ID
} from '../../global';

import {
  HTTPHeaders
} from '../../interfaces';

import {
  FeedbackFormData,
  FeedbackType
} from '../../models';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

export interface FeedbackFormProps {
  open: boolean;
  onClose: () => void;
}
const FeedbackForm: React.FC<FeedbackFormProps> = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const { getAccessToken, isAuthenticated, memberID } = useAuth0User();

  const [errors, setErrors] = React.useState(false);
  const [feedback, setFeedback] = React.useState<FeedbackFormData>(new FeedbackFormData());
  const [feedbackTypes, setFeedbackTypes] = React.useState<Array<FeedbackType>>([]);
  const [formDisabled, setFormDisabled] = React.useState<boolean>(true);
  const [showWorking, setShowWorking] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    button: {
      margin: theme.spacing(0, 4),
    },
    title: {
      flexGrow: 1,
      margin: theme.spacing(2, 0, 3, 0)
    },
    typeContainer: {
      margin: theme.spacing(1, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
 * Get a list of feedback types
 */
  const fetchFeedbackType = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/admin/feedback/types`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Feedback types not retrieved');
        }
        return response.json()
      })
      .then((ft: Array<FeedbackType>) => {
        setFeedbackTypes(ft);
      })
      .catch(err => setErrors(err));

  }

  /*
   * Reset email fields
   */
  const resetFeedback = () => {
    const f = new FeedbackFormData();
    setFeedback(
      {
        ...f
        , 'userID': memberID()
      });
  }

  /*
* Send feedback
*/
  const sendFeedback = async () => {

    setFormDisabled(true);
    setShowWorking(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/admin/feedback`);

    await fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify(feedback),
      headers: headers,
      mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar(`WARNING: Feedback was not submitted`, { variant: 'warning' });
        } else {
          enqueueSnackbar(`Feedback was submitted`, { variant: 'success' });
          resetFeedback();
        }
        setShowWorking(false);
      })
      .catch(error => {
        enqueueSnackbar(`WARNING: Feedback was not submitted, Error: ${error}`, { variant: 'error' });
        setShowWorking(false);
      });
  }

  /*
   * Is feedback valid w/ all necessary info?
   */
  const validateFeedback = () => {

    if ((feedback.feedbackType.feedbackTypeID !== NULL_ID) && feedback.title && feedback.description) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }

  }

  // #endregion

  // #region Event handlers

  /*
 * Handle changes to member status
 */
  const handleFeedbackTypeChange = (id: number) => {

    //// Create FeedbackType instance if not present
    //if (!feedback.feedbackType) {
    //  feedback.feedbackType = new FeedbackType();
    //}

    const feedbackType = feedbackTypes.find(ft => ft.feedbackTypeID = id);
    if (feedbackType != undefined) {
      setFeedback(
        {
          ...feedback,
          feedbackType: feedbackType
        }
      );
    }


  };

  /*
* Handle selection of reason for support
*/
  const handleReasonChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setFeedback({
      ...feedback
      , 'feedbackType': event.target.value as FeedbackType
    });
  };

  /*
   * Handles Froala model change
   */
  const handleModelChange = (model: string) => {

    setFeedback({
      ...feedback
      , 'description': model
    });

  }

  /*
 * Handle changes to text field
 */
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    // Rest phone type if phone number is blank
    if (event.target.name === 'title') {

      setFeedback(
        {
          ...feedback
          , [event.target.name]: event.target.value
        }
      );

    }

  };

  React.useEffect(() => {

    if (isAuthenticated && (memberID() !== NULL_ID)) {
      setFeedback(
        {
          ...feedback
          , 'userID': memberID()
        });
    }

    fetchFeedbackType();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    validateFeedback();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback.description, feedback.title, feedback.feedbackType.feedbackTypeID]);

  // #endregion

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={props.open} onClose={props.onClose}>
      <DialogTitle>Website Feedback</DialogTitle>
      <DialogContent>
        <form autoComplete="off" noValidate>
          <Grid container>
            <Grid container item alignItems="center" sx={styles.typeContainer}>
              <Grid item lg={3}>
                <FeedbackTypeSelect
                  feedbackTypes={feedbackTypes}
                  feedbackTypeID={feedback.feedbackType.feedbackTypeID ? feedback.feedbackType.feedbackTypeID : NULL_ID}
                  feedbackTypeSelected={handleFeedbackTypeChange}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <TextField
                name="title"
                value={feedback.title}
                required
                onChange={handleTextChange}
                label="Enter a descriptive title for the feedback"
                sx={styles.title}
              />
            </Grid>
            <Grid container item>
              <FroalaEditorComponent
                tag='textarea'
                config={{
                  heightMin: 300
                  , heightMax: 4000
                  , key: 'AVB8B-21B2A2B2B2A2E1ugdbymcqxj1b1C-21A-16uB-11mH-9F2xspbA4B3A2A2I3H2C4C6B3D4=='
                  , width: 5000
                  , placeholderText: 'Enter a detailed description of the feedback. Provide enough information to reproduce a bug.'
                }}
                model={feedback.description}
                onModelChange={handleModelChange}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {showWorking &&
          <CircularProgress size="30" />
        }
        <Button
          onClick={props.onClose}
          color="primary"
        >Close</Button>
        <Button
          variant="contained"
          sx={styles.button}
          onClick={sendFeedback}
          color="primary"
          endIcon={<SendIcon />}
          disabled={formDisabled}
        >Submit</Button>
      </DialogActions>
    </Dialog>
  );

}

export default FeedbackForm;
