import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import app from './app/reducers';
import membership from './membership/reducers';
import signups from './signups/reducers';

const rootReducer = combineReducers({
  app: app,
  membership: membership,
  signups: signups
});

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);