import {
  EMPTY_STRING,
  NULL_DATE
} from "../../components/global";

import {
  APP_COPYRIGHT,
  APP_RELEASE_DATE,
  APP_VERSION
} from "../ActionTypes";

const initialState = {
  copyright: EMPTY_STRING,
  releaseDate: NULL_DATE,
  version: EMPTY_STRING
};

const shared = (state: any = initialState, action: any) => {

  switch (action.type) {
    case APP_COPYRIGHT:
      return {
        ...state,
        copyright: action.payload
      };
    case APP_RELEASE_DATE:
      return {
        ...state,
        releaseDate: action.payload
      };
    case APP_VERSION:
      return {
        ...state,
        version: action.payload
      };
    default: {
      return state;
    }
  }

}

export default shared;
