// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// #endregion

// #region interfaces

interface MemberEmailDialogProps {
  show: boolean;
  handleClose: () => void;
}

// #endregion

const MemberEmailHelpDialog: React.FC<MemberEmailDialogProps> = (props) => {

  // #region Styles
  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
    >
      <DialogTitle>Member Email</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>{`As a membership email admin, emails can be sent to all members in one or more member status categories.`}</Typography>
        <Typography variant="button" paragraph>Filtering</Typography>
        <Typography variant="body1" paragraph>{`The member status filter controls which groups of members are sent emails. Select the appropriate status for an email, then enter the subject and email content, selecting Send when complete to send the email.`}</Typography>
        <Typography variant="button" paragraph>Sending an Email</Typography>
        <Typography variant="body1" paragraph>{`When sending an email, select the "From" representing from whom the email will be sent. Any replies to the email will be sent to that email address. The From corresponds to the @montclairskiclub.com email address.`}</Typography>
        <Typography variant="button" paragraph>Attachments</Typography>
        <Typography variant="body1" paragraph>{`One or more attachments up to 10 MB each can be included in an email using the Add Attachment button to attach a file. Attachments need to be added individually, and are currently limited to PDF files.`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default MemberEmailHelpDialog;