// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import MemberEmailForm from './MemberEmailForm';
import MemberStatusFilter from '../../membership/MemberStatusFilter';

import {
  API_URL
  , EMPTY_STRING
  , MemberStatusFilterMode
} from '../../global';

import {
  HTTPHeaders
  , MemberStatusCount
} from '../../interfaces';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';
// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

const MemberEmail: React.FC = () => {

  const { getAccessToken } = useAuth0User();
  const { isMobile } = useResponsive();

  const [errors, setErrors] = React.useState(false);
  const [statusCountsEmail, setStatusCountsEmail] = React.useState<Array<MemberStatusCount>>([]);
  const [statusDrawerVisible, setStatusDrawerVisible] = React.useState<boolean>(false);
  const [statusFilterEmail, setStatusFilterEmail] = React.useState<Array<number>>([]);
  const [totalSelected, setTotalSelected] = React.useState<number>(0);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      display: 'flex',
      padding: theme.spacing(0, 2, 0, 0)
    },
    filterDrawer: {
      padding: theme.spacing(2),
      flex: 1
    },
    statusContainer: {
      padding: theme.spacing(3, 0, 0, 5)
    }
  };

  // #endregion

  // #region Methods

  /*
   * Calculate total across status
   */
  const calculateTotal = () => {
    const total = statusCountsEmail.reduce((accumulator: number, currentValue) => accumulator + currentValue.statusCount, 0);
    setTotalSelected(total);
  }

  /*
* Fetch member status counts for email based upon filter
*/
  const fetchMemberStatusCounts = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/members/status/counts`);

    const params = new URLSearchParams();
    if (statusFilterEmail.length > 0) {
      params.append('status', `${statusFilterEmail.join(',')}`);
    }

    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Member status counts list not retrieved');
        }
        return response.json()
      })
      .then((msc: Array<MemberStatusCount>) => {
        // Number of members for each status value
        setStatusCountsEmail(msc);
      })
      .catch(err => setErrors(err));
  }

  // #endregion

  // #region Event Handlers

  /*
   * Handle clear of email form and status
   */
  const handleClear = () => {
    setStatusCountsEmail([]);
    setStatusFilterEmail([]);
  }

  /*
  * Handle changes to email filter
  */
  const handleEmailFilter = (filter: Array<number>) => {
    setStatusFilterEmail(filter);
  }

  /*
* Handle closing of member status filter drawer
*/
  const handleStatusDrawerClose = () => {
    setStatusDrawerVisible(false);
  };

  /*
* Handle opening of member status filter drawer
*/
  const handleStatusDrawerOpen = () => {
    setStatusDrawerVisible(true);
  };

  React.useEffect(() => {

    // Get status counts when filter changes
    if (statusFilterEmail.length > 0) {
      fetchMemberStatusCounts();
    } else {
      setStatusCountsEmail([]);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilterEmail]);

  React.useEffect(() => {

    calculateTotal();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCountsEmail]);

  // #endregion

  return (
    <>
      <Grid container item sx={styles.root}>
        {!isMobile &&
          <Grid item lg={2} sx={styles.statusContainer}>
            <MemberStatusFilter
              applyFilter={handleEmailFilter}
              filterMessage={EMPTY_STRING}
              filterMode={MemberStatusFilterMode.Filter}
              statusCounts={statusCountsEmail}
              title={'Member Email Filter'}
            />
          </Grid>
        }
        <Grid item xs={12} lg={10}>
          <MemberEmailForm clear={handleClear} statusFilter={statusFilterEmail} showFilter={handleStatusDrawerOpen} totalSelected={totalSelected} />
        </Grid>
      </Grid>
      <Drawer
        variant="temporary"
        anchor={'bottom'} open={statusDrawerVisible}
        onClose={handleStatusDrawerClose}
      >
        <Box sx={styles.filterDrawer}>
          <MemberStatusFilter
            applyFilter={handleEmailFilter}
            closeFilterDrawer={handleStatusDrawerClose}
            filterMessage={EMPTY_STRING}
            filterMode={MemberStatusFilterMode.Filter}
            statusCounts={statusCountsEmail}
            title={'Membership List Filter'}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default MemberEmail;
