import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface UseResponsiveInterface {
  isMobile: boolean;
}

export const useResponsive = (): UseResponsiveInterface => {

  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  // Media query for responsiveness
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  // #endregion

  /*
* Set mobile mode when breakpoint is reached
*/
  React.useEffect(() => {
    setIsMobile(mobileBreakpoint);
  }, [mobileBreakpoint]);


  return { isMobile };
}