// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import { red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  MATERIALUI_DIALOG_REASON_BACKDROPCLICK,
  MATERIALUI_DIALOG_REASON_ESCKEYDOWN
} from '../../global';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region interfaces

interface SignupCancelDialogProps {
  handleAbandon: () => void;
  handleCancel: () => void;
  show: boolean;
}

// #endregion

const SignupCancelDialog: React.FC<SignupCancelDialogProps> = (props) => {

  const { isMobile } = useResponsive();

  // #region Styles

  const theme = useTheme();
  const styles = {
    cancelAction: {
      backgroundColor: red[200],
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: red[500]
      }
    },
  };

  // #endregion

  // #region Event handlers

  /*
* Event handler for Material-UI onClose
*/
  const handleClose = (event: object, reason: string) => {
    if ((reason === MATERIALUI_DIALOG_REASON_BACKDROPCLICK) || (reason === MATERIALUI_DIALOG_REASON_ESCKEYDOWN)) {
      return;
    }
  };

  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>{"Lodge Use Request Cancellation"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Do you want to cancel the entire lodge use request? This can not be reversed and would require a new request with the associated priority based upon signup time.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleAbandon} color="primary">Continue Editing Request</Button>
        <Button variant="contained" sx={styles.cancelAction} autoFocus onClick={props.handleCancel} >Cancel Entire Request</Button>
      </DialogActions>
    </Dialog>
  );

}

export default SignupCancelDialog;