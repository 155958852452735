import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Layout from './components/shared/Layout';
import NotFound from './components/misc/NotFound';

import {
  ROUTER_PATH_ADMIN,
  ROUTER_PATH_HOME,
  ROUTER_PATH_MEMBERSHIP,
  ROUTER_PATH_RESOURCES,
  ROUTER_PATH_SIGNUPS
} from './components/global';

const Routing: React.FC = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTER_PATH_ADMIN} element={<Layout/>} />
        <Route path={ROUTER_PATH_HOME} element={<Layout/>} />
        <Route path={ROUTER_PATH_MEMBERSHIP} element={<Layout />} />
        <Route path={ROUTER_PATH_RESOURCES} element={<Layout />} />
        <Route path={ROUTER_PATH_SIGNUPS} element={<Layout />} />
        <Route element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );

}

export default Routing;
