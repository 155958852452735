// #region Imports

import React from 'react';

// Material-UI
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  FeedbackItem
} from '../../models';

import {
    EMPTY_STRING,
  FeedbackView
} from '../../global';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';
// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface CaldendarGridProps {
  handleViewDetail: (id: number) => void;
  isLoading: boolean;
  rows: Array<FeedbackItem>;
  view: FeedbackView;
}

// #endregion

const FeedbackGrid: React.FC<CaldendarGridProps> = (props) => {

  const { isMobile } = useResponsive();
  const { dateStringToLocalDateFormatted } = useDateFormatting();

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  };

  // #endregion

  // #region Methods

  /*
 * Grid column definitions
 */
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'feedbackID',
        headerName: EMPTY_STRING,
        width: 50,
        type: 'number',
        align: 'center',
        renderCell: (params: GridCellParams) => (
          renderView(params)
        )
      }, {
        field: 'reported',
        headerName: 'Reported',
        valueGetter: renderReportedDate,
        width: isMobile ? 0 : 200,
        type: 'string'
      }, {
        field: 'reportedBy',
        headerName: 'Reported By',
        width: isMobile ? 0 : 150,
        type: 'string'
      }, {
        field: 'feedbackType',
        headerName: 'Type',
        valueGetter: renderType,
        width: isMobile ? 0 : 150,
        type: 'string'
      }, {
        field: 'title',
        headerName: 'Title',
        flex: 1,
        type: 'string'
      }];

  }

  /*
   * Overlay displayed when no data
   */
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No feedback for the selected date range and feedback type</div>
      </GridOverlay>
    );
  }

  /*
   * Reported date (formatted)
   */
  const renderReportedDate = (params: GridValueGetterParams) => {
    const r = params.row.reported;
    return dateStringToLocalDateFormatted(r);
  }

  /*
   * Feedback type
   */
  const renderType = (params: GridValueGetterParams) => {
    return params.row.feedbackType.type;
  }

  /*
 * Render name for row
 */
  const renderView = (params: GridCellParams) => {
    const id = Number(params.row.feedbackID);

    if (id > 0) {
      return (
        <Tooltip title={`View feedback detail`} arrow>
          <IconButton onClick={() => props.handleViewDetail(id)} size="large">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <span></span>
      );
    }

  }

  // #endregion

  // #region Event handlers
  // #endregion

  return (
    <DataGrid
      columns={columns()}
      rows={props.rows}
      getRowId={(row) => row.feedbackID}
      density="compact"
      disableColumnSelector={true}
      disableSelectionOnClick={true}
      hideFooter={true}
      loading={props.isLoading}
      components={{
        NoRowsOverlay: noRowsOverlay
      }}
    />
  );

}

export default FeedbackGrid;