// #region imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders
} from '../interfaces';

import {
  ReleaseCategory,
  Release,
  ReleaseItem
} from '../models';

import {
  API_URL,
  EMPTY_STRING,
  MATERIALUI_DIALOG_REASON_BACKDROPCLICK,
  MATERIALUI_DIALOG_REASON_ESCKEYDOWN
} from '../global';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';
// UTC to local date formatting
import { useDateFormatting } from '../hooks/UseDateFormatting';

// #endregion

// #region interfaces

interface ReleasesDialogProps {
  handleClose: (dontShow: boolean) => void;
  show: boolean;
}

// #endregion

const ReleasesDialog: React.FC<ReleasesDialogProps> = (props) => {

  const { getAccessToken, isAuthenticated } = useAuth0User();
  const { dateStringFormatted } = useDateFormatting();

  const [dontShow, setDontShow] = React.useState(true);
  const [errors, setErrors] = React.useState<boolean>(false);
  const [releases, setReleases] = React.useState<Array<Release>>([]);
  const [selectedRelease, setSelectedRelease] = React.useState<Release>(new Release());

  // #region Styles

  const theme = useTheme();

  const styles = {
    actionContainer: {
      margin: theme.spacing(1, 2, 2, 2)
    },
    releaseContainer: {
      margin: theme.spacing(0, 2, 1, 2)
    },
    releaseSelectContainer: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(2, 2, 1, 2),
      padding: theme.spacing(0, 0, 2, 0)
    },
    selectWidth: {
      flexGrow: 1,
      minWidth: 120,
    },
  };

  // #endregion

  // #region Methods

  /*
  * Get release information
  */
  const fetchReleases = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/about/releases`);

    return await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Unable to get whats new content');
        }
        return response.json()
      })
      .then((r: Array<Release>) => {
        setReleases(r);
      })
      .catch(err => {
        setErrors(err);
        return false;
      }
      );
  }

  // #endregion

  // #region Event handlers

  /*
  * Handler for dont show checkbox
  */
  const handleDontShowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDontShow(event.target.checked);
  };

  /*
  * Event handler for Material-UI onClose
  */
  const handleClose = (event: object, reason: string) => {
    if ((reason === MATERIALUI_DIALOG_REASON_BACKDROPCLICK) || (reason === MATERIALUI_DIALOG_REASON_ESCKEYDOWN)) {
      return;
    }
  };

  /*
  * Handle selection of new release for viewing
  */
  const handleReleaseChange = (event: SelectChangeEvent) => {
    const r = releases.find(r => r.version == event.target.value)
    if (r != undefined) {
      setSelectedRelease(r);
    }
  };

  React.useEffect(() => {
    // Default to top/most recent release
    if (releases.length > 0) {
      setSelectedRelease(releases[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releases.length]);

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchReleases();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      fullWidth={true}
    >
      <Box sx={styles.releaseSelectContainer}>
        <FormControl sx={styles.selectWidth} size="small">
          <InputLabel id="releasesLabel">Releases</InputLabel>
          <Select
            name="Releases"
            label="Releases"
            labelId="releasesLabel"
            onChange={handleReleaseChange}
            value={selectedRelease.version}
          >
            {releases.map((r: Release) => (
              <MenuItem key={r.version} value={r.version}>{`${r.version} ${dateStringFormatted(r.releaseDate, 'MM/dd/yyyy')}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={styles.releaseContainer}>
        {
          selectedRelease.categories.map((c: ReleaseCategory, index) => (
            <Box key={index}>
              <Typography variant="subtitle2">{c.title}</Typography>
              <ul>
                {
                  c.items.map((i: ReleaseItem, index) => (
                    <li key={index}>
                      <Typography variant="body2">{i.description}</Typography>
                    </li>
                  ))
                }
              </ul>
            </Box>
          ))
        }
      </Box>
      <Box sx={styles.actionContainer}>
        <Grid container >
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={dontShow} onChange={handleDontShowChange} />} label="Don't show release information again until next release" />
          </FormGroup>
        </Grid>
        <Grid container justifyContent="center">
          <Button variant="outlined" onClick={() => props.handleClose(dontShow)} color="primary">Close</Button>
        </Grid>
      </Box>
    </Dialog>
  );

}

export default ReleasesDialog;