// #region imports

import React from 'react';

// Material-UI
import {
  Folder,
  PictureAsPdf,
  ViewComfy,
  ViewHeadline
} from '@mui/icons-material';

import {
  GDFileType
} from '../global';

// #endregion

// #region interfaces

interface FileIconProps {
  fileType: GDFileType;
}

// #endregion

const FileIcon: React.FC<FileIconProps> = (props) => {

  // #region Styles
  // #endregion

  // #region Methods

  // Define grid columns
  const getFileIcon = (fileType: GDFileType) => {

    switch (fileType) {
      case GDFileType.Folder:
        return <Folder color="primary" />
      case GDFileType.PDF:
        return <PictureAsPdf color="primary" />
      case GDFileType.Word:
        return <ViewComfy color="primary" />
      case GDFileType.Excel:
        return <ViewHeadline color="primary" />
      default:
        return
    }
  }

  // #endregion

  return (
    <React.Fragment>
      {
        getFileIcon(props.fileType)
      }
    </React.Fragment>
  );

}

export default FileIcon;