// #region Imports

import React from 'react';

// Redux hooks
import { useDispatch } from 'react-redux';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import MemberEditEmailChangedDialog from './MemberEditEmailChangedDialog';
import GenderSelect from './GenderSelect';
import MemberStatusSelect from './MemberStatusSelect';
import PhoneTypeSelect from './PhoneTypeSelect';
import WaiverStatusSelect from '../shared/WaiverStatusSelect';

import {
  API_URL,
  EMPTY_STRING,
  NULL_ID
} from '../global';

import {
  HTTPHeaders
} from "../interfaces";

import {
  Member,
  MemberStatus,
  PhoneType,
  WaiverStatus
} from '../models';

import {
  MEMBER_EDIT_SAVED
} from "../../store/ActionTypes";

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// UTC to local date formatting
import { useDateFormatting } from '../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface MemberEditProps {
  memberID: number;
  handleMemberChanged: (changed: boolean) => void;
}

// #endregion

const MemberEdit: React.FC<MemberEditProps> = (props) => {

  const { getAccessToken, isMembershipAdmin, memberID } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();
  const { enqueueSnackbar } = useSnackbar();

  const [errors, setErrors] = React.useState(false);
  const [changed, setChanged] = React.useState<boolean>(false);
  const [emailChanged, setEmailChanged] = React.useState<boolean>(false);
  const [emailChangedDialogOpen, setEmailChangedDialogOpen] = React.useState<boolean>(false);
  const [member, setMember] = React.useState<Member>(new Member());
  const [memberStatuses, setMemberStatuses] = React.useState<Array<MemberStatus>>([]);
  const [phoneTypes, setPhoneTypes] = React.useState<Array<PhoneType>>([]);
  const [waiverStatus, setWaiverStatus] = React.useState<Array<WaiverStatus>>([]);

  // Redux
  const dispatch = useDispatch();

  // #region Styles

  const theme = useTheme();
  const styles = {
    actions: {
      marginTop: theme.spacing(4)
    },
    rowSpacer: {
      margin: theme.spacing(0, 0, 2, 0)
    },
    updated: {
      marginTop: theme.spacing(2)
    }
  };

  // #endregion

  // #region Methods

  /*
* Fetch member
*/
  const fetchMember = async (id: number) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(API_URL + `/v1/members/${id}`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Member data was not retrieved');
        }
        return response.json()
      })
      .then((m: Member) => {
        setMember(
          {
            ...m
            , 'userID': memberID()
          });
      })
      .catch(err => setErrors(err));

  }

  /*
   * Get a list of member status
   */
  const fetchMemberStatus = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/members/status`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Member status not retrieved');
        }
        return response.json()
      })
      .then((ms: Array<MemberStatus>) => {
        setMemberStatuses(ms);
      })
      .catch(err => setErrors(err));

  }

  /*
   * Get a list of phone types
   */
  const fetchPhoneTypes = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/members/phonetype`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Phone types not retrieved');
        }
        return response.json()
      })
      .then((pts: Array<PhoneType>) => {
        setPhoneTypes(pts);
      })
      .catch(err => setErrors(err));

  }

  /*
   * Get a list of waiver status
   */
  const fetchWaiverStatus = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/members/waiverstatus`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Waiver status not retrieved');
        }
        return response.json()
      })
      .then((vs: Array<WaiverStatus>) => {
        setWaiverStatus(vs);
      })
      .catch(err => setErrors(err));

  }

  /*
  * Cancel edits and reset
  */
  const cancel = () => {

    // Flag that data has changed
    setChanged(false);
    // Notify parent
    props.handleMemberChanged(false);

    fetchMember(member.memberID);
  }

  /*
   * Validate member data
   */
  const validate = () => {

    // Check for missing data
    let messageText = EMPTY_STRING;
    if (!member.firstName) {
      messageText += 'First Name';
    }
    if (!member.lastName) {
      messageText += `${messageText ? ', ' : EMPTY_STRING}Last Name`;
    }

    // If missing data
    if (messageText) {
      enqueueSnackbar(`Missing required fields: ${messageText}`, { variant: 'error' });
    } else if (emailChanged) {
      // Info dialog if email changed
      // Save after closing dialog
      setEmailChangedDialogOpen(true);
    } else {
      save();
    }

  }

  /*
  * Save signup data
  */
  const save = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/members`, {
      method: 'POST'
      , body: JSON.stringify(member)
      , headers: headers
      , mode: 'cors'
    })
      .then(response => {
        if (response.ok) {
          // Flag that data has changed
          setChanged(false);
          // Notify parent
          props.handleMemberChanged(false);
          dispatch({ type: MEMBER_EDIT_SAVED, payload: true })
          enqueueSnackbar(`Changes for ${member.firstName} ${member.lastName} saved`, { variant: 'success' });
        } else {
          enqueueSnackbar(`WARNING: Changes for ${member.firstName} ${member.lastName} were not saved`, { variant: 'warning' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(`WARNING: Changes for ${member.firstName} ${member.lastName} were not saved, Error: ${error}`, { variant: 'error' });
      });

  }

  // #endregion

  // #region Event handlers

  /*
 * Handler for email changed dialog window close
 */
  const handleEmailChangedClose = () => {
    setEmailChangedDialogOpen(false);
    // After info dialog save data
    save();
  }

  /*
 * Handle changes to gender
 */
  const handleGenderChange = (gender: string) => {

    setMember(
      {
        ...member
        , 'gender': gender
      }
    );

    if (!changed) {
      // Flag that data has changed
      setChanged(true);
      // Notify parent
      props.handleMemberChanged(true);
    }

  };

  /*
   * Handle changes to member status
   */
  const handleMemberStatusChange = (memberStatusID: number) => {

    // Create MemberStatus instance if not present
    if (!member.memberStatus) {
      member.memberStatus = new MemberStatus();
    }
    member.memberStatus.memberStatusID = memberStatusID;

    setMember(
      {
        ...member
      }
    );

    if (!changed) {
      // Flag that data has changed
      setChanged(true);
      // Notify parent
      props.handleMemberChanged(true);
    }

  };

  /*
   * Handle changes to phone type
   */
  const handlePhoneTypeChange = (phoneTypeID: number, type: string) => {

    // Phone type validation
    if (type === 'primaryPhoneType') {
      // Create PhoneType instance if not present
      if (!member.primaryPhoneType) {
        member.primaryPhoneType = new PhoneType();
      }
      // Convert to number
      member.primaryPhoneType.phoneTypeID = phoneTypeID;
    } else if (type === 'secondaryPhoneType') {
      // Create PhoneType instance if not present
      if (!member.secondaryPhoneType) {
        member.secondaryPhoneType = new PhoneType();
      }
      // Convert to number
      member.secondaryPhoneType.phoneTypeID = phoneTypeID;
    }

    setMember(
      {
        ...member
      }
    );

    if (!changed) {
      // Flag that data has changed
      setChanged(true);
      // Notify parent
      props.handleMemberChanged(true);
    }

  };

  /*
   * Handle changes to text field
   */
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    // Rest phone type if phone number is blank
    switch (event.target.name) {
      case 'email':
        // Track any changes to email
        setEmailChanged(event.target.value !== member.email);
        break;
      case 'primaryPhone':
        if (!event.target.value && member.primaryPhoneType) {
          member.primaryPhoneType.phoneTypeID = NULL_ID;
          member.primaryPhoneType.type = EMPTY_STRING;
        }
        break;
      case 'secondaryPhone':
        if (!event.target.value && member.secondaryPhoneType) {
          member.secondaryPhoneType.phoneTypeID = NULL_ID;
          member.secondaryPhoneType.type = EMPTY_STRING;
        }
        break;
      default:
    }

    setMember(
      {
        ...member
        , [event.target.name]: event.target.value
      }
    );

    if (!changed) {
      // Flag that data has changed
      setChanged(true);
      // Notify parent
      props.handleMemberChanged(true);
    }

  };

  /*
 * Handle changes to waiver status
 */
  const handleWaiverStatusChange = (waiverStatusID: number) => {

    // Create WaiverStatus instance if not present
    if (!member.waiverStatus) {
      member.waiverStatus = new WaiverStatus();
    }
    member.waiverStatus.waiverStatusID = waiverStatusID;

    setMember(
      {
        ...member
      }
    );

    if (!changed) {
      // Flag that data has changed
      setChanged(true);
      // Notify parent
      props.handleMemberChanged(true);
    }

  };

  React.useEffect(() => {
    fetchMemberStatus();
    fetchPhoneTypes();
    fetchWaiverStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.memberID > 0) {
      fetchMember(props.memberID);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberID]);

  // #endregion

  return (
    <Box my={2}>
      <form autoComplete="off" noValidate>
        <Grid container item lg={12} sx={styles.rowSpacer}>
          <WaiverStatusSelect
            name="waiverStatus"
            waiverStatusID={member.waiverStatus.waiverStatusID ? member.waiverStatus.waiverStatusID : NULL_ID}
            waiverStatus={waiverStatus}
            waiverStatusSelected={handleWaiverStatusChange}
            disabled={!isMembershipAdmin() || !member.waiverStatus || member.waiverStatus.waiverStatusID === NULL_ID}
          />
        </Grid>
        <Grid container item lg={12} sx={styles.rowSpacer}>
          <TextField
            type="text"
            name="firstName"
            label="First Name"
            value={member.firstName}
            fullWidth
            onChange={handleTextChange}
            required
            error={!member.firstName}
            helperText={member.firstName ? EMPTY_STRING : 'First name is required'}
            autoFocus
          />
        </Grid>
        <Grid container item lg={12} sx={styles.rowSpacer}>
          <TextField
            type="text"
            name="lastName"
            label="Last Name"
            value={member.lastName}
            fullWidth
            onChange={handleTextChange}
            required
            error={!member.lastName}
            helperText={member.lastName ? EMPTY_STRING : 'Last name is required'}
          />
        </Grid>
        {member.age &&
          <Grid container item lg={12} sx={styles.rowSpacer} >
            <Typography variant="body1">Age: {member.age}</Typography>
          </Grid>
        }
        <Grid container item lg={12} sx={styles.rowSpacer}>
          <GenderSelect
            gender={member.gender}
            genderSelected={handleGenderChange}
          />
        </Grid>
        <Grid container item lg={12} sx={styles.rowSpacer}>
          <MemberStatusSelect
            name="memberStatus"
            memberStatusID={member.memberStatus.memberStatusID ? member.memberStatus.memberStatusID : NULL_ID}
            memberStatuses={memberStatuses}
            memberStatusSelected={handleMemberStatusChange}
            disabled={!isMembershipAdmin() || !member.memberStatus || member.memberStatus.memberStatusID === NULL_ID}
          />
        </Grid>
        <Grid container item lg={12} sx={styles.rowSpacer}>
          <TextField
            type="email"
            name="email"
            label="Email"
            value={member.email}
            fullWidth
            onChange={handleTextChange}
          />
        </Grid>
        <Grid container item lg={12} sx={styles.rowSpacer} spacing={0}>
          <Grid item lg={4}>
            <TextField
              type="text"
              name="primaryPhone"
              label="Primary Phone"
              value={member.primaryPhone}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item ml={2}>
            <PhoneTypeSelect
              name="primaryPhoneType"
              phoneTypeID={member.primaryPhoneType ? member.primaryPhoneType.phoneTypeID : NULL_ID}
              phoneTypes={phoneTypes} phoneTypeSelected={handlePhoneTypeChange}
              disabled={member.primaryPhone === EMPTY_STRING}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} sx={styles.rowSpacer} spacing={0} >
          <Grid item lg={4}>
            <TextField
              type="text"
              name="secondaryPhone"
              label="Alt Phone"
              value={member.secondaryPhone}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item ml={2}>
            <PhoneTypeSelect
              name="secondaryPhoneType"
              phoneTypeID={member.secondaryPhone && member.secondaryPhoneType ? member.secondaryPhoneType.phoneTypeID : NULL_ID}
              phoneTypes={phoneTypes} phoneTypeSelected={handlePhoneTypeChange}
              disabled={member.secondaryPhone === EMPTY_STRING}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} sx={styles.actions} spacing={2} >
          <Grid item lg={6} justifyContent="center">
            <Tooltip title="Cancel member changes">
              <span>
                <Button variant="outlined" color="primary" fullWidth disabled={!changed} onClick={cancel}>Cancel</Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item lg={6} justifyContent="center">
            <Tooltip title="Save member changes">
              <span>
                <Button variant="contained" color="primary" fullWidth disabled={!changed} onClick={validate}>Save</Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container item lg={12} sx={styles.updated}>
          <Typography variant="overline">Updated: {dateStringToLocalDateFormatted(member.updated)} By {member.updatedBy ? member.updatedBy : EMPTY_STRING}</Typography>
        </Grid>
      </form>
      <MemberEditEmailChangedDialog
        show={emailChangedDialogOpen}
        handleClose={handleEmailChangedClose}
      />
    </Box>
  );
}

export default MemberEdit;
