import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  LodgeUseSummary,
  SignupNote
} from '../../models';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #region interfaces

interface SummaryProps {
  statusTime: string;
  summary: LodgeUseSummary;
}

// #endregion

const Summary: React.FC<SummaryProps> = (props) => {

  const { dateStringToLocalDateFormatted } = useDateFormatting();

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(0, 2, 0, 2),
    },
    notesContainer: {
      border: `1px solid ${theme.palette.divider}`,
      flexGrow: 1,
      height: 100,
      padding: theme.spacing(1, 1, 1, 1)
    },
    note: {
      flexGrow: 1,
      padding: theme.spacing(0, 0, 0, 0)
    },
    notesDivider: {
      margin: theme.spacing(0, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods
  // #endregion

  // #region Event handlers
  // #endregion

  return (
    <Box sx={styles.root}>
      {props.statusTime &&
        <>
          <Grid container item spacing={1}>
            <Grid item>
              <Typography variant="button">{`Status: ${props.statusTime}`}</Typography>
            </Grid >
          </Grid >
          <Grid container item spacing={1}>
            <Grid item xs={4}>
              <Grid container item>
                {props.summary.statusCounts.map(sc => (
                  <Grid container item key={sc.signupStatusID}>
                    <Grid item xs={4}>
                      <Typography variant="caption">{sc.status}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="caption">{sc.statusCount}</Typography>
                    </Grid>
                  </Grid>
                )
                )}
              </Grid >
            </Grid >
            <Grid item xs={8}>
              {props.summary.notes.length > 0 &&
                <Box overflow="auto" sx={styles.notesContainer}>
                  {props.summary.notes.map((n: SignupNote) => (
                    <Box key={n.signupID} sx={styles.note}>
                      <Grid container item>
                        <Typography variant="caption">{`${n.createdBy} - ${dateStringToLocalDateFormatted(n.updated)}`}</Typography>
                      </Grid>
                      <Grid container item spacing={2}>
                        <Grid item>
                          <Typography variant="caption">{`Arrive: ${n.minArrivalDate}`}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">{`Depart: ${n.maxDepartureDate}`}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">{`Nights: ${n.nights}`}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Typography variant="caption" style={{ overflowWrap: 'break-word' }}>{n.notes}</Typography>
                      </Grid>
                      <Divider sx={styles.notesDivider} />
                    </Box>
                  ))}
                </Box>
              }
            </Grid >
          </Grid >
        </>
      }
      {!props.statusTime &&
        <Grid container item spacing={1}>
          <Typography variant="body1">Select dates to view summary</Typography>
        </Grid >
      }
    </Box >
  );

}

export default Summary;