
import {
  addDays,
  getMonth,
  getYear,
  isValid as isDateValid,
  parse,
  subDays,
} from 'date-fns';

import { enUS } from 'date-fns/locale';

import {
  GuestSignup,
  MemberSignup,
} from '../models';

import {
  SIGNUP_MAX_STAY_DAYS
} from '../global';

import {
  setMaxDeparture,
  setMinArrival
} from "../../store/signups/actions";

// Redux
import { useDispatch } from "react-redux";

// UTC to local date formatting
import { useDateFormatting } from '../hooks/UseDateFormatting';

interface UseSignupDatesInterface {
  calcMinArrival: (departure: string) => string;
  calcMaxDeparture: (departure: string) => string;
  calcMaxArrival: (departure: string) => string;
  calcMinDeparture: (departure: string) => string;
  calculateGuestDateRestrictions: (ms: Array<GuestSignup>) => void;
  calculateMemberDateRestrictions: (ms: Array<MemberSignup>) => void;
  getRequestYearEndDate: () => string;
  getRequestYearStartDate: () => string;
}

export const useSignupDates = (): UseSignupDatesInterface => {

  const { dateFormatted } = useDateFormatting();

  // Redux
  const dispatch = useDispatch();

  /*
   * Calculate minimum arrival date
   */
  const calcMinArrival = (departure: string): string => {
    const d = parse(departure, 'MM/dd/yyyy', new Date(), { locale: enUS });
    return dateFormatted(subDays(d, SIGNUP_MAX_STAY_DAYS));
  }

  /*
   * Calculate maximum arrival date
   */
  const calcMaxArrival = (departure: string): string => {
    const d = parse(departure, 'MM/dd/yyyy', new Date(), { locale: enUS });
    return dateFormatted(subDays(d, 1));
  }

  /*
   * Calculate minimum departure date
   */
  const calcMinDeparture = (arrival: string): string => {
    const a = parse(arrival, 'MM/dd/yyyy', new Date(), { locale: enUS });
    return dateFormatted(addDays(a, 1));
  }

  /*
   * Calculate maximum departure date
   */
  const calcMaxDeparture = (arrival: string): string => {
    const a = parse(arrival, 'MM/dd/yyyy', new Date(), { locale: enUS });
    return dateFormatted(addDays(a, SIGNUP_MAX_STAY_DAYS));
  }

  /*
* Calculate min start and max end dates for guests
*/
  const calculateGuestDateRestrictions = (gs: Array<GuestSignup>) => {

    let min: Date | undefined;
    let max: Date | undefined;

    // Find min/max for all active and life members
    gs.forEach(gs => {
      const a = parse(gs.arrivalDate, 'MM/dd/yyyy', new Date(), { locale: enUS });
      if (isDateValid(a)) {
        if (!min || a < min) {
          min = a;
        }
      }

      const d = parse(gs.departureDate, 'MM/dd/yyyy', new Date(), { locale: enUS });
      if (isDateValid(d)) {
        if (!max || d > max) {
          max = d;
        }
      }
    });

    // Update Redux signup dates
    if (min) {
      dispatch(setMinArrival(dateFormatted(min)));
    }

    if (max) {
      dispatch(setMaxDeparture(dateFormatted(max)));
    }

  }

  /*
* Calculate min start and max end dates for members
*/
  const calculateMemberDateRestrictions = (ms: Array<MemberSignup>) => {

    let min: Date | undefined;
    let max: Date | undefined;

    // Find min/max for all active and life members
    ms.forEach(ms => {
      const a = parse(ms.arrivalDate, 'MM/dd/yyyy', new Date(), { locale: enUS });
      if (isDateValid(a)) {
        if (!min || a < min) {
          min = a;
        }
      }

      const d = parse(ms.departureDate, 'MM/dd/yyyy', new Date(), { locale: enUS });
      if (isDateValid(d)) {
        if (!max || d > max) {
          max = d;
        }
      }
    });

    // Update Redux signup dates
    if (min) {
      dispatch(setMinArrival(dateFormatted(min)));
    }

    if (max) {
      dispatch(setMaxDeparture(dateFormatted(max)));
    }

  }

  /*
 * Get the last day of the request year (11/30/yyyy)
 */
  const getRequestYearEndDate = (): string => {

    const now = new Date();
    const month = getMonth(now);
    // Dec
    if (month === 11) {
      return dateFormatted(new Date((getYear(now) + 1), 10, 30)); // 11/30 next year
    } else {
      return dateFormatted(new Date(getYear(now), 10, 30)); // 11/30 this year
    }

  }

  /*
   * Get the first day of the fiscal year (12/1/yyyy)
   */
  const getRequestYearStartDate = (): string => {

    const now = new Date();
    const month = getMonth(now);

    // Dec
    if (month === 11) {
      return dateFormatted(new Date(getYear(now), 11, 1)); // 12/1 next year
    } else {
      return dateFormatted(new Date((getYear(now) - 1), 11, 1)); // 12/1 this year
    }

  }

  return {
    calcMaxArrival,
    calcMaxDeparture,
    calcMinArrival,
    calcMinDeparture,
    calculateGuestDateRestrictions,
    calculateMemberDateRestrictions,
    getRequestYearEndDate,
    getRequestYearStartDate
  };
}