// #region Imports

import React from 'react';

// Material-UI
import AppBar from '@mui/material/AppBar';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Grid from '@mui/material/Grid';
import HotelIcon from '@mui/icons-material/Hotel';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Redux
import { useDispatch, useSelector } from "react-redux";

import {
  DateRange,
  HTTPHeaders,
  Bedroom
} from '../../interfaces';

import {
  API_URL,
  TransferablePassAction,
  NULL_ID,
} from '../../global';

import UnassignedPeople from './UnassignedPeople';
import BedroomSelect from './BedroomSelect';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';
// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';
// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface BedroomsProps {
  clear: boolean;
  dateRange: DateRange;
  refresh: boolean;
  //  disableAction: boolean;
  //  handleAction: (action: LodgeUseActionType) => void;
  //  requests: Array<LodgeUseHouseholdRequest>;
  //  householdRequestSelected: (id: number, isGuest: boolean) => void;
}

// #endregion

const Bedrooms: React.FC<BedroomsProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { isMobile } = useResponsive();
  const { dateFormatted } = useDateFormatting();
  const dispatch = useDispatch();

  const [actionType, setActionType] = React.useState<TransferablePassAction>(TransferablePassAction.Confirm);
  const [bedroom, setBedroom] = React.useState<number>(NULL_ID);
  const [bedrooms, setBedrooms] = React.useState<Array<Bedroom>>([]);
  const [errors, setErrors] = React.useState(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    appBar: {
      margin: theme.spacing(1, 0, 1, 0)
    },
    noLodgeUse: {
      flexGrow: 1,
      margin: theme.spacing(5, 0, 0, 0),
      padding: theme.spacing(5, 0, 0, 0),
      textAlign: 'center'
    },
    toolbarItem: {
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
    toolbarItemDivider: {
      borderRight: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
  };

  // #endregion

  // #region Methods

  /*
* Fetch bedrooms list
*/
  const fetchBedrooms = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/lodgeuse/bedrooms`);

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Bedroom list not retrieved');
        }
        return response.json()
      })
      .then((b: Array<Bedroom>) => {
        setBedrooms(b);
      })
      .catch(err => {
        setErrors(err);
      });
  }

  // #endregion

  // #region Event handlers

  /*
   * Handle assignment of a bedroom
   */
  const handleAssignBedroom = () => {

  }

  /*
* Handle bedroom selected
*/
  const handleBedroomSelected = (id: number) => {
    setBedroom(id);
  }

  /*
   * Handle removal of bedroom assignment
   */
  const handleRemoveBedroomAssignment = () => {

  }

  React.useEffect(() => {
  }, [props.clear]);

  React.useEffect(() => {
  }, [props.refresh]);

  React.useEffect(() => {
    fetchBedrooms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return (
    <div style={styles.root}>
      <AppBar position="static" color="inherit" sx={styles.appBar}>
        <Toolbar>
          <BedroomSelect
            bedroomID={bedroom}
            bedrooms={bedrooms}
            bedroomSelected={handleBedroomSelected}
            disabled={false}
          />
          <Tooltip title="Assign the selected bedroom to the nights">
            <span>
              <IconButton
                disabled={false}
                onClick={handleAssignBedroom}
                size="large"
                sx={styles.toolbarItem}
              >
                <HotelIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Clear the selected bedrooms">
            <span>
              <IconButton
                disabled={false}
                onClick={handleRemoveBedroomAssignment}
                size="large"
                sx={styles.toolbarItemDivider}
              >
                <CancelPresentationIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Grid container item>
        <Grid item xs={5}>
          <UnassignedPeople
            clear={props.clear}
            dateRange={props.dateRange}
            refresh={props.refresh}
          />
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
    </div >
  )

}

export default Bedrooms;