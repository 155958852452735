// #region Imports

import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Components
import HouseholdEdit from './HouseholdEdit';
import HouseholdMembers from './HouseholdMembers';
import HouseholdView from './HouseholdView';
import MemberEdit from './MemberEdit';
import MemberView from './MemberView';

import {
  HouseholdMode, NULL_ID
} from '../global';

// #endregion

// #region Interfaces

interface HouseholdProps {
  closeHouseholdDrawer: () => void;
  memberID: number;
  mode: HouseholdMode;
  setChangedState: (changed: boolean) => void;
}

// #endregion

const Household: React.FC<HouseholdProps> = (props) => {

  const [householdChanged, setHouseholdChanged] = React.useState<boolean>(false);
  const [memberChanged, setMemberChanged] = React.useState<boolean>(false);
  const [memberID, setMemberID] = React.useState<number>(-1);

  // #region Styles

  const styles = {
    root: {
      flexGrow: 1,
    },
  };

  // #endregion

  // #region Event handlers

  /*
   * Handle household information changed
   */
  const handleHouseholdChanged = (changed: boolean) => {
    setHouseholdChanged(changed);
  }

  /*
   * Handle member information changed
   */
  const handleMemberChanged = (changed: boolean) => {
    setMemberChanged(changed);
  }

  /*
* Handler for cancelling edit and closing drawer (parent)
*/
  const handleMemberSelected = (memberID: number): void => {
    setMemberID(memberID);
  };

  React.useEffect(() => {
    // Set changed state in parent
    if (householdChanged || memberChanged) {
      props.setChangedState(true);
    } else {
      props.setChangedState(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [householdChanged, memberChanged]);

  React.useEffect(() => {
    if (props.memberID !== NULL_ID) {
      setMemberID(props.memberID);
    }
  }, [props.memberID]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item alignItems="center">
        <Grid item>
          <IconButton onClick={props.closeHouseholdDrawer} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid>
          <Typography variant="h6">Household</Typography>
        </Grid>
      </Grid>
      {props.mode === HouseholdMode.Edit &&
        <HouseholdEdit
          memberID={props.memberID}
          handleHouseholdChanged={handleHouseholdChanged}
        />
      }
      {props.mode === HouseholdMode.View &&
        <HouseholdView memberID={props.memberID} />
      }
      <Box my={3}>
        <HouseholdMembers memberID={props.memberID} memberSelected={handleMemberSelected} />
      </Box>
      <Divider />
      {props.mode === HouseholdMode.Edit &&
        <MemberEdit
          memberID={memberID}
          handleMemberChanged={handleMemberChanged}
        />
      }
      {props.mode === HouseholdMode.View &&
        <MemberView memberID={memberID} />
      }
    </Box>
  );
}

export default Household;
