// #region imports
import React from 'react';

// Material-UI
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams
} from '@mui/x-data-grid';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import {
  HTTPHeaders,
  SignupResultStatus
} from '../../interfaces';

import {
  MemberNotSignedUp
} from '../../models';

import {
  API_URL,
  NULL_ID,
  SignupExceptionEnum
} from '../../global';

import MemberSignupEditAbandonDialog from './MemberSignupEditAbandonDialog';
import NotSignedUpEdit from './NotSignedUpEdit';

import {
  setNotSignedUpAdded
} from "../../../store/signups/actions";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region Interfaces

interface NotSignedupProps {
  hasChanged: () => void;
  signupID: number;
}

// #endregion

const NotSignedup: React.FC<NotSignedupProps> = (props) => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();

  const [abandonDialogOpen, setAbandonDialogOpen] = React.useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [memberEdit, setMemberEdit] = React.useState<MemberNotSignedUp>(new MemberNotSignedUp());
  const [notSignedUps, setNotSignedUps] = React.useState<Array<MemberNotSignedUp>>([]);

  // Redux
  const dispatch = useDispatch();
  const memberCancelled: boolean = useSelector((state: any) => state.signups.memberCancelled);
  const notSignedUpAdded: boolean = useSelector((state: any) => state.signups.notSignedUpAdded);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(1, 1, 1, 1)
    },
    editDrawer: {
      padding: theme.spacing(2),
      width: 275
    },
    sectionHeading: {
      fontSize: 18,
      marginTop: theme.spacing(2)
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {
    return [
      {
        field: 'memberID',
        headerName: '',
        width: 50,
        type: 'number',
        align: 'center',
        renderCell: (params: GridCellParams) => (
          renderAddIcon(params)
        )
      }, {
        field: 'lastName',
        headerName: 'Name',
        width: isMobile ? 200 : 175,
        type: 'string',
        valueGetter: renderName
      }, {
        field: 'memberStatus',
        headerName: 'Type',
        hide: isMobile,
        width: 150,
        type: 'string',
      }];

  }

  /*
* Fetch members in signup household that have not signed up
*/
  const fetchNotSignupedUps = async () => {

    // Don't fetch for NULL_ID
    if (props.signupID === NULL_ID) {
      return;
    }

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/${props.signupID}/notsignedup`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Not signed ups not retrieved');
        }
        return response.json()
      })
      .then((nsu: Array<MemberNotSignedUp>) => {
        setNotSignedUps(nsu);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
* Render add button
*/
  const renderAddIcon = (params: GridCellParams) => {
    const id = Number(params.row.memberID);

    if (id > 0) {

      const onClick = (e: React.MouseEvent<HTMLElement>) => {
        // Store data for editing member not signed up
        const i = notSignedUps.findIndex(m => { return m.memberID === id });
        const s = { ...notSignedUps[i] };

        // Signup for member not added
        s.signupID = props.signupID;
        // User adding member
        s.userID = memberID();
        setMemberEdit(s);

        // Reset trackng flag
        dispatch(setNotSignedUpAdded(false));
        setDrawerVisible(true);
      }

      return (
        <Tooltip title={`Add member to the request`} arrow>
          <IconButton onClick={onClick} size="medium">
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <span></span>
      );
    }

  }


  /*
* Render member or guest name
*/
  const renderName = (params: GridValueGetterParams) => {
    return `${params.row.lastName}, ${params.row.firstName}`
  }

  // #endregion

  // #region Event handlers

  /*
* Event handler for abandon changes
*/
  const handleAbandon = () => {
    setAbandonDialogOpen(false);
    setDrawerVisible(false);
  };

  /*
* Event handler for edit close dialog - abandon changes
*/
  const handleContinue = () => {
    setAbandonDialogOpen(false);
  };

  /*
 * Handle closing of edit panel
 */
  const handleDrawerClose = () => {
    setAbandonDialogOpen(true);
  };

  /*
* Handle update of member
*/
  const handleUpdate = async (signup: MemberNotSignedUp) => {

    // User updating data
    signup.userID = memberID();

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/member`);

    await fetch(url.toString(),
      {
        body: JSON.stringify(signup)
        , headers: headers
        , method: 'POST'
      }
    )
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar('Member was not added to the lodge use request. Please report using the Feedback form.', { variant: 'error' });
        } else {
          response.json().then((result: SignupResultStatus) => {
            if ((result.statusCode === SignupExceptionEnum.MemberSignupInsertDatesOverlap)
              || (result.statusCode === SignupExceptionEnum.MemberSignupUpdateDatesOverlap)
              || (result.statusCode === SignupExceptionEnum.LodgeNightsDatesOverlap)) {
              enqueueSnackbar(result.message, { variant: 'warning' });
            } else {
              fetchNotSignupedUps();
              // Set trackng flag
              dispatch(setNotSignedUpAdded(true));
              // Flag has changed
              props.hasChanged();
              enqueueSnackbar(`Member added to lodge use request.`, { variant: 'success' });
            }
          });
        }

        setDrawerVisible(false);
      })
      .catch(err => {
        enqueueSnackbar('Member was not added to the lodge use request. Please report using the Feedback form.', { variant: 'error' });
        setErrors(err);
        setDrawerVisible(false);
      });

  }

  React.useEffect(() => {
    if (memberCancelled) {
      fetchNotSignupedUps();
      // Reset memberCancelled in SignupsCanclled
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCancelled]);

  React.useEffect(() => {
    if (notSignedUpAdded) {
      fetchNotSignupedUps();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notSignedUpAdded]);

  React.useEffect(() => {
    fetchNotSignupedUps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.signupID]);

  // #endregion

  return (
    <>
      {
        (notSignedUps.length > 0) &&
        <>
          <Grid container item sx={styles.sectionHeading}>
            <Typography variant="button">Members Not In Request</Typography>
          </Grid>
          <DataGrid
            autoHeight
            columns={columns()}
            density="compact"
            disableColumnSelector={true}
            disableSelectionOnClick={true}
            getRowId={(row) => row.memberID} // Unique ID for each row
            hideFooter={true}
            loading={isLoading}
            rows={notSignedUps}
            sx={styles.root}
          />
          <Drawer variant="temporary"
            anchor={isMobile ? 'bottom' : 'right'}
            open={drawerVisible}
            onClose={handleDrawerClose}
          >
            <div style={styles.editDrawer}>
              <NotSignedUpEdit
                memberSignup={memberEdit}
                handleClose={handleDrawerClose}
                handleUpdate={handleUpdate}
              />
            </div>
          </Drawer>
          <MemberSignupEditAbandonDialog
            show={abandonDialogOpen}
            handleAbandon={handleAbandon}
            handleContinue={handleContinue}
          />
        </>
      }
    </>
  )

  // #endregion

}

export default NotSignedup;