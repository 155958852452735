// #region imports

import React from 'react';
import { format } from 'date-fns';

// Material-UI
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { amber, blueGrey, green, red } from '@mui/material/colors';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams
} from '@mui/x-data-grid';
import Drawer from '@mui/material/Drawer';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import {
  HTTPHeaders
} from '../../interfaces';

import {
  GuestSignup
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  NULL_ID,
  SignupStatusID
} from '../../global';

import GuestEditAbandonDialog from './GuestEditAbandonDialog';
import GuestSignupCancelDialog from './GuestSignupCancelDialog';
import GuestSignupEdit from './GuestSignupEdit';

// Redux
import { useDispatch, useSelector } from "react-redux";

import {
  setGuestCancelled,
  setGuestSaved
} from "../../../store/signups/actions";

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// Signup date criteria
import { useSignupDates } from '../../hooks/UseSignupDates';

// #endregion

// #region Interfaces

interface GuestSignupsListProps {
  addGuest: boolean;
  hasChanged: () => void;
  setAddGuest: React.Dispatch<React.SetStateAction<boolean>>;
  signupID: number;
}

// #endregion

const GuestSignupsList: React.FC<GuestSignupsListProps> = (props) => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  const { calculateGuestDateRestrictions } = useSignupDates();

  const [abandonDialogOpen, setAbandonDialogOpen] = React.useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const [guestCancel, setGuestCancel] = React.useState<GuestSignup>(new GuestSignup());
  const [guestEdit, setGuestEdit] = React.useState<GuestSignup>(new GuestSignup());
  const [guestSignups, setGuestSignups] = React.useState<Array<GuestSignup>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Redux
  const dispatch = useDispatch();
  const guestCancelled: boolean = useSelector((state: any) => state.signups.guestCancelled);
  const guestSaved: boolean = useSelector((state: any) => state.signups.guestSaved);
  // Standardized dates from member in Redux
  const maxDeparture: string = useSelector((state: any) => state.signups.maxDeparture);
  const minArrival: string = useSelector((state: any) => state.signups.minArrival);

  // #region Constants

  // For unique ID in new guest requests
  const GUEST_SIGNUPID_OFFSET = -1000;

  // #endregion

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(1, 1, 1, 1)
    },
    confirmed: {
      color: green[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
    deined: {
      color: red[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
    editDrawer: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    guestButton: {
      marginLeft: theme.spacing(2)
    },
    pending: {
      color: blueGrey[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
    sectionHeading: {
      margin: theme.spacing(3, 0, 0, 0)
    },
    waitlist: {
      color: amber[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
* Add new guest
*/
  const addGuest = () => {

    const s = new GuestSignup();
    // Populate w/ default date from member list
    s.arrivalDate = minArrival;
    s.departureDate = maxDeparture;
    // New guests have negative ID for finding them, but will be treated as insert in repository
    s.guestSignupID = ((guestSignups.length * -1) + GUEST_SIGNUPID_OFFSET);
    setGuestEdit(s);
    // Reset trackng flag
    dispatch(setGuestSaved(false));

    setDrawerVisible(true);

  }

  /*
* Cancel guest signup
*/
  const cancelGuest = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/guest/cancel/${guestCancel.guestSignupID}/${memberID()}`);

    await fetch(url.toString(),
      {
        method: 'DELETE'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar('Guest lodge use request was not cancelled. Please report using the Feedback form.', { variant: 'error' });
        }

        // Flag has changed
        props.hasChanged();
        // Set trackng flag
        dispatch(setGuestCancelled(true));
        enqueueSnackbar(`Guest lodge use request cancelled.`, { variant: 'success' });
      })
      .catch(err => {
        enqueueSnackbar('Guest lodge use request was not cancelled. Please report using the Feedback form.', { variant: 'error' });
        setErrors(err);
      });
  }

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {
    return [
      {
        field: 'signupID',
        headerName: '',
        width: 50,
        type: 'number',
        align: 'center',
        renderCell: (params: GridCellParams) => (
          renderEditIcon(params)
        )
      }, {
        field: 'guestSignupID',
        headerName: '',
        width: 50,
        type: 'number',
        align: 'center',
        renderCell: (params: GridCellParams) => (
          renderCancelIcon(params)
        )
      }, {
        field: 'lastName',
        headerName: 'Name',
        width: isMobile ? 200 : 175,
        type: 'string',
        valueGetter: renderName
      }, {
        field: 'arrivalDate',
        headerName: 'Dates',
        hide: isMobile,
        width: 125,
        type: 'string',
        valueGetter: renderDates
      }, {
        field: 'guestType',
        headerName: 'Type',
        hide: isMobile,
        width: 150,
        type: 'string',
        valueGetter: renderGuestType
      },
      {
        field: 'priorityGuest',
        headerName: 'Priority Guest',
        width: 100,
        type: 'string',
        align: 'left',
        valueGetter: renderPriority
      }, {
        field: 'nights',
        headerName: 'Nights',
        hide: isMobile,
        width: 60,
        type: 'number',
      }, {
        field: 'signupStatusID',
        headerName: 'Status',
        hide: isMobile,
        width: 125,
        renderCell: (params: GridCellParams) => (
          renderStatus(params)
        )
      }
    ];

  }

  /*
* Fetch guest signup for editing
*/
  const fetchSignups = async () => {

    // Don't fetch for NULL_ID
    if (props.signupID === NULL_ID) {
      return;
    }

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/${props.signupID}/guests`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Guest signups not retrieved');
        }
        return response.json()
      })
      .then((signups: Array<GuestSignup>) => {
        calculateGuestDateRestrictions(signups);
        setGuestSignups(signups);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
* Render dates
*/
  const renderDates = (params: GridValueGetterParams) => {
    return `${format(new Date(params.row.arrivalDate), 'MM/dd')}-${format(new Date(params.row.departureDate), 'MM/dd')}`
  }

  /*
* Render cancel button
*/
  const renderCancelIcon = (params: GridCellParams) => {
    const id = Number(params.row.guestSignupID);

    if (id > 0) {

      const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        // Store guest info and display dialog
        const i = guestSignups.findIndex(gs => { return gs.guestSignupID === id });
        const s = { ...guestSignups[i] };
        setGuestCancel(s);

        // Reset trackng flag
        dispatch(setGuestCancelled(false));

        setCancelDialogOpen(true);
      }

      return (
        <Tooltip title={`Cancel guest request`} arrow>
          <IconButton onClick={onClick} size="medium">
            <CancelIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <span></span>
      );
    }

  }

  /*
* Render edit button
*/
  const renderEditIcon = (params: GridCellParams) => {
    const id = Number(params.row.guestSignupID);

    if (id > 0) {

      const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        const i = guestSignups.findIndex(gs => { return gs.guestSignupID === id });
        const s = { ...guestSignups[i] };
        setGuestEdit(s);
        // Reset trackng flag
        dispatch(setGuestSaved(false));

        setDrawerVisible(true);
      }

      return (
        <Tooltip title={`Edit guest request`} arrow>
          <IconButton onClick={onClick} size="medium">
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <span></span>
      );
    }

  }

  /*
   * Render guest type
   */
  const renderGuestType = (params: GridValueGetterParams) => {
    return params.row.guestType.name;
  }

  /*
* Render guest name
*/
  const renderName = (params: GridValueGetterParams) => {
    return `${params.row.lastName}, ${params.row.firstName} ${params.row.priorityGuest ? '*' : EMPTY_STRING}`
  }

  /*
 * Render priority guest
 */
  const renderPriority = (params: GridValueGetterParams) => {
    return params.row.priorityGuest ? 'Yes' : 'No';
  }

  /*
 * Render status icon for request
 */
  const renderStatus = (params: GridCellParams) => {

    switch (params.row.signupStatusID) {
      case SignupStatusID.Confirmed:
        return <Tooltip title="Confirmed">
          <><CheckCircleIcon fontSize="small" sx={styles.confirmed} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      case SignupStatusID.Denied:
        return <Tooltip title="Denied">
          <><BlockIcon fontSize="small" sx={styles.deined} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      case SignupStatusID.Pending:
        return <Tooltip title="Pending">
          <><InfoIcon fontSize="small" sx={styles.pending} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      case SignupStatusID.Waitlist:
        return <Tooltip title="Waitlist">
          <><PanToolIcon fontSize="small" sx={styles.waitlist} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      default:
    }
  }

  // #endregion

  // #region Event handlers

  /*
* Event handler for cancel abandon
*/
  const handleCancelAbandon = () => {
    setCancelDialogOpen(false);
  };

  /*
 * Event handler for cancelling a guest signup
 */
  const handleCancelGuest = () => {
    setCancelDialogOpen(false);
    cancelGuest();
  };

  /*
 * Handle closing of guest edit panel w/ changed check
 */
  const handleDrawerClose = () => {
    setAbandonDialogOpen(true);
  };

  /*
* Event handler for edit abandon
*/
  const handleEditAbandon = () => {
    setAbandonDialogOpen(false);
    setDrawerVisible(false);
  };

  /*
* Event handler for edit continue
*/
  const handleEditContinue = () => {
    setAbandonDialogOpen(false);
  };

  /*
 * Handle add/update of guest
 */
  const handleUpdate = async (signup: GuestSignup) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/guest`);

    signup.signupID = props.signupID;
    signup.userID = memberID();

    await fetch(url.toString(),
      {
        body: JSON.stringify(signup)
        , headers: headers
        , method: 'POST'
      }
    )
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar('Guest lodge use request did not save. Please report using the Feedback form.', { variant: 'error' });
        } else {
          fetchSignups();
          // Flag has changed
          props.hasChanged();
          // Set trackng flag
          dispatch(setGuestSaved(true));
          enqueueSnackbar(`Guest lodge use request saved.`, { variant: 'success' });
        }

        setDrawerVisible(false);
      })
      .catch(err => {
        enqueueSnackbar('Guest lodge use request did not save. Please report using the Feedback form.', { variant: 'error' });
        setErrors(err);
        setDrawerVisible(false);
      });

  }

  React.useEffect(() => {
    if (props.addGuest) {
      addGuest();
      props.setAddGuest(false);
    }
  }, [props.addGuest]);

  React.useEffect(() => {
    if (guestCancelled) {
      fetchSignups();
    }
  }, [guestCancelled]);

  React.useEffect(() => {
    if (guestSaved) {
      fetchSignups();
    }
  }, [guestSaved]);

  React.useEffect(() => {
    fetchSignups();
  }, [props.signupID]);

  // #endregion

  return (
    <>
      {
        (guestSignups.length > 0) &&
        <>
          <Grid container item sx={styles.sectionHeading}>
            <Typography variant="button">Guest Requests</Typography>
          </Grid>
          <DataGrid
            autoHeight
            columns={columns()}
            density="compact"
            disableColumnSelector={true}
            disableSelectionOnClick={true}
            getRowId={(row: GuestSignup) => row.guestSignupID} // Unique ID for each row
            hideFooter={true}
            loading={isLoading}
            rows={guestSignups}
            sx={styles.root}
          />
        </>
      }
      <Drawer variant="temporary"
        anchor={isMobile ? 'bottom' : 'right'}
        open={drawerVisible}
        onClose={handleDrawerClose}
      >
        <div style={styles.editDrawer}>
          <GuestSignupEdit
            guestSignup={guestEdit}
            handleClose={handleDrawerClose}
            handleUpdate={handleUpdate}
          />
        </div>
      </Drawer>
      <GuestEditAbandonDialog
        show={abandonDialogOpen}
        handleAbandon={handleEditAbandon}
        handleContinue={handleEditContinue} />
      <GuestSignupCancelDialog
        show={cancelDialogOpen}
        gs={guestCancel}
        handleAbandon={handleCancelAbandon}
        handleCancel={handleCancelGuest} />
    </>
  )

}

export default GuestSignupsList;