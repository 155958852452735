
import {
  getMonth,
  getYear
} from 'date-fns';

// UTC to local date formatting
import { useDateFormatting } from '../hooks/UseDateFormatting';

interface UseSystemInterface {
  getFiscalYearEndDate: () => string;
  getFiscalYearStartDate: () => string;
}

export const useSystem = (): UseSystemInterface => {

  const { dateFormatted } = useDateFormatting();

  /*
   * Get the last day of the fiscal year (4/30/yyyy)
   */
  const getFiscalYearEndDate = (): string => {

    const now = new Date();
    const month = getMonth(now); // Month 0-11
    if (month <= 3) {
      return dateFormatted(new Date(getYear(now), 3, 30));
    } else {
      return dateFormatted(new Date((getYear(now) + 1), 3, 30));
    }

  }

  /*
   * Get the first day of the fiscal year (5/1/yyyy)
   */
  const getFiscalYearStartDate = (): string => {

    const now = new Date();
    const month = getMonth(now); // Month 0-11
    if (month <= 3) {
      return dateFormatted(new Date((getYear(now)-1), 4, 1));
    } else {
      return dateFormatted(new Date(getYear(now), 4, 1));
    }

  }

  return {
    getFiscalYearEndDate,
    getFiscalYearStartDate
  };
}