// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import Household from './Household';
import HouseholdEditAbandonDialog from './HouseholdEditAbandonDialog';
import MembershipHelp from './MembershipHelp';
import MembershipHelpDialog from './MembershipHelpDialog';
import MembershipSearchMessage from './MembershipSearchMessage';
import MemberStatusFilter from './MemberStatusFilter';
import MemberList from './MemberList';
import MemberListSearch from './MemberListSearch';

import {
  API_URL
  , EMPTY_STRING
  , HouseholdMode
  , MembersFetchActionType
  , MemberStatusFilterMode
  , NULL_ID
} from '../global';

import {
  HTTPHeaders
  , Member
  , MemberStatusCount
} from '../interfaces';

// Redux hooks
import { useDispatch, useSelector } from 'react-redux';
import {
  housedholdEditSavedReset,
  memberEditSavedReset
} from "../../store/membership/actions";

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';
// Responsive hooks
import { useResponsive } from '../hooks/UseResponsive';

// #endregion

const Membership: React.FC = () => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();

  const [abandonDialogOpen, setAbandonDialogOpen] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState(false);
  const [fetchAction, setFetchAction] = React.useState<MembersFetchActionType>(MembersFetchActionType.None);
  const [filterMode, setFilterMode] = React.useState<MemberStatusFilterMode>(MemberStatusFilterMode.Filter);
  const [helpOpen, setHelpOpen] = React.useState<boolean>(false);
  const [householdChanged, setHouseholdChanged] = React.useState<boolean>(false);
  const [householdMemberIDs, setHouseholdMemberIDs] = React.useState<Array<number>>([]);
  const [householdMode, setHouseholdMode] = React.useState<HouseholdMode>(HouseholdMode.View);
  const [householdDrawerVisible, setHouseholdDrawerVisible] = React.useState<boolean>(false);
  const [members, setMembers] = React.useState<Array<Member>>([]);
  const [searchText, setSearchText] = React.useState<string | undefined>(undefined);
  const [selectedMemberID, setSelectedMemberID] = React.useState<number>(NULL_ID);
  const [showWorking, setShowWorking] = React.useState<boolean>(false);
  const [statusCountsMembers, setStatusCountsMembers] = React.useState<Array<MemberStatusCount>>([]);
  const [statusFilterMembers, setStatusFilterMembers] = React.useState<Array<number>>([]);
  const [statusDrawerVisible, setStatusDrawerVisible] = React.useState<boolean>(false);

  // Redux
  const dispatch = useDispatch();
  const householdEditSaved: boolean = useSelector((state: any) => state.membership.householdEditSaved);
  const memberEditSaved: boolean = useSelector((state: any) => state.membership.memberEditSaved);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    filterDrawer: {
      flex: 1,
      padding: theme.spacing(2)
    },
    householdDrawer: {
      flex: 1,
      padding: theme.spacing(2)
    },
    loading: {
      margin: theme.spacing(5, 0, 5, 0)
    },
    memberCount: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    help: {
      flexGrow: 1,
      padding: theme.spacing(2, 2, 2, 0),
    },
    search: {
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 0, 0, 0),
      },
      [theme.breakpoints.down('xl')]: {
        margin: theme.spacing(0, 0, 1, 0),
      },
    },
    statusContainer: {
      padding: theme.spacing(3, 0, 0, 5)
    }
  };

  // #endregion

  // #region Methods

  /*
* Fetch members based upon search text and member status filter
*/
  const fetchMemberIDs = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();
    const id = memberID();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/households/${id}/members/ids`);

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Member IDs not retrieved');
        }
        return response.json()
      })
      .then((ids: Array<number>) => {
        setHouseholdMemberIDs(ids);
      })
      .catch(err => setErrors(err));
  }

  /*
* Fetch members based upon search text and member status filter
*/
  const fetchMembers = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/members`);

    const params = new URLSearchParams();
    if (searchText) {
      params.append('searchname', `${searchText}`);
    }
    if (statusFilterMembers.length > 0) {
      params.append('status', `${statusFilterMembers.join(',')}`);
    }

    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Member list not retrieved');
        }
        return response.json()
      })
      .then((m: Array<Member>) => {
        setMembers(m);
      })
      .catch(err => {
        setErrors(err);
      });
  }

  /*
* Fetch member status counts for email based upon filter
*/
  const fetchMemberStatusCounts = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/members/status/counts`);

    const params = new URLSearchParams();
    if (searchText) {
      params.append('searchname', `${searchText}`);
    }
    if (statusFilterMembers.length > 0) {
      params.append('status', `${statusFilterMembers.join(',')}`);
    }

    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Member status counts list not retrieved');
        }
        return response.json()
      })
      .then((msc: Array<MemberStatusCount>) => {
        // Update status counts used in filter
        setStatusCountsMembers(msc);
      })
      .catch(err => setErrors(err));
  }

  // #endregion

  // #region Event Handlers

  /*
* Event handler for edit abandon
*/
  const handleEditAbandon = () => {
    setAbandonDialogOpen(false);
    setHouseholdDrawerVisible(false);
  };

  /*
* Event handler for edit continue
*/
  const handleEditContinue = () => {
    setAbandonDialogOpen(false);
  };

  /*
 * Handler for help dialog window
 */
  const handleHelpClose = () => {
    setHelpOpen(false);
  }

  /*
  * Handle household/member changed state
  */
  const handleHouseholdChanged = (changed: boolean) => {
    setHouseholdChanged(changed);
  }

  /*
   * Handle closing of household panel w/ changed check
   */
  const handleHouseholdDrawerClose = () => {
    if (householdChanged) {
      setAbandonDialogOpen(true);
    } else {
      setHouseholdDrawerVisible(false);
    }
  };

  /*
   * Handle member household edit
   */
  const handleHouseholdDrawerOpen = (id: number, view: HouseholdMode) => {
    setSelectedMemberID(id);
    setHouseholdMode(view);
    setHouseholdDrawerVisible(true);
  }

  /*
  * Handle changes to membership filter
  */
  const handleMemberStatusFilter = (filter: Array<number>) => {
    setStatusFilterMembers(filter);
  }

  /*
   * Handle search text changes
   */
  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
  };

  /*
   * Handle search clear
   */
  const handleSearchClear = () => {
    setSearchText(EMPTY_STRING);
  };

  /*
 * Handle closing of member status filter drawer
 */
  const handleStatusDrawerClose = () => {
    setStatusDrawerVisible(false);
  };

  /*
   * Handle member status filter drawer open
   */
  const handleStatusOpen = () => {
    setStatusDrawerVisible(true);
  }

  React.useEffect(() => {
    if (memberID() !== NULL_ID) {
      // Member IDs for editing of household members
      fetchMemberIDs();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (searchText || (statusFilterMembers.length > 0)) {
      setFetchAction(MembersFetchActionType.Filter);
      setFilterMode(searchText ? MemberStatusFilterMode.Search : MemberStatusFilterMode.Filter);
      setShowWorking(true);
      fetchMembers();
    } else {
      setFetchAction(MembersFetchActionType.None);
      setFilterMode(MemberStatusFilterMode.Filter);
      setMembers([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, statusFilterMembers]);

  React.useEffect(() => {
    // Status counts for filter and total display
    if (members.length > 0) {
      fetchMemberStatusCounts();
    } else {
      setStatusCountsMembers([]);
    }

    setShowWorking(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  React.useEffect(() => {
    if (householdEditSaved) {
      fetchMembers();
      dispatch(housedholdEditSavedReset());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [householdEditSaved]);

  React.useEffect(() => {
    if (memberEditSaved) {
      fetchMembers();
      dispatch(memberEditSavedReset());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberEditSaved]);

  // #endregion

  return <>
    <Grid container item sm={12}>
      {!isMobile &&
        <Grid item lg={2} sx={styles.statusContainer}>
          <MemberStatusFilter
            applyFilter={handleMemberStatusFilter}
            filterMessage={searchText ? 'Note: when search results are present, filters apply to the search results.' : EMPTY_STRING}
            filterMode={filterMode}
            statusCounts={statusCountsMembers}
            title={'Membership Filter'}
          />
        </Grid>
      }
      <Grid item sm={12} lg={10} sx={styles.content}>
        <Grid container item sm={12} md={4} sx={styles.search} alignItems="center" justifyContent="flex-start">
          <MemberListSearch
            handleSearch={handleSearch}
            handleSearchClear={handleSearchClear} />
          <Tooltip title="Membership help">
            <IconButton onClick={() => setHelpOpen(true)} size="large">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid container item spacing={2} sx={styles.memberCount} alignItems="center">
          {isMobile &&
            <Grid item sm={2}>
              <Tooltip title="Membership help">
                <IconButton onClick={handleStatusOpen} size="large">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          }
          <Grid item>
            <Typography variant="button">{`Members (${members.length})`}</Typography>
          </Grid>
        </Grid>
        {members.length > 0 &&
          <MemberList
            handleHouseholdOpen={handleHouseholdDrawerOpen}
            householdMemberIDs={householdMemberIDs}
            members={members}
          />
        }
        <Grid container item>
          {!showWorking && fetchAction === MembersFetchActionType.None &&
            <Box sx={styles.help}>
              <MembershipHelp />
            </Box>
          }
          {!showWorking && fetchAction === MembersFetchActionType.Search && members.length === 0 &&
            <Box sx={styles.help}>
              <MembershipSearchMessage actionType={MembersFetchActionType.Search} searchText={searchText} />
            </Box>
          }
          {!showWorking && fetchAction === MembersFetchActionType.Filter && members.length === 0 &&
            <Box sx={styles.help}>
              <MembershipSearchMessage actionType={MembersFetchActionType.Filter} />
            </Box>
          }
          {showWorking &&
            <Grid container item justifyContent="center" sx={styles.loading}>
              <CircularProgress />
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid >
    <Drawer
      variant="temporary"
      anchor={isMobile ? 'bottom' : 'right'}
      open={householdDrawerVisible}
      onClose={handleHouseholdDrawerClose}
    >
      <div style={styles.householdDrawer}>
        <Household
          memberID={selectedMemberID}
          mode={householdMode}
          setChangedState={handleHouseholdChanged}
          closeHouseholdDrawer={handleHouseholdDrawerClose}
        />
      </div>
    </Drawer>
    <Drawer
      variant="temporary"
      anchor={'bottom'}
      open={statusDrawerVisible}
      onClose={handleStatusDrawerClose}
    >
      <div style={styles.filterDrawer}>
        <MemberStatusFilter
          applyFilter={handleMemberStatusFilter}
          closeFilterDrawer={handleStatusDrawerClose}
          filterMessage={searchText ? 'When searching, the active filters and counts correspond to the search results.' : EMPTY_STRING}
          filterMode={filterMode}
          statusCounts={statusCountsMembers}
          title={'Membership Filter'}
        />
      </div>
    </Drawer>
    <MembershipHelpDialog
      show={helpOpen}
      handleClose={handleHelpClose} />
    <HouseholdEditAbandonDialog
      show={abandonDialogOpen}
      handleAbandon={handleEditAbandon}
      handleContinue={handleEditContinue} />
  </ >;
}

export default Membership;
