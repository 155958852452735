// #region imports

import React from 'react';
import { eachDayOfInterval, format, isEqual, isValid as isDateValid } from 'date-fns';

// Material-UI
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import TransferablePassRequest from './TransferablePassRequest';

import {
  TransferablePassSignup
} from '../models';

import {
  TransferablePassStatus
} from '../global';

// #endregion

// #region interfaces

interface TransferablePassesProps {
  handlePassesChange: (passes: Array<TransferablePassSignup>) => void;
  minArrivalDate: string;
  maxDepartureDate: string;
  passes: Array<TransferablePassSignup>;
}

// #endregion

const TransferablePasses: React.FC<TransferablePassesProps> = (props) => {

  const [dateRange, setDateRange] = React.useState<Array<Date>>([]);

  // #region Methods

  /*
   *Calculate date range for the passes
   */
  const calculateDateRange = () => {

    setDateRange(
      eachDayOfInterval({
        start: new Date(props.minArrivalDate),
        end: new Date(props.maxDepartureDate)
      })
    );

  }

  /*
   * Determine transferable pass for a date
   */
  const passForDate = (d: Date): TransferablePassSignup => {
    // Remove time from calendar date for comparison to pass date
    const pass = props.passes.find(p => isEqual(new Date(p.passDate), new Date(d.toDateString())));
    if (pass === undefined) {
      const p = new TransferablePassSignup();
      p.passDate = format(d, 'M/d/yyyy');
      return p;
    } else {
      return pass;
    }
  }

  // #endregion

  // #region Event handlers

  /*
   * Handle change to # passes for a date
   */
  const handlePassChange = (changedPass: TransferablePassSignup): void => {

    let passes = [...props.passes];

    // Is there an existing request for the changed pass?
    const index = props.passes.findIndex(p => isEqual(new Date(p.passDate), new Date(changedPass.passDate)));

    if (index === -1) {
      // No existing pass request, add it
      passes = [...passes, changedPass];
    } else {
      // Change to # of passes requested
      // Changing to zero (0) will delete pass record
      passes = props.passes.map((p: TransferablePassSignup, i: number) => {
        if (i === index) {
          return { ...p, passes: changedPass.passes };
        } else {
          return p;
        }
      }
      );
    }

    props.handlePassesChange(passes);

  }

  React.useEffect(() => {
    // Calculate date range when min/max dates change
    if (isDateValid(new Date(props.minArrivalDate)) && isDateValid(new Date(props.maxDepartureDate))) {
      calculateDateRange();
    } else if (dateRange.length > 0) {
      setDateRange([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.minArrivalDate, props.maxDepartureDate]);

  // #endregion

  return (
    <Grid container item xs={12} >
      {dateRange.length > 0 &&
        dateRange.map((d: Date) =>
          <Grid container item key={d.getTime().toString()}>
            <TransferablePassRequest
              handlePassChange={handlePassChange}
              pass={passForDate(d)}
            />
          </Grid>
        )
      }
      {dateRange.length === 0 &&
        <Typography variant="body1">No request dates selected</Typography>
      }
    </Grid>
  );

}

export default TransferablePasses;