// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import {
  GuestSignup
} from '../../models';

import {
  MATERIALUI_DIALOG_REASON_BACKDROPCLICK,
  MATERIALUI_DIALOG_REASON_ESCKEYDOWN
} from '../../global';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region interfaces

interface MemberSignupCancelDialogProps {
  gs: GuestSignup
  handleAbandon: () => void;
  handleCancel: () => void;
  show: boolean;
}

// #endregion

const MemberSignupCancelDialog: React.FC<MemberSignupCancelDialogProps> = (props) => {

  const { isMobile } = useResponsive();

  // #region Styles
  // #endregion

  // #region Event handlers

  /*
* Event handler for Material-UI onClose
*/
  const handleClose = (event: object, reason: string) => {
    if ((reason === MATERIALUI_DIALOG_REASON_BACKDROPCLICK) || (reason === MATERIALUI_DIALOG_REASON_ESCKEYDOWN)) {
      return;
    }
  };

  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>{"Guest Request Cancellation"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Cancel lodge use request for {`${props.gs.firstName} ${props.gs.lastName}`}? This can not be reversed and would require a new request with the associated priority based upon the time the request is added.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleAbandon} color="primary">Abandon</Button>
        <Button variant="contained" onClick={props.handleCancel} color="primary" autoFocus>Cancel Request</Button>
      </DialogActions>
    </Dialog>
  );

}

export default MemberSignupCancelDialog;