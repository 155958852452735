// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MyLodgeUseRequestEditHelp from './MyLodgeUseRequestEditHelp';

// #endregion

// #region interfaces

interface MyLodgeUseRequestEditHelpDialogProps {
  handleClose: () => void;
  show: boolean;
}

// #endregion


const MyLodgeUseRequestEditHelpDialog: React.FC<MyLodgeUseRequestEditHelpDialogProps> = (props) => {

  // #region Styles
  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
    >
      <DialogTitle>Request Edit</DialogTitle>
      <DialogContent>
        <MyLodgeUseRequestEditHelp />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default MyLodgeUseRequestEditHelpDialog;