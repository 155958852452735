// #region imports

import React from "react";

// Material-UI
import AppBar from '@mui/material/AppBar';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Froala Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';

// Froala Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/char_counter.min.css';

// Froala Editor
import FroalaEditorComponent from 'react-froala-wysiwyg';

// Components
import EmailFormHelpDialog from './EmailFormHelpDialog';

import {
  EmailAttachment,
  EmailMessage,
  LodgeUseEmailMessage
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  formatBytes
} from '../../global';

import {
  HTTPHeaders
} from '../../interfaces';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// #endregion

// #region interfaces

interface EmailProps {
  ids: Array<number> //MemberSignupIDs for email
}

// #endregion

const EmailForm: React.FC<EmailProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { enqueueSnackbar } = useSnackbar();

  const [emailAttachments, setEmailAttachments] = React.useState<Array<EmailAttachment>>([]);
  const [emailMessage, setEmailMessage] = React.useState<EmailMessage>(new EmailMessage());
  const [fileExt] = React.useState(['.pdf', '.doc', '.docx']); //['.txt', '.pdf', '.doc','.docx', '.json', '.html']);
  const [helpOpen, setHelpOpen] = React.useState<boolean>(false);
  const [sendDisabled, setSendDisabled] = React.useState<boolean>(true);
  const [working, setWorking] = React.useState<boolean>(false);

  // Reference to Froala component
  const froalaRef = React.useRef<FroalaEditorComponent | null>(null);
  const fileUploadRef = React.useRef<HTMLInputElement | null>(null);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      width: 600
    },
    appBar: {
      margin: theme.spacing(1, 0, 1, 0)
    },
    attachmentsHeading: {
      paddingTop: theme.spacing(1)
    },
    attachmentListItem: {
      margin: theme.spacing(1, 0, 0, 0)
    },
    attachmentListItemText: {
      padding: theme.spacing(0, 0, 0, 1)
    },
    editor: {
      margin: theme.spacing(1, 1, 1, 1)
    },
    fileUpload: {
      display: 'none'
    },
    menuItem: {
      marginRight: theme.spacing(2),
    },
    subject: {
      flexGrow: 1
      , margin: theme.spacing(1, 1, 1, 1)
    },
    toolbar: {
      margin: theme.spacing(1, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
   * Clear files in file upload FileList object
   */
  const clearFileList = () => {

    // Clear FileList queue
    if (fileUploadRef.current) {
      const dataTransfer = new DataTransfer();
      fileUploadRef.current.files = dataTransfer.files;
    }

  }

  /*
   * Reset email fields
   */
  const clearEmail = () => {
    setEmailMessage(new EmailMessage());
    setEmailAttachments([]);
  }

  /*
* Send lodge use email
*/
  const sendEmail = async () => {

    setWorking(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/email/lodgeuse`);

    const lodgeUseEmailMessage: LodgeUseEmailMessage = new LodgeUseEmailMessage();
    lodgeUseEmailMessage.ids = props.ids.join(',');
    lodgeUseEmailMessage.subject = emailMessage.subject;
    lodgeUseEmailMessage.htmlContent = emailMessage.htmlContent;
    if (emailAttachments.length > 0) {
      let attachments: string = EMPTY_STRING;
      emailAttachments.forEach((ea: EmailAttachment) => { attachments += `${attachments ? ',' : EMPTY_STRING}${ea.emailAttachmentID}` });
      lodgeUseEmailMessage.attachments = attachments;
    }

    await fetch(url.toString(), {
      method: 'POST'
      , body: JSON.stringify(lodgeUseEmailMessage)
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar(`WARNING: Email was not sent to lodge use members`, { variant: 'warning' });
        } else {
          enqueueSnackbar(`Email sent to selected lodge use members and guests`, { variant: 'success' });
          clearEmail();
        }
        setWorking(false);
      })
      .catch(error => {
        setWorking(false);
        enqueueSnackbar(`WARNING: Email was not sent to lodge use members, Error: ${error}`, { variant: 'error' });
      });
  }

  /*
   * Upload email attachment for storage
   */
  const uploadAttachment = async (file: File) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/email/attachment`);

    const formData: FormData = new FormData();
    formData.append('File', file);

    await fetch(url.toString(), {
      method: 'POST'
      , body: formData
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Attachment not uploaded');
        }
        return response.json()
      })
      .then((attachment: EmailAttachment) => {
        attachment.size = formatBytes(parseInt(attachment.size));
        setEmailAttachments(emailAttachments => [...emailAttachments, ...[attachment]]);
        enqueueSnackbar(`${file.name} (${formatBytes(file.size)}) attachment added`, { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(`WARNING: ${file.name} was not added as an attachment`, { variant: 'error' });
      });

  }

  /*
   * Is email valid w/ all necessary info?
   */
  const validateEmail = () => {

    if (props.ids.length > 0
      && emailMessage.subject
      && emailMessage.htmlContent
    ) {
      setSendDisabled(false);
    } else {
      setSendDisabled(true);
    }

  }

  // #endregion

  // #region Event handlers

  /*
   * Remove attachment from list of attachments
   */
  const handleAttachmentRemoved = (id: number) => {

    // TBD: Call API and remove from blob storage?

    const ea = emailAttachments.find((ea: EmailAttachment) => ea.emailAttachmentID === id);

    setEmailAttachments(emailAttachments => {
      return emailAttachments.filter((ea: EmailAttachment) => ea.emailAttachmentID !== id)
    });

    if (ea) {
      enqueueSnackbar(`${ea.attachmentFileName} attachment removed`, { variant: 'success' });
    }

  }

  /*
 * Add new attachment to list of attachments
 */
  const handleAttachmentUpload = async (files: FileList | null) => {

    // Check for only one file
    if (files && files.length === 1) {

      const file: File = files[0];

      // Test if file is already an attachment
      if (emailAttachments.findIndex((ea: EmailAttachment) => ea.attachmentFileName === file.name) !== -1) {
        enqueueSnackbar(`${file.name} is already an attachment`, { variant: 'warning' });
        clearFileList();
        return;
      }

      // Test max file size (10 MB)
      if (file.size > 10000000) {
        enqueueSnackbar(`${file.name} size of ${formatBytes(file.size)} exceeds the maximum attachment size of 10 MB`, { variant: 'warning' });
        return;
      }

      // Test for accepted file type
      const ext: string = file.name.substr(file.name.lastIndexOf('.'));
      //      if (fileMimetypes.includes(file.type) && fileExt.includes(ext)) {
      if (fileExt.includes(ext)) {
        await uploadAttachment(file);
      } else {
        enqueueSnackbar(`${file.name} is not a valid attachment file type`, { variant: 'warning' });
      }

      clearFileList();
    }

  }

  /*
   * Maps the button click event to the file upload element click event
   */
  const handleFileUploadClick = () => {
    fileUploadRef.current?.click();
  }

  /*
   * Handler for help dialog window
   */
  const handleHelpClose = () => {
    setHelpOpen(false);
  }

  /*
   * Handles Froala model change
   */
  const handleModelChange = (model: string) => {

    setEmailMessage({
      ...emailMessage
      , 'htmlContent': model
    });

  }

  /*
   * Handle changes to text field
   */
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setEmailMessage(emailMessage => {
      return {
        ...emailMessage
        , [event.target.name]: event.target.value
      }
    });

  };

  React.useEffect(() => {

    validateEmail();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailMessage]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <AppBar position="static" color="inherit" sx={styles.appBar}>
        <Toolbar>
          <form>
            <input
              type="file"
              ref={fileUploadRef}
              accept=".pdf,application/pdf,.doc,.docx,application/msword"
              style={styles.fileUpload}
              onChange={(event) => handleAttachmentUpload(event.target.files)}
            />
            <Tooltip title="Lodge use email help">
              <IconButton
                onClick={() => setHelpOpen(true)}
                sx={styles.menuItem}
                size="large">
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear all email content">
              <IconButton onClick={clearEmail} sx={styles.menuItem} size="large">
                <ClearIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add email attachment">
              <IconButton onClick={handleFileUploadClick} sx={styles.menuItem} size="large">
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
          </form>
          <Tooltip title="Send email">
            <span>
              <IconButton
                disabled={sendDisabled}
                onClick={sendEmail}
                sx={styles.menuItem}
                size="large">
                <SendIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid container item spacing={2} alignItems="center" justifyContent="flex-start">
          <Grid item xs={7}>
            <Typography sx={styles.menuItem}>{`Lodge Use Email Receipients: ${props.ids.length} members`}</Typography>
          </Grid>
          <Grid item>
            {working &&
              <CircularProgress size="30" />
            }
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <TextField
            name="subject"
            value={emailMessage.subject}
            autoFocus
            label="Subject"
            sx={styles.subject}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid container item sx={styles.editor}>
          <FroalaEditorComponent
            ref={froalaRef}
            tag='textarea'
            config={{
              charCounterCount: true
              , heightMin: 375
              , heightMax: 4000
              , key: 'AVB8B-21B2A2B2B2A2E1ugdbymcqxj1b1C-21A-16uB-11mH-9F2xspbA4B3A2A2I3H2C4C6B3D4=='
              , width: 4000
              , placeholderText: 'Enter and format email content here'
              , toolbarButtonsSM: {
                'moreText': {
                  'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                  , 'buttonsVisible': 3
                },
                'moreParagraph': {
                  'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'lineHeight', 'outdent', 'indent', 'quote']
                  , 'buttonsVisible': 7
                },
                'moreRich': {
                  'buttons': ['insertLink', 'insertHR', 'insertTable', 'emoticons', 'fontAwesome', 'embedly']
                  , 'buttonsVisible': 2
                },
                'moreMisc': {
                  'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                  'align': 'right',
                  'buttonsVisible': 2
                }
              }
              , toolbarButtonsXS: {
                'moreText': {
                  'buttons': ['bold', 'italic', 'underline', 'fontFamily', 'fontSize']
                  , 'buttonsVisible': 3
                },
                'moreParagraph': {
                  'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify']
                  , 'buttonsVisible': 3
                },
              }
            }}
            model={emailMessage.htmlContent}
            onModelChange={handleModelChange}
          />
        </Grid>
        {emailAttachments.length > 0 &&
          <>
            <Grid container item sx={styles.attachmentsHeading}>
              <Typography variant="button" >{`Attachments (${emailAttachments.length})`}</Typography>
            </Grid>
            <Grid container item >
              <List>
                {emailAttachments.map((a: EmailAttachment) => (
                  < Paper elevation={3} key={a.emailAttachmentID} sx={styles.attachmentListItem}>
                    <ListItem >
                      <ListItemText primary={`${a.attachmentFileName} (${a.size})`} sx={styles.attachmentListItemText} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() => handleAttachmentRemoved(a.emailAttachmentID)}
                          size="large">
                          <ClearIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Paper>
                ))}
              </List>
            </Grid>
          </>
        }
      </Grid>
      <EmailFormHelpDialog show={helpOpen} handleClose={handleHelpClose} />
    </Box>
  );

}

export default EmailForm;