// #region imports

import React from 'react';
import {
  addDays,
  differenceInCalendarDays,
  format,
  isValid as isDateValid,
  subDays
} from 'date-fns';

// Material-UI
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  DateRange
} from '../models';

import {
  DateFilterYear,
  EMPTY_STRING
} from '../global';

// Signup hooks
import { useSignupDates } from '../hooks/UseSignupDates';
import { useSystem } from '../hooks/UseSystem';

// #endregion

// #region interfaces

interface DateRangeFilterProps {
  clearDateRange: boolean;
  dateRange: DateRange; // Date range stored in parent
  disabled: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  filterYear: DateFilterYear;
  handleChange: (range: DateRange) => void;
  isArrivalDeparture: boolean; // Component labels
  maxInterval?: number;
}

// #endregion

const DateRangeFilter: React.FC<DateRangeFilterProps> = (props) => {

  const { getRequestYearEndDate, getRequestYearStartDate } = useSignupDates();
  const { getFiscalYearEndDate, getFiscalYearStartDate } = useSystem();

  const [disableStartEarlier, setDisableStartEarlier] = React.useState<boolean>(true);
  const [disableStartLater, setDisableStartLater] = React.useState<boolean>(true);
  const [disableEndEarlier, setDisableEndEarlier] = React.useState<boolean>(true);
  const [disableEndLater, setDisableEndLater] = React.useState<boolean>(true);
  const [disableInterval, setDisableInterval] = React.useState<boolean>(true);
  const [maxEnd, setMaxEnd] = React.useState<string>(EMPTY_STRING);
  const [minEnd, setMinEnd] = React.useState<string>(EMPTY_STRING);
  const [maxStart, setMaxStart] = React.useState<string>(EMPTY_STRING);
  const [minStart, setMinStart] = React.useState<string>(EMPTY_STRING);
  const [moveInterval, setMoveInterval] = React.useState<number>(0);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(0, 0, 0, 0),
    },
    datePicker: {
      width: 150
    },
    datePickerWithSpacer: {
      margin: theme.spacing(0, 1, 0, 0),
      width: 150
    }
  };

  // #endregion

  // #region Methods

  /*
*  Clear date range filter
*/
  const clearDateRange = (): void => {
    setDefaultMinMax();
  };

  /*
   * Set default min and max arrival/departure dates
   */
  const setDefaultMinMax = () => {
    if (props.filterYear === DateFilterYear.RequestYear) {
      setMinStart(getRequestYearStartDate());
      setMaxStart(getRequestYearEndDate());
      setMinEnd(getRequestYearStartDate());
      setMaxEnd(getRequestYearEndDate());
    } else {
      setMinStart(getFiscalYearStartDate());
      setMaxStart(getFiscalYearEndDate());
      setMinEnd(getFiscalYearStartDate());
      setMaxEnd(getFiscalYearEndDate());
    }
  }

  // #endregion

  // #region Event handlers

  /*
  * Handle change in end date
  */
  const handleEndDateChange = (date: Date | null): void => {

    // Test valid date format
    if (!isDateValid(date)) {
      // Resent max start date to default
      if (props.filterYear === DateFilterYear.RequestYear) {
        setMaxStart(getRequestYearEndDate());
      } else {
        setMaxStart(getFiscalYearEndDate());
      }
      return;
    } else if (date) {

      props.handleChange({
        ...props.dateRange,
        endDate: format(date, 'MM/dd/y')
      });

      // If selection interval is limited
      if (props.maxInterval) {

        const start = (props.filterYear === DateFilterYear.FiscalYear ? new Date(getFiscalYearStartDate()) : new Date(getRequestYearStartDate()));

        if (props.disablePast && (differenceInCalendarDays(start, date) > props.maxInterval)) {
          setMaxStart(format(start, 'MM/d/y'));
          setMinStart(format(start, 'MM/d/y'));
        } else {
          setMaxStart(format(subDays(date, 1), 'MM/dd/y'));
          setMinStart(format(subDays(date, props.maxInterval), 'MM/dd/y'));
        }
      } else {
        // Start can't be >= end
        setMaxStart(format(subDays(date, 1), 'MM/dd/y'));
      }

    }

  };

  /*
   * Handle move of one day for end date
   */
  const handleMoveEndDate = (move: number) => {
    const end = new Date(props.dateRange.endDate);

    if (Math.sign(move) === 1) {
      props.handleChange({
        ...props.dateRange,
        endDate: format(addDays(end, Math.abs(move)), 'MM/d/y')
      });
    } else if (Math.sign(move) === -1) {
      props.handleChange({
        ...props.dateRange,
        endDate: format(subDays(end, Math.abs(move)), 'MM/d/y')
      });
    }

  }

  /*
   * Handle move of one day for start date
   */
  const handleMoveStartDate = (move: number) => {
    const start = new Date(props.dateRange.startDate);

    if (Math.sign(move) === 1) {
      props.handleChange({
        ...props.dateRange,
        startDate: format(addDays(start, Math.abs(move)), 'MM/d/y')
      });
    } else if (Math.sign(move) === -1) {
      props.handleChange({
        ...props.dateRange,
        startDate: format(subDays(start, Math.abs(move)), 'MM/d/y')
      });
    }

  }

  /*
   * Handle move of one calendar interval
   */
  const handleMoveInterval = (move: number) => {
    const start = new Date(props.dateRange.startDate);
    const end = new Date(props.dateRange.endDate);

    if (Math.sign(move) === 1) {
      props.handleChange({
        ...props.dateRange,
        startDate: format(addDays(start, Math.abs(moveInterval)), 'MM/d/y'),
        endDate: format(addDays(end, Math.abs(moveInterval)), 'MM/d/y')
      });
    } else if (Math.sign(move) === -1) {
      props.handleChange({
        ...props.dateRange,
        startDate: format(subDays(start, Math.abs(moveInterval)), 'MM/d/y'),
        endDate: format(subDays(end, Math.abs(moveInterval)), 'MM/d/y')
      });
    }

  }

  /*
   * Handle change in start date
   */
  const handleStartDateChange = (date: Date | null): void => {

    // Test valid date format
    if (!isDateValid(date)) {
      // Reset min end date to default
      if (props.filterYear === DateFilterYear.RequestYear) {
        setMinEnd(getRequestYearStartDate());
      } else {
        setMinEnd(getFiscalYearStartDate());
      }
      return;
    } else if (date) {

      props.handleChange({
        ...props.dateRange,
        startDate: format(date, 'MM/dd/y')
      });

      // If selection interval is limited
      if (props.maxInterval) {
        const today = new Date();

        // Selected date is today
        if (props.disableFuture && (differenceInCalendarDays(date, today) > 0)) {
          setMaxEnd(format(today, 'MM/d/y'));
          setMinEnd(format(today, 'MM/d/y'));
        } else {
          setMaxEnd(format(addDays(date, props.maxInterval), 'MM/dd/y'));
          setMinEnd(format(addDays(date, 1), 'MM/dd/y'));
        }
      } else {
        // End start + 1
        setMinEnd(format(addDays(date, 1), 'MM/dd/y'));
      }
    }

  };

  React.useEffect(() => {
    setDefaultMinMax();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    // If selection interval is limited
    if (props.maxInterval) {

      if (props.dateRange
        && (props.dateRange.startDate && isDateValid(new Date(props.dateRange.startDate)))
        && (props.dateRange.endDate && isDateValid(new Date(props.dateRange.endDate)))
      ) {


        // Set calendar min/max selection
        const start = new Date(props.dateRange.startDate);
        // Display night based so subtract 1 from end date
        const end = new Date(props.dateRange.endDate);

        setMaxStart(format(subDays(end, 1), 'MM/dd/y'));
        setMinStart(format(subDays(end, props.maxInterval), 'MM/dd/y'));
        setMaxEnd(format(addDays(start, props.maxInterval), 'MM/dd/y'));
        setMinEnd(format(addDays(start, 1), 'MM/dd/y'));

        // # of days in calender up to max
        const days = differenceInCalendarDays(new Date(props.dateRange.endDate), new Date(props.dateRange.startDate));

        // Move buttons based upon max allowable # of days
        if (days === 1) {
          setDisableEndEarlier(true);
          setDisableEndLater(false);
          setDisableStartEarlier(false);
          setDisableStartLater(true);
        }
        else if (days < props.maxInterval) {
          setDisableEndEarlier(false);
          setDisableEndLater(false);
          setDisableStartEarlier(false);
          setDisableStartLater(false);
        } else if (days === props.maxInterval) {
          setDisableEndEarlier(false);
          setDisableEndLater(true);
          setDisableStartEarlier(true);
          setDisableStartLater(false);
        }
        setDisableInterval(false);

        // Add day to interval so start date is one beyond current
        setMoveInterval((days > props.maxInterval) ? props.maxInterval : days);

      } else {
        setDisableEndEarlier(true);
        setDisableEndLater(true);
        setDisableInterval(true);
        setDisableStartEarlier(true);
        setDisableStartLater(true);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange.startDate, props.dateRange.endDate]);

  React.useEffect(() => {
    clearDateRange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clearDateRange]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Stack direction="row" alignItems="flex-end">
        <Tooltip title={`Move back ${moveInterval} days`}>
          <span>
            <IconButton size="small" disabled={disableInterval || props.disabled} onClick={() => handleMoveInterval(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Move date one day earlier">
          <span>
            <IconButton size="small" disabled={disableStartEarlier || props.disabled} onClick={() => handleMoveStartDate(-1)}>
              <NavigateBeforeIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Move date one day later">
          <span>
            <IconButton size="small" disabled={disableStartLater || props.disabled} onClick={() => handleMoveStartDate(1)}>
              <NavigateNextIcon />
            </IconButton>
          </span>
        </Tooltip>
        <DatePicker
          disabled={props.disabled}
          disableFuture={props.disableFuture ? props.disableFuture : false}
          disablePast={props.disablePast ? props.disablePast : false}
          inputFormat="MM/dd/yyyy"
          label={`${props.isArrivalDeparture ? 'Arrive' : 'Start'}`}
          minDate={minStart ? new Date(minStart) : undefined}
          maxDate={maxStart ? new Date(maxStart) : undefined}
          onChange={handleStartDateChange}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} sx={styles.datePickerWithSpacer} />}
          value={props.dateRange.startDate === EMPTY_STRING ? null : new Date(props.dateRange.startDate)}
          views={['month', 'day']}
        />
        <DatePicker
          defaultCalendarMonth={props.dateRange.startDate ? new Date(props.dateRange.startDate) : undefined}
          disabled={props.disabled}
          disableFuture={props.disableFuture ? props.disableFuture : false}
          disablePast={props.disablePast ? props.disablePast : false}
          inputFormat="MM/dd/yyyy"
          label={`${props.isArrivalDeparture ? 'Depart' : 'End'}`}
          minDate={minEnd ? new Date(minEnd) : undefined}
          maxDate={maxEnd ? new Date(maxEnd) : undefined}
          onChange={handleEndDateChange}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} sx={styles.datePicker} />}
          value={props.dateRange.endDate === EMPTY_STRING ? null : new Date(props.dateRange.endDate)}
          views={['month', 'day']}
        />
        <Tooltip title="Move date one day earlier">
          <span>
            <IconButton size="small" disabled={disableEndEarlier || props.disabled} onClick={() => handleMoveEndDate(-1)}>
              <NavigateBeforeIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Move date one day later">
          <span>
            <IconButton size="small" disabled={disableEndLater || props.disabled} onClick={() => handleMoveEndDate(1)}>
              <NavigateNextIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={`Move forward ${moveInterval} days`}>
          <span>
            <IconButton size="small" disabled={disableInterval || props.disabled} onClick={() => handleMoveInterval(1)}>
              <ArrowForwardIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Box>
  );

}

export default DateRangeFilter;