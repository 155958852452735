// #region imports

import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'


// Styles
import { useTheme } from '@mui/material/styles';

import {
  API_URL, EMPTY_STRING
} from '../../global';

import {
  GuestType
  , HTTPHeaders
} from '../../interfaces';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// #endregion

// #region Interfaces

interface GuestTypeSelectProps {
  guestTypeID: number;
  guestTypeChanged(id: number, name: string): any;
}

// #endregion

const GuestTypeSelect: React.FC<GuestTypeSelectProps> = (props) => {

  const { getAccessToken } = useAuth0User();

  const [errors, setErrors] = React.useState(false);
  const [guestTypes, setGuestTypes] = React.useState<Array<GuestType>>([]);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      background: '#F5F5F5',
      padding: theme.spacing(1)
    },
    selectWidth: {
      flexGrow: 1,
      margin: theme.spacing(0),
      minWdith: 200
    },
  };

  // #endregion

  // #region Methods

  /*
* Fetch guest types for select element
*/
  const fetchGuestTypes = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/guesttype`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Guest types not retrieved');
        }
        return response.json()
      })
      .then((guesttypes: Array<GuestType>) => setGuestTypes(guesttypes))
      .catch(err => setErrors(err));

  }

  // #endregion

  // #region Event handlers

  /*
   * Event handler for changes to guest type
   */
  const handleGuestTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.value);
    const gt = guestTypes.find(gt => gt.guestTypeID === id);
    props.guestTypeChanged(id, gt ? gt?.name : EMPTY_STRING);
  }

  React.useEffect(() => {
    fetchGuestTypes();
  }, []);

  // #endregion

  return (
    <TextField
      select
      label='Guest Type'
      sx={styles.selectWidth}
      onChange={handleGuestTypeChange}
      value={props.guestTypeID.toString()}
    >
      <MenuItem key={-1} value={-1}>Select guest type</MenuItem>
      {guestTypes.map((gt: GuestType) => (
        <MenuItem key={gt.guestTypeID} value={gt.guestTypeID}>{gt.name}</MenuItem>
      ))}
    </ TextField>
  )
}

export default GuestTypeSelect;