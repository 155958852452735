import {
  APP_COPYRIGHT,
  APP_RELEASE_DATE,
  APP_VERSION
} from "../ActionTypes";

export const setCopyright = (copyright: string) => ({
  type: APP_COPYRIGHT,
  payload: copyright
});

export const setReleaseDate = (releaseDate: Date) => ({
  type: APP_RELEASE_DATE,
  payload: releaseDate
});

export const setVersion = (version: string) => ({
  type: APP_VERSION,
  payload: version
});
