// #region imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import Typography from '@mui/material/Typography';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';
// Responsive hooks
import { useResponsive } from '../hooks/UseResponsive';

// #endregion

// #endregion

const MembershipHelp: React.FC = () => {

  const { isMembershipAdmin } = useAuth0User();
  const { isMobile } = useResponsive();

  // #region Styles
  // #endregion

  return (
    <Box>
      {isMobile &&
        <>
          <Typography variant="h6">Mobile</Typography>
          <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Tap <FilterListIcon fontSize="small" /> to view the member status filter panel. Tap <CloseIcon fontSize="small" /> to close the filter panel and view filtered membership list.</Typography>
        </>
      }
      <Typography variant="h6">Searching</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Search for members by entering the first few characters of their first or last name. If the members are not listed, delete a few charcters for a broader search.</Typography>
      <Typography variant="h6" >Filtering</Typography>
      <Typography variant="caption" >Full Membership List</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Select one or more of the status values without any search text to display all the members with that status.</Typography>
      <Typography variant="caption" >Search Results</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>When search results are present, filters apply to the search results only. Clear will remove the filters and display all members matching the search text.</Typography>
      {isMembershipAdmin() &&
        <>
          <Typography variant="h6">{`Administration`}</Typography>
          <Typography variant={isMobile ? 'body2' : 'body1'}>{`As a membership administrator, all members will have an Edit button. The View button is not present in admin mode.`}</Typography>
        </>
      }
    </Box>
  );

}

export default MembershipHelp;