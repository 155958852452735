// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders
} from "../../interfaces";

import {
  EmailQueueStatus
} from "../../models";

import {
  API_URL
} from '../../global';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// #endregion

// #region Interfaces

interface EmailQueueSummaryProps {
  refresh: boolean;
}

// #endregion

const EmailQueueSummary: React.FC<EmailQueueSummaryProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();
  const { enqueueSnackbar } = useSnackbar();

  const [errors, setErrors] = React.useState(false);
  const [emailQueueStatus, setEmailQueueStatus] = React.useState<EmailQueueStatus>(new EmailQueueStatus());

  // #region Styles

  const theme = useTheme();
  const styles = {
    emailQueueStatus: {
      flexGrow: 1,
      textAlign: 'right',
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
    toolbarItem: {
      borderRight: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
  };

  // #endregion

  // #region Methods

  /*
 * Fetch email queue status
 */
  const fetchEmailQueueStatus = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/lodgeuse/emailqueue/status`);

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Email queue status not retrieved');
        }
        return response.json()
      })
      .then((eq: EmailQueueStatus) => {
        setEmailQueueStatus(eq);
      })
      .catch(err => {
        setErrors(err);
      });
  }

 /*
* Send lodge use status emails
*/
  const sendEmails = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/email/lodgeuse/status`);

    await fetch(url.toString(), {
      method: 'GET',
      cache: 'no-cache',
      headers: headers,
      mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar('Error processing request status emails.', { variant: 'warning' });
          throw new Error('Error processing request status emails.');
        } else {
          enqueueSnackbar('Request status emails sent.', { variant: 'success' });
          fetchEmailQueueStatus();
        }
      })
      .catch(err => {
        setErrors(err);
        enqueueSnackbar('Error processing requeste status emails.', { variant: 'warning' });
      });
  }

  React.useEffect(() => {

    fetchEmailQueueStatus();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  //#endregion

  return (
    <Box sx={styles.emailQueueStatus} >
      <Tooltip title="Send request status emails">
        <span>
          <IconButton
            disabled={(emailQueueStatus.requestCount === 0)}
            onClick={sendEmails}
            size="large"
            sx={styles.toolbarItem}
          >
            {(emailQueueStatus.requestCount > 0) &&
              <span>*</span>
            }
            <EmailIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Typography variant="overline">
        {`${emailQueueStatus.requestCount} Request Status Emails ${dateStringToLocalDateFormatted(emailQueueStatus.statusTime)}`}
      </Typography>
    </Box >
  );

}

export default EmailQueueSummary;