import {
  SIGNUP_CANCELLED,
  SIGNUP_CLEAR_CALENDARFILTER,
  SIGNUP_GUEST_CANCELLED,
  SIGNUP_GUEST_REQUEST_TIME,
  SIGNUP_GUEST_SAVED,
  SIGNUP_MAXDEPARTURE,
  SIGNUP_MEMBER_CANCELLED,
  SIGNUP_MEMBER_SAVED,
  SIGNUP_MINARRIVAL,
  SIGNUP_NOTSIGNEDUP_ADDED,
  SIGNUP_OFFSEASON_STARTDATE
} from "../ActionTypes";
 
export const setSignupCancelled = (signupCancelled:boolean) => ({
  type: SIGNUP_CANCELLED,
  payload: signupCancelled
});

export const setClearCalendarFilter = (clearCalendarFilter: boolean) => ({
  type: SIGNUP_CLEAR_CALENDARFILTER,
  payload: clearCalendarFilter
});

export const setGuestCancelled = (guestCancelled: boolean) => ({
  type: SIGNUP_GUEST_CANCELLED,
  payload: guestCancelled
});

export const setGuestRequestTime = (guresRequestTime: number) => ({
  type: SIGNUP_GUEST_REQUEST_TIME,
  payload: guresRequestTime
});

export const setGuestSaved = (guestSaved: boolean) => ({
  type: SIGNUP_GUEST_SAVED,
  payload: guestSaved
});

export const setMemberCancelled = (memberCancelled: boolean) => ({
  type: SIGNUP_MEMBER_CANCELLED,
  payload: memberCancelled
});

export const setMemberSaved = (memberSaved: boolean) => ({
  type: SIGNUP_MEMBER_SAVED,
  payload: memberSaved
});

export const setMaxDeparture = (maxDeparture: string) => ({
  type: SIGNUP_MAXDEPARTURE,
  payload: maxDeparture
});

export const setMinArrival = (minArrival: string) => ({
  type: SIGNUP_MINARRIVAL,
  payload: minArrival
});

export const setNotSignedUpAdded = (notSignedUpAdded: boolean) => ({
  type: SIGNUP_NOTSIGNEDUP_ADDED,
  payload: notSignedUpAdded
});

export const setOffSeasonStartDate = (offSeasonStartDate: Date) => ({
  type: SIGNUP_OFFSEASON_STARTDATE,
  payload: offSeasonStartDate
});