// #region imports

import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

import {
  PhoneType
} from '../interfaces';

import { NULL_ID } from '../global';

// #endregion

// #region interfaces

interface PhoneTypeSelectProps {
  disabled: boolean;
  name: string;
  phoneTypeID: number;
  phoneTypes: Array<PhoneType>;
  phoneTypeSelected: (phoneTypeID: number, type: string) => void;
}

// #endregion

const PhoneTypeSelect: React.FC<PhoneTypeSelectProps> = (props) => {

  // #region Styles

  const styles = {
    selectWidth: {
      flexGrow: 1,
      minWidth: 200,
    },
  };

  // #endregion

  // #region Event handlers

  /*
   * Handles change in status
   */
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Convert value to number
    props.phoneTypeSelected(Number(event.target.value), props.name);

  };

  // #endregion

  return (
    <TextField
      sx={styles.selectWidth}
      fullWidth
      label="Phone Type"
      name={props.name}
      onChange={handleStatusChange}
      select
      value={props.phoneTypeID.toString()}
    >
      <MenuItem key={NULL_ID} value={NULL_ID}>None</MenuItem>
      {props.phoneTypes.map((pt: PhoneType) => (
        <MenuItem key={pt.phoneTypeID} value={pt.phoneTypeID}>{pt.type}</MenuItem>
      ))}
    </TextField>
  );

}

export default PhoneTypeSelect;