// #region imports

import { AUTH0_CONFIG } from './Auth0Config';
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

import App from './App';

// Redux store
import { Provider } from "react-redux";
import store from "./store";

// #endregion

// #region Methods

/*
* Auth0 redirect after authentication
*/
const onRedirectCallback = (appState: any) => {

  const history = createBrowserHistory();

  // Navigate after Auth0 callback
  // Auth0 returnTo of current location
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
}

// #endregion


const container = document.getElementById('appContainer');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <Auth0Provider
    audience={AUTH0_CONFIG.audience}
    cacheLocation='memory'
    clientId={AUTH0_CONFIG.clientID}
    domain={AUTH0_CONFIG.domain}
    onRedirectCallback={onRedirectCallback}
    redirectUri={AUTH0_CONFIG.redirectUri}
    useRefreshTokens={true}
  >
    <Provider store={store}>
      <App />
    </Provider >
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
