// #region imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  DateRange,
  PriorityPeriod
} from '../../models';

import {
  DateFilterYear,
  REQUEST_FILTER_MAX_INTERVAL,
  SignupCalendarView
} from '../../global';

import PriorityPeriodList from '../../shared/PriorityPeriodList';
import DateRangeFilter from '../../shared/DateRangeFilter';
import DateRangeFilterMobile from '../../shared/DateRangeFilterMobile';

// Redux
import { useSelector } from "react-redux";

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region interfaces

interface CalendarFilterProps {
  clearFilter: boolean;
  dateRange: DateRange;
  handleFilterChanged: (dateRange: DateRange) => void;
  disableActions: boolean;
  handleClear: () => void;
  handleRefresh: () => void;
  handleViewChanged: (view: SignupCalendarView) => void;
}

// #endregion

const CalendarFilter: React.FC<CalendarFilterProps> = (props) => {

  const { isMobile } = useResponsive();

  const [offSeason, setOffSeason] = React.useState<boolean>(false);
  const [view, setView] = React.useState<SignupCalendarView>(SignupCalendarView.All);

  // Redux
  const offSeasonStartDate: Date = useSelector((state: any) => state.signups.offSeasonStartDate);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      margin: theme.spacing(0, 1, 0, 1),
    },
    topContainer: {
      padding: theme.spacing(0, 0, 2, 0),
    },
    viewWidth: {
      flexGrow: 1,
      minWidth: 150,
    },
  };

  // #endregion

  // #region Methods
  // #endregion

  // #region Event handlers

  /*
   * Handle changes to date filter
   */
  const handleFilter = (dateRange: DateRange) => {
    props.handleFilterChanged(dateRange);
  }

  /*
   * Handler for change in selected weekend
   */
  const handlePriorityPeriodChange = (period: PriorityPeriod) => {
    const d = new DateRange(period.arrivalDate, period.departureDate);
    props.handleFilterChanged(d);
  }

  /*
   * Handle changes to date filter
   */
  const handleClear = () => {
    setView(SignupCalendarView.All);
    props.handleClear();
  }

  /*
   * Handle refresh
   */
  const handleRefresh = () => {
    props.handleRefresh();
  }

  /*
   * Handle change in requests view
   */
  const handleViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const view = Number(event.target.value); // SignupCalendarView;
    setView(view);
    props.handleViewChanged(view);
  }

  React.useEffect(() => {
    if (offSeasonStartDate !== undefined) {
      // Set offseason flag in local state
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const offSeasonDate = new Date(offSeasonStartDate)
      const isOffSeason = (today >= offSeasonDate);
      setOffSeason(isOffSeason);
    }
  }, [offSeasonStartDate]);


  // #endregion

  return (
    <Box sx={styles.root}>
      {!isMobile &&
        <Grid container item spacing={isMobile ? 1 : 2} alignItems="flex-end" sx={styles.topContainer}>
          {!offSeason &&
            <Grid item xs={7}>
              <PriorityPeriodList
                disabled={false}
                handlePriorityPeriodChange={handlePriorityPeriodChange}
                reset={props.clearFilter}
              />
            </Grid>
          }
          <Grid item>
            <TextField
              select
              label='View'
              sx={styles.viewWidth}
              onChange={handleViewChange}
              value={view.toString()}
            >
              <MenuItem key={SignupCalendarView.All} value={SignupCalendarView.All}>All</MenuItem>
              <MenuItem key={SignupCalendarView.Household} value={SignupCalendarView.Household}>My Household</MenuItem>
            </TextField>
          </Grid>
          <Grid item>
            <Tooltip title="Refresh the request calendar">
              <span>
                <Button variant="outlined" color="primary" disabled={props.disableActions} onClick={handleRefresh}>
                  <RefreshIcon />
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Clear filter and request calendar">
              <span>
                <Button variant="outlined" color="primary" disabled={props.disableActions} onClick={handleClear}>
                  <ClearIcon />
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      }
      <Grid container item alignItems="flex-end" spacing={1}>
        {!isMobile &&
          <>
            <Grid item xs={9}>
              <DateRangeFilter
                clearDateRange={props.clearFilter}
                disabled={false}
                dateRange={props.dateRange}
                filterYear={DateFilterYear.RequestYear}
                handleChange={handleFilter}
                isArrivalDeparture={true}
                maxInterval={REQUEST_FILTER_MAX_INTERVAL}
              />
            </Grid>
          </>
        }
        {isMobile &&
          <DateRangeFilterMobile
            clearDateRange={props.clearFilter}
            disabled={false}
            disablePast={true}
            dateRange={props.dateRange}
            filterYear={DateFilterYear.RequestYear}
            handleChange={handleFilter}
            isArrivalDeparture={true}
            maxInterval={REQUEST_FILTER_MAX_INTERVAL}
          />
        }
      </Grid>
    </Box>
  );

}

export default CalendarFilter;