// #region Imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import MemberAvatar from './MemberAvatar';

import { Member } from '../interfaces';

import {
  formatGenderAge,
  HouseholdMode
} from '../global';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// #endregion

// #region Interfaces

interface MemberListActionProps {
  handleHouseholdOpen: (id: number, view: HouseholdMode) => void;
  householdMemberIDs: Array<number>;
  member: Member;
}

// #endregion

const MemberListAction: React.FC<MemberListActionProps> = (props) => {

  const { isMembershipAdmin } = useAuth0User();

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1
      , borderTop: '1px solid #e0e0e0'
      , padding: theme.spacing(2, 0, 0, 0)
    },
    editIcon: {
      margin: theme.spacing(1, 0, 1, 0)
    },
    statusDisplay: {
      marginTop: theme.spacing(1)
    },
  };

  // #endregion

  /*
 * Renders action button for edit/view of household
 */
  const renderActionButton = (id: number): React.ReactElement => {

    // Admin, current user, or other household members
    if (isMembershipAdmin() || props.householdMemberIDs.includes(id)) {
      return (
        <Tooltip title="Edit household members info">
          <Button size="small" onClick={() => { props.handleHouseholdOpen(id, HouseholdMode.Edit) }}>Edit</Button>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title="View household members info">
          <Button size="small" onClick={() => { props.handleHouseholdOpen(id, HouseholdMode.View) }}>View</Button>
        </Tooltip>
      )
    }

  }

  return <>
    <Grid container item justifyContent="center">
      <MemberAvatar member={props.member} />
    </Grid>
    <Grid container item justifyContent="center" sx={styles.statusDisplay} >
      <Typography variant="caption" align="center">{props.member.memberStatus.status}</Typography>
    </Grid>
    {(props.member.gender || props.member.age) &&
      < Grid container item justifyContent="center">
        <Typography variant="caption">{`(${formatGenderAge(props.member.gender, props.member.age)})`}</Typography>
      </Grid>
    }
    <Grid container item justifyContent="center" sx={styles.editIcon} >
      {renderActionButton(props.member.memberID)}
    </Grid>
  </>;

}

export default MemberListAction;
