import React from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from '@mui/icons-material/List';
import MessageIcon from '@mui/icons-material/Message';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import {
  ROUTER_PATH_ADMIN,
  ROUTER_PATH_HOME,
  ROUTER_PATH_MEMBERSHIP,
  ROUTER_PATH_RESOURCES,
  ROUTER_PATH_SIGNUPS
} from '../global';

// Auth0 user hooks
import { useAuth0User } from '../hooks/UseAuth0User';

interface SideBarMenuProps {
  handleCloseSidebarMenu: () => void;
  aboutSelectedHandler: () => void;
  feedbackSelectedHandler: () => void;
  logoutSelectedHandler: () => void;
  profileSelectedHandler: () => void;
}

interface ListItemLinkProps {
  divider?: boolean;
  icon?: React.ReactElement;
  linkSelectedHandler: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  primary: string;
  to: string;
}

const SideBarMenu: React.FC<SideBarMenuProps> = (props: SideBarMenuProps) => {

  // Auth0 hooks
  const { userName } = useAuth0User();

  // #region Styles
  // #endregion

  // #region Methods

  /*
   * Custom component for a link in a list
   */
  const ListItemLink = (props: ListItemLinkProps) => {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
          itemProps,
          ref,
        ) {
          return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
        }),
      [to],
    );

    return (
      <li>
        <ListItem
          button
          component={renderLink} onClick={handleLinkSelected} >
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }

  // #endregion

  const handleLinkSelected = () => {
    props.handleCloseSidebarMenu();
  }

  return <>
    <Grid container item justifyContent="flex-end">
      <IconButton onClick={handleLinkSelected} size="large">
        <CloseIcon />
      </IconButton>
    </Grid>
    <Divider />
    <List component="nav">
      <ListItemLink to={ROUTER_PATH_HOME} primary="Home" icon={<HomeIcon />} linkSelectedHandler={handleLinkSelected} />
      <ListItemLink to={ROUTER_PATH_SIGNUPS} primary="Signups" icon={<AssignmentIcon />} linkSelectedHandler={handleLinkSelected} />
      <ListItemLink to={ROUTER_PATH_RESOURCES} primary="Resources" icon={<ListIcon />} divider={true} linkSelectedHandler={handleLinkSelected} />
      <ListItemLink to={ROUTER_PATH_MEMBERSHIP} primary="Membership" icon={<PeopleIcon />} linkSelectedHandler={handleLinkSelected} />
      <ListItemLink to={ROUTER_PATH_ADMIN} primary="Admin" icon={<ListIcon />} divider={true} linkSelectedHandler={handleLinkSelected} />
    </List>
    <List component="nav">
      <ListItem button onClick={props.profileSelectedHandler}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={`${userName()} Profile`} />
      </ListItem>
      <ListItem button divider={true} onClick={props.feedbackSelectedHandler}>
        <ListItemIcon>
          <MessageIcon />
        </ListItemIcon>
        <ListItemText primary="Provide Feedback" />
      </ListItem>
    </List>
    <List component="nav">
      <ListItem button onClick={props.aboutSelectedHandler}>
        <ListItemText primary="About the MSC Website" />
      </ListItem>
      <ListItem button onClick={props.logoutSelectedHandler}>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  </>;
}

export default SideBarMenu;
