// #region Imports

import React from 'react';

// Material-UI
import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Drawer from '@mui/material/Drawer';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import About from '../misc/About';
import FeedbackForm from '../admin/feedback/FeedbackForm';
import Household from '../membership/Household';
import HouseholdEditAbandonDialog from '../membership/HouseholdEditAbandonDialog';
import SideBarMenu from './SideBarMenu';

import {
  HouseholdMode,
  ROUTER_PATH_ADMIN,
  ROUTER_PATH_HOME,
  ROUTER_PATH_MEMBERSHIP,
  ROUTER_PATH_RESOURCES,
  ROUTER_PATH_SIGNUPS
} from '../global';

// Auth0 user hooks
import { useAuth0User } from '../hooks/UseAuth0User';
// Responsive hooks
import { useResponsive } from '../hooks/UseResponsive';

// #endregion


const TopBar: React.FC = () => {

  const { isAuthenticated, isEmailAdmin, isPassAdmin, isSignupCoordinator, isSystemAdmin, logout, memberID, userName } = useAuth0User();
  const { isMobile } = useResponsive();

  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [aboutOpen, setAboutOpen] = React.useState(false);
  const [profileAbandonDialogOpen, setProfileAbandonDialogOpen] = React.useState<boolean>(false);
  const [profileChanged, setProfileChanged] = React.useState<boolean>(false);
  const [profileDrawerOpen, setProfileDrawerOpen] = React.useState<boolean>(false);
  const [sideBarMenuVisible, setSideBarMenuVisible] = React.useState<boolean>(false);

  const anchorRefAccountMenu = React.useRef<HTMLButtonElement>(null);
  const prevAccountMenuOpen = React.useRef(accountMenuOpen);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
    },
    menuLink: {
      color: '#FFFFFF',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    profileDrawer: {
      padding: theme.spacing(2),
      flex: 1
    },
    title: {
      flexGrow: 1,
    },
    titleLink: {
      color: '#FFFFFF',
    },
  };

  // #endregion

  // #region Event Handlers

  /*
   * Handle about window close
   */
  const handleAboutClose = () => {
    setAboutOpen(false);
  };

  /*
   * Handle about window selected
   */
  const handleAboutSelected = () => {
    if (isMobile) {
      setSideBarMenuVisible(false);
    } else {
      setAccountMenuOpen(false);
    }
    setAboutOpen(true);
  };

  /*
* Handle account menu toggle
*/
  const handleAccountMenuToggle = () => {
    setAccountMenuOpen(prevAccountMenuOpen => !prevAccountMenuOpen);
  };

  /*
  * Handle account menu close
  */
  const handleAccountMenuClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRefAccountMenu.current && anchorRefAccountMenu.current.contains(event.target as HTMLElement)) {
      return;
    }

    setAccountMenuOpen(false);
  };

  /*
  * Handle account menu key event
  */
  const handleAccountMenuListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAccountMenuOpen(false);
    }
  }

  /*
   * Handle feedback window close
   */
  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  /*
   * Handle feedback window open
   */
  const handleFeedbackSelected = () => {
    if (isMobile) {
      setSideBarMenuVisible(false);
    } else {
      setAccountMenuOpen(false);
    }
    setFeedbackOpen(true);
  };

  /*
   * Handle navigation link selected
   */
  const handleCloseSidebarMenu = () => {
    setSideBarMenuVisible(false);
  };

  /*
* Event handler for Logout element
*/
  const handleLogoutSelected = (): void => {
    if (isMobile) {
      setSideBarMenuVisible(false);
    } else {
      setAccountMenuOpen(false);
    }
    logout();
  }

  /*
* Event handler for edit abandon
*/
  const handleProfileAbandon = () => {
    setProfileAbandonDialogOpen(false);
    setProfileDrawerOpen(false);
  };

  /*
* Event handler for edit continue
*/
  const handleProfileContinue = () => {
    setProfileAbandonDialogOpen(false);
  };

  /*
 * Handle household/member changed state
 */
  const handleProfileSelected = () => {
    if (isMobile) {
      setSideBarMenuVisible(false);
    } else {
      setAccountMenuOpen(false);
    }
    setProfileDrawerOpen(true);
  }

  /*
 * Handle household/member changed state
 */
  const handleProfileChanged = (changed: boolean) => {
    setProfileChanged(changed);
  }

  /*
   * Handle closing of household paneal w/ changed check
   */
  const handleProfileClose = () => {
    if (profileChanged) {
      setProfileAbandonDialogOpen(true);
    } else {
      setProfileDrawerOpen(false);
    }
  };

  /*
  * Return focus to the account menu item when we transitioned from !open -> open
  */
  React.useEffect(() => {
    if (prevAccountMenuOpen.current === true && accountMenuOpen === false) {
      if (anchorRefAccountMenu.current) {
        anchorRefAccountMenu.current.focus();
      }
    }

    prevAccountMenuOpen.current = accountMenuOpen;
  }, [accountMenuOpen]);

  // #endregion

  return <>
    <div style={styles.root}>
      {(isMobile && isAuthenticated) &&
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              sx={styles.menuButton}
              color="inherit"
              onClick={() => setSideBarMenuVisible(true)}
              size="large">
              <MenuIcon />
            </IconButton>
            <div style={styles.title}>
              <Typography variant="h6">Montclair Ski Club</Typography>
            </div>
          </Toolbar>
        </AppBar>
      }
      {(!isMobile && isAuthenticated) &&
        <AppBar position="sticky">
          <Toolbar>
            <div style={styles.title}>
              <Typography variant="h6">
                <Link component={RouterLink} to={ROUTER_PATH_HOME} underline="none" sx={styles.titleLink}>Montclair Ski Club</Link>
              </Typography>
            </div>
            <>
              <Button component={RouterLink} to={ROUTER_PATH_SIGNUPS} sx={styles.menuLink}>Signups</Button>
              <Button component={RouterLink} to={ROUTER_PATH_RESOURCES} sx={styles.menuLink}>Resources</Button>
              <Button component={RouterLink} to={ROUTER_PATH_MEMBERSHIP} sx={styles.menuLink}>Membership</Button>
              {(isEmailAdmin() || isPassAdmin() || isSignupCoordinator() || isSystemAdmin()) &&
                <Button component={RouterLink} to={ROUTER_PATH_ADMIN} sx={styles.menuLink}>Admin</Button>
              }
              <Tooltip title="Account Options" >
                <IconButton
                  ref={anchorRefAccountMenu}
                  color="inherit"
                  onClick={handleAccountMenuToggle}
                  size="large">
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Popper open={accountMenuOpen} anchorEl={anchorRefAccountMenu.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleAccountMenuClose}>
                        <MenuList autoFocusItem={accountMenuOpen} onKeyDown={handleAccountMenuListKeyDown}>
                          <MenuItem onClick={handleProfileSelected}>{`${userName()} Profile`}</MenuItem>
                          <MenuItem onClick={handleFeedbackSelected}>Provide Feedback</MenuItem>
                          <MenuItem divider onClick={handleAboutSelected}>About the MSC Website</MenuItem>
                          <MenuItem onClick={handleLogoutSelected}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          </Toolbar>
        </AppBar>
      }
    </div>
    {(isMobile && isAuthenticated) &&
      <Drawer
        anchor={"left"}
        open={sideBarMenuVisible}
        onClose={() => setSideBarMenuVisible(false)}
      >
        <SideBarMenu
          aboutSelectedHandler={handleAboutSelected}
          feedbackSelectedHandler={handleFeedbackSelected}
          handleCloseSidebarMenu={handleCloseSidebarMenu}
          logoutSelectedHandler={handleLogoutSelected}
          profileSelectedHandler={handleProfileSelected}
        />
      </Drawer>
    }
    <About open={aboutOpen} onClose={handleAboutClose} />
    <FeedbackForm open={feedbackOpen} onClose={handleFeedbackClose} />
    <Drawer
      variant="temporary"
      anchor={isMobile ? 'bottom' : 'right'}
      open={profileDrawerOpen}
      onClose={handleProfileClose}
    >
      <div style={styles.profileDrawer}>
        <Household
          memberID={memberID()}
          mode={HouseholdMode.Edit}
          setChangedState={handleProfileChanged}
          closeHouseholdDrawer={handleProfileClose} />
      </div>
    </Drawer>
    <HouseholdEditAbandonDialog
      show={profileAbandonDialogOpen}
      handleAbandon={handleProfileAbandon}
      handleContinue={handleProfileContinue} />
  </>;
}

export default TopBar;
