import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { amber } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import Calendar from './requests/Calendar';
import MyLodgeUse from './mylodgeuse/MyLodgeUse';
import RequestForm from './newrequest/RequestForm';
import SignupsOffline from './SignupsOffline';
import SignupSummary from './status/Status';

import {
  HTTPHeaders
} from '../interfaces';

import {
  HouseholdMemberWaiverStatus
} from '../models';

import {
  API_URL,
  NULL_DATE
} from '../global';

// Redux hooks
import { useDispatch, useSelector } from 'react-redux';
import {
  memberEditSavedReset
} from "../../store/membership/actions";

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../hooks/UseResponsive';

enum Nav {
  MyLodgeUse = 0,
  Requests = 1,
  Status = 2,
  NewRequest = 3
}

// #region Interfaces

interface SignupsProps {
  signupsOffline: boolean;
  signupsStartTime: string;
}

// #endregion

const Signups: React.FC<SignupsProps> = (props: SignupsProps) => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();

  const [activeNav, setActiveNav] = React.useState(Nav.MyLodgeUse);
  const [errors, setErrors] = React.useState<boolean>(false);
  const [offSeason, setOffSeason] = React.useState<boolean>(false);
  const [offSeasonStartDate, setOffSeasonStartDate] = React.useState<Date>(NULL_DATE);
  const [waiversNeeded, setWaiversNeeded] = React.useState<Array<HouseholdMemberWaiverStatus>>([]);

  // Redux
  const dispatch = useDispatch();
  const memberEditSaved: boolean = useSelector((state: any) => state.membership.memberEditSaved);
  const startDate: Date = useSelector((state: any) => state.signups.offSeasonStartDate);

  // #region Styles

  const theme = useTheme();
  const styles = {
    nav: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      flexGrow: 1,
      margin: theme.spacing(1, 0, 1, 0)
    },
    requirements: {
      border: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(1, 0, 1, 0),
      padding: theme.spacing(1, 2, 1, 2),
      backgroundColor: amber[300]
    },
    requirementsText: {
      padding: theme.spacing(0, 0, 0, 0)
    },
    waiversNeededContainer: {
      padding: theme.spacing(1, 0, 0, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
  * Get household waivers needed
  */
  const fetchWaiversNeeded = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const id = memberID();
    const url = new URL(`${API_URL}/v1/signups/waiversneeded/${id}`);

    return await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Unable to get household member waivers needed');
        }
        return response.json()
      })
      .then((wn: Array<HouseholdMemberWaiverStatus>) => {
        setWaiversNeeded(wn);
      })
      .catch(err => {
        setErrors(err);
        return false;
      }
      );
  }

  // #endregion

  // #region Event Handlers

  /*
   * Handle change in nav option
   */
  const handleNavChange = (event: React.ChangeEvent<{}>, navIndex: number) => {
    setActiveNav(navIndex);
  };

  //  /*
  //* Handler for requirements dialog window
  //*/
  //  const handleRequirementsClose = () => {
  //    setRequirementsOpen(false);
  //  }

  //  /*
  //* Handler for requirements dialog window
  //*/
  //  const handleRequirementsOpen = () => {
  //    setRequirementsOpen(true);
  //  }

  React.useEffect(() => {

    if (startDate !== undefined || !startDate) {
      setOffSeasonStartDate(new Date(startDate));
    }

    fetchWaiversNeeded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      setActiveNav(Nav.MyLodgeUse);
      //  if (isSignupCoordinator()) {
      //    setActiveNav(Nav.MyLodgeUse);
      //  } else {
      //    setActiveNav(Nav.NewRequest);
      //  }
    } else {
      setActiveNav(Nav.MyLodgeUse);
    }
  }, [isMobile]);

  React.useEffect(() => {
    if (memberEditSaved) {
      fetchWaiversNeeded();
      dispatch(memberEditSavedReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberEditSaved]);

  React.useEffect(() => {
    if (offSeasonStartDate !== undefined) {
      // Set offseason flag in local state
      // Today >= offseason start date
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setOffSeason(today >= offSeasonStartDate);
    }

  }, [offSeasonStartDate]);

  // #endregion

  return (
    <>
      {(waiversNeeded.length > 0) &&
        <Grid container sx={styles.requirements}>
          <Grid container item justifyContent="center">
            <Typography variant={isMobile ? 'caption' : 'body1'}>{`The following household members (age 18+) are missing a waiver for the current year (5/1-4/30).`}</Typography>
          </Grid>
          <Grid container sx={styles.waiversNeededContainer}>
            {waiversNeeded.map(m => (
              <Grid container justifyContent="center">
                <Typography variant={isMobile ? 'caption' : 'body1'}>{`${m.memberName} (${m.status}) `}<Link target="_blank" href="https://form.jotform.com/201845348284157">Complete a Waiver</Link></Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container item justifyContent="center" sx={styles.requirementsText}>
            <Typography variant={isMobile ? 'caption' : 'body1'}><b>IMPORTANT: A waiver only needs to be signed one (1) time per year (5/1-4/30).</b></Typography>
          </Grid>
        </Grid >
      }
      {(props.signupsOffline) &&
        <SignupsOffline
          signupsStartTime={props.signupsStartTime}
        />
      }
      {(!props.signupsOffline && !isMobile) &&
        <>
          <Grid container>
            <BottomNavigation
              value={activeNav}
              onChange={handleNavChange}
              showLabels
              sx={styles.nav}
            >
              <BottomNavigationAction value={Nav.MyLodgeUse} label="My Lodge Use" icon={<PeopleIcon />} />
              <BottomNavigationAction value={Nav.NewRequest} label="New Request" icon={<AddIcon />} />
              <BottomNavigationAction value={Nav.Requests} label="Requests" icon={<CalendarTodayIcon />} />
              {!offSeason &&
                <BottomNavigationAction value={Nav.Status} label="Status" icon={<ListAltIcon />} />
              }
            </BottomNavigation>
          </Grid>
          <Grid container>
            {activeNav === Nav.MyLodgeUse &&
              <MyLodgeUse />
            }
            {activeNav === Nav.NewRequest &&
              <RequestForm />
            }
            {activeNav === Nav.Requests &&
              <Calendar />
            }
            {activeNav === Nav.Status &&
              <SignupSummary />
            }
          </Grid>
        </>
      }
      {(!props.signupsOffline && isMobile) &&
        <>
          <Grid container>
            <BottomNavigation
              value={activeNav}
              onChange={handleNavChange}
              showLabels
              sx={styles.nav}
            >
              <BottomNavigationAction value={Nav.MyLodgeUse} label="My Lodge Use" icon={<PeopleIcon />} />
              <BottomNavigationAction value={Nav.Requests} label="Requests" icon={<CalendarTodayIcon />} />
              <BottomNavigationAction value={Nav.NewRequest} label="New Request" icon={<AddIcon />} />
              {!offSeason &&
                <BottomNavigationAction value={Nav.Status} label="Status" icon={<ListAltIcon />} />
              }
            </BottomNavigation>
          </Grid>
          <Grid container>
            {activeNav === Nav.MyLodgeUse &&
              <MyLodgeUse />
            }
            {activeNav === Nav.Requests &&
              <Calendar />
            }
            {activeNav === Nav.NewRequest &&
              <RequestForm />
            }
            {activeNav === Nav.Status &&
              <SignupSummary />
            }
          </Grid>
        </>
      }
    </>
  );
}

export default Signups;
