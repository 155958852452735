// #region imports

import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

import {
  FeedbackType
} from '../../interfaces';

import { NULL_ID } from '../../global';

// #endregion

// #region interfaces

interface FeedbackTypeSelectProps {
  feedbackTypeID: number;
  feedbackTypes: Array<FeedbackType>;
  feedbackTypeSelected: (feedbackTypeID: number) => void;
}

// #endregion

const FeedbackTypeSelect: React.FC<FeedbackTypeSelectProps> = (props) => {

  // #region Styles

  const styles = {
    selectWidth: {
      flexGrow: 1,
      minWidth: 120,
    },
  };

  // #endregion

  // #region Event handlers

  const handleFeedbackTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Convert value to number
    props.feedbackTypeSelected(Number(event.target.value));
  };

  // #endregion

  return (
    <TextField
      sx={styles.selectWidth}
      label="Feedback Type"
      name="feedbackType"
      onChange={handleFeedbackTypeChange}
      select
      value={props.feedbackTypeID.toString()}
    >
      <MenuItem key={NULL_ID} value={NULL_ID}>Select feedback type</MenuItem>
      {props.feedbackTypes.map((ft: FeedbackType) => (
        <MenuItem key={ft.feedbackTypeID} value={ft.feedbackTypeID}>{ft.type}</MenuItem>
      ))}
    </TextField>
  );

}

export default FeedbackTypeSelect;