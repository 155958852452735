// #region Imports

import React from 'react';
import { saveAs } from 'file-saver';

// Material-UI
import { CloudDownload } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import {
  DataGrid,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridRowData,
  GridRowParams
} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import FileIcon from './FileIcon';

import {
  API_URL
  , GDFileType
  , RESOURCE_BREADCRUMB_ROOT_ID
} from '../global';

import {
  GDFile
  , HTTPHeaders
  , ResourceBreadcrumb
} from "../interfaces";

// Auth0 user hooks
import { useAuth0User } from '../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../hooks/UseResponsive';

// #endregion

// #region Interfaces

interface ResourceListProps {
  resourceSelected: (resource: GDFile) => void;
  selectedBreadcrumb: ResourceBreadcrumb;
  viewFile: (resource: GDFile) => void;
}

// #endregion

const ResourceList: React.FC<ResourceListProps> = (props) => {

  // Auth0 hooks
  const { getAccessToken } = useAuth0User();
  // Responsive hooks
  const { isMobile } = useResponsive();

  const [errors, setErrors] = React.useState<boolean>(false);
  const [gdFiles, setGDFiles] = React.useState<Array<GDFile>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    fileName: {
      padding: theme.spacing(0, 0, 0, 1)
    }
  };

  // #endregion

  // #region Methods

  /*
   * Conditional rendering of download icon based upon file type
   */
  const download = (params: GridCellParams): JSX.Element => {

    const fileType = params.row.fileType as GDFileType;
    if (fileType === GDFileType.PDF) {
      return (
        <>
        </>
      )
    } else {
      return <>
        {params.value as boolean ?
          <Tooltip title={`Download ${params.row.name}`}>
            <IconButton
              onClick={() => handleDownload(params.row.id as string, params.row.name as string)}
              size="large"><CloudDownload />
            </IconButton>
          </Tooltip>
          : null
        }
      </>;
    }

  }
  /*
 * Get folder content for a specified folder
 */
  const fetchFiles = async (id?: string) => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;
    headers['Content-Type'] = 'application/json';

    const url = new URL(`${API_URL}/v1/resources`);

    // If file ID is passed
    if (id) {
      url.pathname += `/${id}`;
    }

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Root resources not retrieved');
        }
        return response.json()
      })
      .then((f: string) => {
        setGDFiles(JSON.parse(f) as Array<GDFile>);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setErrors(err);
      });

  }

  /*
   * Formats file name based upon file type
   */
  const fileName = (params: GridCellParams): JSX.Element => {

    const fileType = params.value as GDFileType;
    if (fileType === GDFileType.PDF) {
      return (
        <>
          <FileIcon fileType={params.value as GDFileType} />
          <Link component="button" underline="hover" sx={styles.fileName} onClick={() => handleFileView(params.row)}>{params.row.name}</Link>
        </>
      )
    } else {
      return (
        <>
          <FileIcon fileType={params.value as GDFileType} />
          <Link href="#" underline="hover" style={styles.fileName}>{params.row.name}</Link>
        </>
      )
    }
  }

  /*
   * Is the file type a folder?
   */
  const isFolder = (fileType: GridCellValue): boolean => {
    return (fileType === GDFileType.Folder);
  }

  // #endregion

  // #region Event Handlers

  /*
   * Handle file  download
   */
  const handleDownload = async (id: string, fileName: string) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/resources/download/${id}`);

    const params = new URLSearchParams();
    params.append('name', `${fileName}`);

    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('File was not downloaded');
        }
        return response.blob()
      })
      .then((f: Blob) => {
        saveAs(f, fileName);
      })
      .catch(err => {
        setErrors(err);
      });

  }

  /*
   * Handle viewing PDF file
   */
  const handleFileView = (row: GridRowData) => {
    const gdFile: GDFile = row as GDFile;
    if (gdFile.fileType === GDFileType.PDF) {
      props.viewFile(gdFile);
    }
  }

  /*
   * Handles resource grid row click
   * Loads contents for a folder
   */
  const handleRowClick = async (params: GridRowParams) => {
    const row: GDFile = params.row as GDFile;
    if (row.fileType === GDFileType.Folder) {
      props.resourceSelected(params.row as GDFile);
      await fetchFiles(params.row.id as string);
    }
  }

  React.useEffect(() => {

    (async () => {
      // Select files based on a breadcumb selected
      if (props.selectedBreadcrumb && props.selectedBreadcrumb.id !== RESOURCE_BREADCRUMB_ROOT_ID) {
        await fetchFiles(props.selectedBreadcrumb.id);
      } else {
        await fetchFiles();
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedBreadcrumb]);

  // #endregion

  // #region Grid definition

  /*
   * Grid column definitions
   */
  const columns: Array<GridColDef> =
    [
      {
        field: 'fileType'
        , headerName: 'Name'
        // eslint-disable-next-line react/display-name
        , renderCell: (params: GridCellParams) => { return fileName(params); }
        , flex: 1
      },
      {
        field: 'modifiedTime'
        , headerName: 'Last modified'
        , width: 200
        , type: 'dateTime'
        , hide: isMobile
      },
      {
        field: 'size'
        , headerName: 'File size'
        // eslint-disable-next-line react/display-name
        , renderCell: (params: GridCellParams) => (
          <>
            {isFolder(params.row.fileType) ? '-' : params.value}
          </>
        )
        , width: 125
        , hide: isMobile
      },
      {
        field: 'canDownload'
        , headerName: ' '
        // eslint-disable-next-line react/display-name
        , renderCell: (params: GridCellParams) => { return download(params); }
        , flex: 1
      },
    ];

  // #endregion

  return (
    <DataGrid
      autoHeight
      rows={gdFiles}
      columns={columns}
      disableColumnMenu={true}
      disableColumnSelector={true}
      disableSelectionOnClick={true}
      hideFooter={true}
      loading={isLoading}
      onRowClick={handleRowClick}
    />
  );
}

export default ResourceList;
