// #region imports

import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
//import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  DateRange,
  PriorityPeriod
} from '../../models';

import {
  DateFilterYear,
  LODGEUSE_FILTER_MAX_INTERVAL,
  LodgeUseView
} from '../../global';

import PriorityPeriodList from '../../shared/PriorityPeriodList';
import DateRangeFilter from '../../shared/DateRangeFilter';

import { useSignupDates } from '../../hooks/UseSignupDates';

// #endregion

// #region interfaces

interface LodgeUseFilterProps {
  clear: boolean;
  dateRange: DateRange;
  disableClear: boolean;
  disableFilter: boolean;
  disableRefresh: boolean;
  filterChanged: (dateRange: DateRange) => void;
  handleClear: () => void;
  handleRefresh: () => void;
  handleView: (view: LodgeUseView) => void;
}

// #endregion

const LodgeUseFilter: React.FC<LodgeUseFilterProps> = (props) => {

  const { getRequestYearStartDate } = useSignupDates();

  const [view, setView] = React.useState<LodgeUseView>(LodgeUseView.HouseholdRequests);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    dividerRight: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    leftMargin: {
      margin: theme.spacing(0, 0, 0, 2),
    },
    topPadding: {
      padding: theme.spacing(1, 0, 0, 0),
    },
    viewWidth: {
      flexGrow: 1,
      minWidth: 150,
    },
  };

  // #endregion

  // #region Event handlers

  /*
   * Handle changes to date filter
   */
  const handleFilter = (dateRange: DateRange) => {
    props.filterChanged(dateRange);
  }

  /*
   * Handler for change in selected priority period
   */
  const handlePriorityPeriodChange = (period: PriorityPeriod) => {
    const dr = new DateRange(period.arrivalDate, period.departureDate);
    props.filterChanged(dr);
  }

  /*
* Clear filter
*/
  const handleClear = () => {
    setView(LodgeUseView.HouseholdRequests);
    props.handleClear();
  }

  /*
   * Handle refresh
   */
  const handleRefresh = () => {
    props.handleRefresh();
  }

  /*
 * Handle change in requests view
 */
  const handleViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const view = Number(event.target.value); // LodgeUseView
    setView(view);
    props.handleView(view);
  }

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item sx={[styles.dividerRight, styles.topPadding]}>
          <Grid container>
            <PriorityPeriodList
              disabled={props.disableFilter}
              handlePriorityPeriodChange={handlePriorityPeriodChange}
              reset={props.clear}
              startDate={getRequestYearStartDate()}
            />
          </Grid>
          <Grid container sx={styles.topPadding}>
            <DateRangeFilter
              clearDateRange={props.clear}
              dateRange={props.dateRange}
              disabled={props.disableFilter}
              filterYear={DateFilterYear.RequestYear}
              handleChange={handleFilter}
              isArrivalDeparture={true}
              maxInterval={LODGEUSE_FILTER_MAX_INTERVAL}
            />
          </Grid>
        </Grid>
        <Grid item sx={[styles.topPadding, styles.leftMargin]}>
          <Grid container>
            <TextField
              select
              label='View'
              sx={styles.viewWidth}
              onChange={handleViewChange}
              value={view.toString()}
            >
              <MenuItem key={LodgeUseView.HouseholdRequests} value={LodgeUseView.HouseholdRequests}>Household Requests</MenuItem>
              <MenuItem key={LodgeUseView.GuestRequests} value={LodgeUseView.GuestRequests}>Guest Requests</MenuItem>
              <MenuItem key={LodgeUseView.PostDeadlineRequests} value={LodgeUseView.PostDeadlineRequests}>Post Deadline Requests</MenuItem>
              <MenuItem key={LodgeUseView.Cancelled} value={LodgeUseView.Cancelled}>Cancellations</MenuItem>
            {/*  <Divider/>*/}
            {/*  <MenuItem key={LodgeUseView.EmailQueue} value={LodgeUseView.EmailQueue}>Requests Email Queue</MenuItem>*/}
            </TextField>
          </Grid>
          <Grid container spacing={2} justifyContent="center" sx={styles.topPadding}>
            <Grid item>
              <Tooltip title="Clear list">
                <span>
                  <Button variant="outlined" color="primary" disabled={props.disableClear} onClick={handleClear}>
                    <ClearIcon />
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Refresh the list">
                <span>
                  <Button variant="outlined" color="primary" disabled={props.disableRefresh} onClick={handleRefresh}>
                    <RefreshIcon />
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );

}

export default LodgeUseFilter;