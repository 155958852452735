// #region imports
import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridValueGetterParams
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import {
  HTTPHeaders
} from '../../interfaces';

import {
  PriorityRequestCount
} from '../../models';

import {
  API_URL,
  NULL_DATETIME_STRING
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Signup date criteria
import { useSignupDates } from '../../hooks/UseSignupDates';

// UTC to local date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface RequestCountsProps {
  refresh: boolean;
}

// #endregion

const RequestCounts: React.FC<RequestCountsProps> = (props: RequestCountsProps) => {

  const { getAccessToken } = useAuth0User();
  const { getRequestYearEndDate } = useSignupDates();
  const { dateStringToLocalDateFormatted, timeStamp } = useDateFormatting();

  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [requestCounts, setRequestCounts] = React.useState<Array<PriorityRequestCount>>([]);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      width: '100%'
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'weekendholiday',
        headerName: 'Weekend/Holiday',
        width: 200,
        type: 'string',
        align: 'left',
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.arrivalDate}-${params.row.departureDate}`
      },
      {
        field: 'householdCount',
        headerName: 'Households',
        width: 90,
        type: 'number',
        align: 'right',
      },
      {
        field: 'individualCount',
        headerName: 'Individuals',
        width: 90,
        type: 'number',
        align: 'right',
      },
      {
        field: 'signupDeadline',
        headerName: 'Deadline',
        width: 225,
        type: 'string',
        align: 'left',
        renderCell: (params: GridCellParams) => (
          renderDeadline(params)
        )
      },
    ];

  }

  /*
* Custom title for datagrid
*/
  function DataGridTitle() {
    return (
      <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="button">Lodge Use Weekend/Holiday Requests</Typography>
      </Box>
    )
  }

  /*
  * Fetch priority period request counts
  */
  const fetchRequestCounts = async () => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const params = new URLSearchParams();
    params.append('startDate', timeStamp('MM/dd/yyyy'));
    params.append('endDate', getRequestYearEndDate());

    const url = new URL(`${API_URL}/v1/signups/priorityperiods/requestcounts`);
    url.search = params.toString();

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Weekend/holiday counts not retrieved');
        }
        return response.json()
      })
      .then((rc: Array<PriorityRequestCount>) => {
        setIsLoading(false);
        setRequestCounts(rc);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
* Overlay displayed when no data
*/
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No weekend/holidays statistics to display</div>
      </GridOverlay>
    );
  }

  const renderDeadline = (params: GridCellParams) => {
    return (
      params.value !== NULL_DATETIME_STRING ? dateStringToLocalDateFormatted(params.value, 'MM/dd/yyyy HH:mm') : 'n/a'
    );
  }

  // #endregion

  // #region Event handlers

  React.useEffect(() => {
    fetchRequestCounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  // #endregion

  return (
    <>
      <DataGridTitle />
      <DataGrid
        columns={columns()}
        components={{
          NoRowsOverlay: noRowsOverlay
        }}
        density="compact"
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        getRowId={(row) => row.priorityPeriodID}
        hideFooter={true}
        loading={isLoading}
        rows={requestCounts}
      />
    </>
  )

  // #endregion

}

export default RequestCounts;