import {
  EMPTY_STRING,
  GDFileType,
  NULL_DATE,
  NULL_DATETIME_TIMEZONE_STRING,
  NULL_ID
} from './global';
import * as Interfaces from './interfaces';

export class ClubEmailAddress implements Interfaces.ClubEmailAddress {
  constructor(
    public clubEmailAddressID: number = NULL_ID
    , public entityName: string = EMPTY_STRING
    , public emailAddress: string = EMPTY_STRING
  ) {
    this.clubEmailAddressID = clubEmailAddressID;
    this.entityName = entityName;
    this.emailAddress = emailAddress;
  }
}

export class DateRange implements Interfaces.DateRange {
  constructor(
    public startDate = EMPTY_STRING,
    public endDate = EMPTY_STRING,
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

export class EmailAttachment implements Interfaces.EmailAttachemnt {
  constructor(
    public emailAttachmentID: number = NULL_ID
    , public attachmentFileName: string = EMPTY_STRING
    , public size: string = EMPTY_STRING
  ) {
    this.emailAttachmentID = emailAttachmentID;
    this.attachmentFileName = attachmentFileName;
    this.size = size;
  }
}

export class EmailMessage implements Interfaces.EmailMessage {
  constructor(
    public subject: string = EMPTY_STRING
    , public htmlContent: string = EMPTY_STRING
  ) {
    this.subject = subject;
    this.htmlContent = htmlContent;
  }
}

export class EmailQueueStatus implements Interfaces.EmailQueueStatus {
  constructor(
    public passCount: number = 0,
    public requestCount: number = 0,
    public statusTime: string = EMPTY_STRING
  ) {
    this.passCount = passCount;
    this.requestCount = requestCount;
    this.statusTime = statusTime;
  }
}

export class FeedbackFormData implements Interfaces.FeedbackFormData {
  constructor(
    public feedbackID: number = NULL_ID,
    public feedbackType: FeedbackType = new FeedbackType(),
    public title: string = EMPTY_STRING,
    public description: string = EMPTY_STRING,
    public userID: number = NULL_ID
  ) {
    this.feedbackID = feedbackID;
    this.feedbackType = feedbackType;
    this.title = title;
    this.description = description;
    this.userID = userID;
  }
}

export class FeedbackFilterData implements Interfaces.FeedbackFilterData {
  constructor(
    public startDate = EMPTY_STRING,
    public endDate = EMPTY_STRING,
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

export class FeedbackItem implements Interfaces.FeedbackItem {
  constructor(
    public feedbackID: number = NULL_ID,
    public feedbackType: FeedbackType = new FeedbackType(),
    public title: string = EMPTY_STRING,
    public description: string = EMPTY_STRING,
    public reported: string = NULL_DATETIME_TIMEZONE_STRING,
    public reportedBy: string = EMPTY_STRING
  ) {
    this.feedbackID = feedbackID;
    this.feedbackType = feedbackType;
    this.title = title;
    this.description = description;
    this.reported = reported;
    this.reportedBy = reportedBy;
  }
}

export class FeedbackType implements Interfaces.FeedbackType {
  constructor(
    public feedbackTypeID: number = NULL_ID,
    public type: string = EMPTY_STRING
  ) {
    this.feedbackTypeID = feedbackTypeID;
    this.type = type;
  }
}

export class GDFile implements Interfaces.GDFile {
  constructor(
    public id: string = EMPTY_STRING
    , public name: string = EMPTY_STRING
    , public fileType: GDFileType = GDFileType.Unknown
    , public modifiedTime: string = EMPTY_STRING
    , public size: string = EMPTY_STRING
    , public hasThumbnail: boolean = false
    , public thumbnailLink: string = EMPTY_STRING
    , public canDownLoad: boolean = false
    , public canView: boolean = false
  ) {
    this.id = id;
    this.name = name;
    this.fileType = fileType;
    this.modifiedTime = modifiedTime;
    this.size = size;
    this.hasThumbnail = hasThumbnail;
    this.thumbnailLink = thumbnailLink;
    this.canDownLoad = canDownLoad;
    this.canView = canView;
  }
}

export class GuestSignup implements Interfaces.GuestSignup {

  constructor(
    public signupID: number = NULL_ID,
    public guestSignupID = NULL_ID,
    public guestType = new GuestType(),
    public firstName: string = EMPTY_STRING,
    public lastName: string = EMPTY_STRING,
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public priorityGuest: boolean = false,
    public signupDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public cancelDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public nights: number = 0,
    public maxSignupPosition: number = 0,
    public signupStatus: string = EMPTY_STRING,
    public signupStatusID: number = NULL_ID,
    public priority: number = 0,
    public updated: string = NULL_DATETIME_TIMEZONE_STRING,
    public updatedBy: string = EMPTY_STRING,
    public userID = NULL_ID
  ) {
    this.guestSignupID = guestSignupID;
    this.signupID = signupID;
    this.guestType = guestType;
    this.firstName = firstName;
    this.lastName = lastName;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.priorityGuest = priorityGuest;
    this.signupDate = signupDate;
    this.cancelDate = cancelDate;
    this.nights = nights;
    this.maxSignupPosition = maxSignupPosition;
    this.signupStatus = signupStatus;
    this.signupStatusID = signupStatusID;
    this.priority = priority;
    this.updated = updated;
    this.updatedBy = updatedBy;
    this.userID = userID;
  }
}

export class GuestType implements Interfaces.GuestType {
  constructor(
    public guestTypeID: number = NULL_ID
    , public name: string = EMPTY_STRING
  ) {
    this.guestTypeID = guestTypeID;
    this.name = name;
  }
}

export class Household implements Interfaces.Household {
  constructor(
    public householdID: number = NULL_ID,
    public address1: string = EMPTY_STRING,
    public address2: string = EMPTY_STRING,
    public city: string = EMPTY_STRING,
    public stateRegion: string = EMPTY_STRING,
    public postalCode: string = EMPTY_STRING,
    public country: string = EMPTY_STRING,
    public isDeceased: boolean = false,
    public created: string = EMPTY_STRING,
    public createdBy: string = EMPTY_STRING,
    public updated: string = EMPTY_STRING,
    public updatedBy: string = EMPTY_STRING,
    public userID: number = NULL_ID
  ) {
    this.householdID = householdID;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.stateRegion = stateRegion;
    this.postalCode = postalCode;
    this.country = country;
    this.isDeceased = isDeceased;
    this.created = created;
    this.createdBy = createdBy;
    this.updated = updated;
    this.updatedBy = updatedBy;
    this.userID = userID
  }
}

export class HouseholdMemberWaiverStatus implements Interfaces.HouseholdMemberWaiverStatus {
  constructor(
    public memberName: string = EMPTY_STRING,
    public status: string = EMPTY_STRING,
    public waiverStatus: string = EMPTY_STRING
  ) {
    this.memberName = memberName;
    this.status = status;
    this.waiverStatus = waiverStatus;
  }
}

export class HouseholdRequestStatus implements Interfaces.HouseholdRequestStatus {
  constructor(
    public futurePriorityPeriods = false,
    public maxRequests = 0,
    public postDeadline = new PostDeadline(),
    public requestCount = -1, // Indicates count has not been retrieved
    public requests: PriorityRequest[] = [],
  ) {
    this.futurePriorityPeriods = futurePriorityPeriods;
    this.maxRequests = maxRequests;
    this.postDeadline = postDeadline;
    this.requestCount = requestCount;
    this.requests = requests;
  }
}

export class HouseholdSignups implements Interfaces.HouseholdSignups {
  constructor(
    public cancelledSignups: SignupHousehold[] = []
    , public currentSignups: SignupHousehold[] = []
    , public futureSignups: SignupHousehold[] = []
    , public pastSignups: SignupHousehold[] = []
  ) {
    this.cancelledSignups = cancelledSignups;
    this.currentSignups = currentSignups;
    this.futureSignups = futureSignups;
    this.pastSignups = pastSignups;
  }
}

export class LodgeUseData implements Interfaces.LodgeUseData {

  constructor(
    public households: Array<LodgeUseRequest> = [],
    public summary: LodgeUseSummary = new LodgeUseSummary()
  ) {
    this.households = households;
    this.summary = summary;
  }
}

export class LodgeUseEmailMessage implements Interfaces.LodgeUseEmailMessage {
  constructor(
    public ids: string = EMPTY_STRING,
    public subject: string = EMPTY_STRING,
    public htmlContent: string = EMPTY_STRING,
    public attachments: string = EMPTY_STRING
  ) {
    this.ids = ids;
    this.subject = subject;
    this.htmlContent = htmlContent;
    this.attachments = attachments;
  }
}

export class LodgeUseMemberRequest implements Interfaces.LodgeUseMemberRequest {
  constructor(
    public id: number = NULL_ID,
    public signupID: number = NULL_ID,
    public referenceNumber: string = EMPTY_STRING,
    public householdID: number = NULL_ID,
    public firstName: string = EMPTY_STRING,
    public lastName: string = EMPTY_STRING,
    public gender: string = EMPTY_STRING,
    public age: number = 0,
    public waiverStatusID: number = NULL_ID,
    public waiverStatus: string = EMPTY_STRING,
    public priorityGuest: boolean = false,
    public type: string = EMPTY_STRING,
    public phone: string = EMPTY_STRING,
    public email: string = EMPTY_STRING,
    public requestDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public cancelDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public statusID: number = NULL_ID,
    public status: string = EMPTY_STRING,
    public priority: number = 0,
    public nights: number = NULL_ID,
    public isGuest: boolean = false,
    public guestOf: string = EMPTY_STRING,
    public priorityRequest: boolean = false,
    public offSeason: boolean = false
  ) {
    this.id = id;
    this.signupID = signupID;
    this.referenceNumber = referenceNumber;
    this.householdID = householdID;
    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;
    this.age = age;
    this.waiverStatusID = waiverStatusID;
    this.waiverStatus = waiverStatus;
    this.priorityGuest = priorityGuest;
    this.type = type;
    this.phone = phone;
    this.email = email;
    this.requestDate = requestDate;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.cancelDate = cancelDate;
    this.statusID = statusID;
    this.status = status;
    this.priority = priority;
    this.nights = nights;
    this.isGuest = isGuest;
    this.guestOf = guestOf;
    this.priorityRequest = priorityRequest;
    this.offSeason = offSeason;
  }
}

export class LodgeUseRequest implements Interfaces.LodgeUseRequest {
  constructor(
    public signupID: number = NULL_ID,
    public referenceNumber: string = EMPTY_STRING,
    public householdID: number = NULL_ID,
    public hasGuests: boolean = false,
    public memberName: string = EMPTY_STRING,
    public requestDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public priority: number = 0,
    public status: string = EMPTY_STRING,
    public statusID: number = NULL_ID,
    public minArrivalDate: string = EMPTY_STRING,
    public maxDepartureDate: string = EMPTY_STRING,
    public phone: string = EMPTY_STRING,
    public email: string = EMPTY_STRING,
    public cancelDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public nights: number = NULL_ID,
    public notes: string = EMPTY_STRING,
    public modified: boolean = false,
    public memberRequests: Array<LodgeUseMemberRequest> = []
  ) {
    this.signupID = signupID;
    this.referenceNumber = referenceNumber;
    this.householdID = householdID;
    this.hasGuests = hasGuests;
    this.memberName = memberName;
    this.requestDate = requestDate;
    this.priority = priority;
    this.statusID = statusID;
    this.status = status;
    this.minArrivalDate = minArrivalDate;
    this.maxDepartureDate = maxDepartureDate;
    this.phone = phone;
    this.email = email;
    this.cancelDate = cancelDate;
    this.nights = nights;
    this.notes = notes;
    this.modified = modified;
    this.memberRequests = memberRequests;
  }
}

export class LodgeUseStatusCount implements Interfaces.LodgeUseStatusCount {

  constructor(
    public signupStatusID: number = NULL_ID,
    public status: string = EMPTY_STRING,
    public statusCount: number = 0
  ) {
    this.signupStatusID = signupStatusID;
    this.status = status;
    this.statusCount = statusCount;
  }
}

export class LodgeUseStatusChange implements Interfaces.LodgeUseStatusChange {

  constructor(
    public memberSignupIDs: Array<number> = [],
    public guestSignupIDs: Array<number> = [],
    public signupStatusID: number = NULL_ID,
  ) {
    this.memberSignupIDs = memberSignupIDs;
    this.guestSignupIDs = guestSignupIDs;
    this.signupStatusID = signupStatusID;
  }
}

export class LodgeUseSummary implements Interfaces.LodgeUseSummary {

  constructor(
    public deadline: SignupDeadline = new SignupDeadline(),
    public notes: Array<SignupNote> = [],
    public statusCounts: Array<LodgeUseStatusCount> = [],
  ) {
    this.deadline = deadline;
    this.notes = notes;
    this.statusCounts = statusCounts;
  }
}

export class MemberEmailFilter implements Interfaces.MemberEmailFilter {
  constructor(
    public status: string = EMPTY_STRING
  ) {
    this.status = status;
  }
}

export class MemberEmailMessage implements Interfaces.MemberEmailMessage {
  constructor(
    public filter: MemberEmailFilter = new MemberEmailFilter()
    , public subject: string = EMPTY_STRING
    , public htmlContent: string = EMPTY_STRING
    , public clubEmailAddress = new ClubEmailAddress()
    , public attachments: string = EMPTY_STRING
  ) {
    this.filter = filter;
    this.subject = subject;
    this.htmlContent = htmlContent;
    this.clubEmailAddress = clubEmailAddress;
    this.attachments = attachments;
  }
}

export class MemberNotSignedUp implements Interfaces.MemberNotSignedUp {

  constructor(
    public signupID: number = NULL_ID
    , public memberID: number = NULL_ID
    , public memberStatus = EMPTY_STRING
    , public firstName: string = EMPTY_STRING
    , public lastName: string = EMPTY_STRING
    , public arrivalDate: string = EMPTY_STRING
    , public departureDate: string = EMPTY_STRING
    , public userID = NULL_ID
  ) {
    this.signupID = signupID;
    this.memberID = memberID;
    this.memberStatus = memberStatus;
    this.firstName = firstName;
    this.lastName = lastName;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.userID = userID;
  }
}

export class MemberStatus implements Interfaces.MemberStatus {
  constructor(
    public memberStatusID: number = NULL_ID
    , public status: string = EMPTY_STRING
  ) {
    this.memberStatusID = memberStatusID;
    this.status = status;
  }
}

export class PhoneType implements Interfaces.PhoneType {
  constructor(
    public phoneTypeID: number = NULL_ID
    , public type: string = EMPTY_STRING
  ) {
    this.phoneTypeID = phoneTypeID;
    this.type = type;
  }
}

export class Member implements Interfaces.Member {

  constructor(
    public memberID: number = NULL_ID,
    public firstName: string = EMPTY_STRING,
    public lastName: string = EMPTY_STRING,
    public gender: string = EMPTY_STRING,
    public age: string = EMPTY_STRING,
    public email: string = EMPTY_STRING,
    public primaryPhone: string = EMPTY_STRING,
    public primaryPhoneType: PhoneType = new PhoneType(),
    public secondaryPhone: string = EMPTY_STRING,
    public secondaryPhoneType: PhoneType = new PhoneType(),
    public waiverStatus: WaiverStatus = new WaiverStatus(),
    public memberStatus: MemberStatus = new MemberStatus(),
    public household: Household = new Household(),
    public created: string = EMPTY_STRING,
    public createdBy: string = EMPTY_STRING,
    public updated: string = EMPTY_STRING,
    public updatedBy: string = EMPTY_STRING,
    public userID: number = NULL_ID,
    public selected = false
  ) {
    this.memberID = memberID;
    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;
    this.age = age;
    this.email = email;
    this.primaryPhone = primaryPhone;
    this.primaryPhoneType = primaryPhoneType;
    this.secondaryPhone = secondaryPhone;
    this.secondaryPhoneType = secondaryPhoneType;
    this.waiverStatus = waiverStatus;
    this.memberStatus = memberStatus;
    this.household = household;
    this.created = created;
    this.createdBy = createdBy;
    this.updated = updated;
    this.updatedBy = updatedBy;
    this.userID = userID
  }
}

export class MemberSignup implements Interfaces.MemberSignup {
  constructor(
    public memberSignupID: number = NULL_ID,
    public signupID: number = NULL_ID,
    public memberID: number = NULL_ID,
    public memberStatus: string = EMPTY_STRING,
    public firstName: string = EMPTY_STRING,
    public lastName: string = EMPTY_STRING,
    public gender: string = EMPTY_STRING,
    public age: number = 0,
    public signupDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public cancelDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public nights: number = 0,
    public maxSignupPosition: number = 0,
    public signupStatus: string = EMPTY_STRING,
    public signupStatusID: number = NULL_ID,
    public priority: number = 0,
    public updated: string = NULL_DATETIME_TIMEZONE_STRING,
    public updatedBy: string = EMPTY_STRING,
    public userID = NULL_ID
  ) {
    this.memberSignupID = memberSignupID;
    this.signupID = signupID;
    this.memberID = memberID;
    this.memberStatus = memberStatus;
    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;
    this.age = age;
    this.signupDate = signupDate;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.cancelDate = cancelDate;
    this.nights = nights;
    this.maxSignupPosition = maxSignupPosition;
    this.signupStatus = signupStatus;
    this.signupStatusID = signupStatusID;
    this.priority = priority;
    this.updated = updated;
    this.updatedBy = updatedBy;
    this.userID = userID;
  }
}

export class MemberSignupInputForm implements Interfaces.MemberSignupInputForm {
  constructor(
    public memberSignupID: number = NULL_ID
    , public signupID: number = NULL_ID
    , public memberID: number = NULL_ID
    , public arrivalDate: Date
    , public departureDate: Date
    , public cancel: boolean = false
  ) {
    this.memberSignupID = memberSignupID;
    this.signupID = signupID;
    this.memberID = memberID;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.cancel = cancel;
  }
}

export class PostDeadline implements Interfaces.PostDeadline {
  constructor(
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public signupDeadline: string = EMPTY_STRING
  ) {
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.signupDeadline = signupDeadline;
  }
}

export class Priority implements Interfaces.Priority {

  constructor(
    public priorityGood = 0,
    public priorityWaitlist = 0
  ) {
    this.priorityGood = priorityGood;
    this.priorityWaitlist = priorityWaitlist;
  }
}

export class PriorityPeriod implements Interfaces.PriorityPeriod {
  constructor(
    public priorityPeriodID: number = NULL_ID,
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public description: string = EMPTY_STRING,
    public signupDeadline: Date = NULL_DATE,
    public cancelDeadline: Date = NULL_DATE,
  ) {
    this.priorityPeriodID = priorityPeriodID;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.description = description;
    this.signupDeadline = signupDeadline;
    this.cancelDeadline = cancelDeadline;
  }
}

export class PriorityRequest implements Interfaces.PriorityRequest {
  constructor(
    public signupID: number = NULL_ID,
    public referenceNumber: string = EMPTY_STRING,
    public minArrivalDate: string = EMPTY_STRING,
    public maxDepartureDate: string = EMPTY_STRING,
    public priorityPeriods: number = 0

  ) {
    this.signupID = signupID;
    this.referenceNumber = referenceNumber;
    this.minArrivalDate = minArrivalDate;
    this.maxDepartureDate = maxDepartureDate;
    this.priorityPeriods = priorityPeriods;
  }
}

export class PriorityRequestCount implements Interfaces.PriorityRequestCount {
  constructor(
    public priorityPeriodID: number = NULL_ID,
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public description: string = EMPTY_STRING,
    public signupDeadline: string = EMPTY_STRING,
    public householdCount: number = 0,
    public individualCount: number = 0
  ) {
    this.priorityPeriodID = priorityPeriodID;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.description = description;
    this.signupDeadline = signupDeadline;
    this.householdCount = householdCount;
    this.individualCount = individualCount;
  }
}

export class ReleaseCategory implements Interfaces.ReleaseCategory {
  constructor(
    public items: Array<ReleaseItem> = [],
    public title: string = EMPTY_STRING
  ) {
    this.items = items;
    this.title = title;
  }
}

export class Release implements Interfaces.Release {
  constructor(
    public categories: Array<ReleaseCategory> = [],
    public releaseDate: string = EMPTY_STRING,
    public version: string = EMPTY_STRING
  ) {
    this.categories = categories;
    this.releaseDate = releaseDate;
    this.version = version;
  }
}

export class ReleaseItem implements Interfaces.ReleaseItem {
  constructor(
    public description: string = EMPTY_STRING
  ) {
    this.description = description;
  }
}

export class ReleasesIndicator implements Interfaces.ReleasesIndicator {
  constructor(
    public display: boolean = false,
    public version: string = EMPTY_STRING
  ) {
    this.display = display;
    this.version = version;
  }
}

export class RequestCalendar implements Interfaces.RequestCalendar {

  constructor(
    public columnHeaders: Array<RequestCalendarColumnHeader> = [],
    public rows: Array<RequestCalendarRow> = [],
    public summary: RequestCalendarSummary = new RequestCalendarSummary()
  ) {
    this.columnHeaders = columnHeaders;
    this.rows = rows;
    this.summary = summary;
  }
}

export class RequestCalendarColumnHeader implements Interfaces.RequestCalendarColumnHeader {
  constructor(
    public lodgeNight = EMPTY_STRING,
    public priority = 0,
    public nightCount: number = 0
  ) {
    this.lodgeNight = lodgeNight;
    this.nightCount = nightCount;
    this.priority = priority;
  }
}

export class RequestCalendarRow implements Interfaces.RequestCalendarRow {

  constructor(
    public rowID: number = 0,
    public signupID: number = NULL_ID,
    public referenceNumber: string = EMPTY_STRING,
    public personID: number = NULL_ID,
    public firstName: string = EMPTY_STRING,
    public lastName: string = EMPTY_STRING,
    public personType: string = EMPTY_STRING,
    public rank: number = 0,
    public householdID: number = NULL_ID,
    public isGuest: boolean = false,
    public guestOf: string = EMPTY_STRING,
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public priorityGuest: boolean = false,
    public signupStatusID: number = NULL_ID,
    public night1: number = NULL_ID,
    public night2: number = NULL_ID,
    public night3: number = NULL_ID,
    public night4: number = NULL_ID,
    public night5: number = NULL_ID,
    public night6: number = NULL_ID,
    public night7: number = NULL_ID,
    public night8: number = NULL_ID,
    public night9: number = NULL_ID,
    public night10: number = NULL_ID,
    public night11: number = NULL_ID,
    public night12: number = NULL_ID,
    public night13: number = NULL_ID,
    public night14: number = NULL_ID
  ) {
    this.rowID = rowID;
    this.signupID = signupID;
    this.referenceNumber = referenceNumber;
    this.personID = personID;
    this.firstName = firstName;
    this.lastName = lastName;
    this.personType = personType;
    this.rank = rank;
    this.householdID = householdID;
    this.isGuest = isGuest;
    this.guestOf = guestOf;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.priorityGuest = priorityGuest;
    this.signupStatusID = signupStatusID;
    this.night1 = night1;
    this.night2 = night2;
    this.night3 = night3;
    this.night4 = night4;
    this.night5 = night5;
    this.night6 = night6;
    this.night7 = night7;
    this.night8 = night8;
    this.night9 = night9;
    this.night10 = night10;
    this.night11 = night11;
    this.night12 = night12;
    this.night13 = night13;
    this.night14 = night14;
  }
}

export class RequestCalendarSummary implements Interfaces.RequestCalendarSummary {

  constructor(
    public householdID: number = NULL_ID,
    public deadline: SignupDeadline = new SignupDeadline(),
    public notes: Array<SignupNote> = [],
  ) {
    this.householdID = householdID;
    this.deadline = deadline;
    this.notes = notes;
  }
}

export class ResourceBreadcrumb implements Interfaces.ResourceBreadcrumb {
  constructor(
    public id: string = EMPTY_STRING,
    public name: string = EMPTY_STRING
  ) {
    this.id = id;
    this.name = name;
  }
}

export class Signup implements Interfaces.Signup {
  constructor(
    public signupID: number = NULL_ID,
    public householdID: number = NULL_ID,
    public referenceNumber: string = EMPTY_STRING,
    public signupStatus: SignupStatus = new SignupStatus(),
    public priorityRequest: boolean = false,
    public notes: string = EMPTY_STRING,
    public transferablePasses: Array<TransferablePassSignup> = [],
    public minArrivalDate: string = EMPTY_STRING,
    public maxDepartureDate: string = EMPTY_STRING,
    public cancelDate: string = NULL_DATETIME_TIMEZONE_STRING,
    public nights: number = NULL_ID,
    public userID: number = NULL_ID,
    public cancel = false
  ) {
    this.signupID = signupID;
    this.householdID = householdID;
    this.referenceNumber = referenceNumber;
    this.signupStatus = signupStatus;
    this.priorityRequest = priorityRequest;
    this.notes = notes;
    this.transferablePasses = transferablePasses;
    this.minArrivalDate = minArrivalDate;
    this.maxDepartureDate = maxDepartureDate;
    this.cancelDate = cancelDate;
    this.nights = nights;
    this.userID = userID;
    this.cancel = cancel;
  }
}

export class SignupCancelled implements Interfaces.SignupCancelled {

  constructor(
    public signupID: number = NULL_ID,
    public referenceNumber: string = EMPTY_STRING,
    public guestSignups: Array<Interfaces.SignupDetailGuest> = [],
    public memberSignups: Array<Interfaces.SignupDetailMember> = [],
    public notes: string = EMPTY_STRING,
    public minArrivalDate: string = EMPTY_STRING,
    public maxDepartureDate: string = EMPTY_STRING,
    public cancelDate: string = EMPTY_STRING,
    public nights: number = NULL_ID
  ) {
    this.signupID = signupID;
    this.referenceNumber = referenceNumber;
    this.guestSignups = guestSignups;
    this.memberSignups = memberSignups;
    this.notes = notes;
    this.minArrivalDate = minArrivalDate;
    this.maxDepartureDate = maxDepartureDate;
    this.cancelDate = cancelDate;
    this.nights = nights;
  }
}

export class SignupDeadline implements Interfaces.SignupDeadline {

  constructor(
    public priorityPeriodID: number = NULL_ID,
    public arrivalDate: string = EMPTY_STRING,
    public departureDate: string = EMPTY_STRING,
    public deadline: string = EMPTY_STRING,
    public cancelDeadline: string = EMPTY_STRING,
  ) {
    this.priorityPeriodID = priorityPeriodID;
    this.arrivalDate = arrivalDate;
    this.departureDate = departureDate;
    this.deadline = deadline;
    this.cancelDeadline = cancelDeadline;
  }
}

export class SignupEdit implements Interfaces.SignupEdit {
  constructor(
    public signupID: number = NULL_ID,
    public notes: string = EMPTY_STRING,
    public transferablePasses: Array<TransferablePassSignup> = [],
    public passesChanged:boolean,
    public userID: number = NULL_ID
  ) {
    this.signupID = signupID;
    this.notes = notes;
    this.transferablePasses = transferablePasses;
    this.passesChanged = passesChanged;
    this.userID = userID;
  }
}

export class SignupHousehold implements Interfaces.SignupHousehold {

  constructor(
    public signupID: number = NULL_ID,
    public householdID: number = NULL_ID,
    public signupStatusID: number = NULL_ID,
    public status: string = EMPTY_STRING,
    public priorityRequest: boolean = false,
    public minArrivalDate: string = EMPTY_STRING,
    public maxDepartureDate: string = EMPTY_STRING,
    public cancelDate: string = EMPTY_STRING,
    public offSeason: boolean = false,
    public nights: number = 0
  ) {
    this.signupID = signupID;
    this.householdID = householdID;
    this.signupStatusID = signupStatusID;
    this.status = status;
    this.priorityRequest = priorityRequest;
    this.minArrivalDate = minArrivalDate;
    this.maxDepartureDate = maxDepartureDate;
    this.cancelDate = cancelDate;
    this.offSeason = offSeason;
    this.nights = nights;
  }

}

export class SignupHouseholdFilter implements Interfaces.SignupHouseholdFilter {
  constructor(
    public startDate = EMPTY_STRING,
    public endDate = EMPTY_STRING
  ) {
    this.startDate = startDate;
    this.endDate = endDate
  }
}

export class SignupNew implements Interfaces.SignupNew {
  constructor(
    public memberID: number = NULL_ID,
    public memberSignups: Array<Interfaces.MemberSignupInputForm> = [],
    public notes: string = EMPTY_STRING,
    public transferablePasses: Array<TransferablePassSignup> = [],
    public userID: number = NULL_ID
  ) {
    this.memberID = memberID;
    this.memberSignups = memberSignups;
    this.notes = notes;
    this.transferablePasses = transferablePasses;
    this.userID = userID;
  }
}

export class SignupNote implements Interfaces.SignupNote {

  constructor(
    public signupID: number = NULL_ID,
    public householdID: number = NULL_ID,
    public notes: string = EMPTY_STRING,
    public createdBy: string = EMPTY_STRING,
    public updated: string = EMPTY_STRING,
    public minArrivalDate: string = EMPTY_STRING,
    public maxDepartureDate: string = EMPTY_STRING,
    public nights: number = 0,
    public cancelDate: string = EMPTY_STRING
  ) {
    this.signupID = signupID;
    this.householdID = householdID;
    this.notes = notes;
    this.createdBy = createdBy;
    this.updated = updated;
    this.minArrivalDate = minArrivalDate;
    this.maxDepartureDate = maxDepartureDate;
    this.nights = nights;
    this.cancelDate = cancelDate;
  }
}

export class SignupPast implements Interfaces.SignupPast {

  constructor(
    public signupID: number = NULL_ID,
    public referenceNumber: string = EMPTY_STRING,
    public guestSignups: Array<Interfaces.SignupDetailGuest> = [],
    public memberSignups: Array<Interfaces.SignupDetailMember> = [],
    public notes: string = EMPTY_STRING,
    public minArrivalDate: string = EMPTY_STRING,
    public maxDepartureDate: string = EMPTY_STRING,
    public cancelDate: string = EMPTY_STRING,
    public nights: number = NULL_ID
  ) {
    this.signupID = signupID;
    this.referenceNumber = referenceNumber;
    this.guestSignups = guestSignups;
    this.memberSignups = memberSignups;
    this.notes = notes;
    this.minArrivalDate = minArrivalDate;
    this.maxDepartureDate = maxDepartureDate;
    this.cancelDate = cancelDate;
    this.nights = nights;
  }

}

export class SignupStatus implements Interfaces.SignupStatus {
  constructor(
    public signupStatusID: number = NULL_ID,
    public status: string = EMPTY_STRING
  ) {
    this.signupStatusID = signupStatusID;
    this.status = status;
  }
}

export class StartupOptions implements Interfaces.StartupOptions {
  constructor(
    public applicationMode: number = NULL_ID,
    public applicationOffline: boolean = false,
    public copyright: string = EMPTY_STRING,
    public releaseDate: Date = NULL_DATE,
    public version: string = EMPTY_STRING,
    public guestRequestTime: number = 0,
    public offSeasonStartDate: Date = NULL_DATE,
    public signupsOffline: boolean = true,
    public signupsStartTime: string = EMPTY_STRING
  ) {
    this.applicationMode = applicationMode;
    this.applicationOffline = applicationOffline;
    this.copyright = copyright;
    this.releaseDate = releaseDate;
    this.version = version;
    this.guestRequestTime = guestRequestTime;
    this.offSeasonStartDate = offSeasonStartDate;
    this.signupsOffline = signupsOffline;
    this.signupsStartTime = signupsStartTime;
  }
}

export class TransferablePass implements Interfaces.TransferablePass {
  constructor(
    public transferablePassID: number = NULL_ID,
    public householdID: number = NULL_ID,
    public fullName:string=EMPTY_STRING,
    public passDate: string = EMPTY_STRING,
    public requested: string = NULL_DATETIME_TIMEZONE_STRING,
    public transferablePassStatusID:number=NULL_ID,
    public status: string =EMPTY_STRING,
    public userID: number = NULL_ID,
    public created: string = EMPTY_STRING,
    public createdBy: string = EMPTY_STRING,
    public updated: string = EMPTY_STRING,
    public updatedBy: string = EMPTY_STRING,
  ) {
    this.transferablePassID = transferablePassID;
    this.householdID = householdID;
    this.fullName = fullName;
    this.passDate = passDate;
    this.requested = requested;
    this.transferablePassStatusID = transferablePassStatusID;
    this.status = status;
    this.userID = userID;
    this.created = created;
    this.createdBy = createdBy;
    this.updated = updated;
    this.updatedBy = updatedBy;
  }
}

export class TransferablePassSignup implements Interfaces.TransferablePassSignup {
  constructor(
    public householdID: number = NULL_ID,
    public passDate: string = EMPTY_STRING,
    public passes: number = 0,
    public status: string = EMPTY_STRING,
  ) {
    this.householdID = householdID;
    this.passDate = passDate;
    this.passes = passes;
    this.status = status;
  }
}

export class TransferablePassStatusChange implements Interfaces.TransferablePassStatusChange {

  constructor(
    public ids: Array<number> = [],
    public statusID: number = NULL_ID,
  ) {
    this.ids = ids;
    this.statusID = statusID;
  }
}

export class TransferablePassStatusCount implements Interfaces.TransferablePassStatusCount {

  constructor(
    public transferablePassStatusID: number = NULL_ID,
    public status: string = EMPTY_STRING,
    public statusCount: number = 0
  ) {
    this.transferablePassStatusID = transferablePassStatusID;
    this.status = status;
    this.statusCount = statusCount;
  }
}

export class WaiverStatus implements Interfaces.WaiverStatus {
  constructor(
    public waiverStatusID: number = 99, //Not reported
    public status: string = EMPTY_STRING
  ) {
    this.waiverStatusID = waiverStatusID;
    this.status = status;
  }
}

export class Weather implements Interfaces.Weather {
  constructor(
    public updated: string = EMPTY_STRING,
    public units: string = EMPTY_STRING,
    public forecastGenerator: string = EMPTY_STRING,
    public generatedAt: string = EMPTY_STRING,
    public updateTime: string = EMPTY_STRING,
    public validTimes: string = EMPTY_STRING,
    public elevation: WeatherElevation = new WeatherElevation(),
    public periods: Array<WeatherPeriod> = []
  ) {
    this.updated = updated;
    this.units = units;
    this.forecastGenerator = forecastGenerator;
    this.generatedAt = generatedAt;
    this.updateTime = updateTime;
    this.validTimes = validTimes;
    this.elevation = elevation;
    this.periods = periods;
  }
}

export class WeatherElevation implements Interfaces.WeatherElevation {
  constructor(
    public unitCode: string = EMPTY_STRING,
    public value: number = NULL_ID
  ) {
    this.unitCode = unitCode;
    this.value = value;
  }
}

export class WeatherPeriod implements Interfaces.WeatherPeriod {
  constructor(
    public number: number = NULL_ID,
    public name: string = EMPTY_STRING,
    public startTime: string = EMPTY_STRING,
    public endTime: string = EMPTY_STRING,
    public isDaytime: boolean = true,
    public temperature: number = NULL_ID,
    public temperatureUnit: string = EMPTY_STRING,
    public temperatureTrend: string = EMPTY_STRING,
    public windSpeed: string = EMPTY_STRING,
    public windDirection: string = EMPTY_STRING,
    public icon: string = EMPTY_STRING,
    public shortForecast: string = EMPTY_STRING,
    public detailedForecast: string = EMPTY_STRING
  ) {
    this.number = number;
    this.name = name;
    this.startTime = startTime;
    this.endTime = endTime;
    this.isDaytime = isDaytime;
    this.temperature = temperature;
    this.temperatureUnit = temperatureUnit;
    this.temperatureTrend = temperatureTrend;
    this.windSpeed = windSpeed;
    this.windDirection = windDirection;
    this.icon = icon;
    this.shortForecast = shortForecast;
    this.detailedForecast = detailedForecast;
  }
}