// #region Imports

import React from 'react';

// Material-UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { amber, blue, blueGrey, green, grey, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import PeopleIcon from '@mui/icons-material/People';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import EmailQueueSummary from './EmailQueueSummary';

import {
  LodgeUseRequest,
  LodgeUseStatusChange
} from '../../models';

import {
  EMPTY_STRING,
  LodgeUseAction,
  SignupStatusID
} from '../../global';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface RequestsProps {
  refreshEmailQueue: boolean;
  handleEmail: (ids: Array<number>) => void;
  handleStatusChange: (changes: LodgeUseStatusChange) => void;
  modifiedViewed: (id: number) => void;
  lodgeUseRequests: Array<LodgeUseRequest>;
  working: boolean;
}

// #endregion

const Requests: React.FC<RequestsProps> = (props) => {

  const { dateFormatted, dateStringToLocalDateFormatted } = useDateFormatting();

  const [actionType, setActionType] = React.useState<LodgeUseAction>(LodgeUseAction.Confirm);
  const [allChecked, setAllChecked] = React.useState<boolean>(false);
  const [membersSelected, setMembersSelected] = React.useState<Array<number>>([]);
  const [requestsSelected, setRequestsSelected] = React.useState<Array<number>>([]);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2, 2, 2, 2),
    },
    appBar: {
      margin: theme.spacing(0, 0, 1, 0)
    },
    cancelled: {
      color: blue[500]
    },
    cancelledlate: {
      color: red[500]
    },
    confirmed: {
      color: green[500]
    },
    deined: {
      color: red[500]
    },
    guest: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    header: {
      backgroundColor: grey[100]
    },
    memberName: {
      padding: theme.spacing(0, 2, 0, 0)
    },
    noRequests: {
      flexGrow: 1,
      margin: theme.spacing(5, 0, 0, 0),
      padding: theme.spacing(5, 0, 0, 0),
    },
    pending: {
      color: blueGrey[500]
    },
    toolbarItem: {
      borderRight: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
    waitlist: {
      color: amber[500]
    }
  };

  // #endregion

  // #region Methods

  /*
   * Get signup status icon
   */
  const headerStatusIcon = (status: number): JSX.Element => {

    switch (status) {
      case SignupStatusID.Pending:
        return (<InfoIcon fontSize="small" sx={styles.pending} />);
      case SignupStatusID.Confirmed:
        return (<CheckCircleIcon fontSize="small" sx={styles.confirmed} />);
      case SignupStatusID.Waitlist:
        return (<PanToolIcon fontSize="small" sx={styles.waitlist} />);
      case SignupStatusID.Denied:
        return (<BlockIcon fontSize="small" sx={styles.deined} />);
      case SignupStatusID.Cancelled:
        return (<CancelIcon fontSize="small" sx={styles.cancelled} />);
      case SignupStatusID.CancelledLate:
        return (<CancelIcon fontSize="small" sx={styles.cancelledlate} />);
      default:
        return <span />;
    }
  }

  /*
   * Get header signup status element
   */
  const headerStatus = (h: LodgeUseRequest): JSX.Element => {

    // Display Mixed for household that has different request status
    return (
      <FormControlLabel
        control={h.statusID === SignupStatusID.Mixed ? <DragIndicatorIcon /> : headerStatusIcon(h.statusID)}
        label={h.status}
      />
    )

  }

  /*
   * Render formatted member namess
   */
  const memberName = (h: LodgeUseRequest) => {

    return (
      <Typography sx={styles.memberName}>{h.memberName}{!h.memberRequests.some(r => r.offSeason) && h.memberRequests.some(r => !r.priorityRequest) ? ' (P)' : EMPTY_STRING}</Typography>
    )
    //}

  }

  /*
 * Get # of household requests selected
 */
  const selectedCount = (id: number): JSX.Element => {

    const h = props.lodgeUseRequests.find(h => h.householdID === id);
    if (h) {

      const mr = h.memberRequests.filter(r => !r.isGuest && r.priorityRequest)
      const rs = h.memberRequests.filter(r => !r.isGuest && r.priorityRequest && membersSelected.includes(r.id));

      if (requestsSelected.includes(id)) {
        return (<FormControlLabel
          control={<PeopleIcon fontSize="small" />}
          label={`${rs.length}/${mr.length}`}
        />);
      } else {
        return (<FormControlLabel
          control={<CheckIcon fontSize="small" />}
          label={`${rs.length}/${mr.length}`}
        />);
      }
    } else {
      return (<span />);
    }

  }

  // #endregion

  // #region Event handlers

  /*
* Handles email action
*/
  const handleActionEmail = () => {
    props.handleEmail(membersSelected);
  };

  /*
 * Handle all households selected
 */
  const handleAllSelected = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.checked) {

      // All member priority requests
      const mids = props.lodgeUseRequests.flatMap(h => h.memberRequests.filter(r => !r.isGuest)).map(r => r.id);
      // Requests IDS
      const sids = props.lodgeUseRequests.map(r => r.signupID);

      // All checkbox selected
      setAllChecked(true);

      // Add priority member IDs selected
      setMembersSelected([...mids]);

      // Add request IDs selected
      setRequestsSelected([...sids]);

    } else {
      setMembersSelected([]);
      setRequestsSelected([]);
      setAllChecked(false);
    }

  }

  /*
   * Handle selected request
   */
  const handleRequestSelected = (event: React.ChangeEvent<HTMLInputElement>) => {

    const signupID = Number(event.target.id);

    // All member priority requests
    const memberRequests = props.lodgeUseRequests.filter(req => req.signupID === signupID).flatMap(req => req.memberRequests.filter(mr => !mr.isGuest));

    const ids = memberRequests.map(r => r.id);

    if (event.target.checked) {
      // Add member IDs to selected
      setMembersSelected([...membersSelected, ...ids]);

      // Add signup request ID to selected
      setRequestsSelected([...requestsSelected, signupID]);

    } else {
      // Get a member IDS except those for the selected signup
      const m = [...membersSelected];
      const mr = m.filter(id => !ids.includes(id));
      // Remove members no longer selected
      setMembersSelected(mr);

      // Get signup IDS except those for the selected signup
      const requests = [...requestsSelected];
      const lodgeUseRequests = requests.filter(id => id !== signupID);
      // Set requests selected to all those except those no longer selected
      setRequestsSelected(lodgeUseRequests);
    }

  }

  /*
   * Handle selected member request
   */
  const handleMemberSelected = (event: React.ChangeEvent<HTMLInputElement>) => {

    const id = Number(event.target.id);

    if (event.target.checked) {
      setMembersSelected([...membersSelected, id]);
    } else {
      // Remove member no longer selected
      const i = membersSelected.findIndex(i => i === id);
      const m = [...membersSelected];
      m.splice(i, 1);
      setMembersSelected(m);

      // Check is request no longer has any members selected
      // Get all mmember IDs in corresponding request
      const r = props.lodgeUseRequests.find(r => r.memberRequests.some(m => m.id === id));
      if (r) {
        const mids = r?.memberRequests.filter(m => m.id !== id).flatMap(m => m.id);
        // Are any of those IDs in the selected member list?
        if (mids && (!membersSelected.some(m => mids.includes(m)))) {
          // If not, remove request from selected request list
          const requests = [...requestsSelected];
          const lodgeUseRequests = requests.filter(id => id !== r.signupID);
          // Set requests selected to all those except those no longer selected
          setRequestsSelected(lodgeUseRequests);
        }
      }

    }
  }

  /*
   * Handles status change action
   */
  const handleStatusChange = () => {
    // Process status change for selected members
    const statusChange = new LodgeUseStatusChange();
    statusChange.signupStatusID = actionType;
    statusChange.memberSignupIDs = membersSelected;
    props.handleStatusChange(statusChange);

    // Clear selections
    setMembersSelected([]);
    setRequestsSelected([]);
    setAllChecked(false);
    setActionType(LodgeUseAction.Confirm)

  };

  /*
 * Handles status type change
 */
  const handleStatusTypeChanged = (
    event: React.MouseEvent<HTMLElement>,
    a: LodgeUseAction
  ) => {
    if (a !== null) {
      setActionType(a);
    }
  };

  React.useEffect(() => {

    if (membersSelected.length === 0) {
      setAllChecked(false);
    } else {
      setAllChecked(true);
    }

  }, [membersSelected.length]);

  // #endregion

  return (
    <div style={styles.root}>
      <AppBar position="static" color="inherit" sx={styles.appBar}>
        <Toolbar>
          <Checkbox
            disabled={props.lodgeUseRequests.length === 0}
            checked={allChecked}
            onChange={handleAllSelected}
          />
          <ToggleButtonGroup
            disabled={props.lodgeUseRequests.length === 0}
            value={actionType}
            exclusive
            onChange={handleStatusTypeChanged}
          >
            <ToggleButton value={LodgeUseAction.Pending}>
              <Tooltip title="Pending">
                <InfoIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Denied}>
              <Tooltip title="Deny">
                <BlockIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Waitlist}>
              <Tooltip title="Waitlist">
                <PanToolIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Confirm}>
              <Tooltip title="Confirm">
                <CheckCircleIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title="Approve the status change for selected individuals">
            <span>
              <IconButton
                disabled={(membersSelected.length === 0)}
                onClick={handleStatusChange}
                size="large"
                sx={styles.toolbarItem}
              >
                <PlaylistPlayIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Tooltip title="Send an email to the selected individuals">
            <span>
              <IconButton
                disabled={(membersSelected.length === 0)}
                onClick={handleActionEmail}
                size="large"
                sx={styles.toolbarItem}
              >
                <EmailIcon />
              </IconButton>
            </span>
          </Tooltip>
          <EmailQueueSummary refresh={props.refreshEmailQueue} />
        </Toolbar>
      </AppBar>
      {props.lodgeUseRequests.length > 0 &&
        props.lodgeUseRequests.map(h => (
          <Accordion key={h.signupID}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={styles.header} >
              <Grid container item alignItems="center">
                <Grid item xs={1}>
                  <Badge
                    color="secondary"
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    invisible={!h.modified}
                  >
                    <FormControlLabel
                      control={<Checkbox
                        id={h.signupID.toString()}
                        checked={requestsSelected.includes(h.signupID)}
                        onClick={(event) => { event.stopPropagation() }}
                        disabled={(!h.memberRequests.some(r => r.offSeason) && !h.memberRequests.some(r => r.priorityRequest))}
                        onFocus={(event) => event.stopPropagation()}
                        onChange={handleRequestSelected}
                      />}
                      onFocus={(event) => event.stopPropagation()}
                      label={h.referenceNumber}
                    />
                  </Badge>
                </Grid>
                <Grid item xs={1}>
                  {(h.memberRequests.some(r => r.priorityRequest)) &&
                    selectedCount(h.householdID)
                  }
                </Grid>
                <Grid item xs={1}>
                  {headerStatus(h)}
                </Grid>
                <Grid item xs={2}>
                  {memberName(h)}
                </Grid>
                <Grid item xs={2}>
                  <Typography>{`${dateFormatted(new Date(h.minArrivalDate))}-${dateFormatted(new Date(h.maxDepartureDate))}`}</Typography>
                </Grid>
                <Grid item xs={1}>
                  {(h.statusID !== 5 && h.statusID !== 6) &&
                    <Typography>{`Priority: ${h.priority}`}</Typography>
                  }
                </Grid>
                <Grid item xs={2}>
                  <Typography>{`R: ${dateStringToLocalDateFormatted(h.requestDate)}`}</Typography>
                </Grid>
                {h.modified &&
                  <Grid item xs={1}>
                    <Tooltip title="Mark request as viewed">
                      <Button variant="outlined" size="small" color="primary"
                        onFocus={(event) => event.stopPropagation()}
                        onClick={(event) => {
                          props.modifiedViewed(h.signupID)
                          event.stopPropagation()
                        }}>Viewed</Button>
                    </Tooltip>
                  </Grid>
                }
              </Grid>
            </AccordionSummary>
            {
              h.memberRequests.map(mr => (
                <Grid container item alignItems="center" key={mr.id.toString()} spacing={1}>
                  <Grid item xs={2}>
                    {(!mr.offSeason && !mr.priorityRequest) &&
                      <Typography variant="h6" display="inline" mx={2}>P</Typography>
                    }
                    {((mr.offSeason || mr.priorityRequest) && mr.isGuest) &&
                      <Typography variant="h6" display="inline" mx={2}>G</Typography>
                    }
                    {((mr.offSeason || mr.priorityRequest) && !mr.isGuest) &&
                      <Checkbox
                        id={mr.id.toString()}
                        checked={membersSelected.includes(mr.id)}
                        onClick={(event) => {
                          //action here
                          event.stopPropagation()
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        onChange={handleMemberSelected}
                      />
                    }
                    <Typography variant="body2" display="inline">{`${mr.lastName}, ${mr.firstName} (${mr.gender}) ${mr.priorityGuest ? '*' : EMPTY_STRING}`}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2" display="inline">{`Priority: ${mr.priority}`}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2">{mr.status}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2">{mr.type}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">{`${dateFormatted(new Date(mr.arrivalDate))}-${dateFormatted(new Date(mr.departureDate))}`}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2">{`Waiver: ${mr.waiverStatus == null ? 'n/a' : mr.waiverStatus}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{mr.phone}</Typography>
                  </Grid>
                </Grid>
              ))
            }
          </Accordion>
        ))
      }
      {
        props.lodgeUseRequests.length === 0 &&
        <Box sx={styles.noRequests}>
          {props.working &&
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }
          {!props.working &&
            <div style={{ textAlign: 'center' }}>
              <Typography>No household requests for the selected date range and view</Typography>
            </div>
          }
        </Box>
      }
    </div >);

}

export default Requests;