// #region imports

import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

// Styles
import { useTheme } from '@mui/material/styles';

import {
  ClubEmailAddress
  , HTTPHeaders
} from '../../interfaces';

import {
  API_URL
  , NULL_ID
} from '../../global';


// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

const DEFAULT_CLUBEMAILADDRESS_ID = 1;

// #endregion

// #region interfaces

interface ClubEmailSelectProps {
  clubEmailSelected: (clubEmail: ClubEmailAddress) => void;
}

// #endregion

const ClubEmailSelect: React.FC<ClubEmailSelectProps> = (props) => {

  const { getAccessToken } = useAuth0User();

  const [errors, setErrors] = React.useState(false);
  const [clubEmailAddressID, setClubEmailAddressID] = React.useState<number>(NULL_ID);
  const [clubEmailAddressList, setClubEmailAddressList] = React.useState<Array<ClubEmailAddress>>([]);

  // #region Styles

  const theme = useTheme();
  const styles = {
    selectWidth: {
      flexGrow: 1,
      margin: theme.spacing(1),
      minWidth: 175,
    },
  };

  // #endregion

  // #region Methods

  /*
* Fetch club email addresses
*/
  const fetchClubEmails = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/email/clubemails`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Club email addresses were not retrieved');
        }
        return response.json()
      })
      .then((ce: Array<ClubEmailAddress>) => {
        setClubEmailAddressList(ce);
      }
      )
      .catch(err => setErrors(err));
  }

  // #endregion

  // #region Event handlers

  /*
 * Handle selection of club email address
 */
  const handleClubEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    // Convert value to number
    const clubEmail = clubEmailAddressList.find(ce => ce.clubEmailAddressID === Number(event.target.value));
    if (clubEmail) {
      setClubEmailAddressID(clubEmail.clubEmailAddressID);
      props.clubEmailSelected(clubEmail);
    }

  };

  React.useEffect(() => {
    fetchClubEmails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (clubEmailAddressList.length > 0) {
      setClubEmailAddressID(DEFAULT_CLUBEMAILADDRESS_ID);
    }
  }, [clubEmailAddressList]);

  // #endregion

  return (
    <TextField
      select
      label='From'
      sx={styles.selectWidth}
      value={clubEmailAddressID.toString()}
      onChange={handleClubEmailChange}
    >
      {clubEmailAddressList.map((ce: ClubEmailAddress) => (
        <MenuItem key={ce.clubEmailAddressID} value={ce.clubEmailAddressID}>{ce.entityName}</MenuItem>
      ))}
    </TextField>
  );

}

export default ClubEmailSelect;