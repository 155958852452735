import React from 'react';

import BookOnlineIcon from '@mui/icons-material/BookOnline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ListIcon from '@mui/icons-material/List';

// Styles
import { useTheme } from '@mui/material/styles';

import Feedback from './feedback/Feedback';
import LodgeUse from './lodgeuse/LodgeUse';
import MemberEmail from './memberemail/MemberEmail';
import Passes from './passes/Passes';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

enum Nav {
  None = -1,
  LodgeUse = 0,
  Passes = 1,
  MemberEmail = 2,
  Feedback = 3
}

const Admin: React.FC = () => {

  const { isEmailAdmin, isPassAdmin, isSignupCoordinator, isSystemAdmin } = useAuth0User();

  const initializeActiveNav = () => {

    if (isSignupCoordinator()) {
      return Nav.LodgeUse;
    } else if (isPassAdmin()) {
      return Nav.Passes
    } else if (isEmailAdmin()) {
      return Nav.MemberEmail;
    } else {
      return Nav.Feedback;
    }
  }

  const [activeNav, setActiveNav] = React.useState<Nav>(initializeActiveNav());

  // #region Styles

  const theme = useTheme();
  const styles = {
    nav: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      flexGrow: 1,
      margin: theme.spacing(1, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods
  // #endregion

  // #region Event Handlers

  const handleNavChange = (event: React.ChangeEvent<{}>, index: number) => {
    setActiveNav(index);
  };

  React.useEffect(() => {

    initializeActiveNav();;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return (
    <>
      <Grid container item>
        <BottomNavigation
          value={activeNav}
          onChange={handleNavChange}
          showLabels
          sx={styles.nav}
        >
          {isSignupCoordinator() &&
            <BottomNavigationAction label="Lodge Use" icon={<ListIcon />} />
          }
          {isPassAdmin() &&
            <BottomNavigationAction label="Passes" icon={<BookOnlineIcon />} />
          }
          {isEmailAdmin() &&
            <BottomNavigationAction label="Member Email" icon={<EmailIcon />} />
          }
          {isSystemAdmin() &&
            <BottomNavigationAction label="Feedback" icon={<FeedbackIcon />} />
          }
        </BottomNavigation>
      </Grid>
      <Grid container item>
        {activeNav === Nav.LodgeUse &&
          <LodgeUse />
        }
        {activeNav === Nav.Passes &&
          <Passes />
        }
        {activeNav === Nav.MemberEmail &&
          <MemberEmail />
        }
        {activeNav === Nav.Feedback &&
          <Feedback />
        }
      </Grid>
    </>
  );
}

export default Admin;
