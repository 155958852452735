// #region imports

import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

import {
  MemberStatus
} from '../interfaces';

import { NULL_ID } from '../global';

// #endregion

// #region interfaces

interface MemberStatusSelectProps {
  disabled: boolean;
  name: string;
  memberStatusID: number;
  memberStatuses: Array<MemberStatus>;
  memberStatusSelected: (memberStatusID: number) => void;
}

// #endregion

const MemberStatusSelect: React.FC<MemberStatusSelectProps> = (props) => {

  // #region Styles

  const styles = {
    selectWidth: {
      flexGrow: 1,
      minWidth: 120,
    },
  };

  // #endregion

  // #region Event handlers

  /*
  * Handle selection of member status
  */
  const handleMemberStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Convert value to number
    props.memberStatusSelected(Number(event.target.value));
  };

  // #endregion

  return (
    <TextField
      sx={styles.selectWidth}
      name={props.name}
      label="Member Status"
      onChange={handleMemberStatusChange}
      select
      value={props.memberStatusID.toString()}
    >
      <MenuItem key={NULL_ID} value={NULL_ID}>None</MenuItem>
      {props.memberStatuses.map((ms: MemberStatus) => (
        <MenuItem key={ms.memberStatusID} value={ms.memberStatusID}>{ms.status}</MenuItem>
      ))}
    </TextField>
  );

}

export default MemberStatusSelect;