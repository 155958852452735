// #region imports
import React from 'react';
import { format } from 'date-fns';

// Material-UI
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { amber, blueGrey, green, red } from '@mui/material/colors';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams
} from '@mui/x-data-grid';
import Drawer from '@mui/material/Drawer';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import {
  HTTPHeaders,
  SignupResultStatus
} from '../../interfaces';

import {
  MemberSignup
} from '../../models';

import {
  API_URL,
  NULL_ID,
  SignupExceptionEnum,
  SignupStatusID
} from '../../global';

import MemberSignupEditAbandonDialog from './MemberSignupEditAbandonDialog';
import MemberSignupCancelDialog from './MemberSignupCancelDialog';
import MemberSignupEdit from './MemberSignupEdit';

// Redux
import { useDispatch, useSelector } from "react-redux";

import {
  setMemberCancelled,
  setMemberSaved
} from "../../../store/signups/actions";

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// Signup date criteria
import { useSignupDates } from '../../hooks/UseSignupDates';

// #endregion

// #region Interfaces

interface MemberSignupsListProps {
  arrivalDateChanged: (arrival: Date) => void;
  hasChanged: () => void;
  signupID: number;
}

// #endregion

const MemberSignupsList: React.FC<MemberSignupsListProps> = (props) => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  const { calculateMemberDateRestrictions } = useSignupDates();

  const [abandonDialogOpen, setAbandonDialogOpen] = React.useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [errors, setErrors] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [memberCancel, setMemberCancel] = React.useState<MemberSignup>(new MemberSignup());
  const [memberEdit, setMemberEdit] = React.useState<MemberSignup>(new MemberSignup());
  const [memberSignups, setMemberSignups] = React.useState<Array<MemberSignup>>([]);

  // Redux
  const dispatch = useDispatch();
  const memberCancelled: boolean = useSelector((state: any) => state.signups.memberCancelled);
  const memberSaved: boolean = useSelector((state: any) => state.signups.memberSaved);
  const notSignedUpAdded: boolean = useSelector((state: any) => state.signups.notSignedUpAdded);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(1, 1, 1, 1)
    },
    confirmed: {
      color: green[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
    deined: {
      color: red[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
    editDrawer: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    pending: {
      color: blueGrey[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
    sectionHeading: {
      margin: theme.spacing(1, 0, 1, 0)
    },
    waitlist: {
      color: amber[500],
      padding: theme.spacing(0, 1, 0, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
 * Cancel member signup
 */
  const cancelMember = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/member/cancel/${memberCancel.memberSignupID}/${memberID()}`);

    await fetch(url.toString(),
      {
        method: 'DELETE'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar('Member lodge use request was not cancelled. Please report using the Feedback form.', { variant: 'error' });
        }

        // Flag has changed
        props.hasChanged();
        // Set trackng flag
        dispatch(setMemberCancelled(true));
        enqueueSnackbar(`Member lodge use request cancelled.`, { variant: 'success' });
      })
      .catch(err => {
        enqueueSnackbar('Member lodge use request was not cancelled. Please report using the Feedback form.', { variant: 'error' });
        setErrors(err);
      });
  }

  /*
 * Fetch member signup for editing
 */
  const fetchSignups = async () => {

    // Don't fetch for NULL_ID
    if (props.signupID === NULL_ID) {
      return;
    }

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/${props.signupID}/members`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Member signups not retrieved');
        }
        return response.json()
      })
      .then((signups: Array<MemberSignup>) => {
        calculateMemberDateRestrictions(signups);
        setMemberSignups(signups);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {
    return [
      {
        field: 'signupID',
        headerName: '',
        width: 50,
        type: 'number',
        align: 'center',
        renderCell: (params: GridCellParams) => (
          renderEditIcon(params)
        )
      }, {
        field: 'memberSignupID',
        headerName: '',
        width: 50,
        type: 'number',
        align: 'center',
        renderCell: (params: GridCellParams) => (
          renderCancelIcon(params)
        )
      }, {
        field: 'lastName',
        headerName: 'Name',
        width: isMobile ? 200 : 175,
        type: 'string',
        valueGetter: renderName
      }, {
        field: 'arrivalDate',
        headerName: 'Dates',
        hide: isMobile,
        width: 125,
        type: 'string',
        valueGetter: renderDates
      }, {
        field: 'memberStatus',
        headerName: 'Type',
        hide: isMobile,
        width: 150,
        type: 'string',
      }, {
        field: 'nights',
        headerName: 'Nights',
        hide: isMobile,
        width: 60,
        type: 'number',
      }, {
        field: 'signupStatusID',
        headerName: 'Status',
        hide: isMobile,
        width: 125,
        renderCell: (params: GridCellParams) => (
          renderStatus(params)
        )
      }
    ];

  }

  /*
* Render dates
*/
  const renderDates = (params: GridValueGetterParams) => {
    return `${format(new Date(params.row.arrivalDate), 'MM/dd')}-${format(new Date(params.row.departureDate), 'MM/dd')}`
  }

  /*
* Render cancel button
*/
  const renderCancelIcon = (params: GridCellParams) => {
    const id = Number(params.row.memberSignupID);

    if ((id > 0) && (memberSignups.length > 1)) {

      const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        // Store member data for editing
        const i = memberSignups.findIndex(ms => { return ms.memberSignupID === id });
        const s = { ...memberSignups[i] };
        setMemberCancel(s);

        // Reset trackng flag
        dispatch(setMemberCancelled(false));

        setCancelDialogOpen(true);
      }

      return (
        <Tooltip title={`Cancel member request`} arrow>
          <IconButton onClick={onClick} size="medium">
            <CancelIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <span></span>
      );
    }

  }

  /*
* Render edit button
*/
  const renderEditIcon = (params: GridCellParams) => {
    const id = Number(params.row.memberSignupID);

    if (id > 0) {

      const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        const i = memberSignups.findIndex(ms => { return ms.memberSignupID === id });
        const s = { ...memberSignups[i] };
        setMemberEdit(s);

        // Reset trackng flag
        dispatch(setMemberSaved(false));

        setDrawerVisible(true);
      }

      return (
        <Tooltip title={`Edit member request`} arrow>
          <IconButton onClick={onClick} size="medium">
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <span></span>
      );
    }

  }

  /*
* Render member name
*/
  const renderName = (params: GridValueGetterParams) => {
    return `${params.row.lastName}, ${params.row.firstName}`
  }

  /*
 * Render status icon for request
 */
  const renderStatus = (params: GridCellParams) => {

    switch (params.row.signupStatusID) {
      case SignupStatusID.Confirmed:
        return <Tooltip title="Confirmed">
          <><CheckCircleIcon fontSize="small" sx={styles.confirmed} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      case SignupStatusID.Denied:
        return <Tooltip title="Denied">
          <><BlockIcon fontSize="small" sx={styles.deined} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      case SignupStatusID.Pending:
        return <Tooltip title="Pending">
          <><InfoIcon fontSize="small" sx={styles.pending} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      case SignupStatusID.Waitlist:
        return <Tooltip title="Waitlist">
          <><PanToolIcon fontSize="small" sx={styles.waitlist} />{params.row.signupStatus}</>
        </Tooltip>;
        break;
      default:
    }
  }

  // #endregion

  // #region Event handlers

  /*
* Event handler for cancel abandon
*/
  const handleCancelAbandon = () => {
    setCancelDialogOpen(false);
  };

  /*
   * Event handler for cancelling a member signup
   */
  const handleCancelMember = () => {
    setCancelDialogOpen(false);
    cancelMember();
  };

  /*
 * Handle closing of edit panel
 */
  const handleDrawerClose = () => {
    setAbandonDialogOpen(true);
  };

  /*
* Event handler for edit abandon
*/
  const handleEditAbandon = () => {
    setAbandonDialogOpen(false);
    setDrawerVisible(false);
  };

  /*
* Event handler for edit continue
*/
  const handleEditContinue = () => {
    setAbandonDialogOpen(false);
  };

  /*
* Handle update of member
*/
  const handleUpdate = async (signup: MemberSignup) => {

    // User updating data
    signup.userID = memberID();

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/member`);

    await fetch(url.toString(),
      {
        body: JSON.stringify(signup)
        , headers: headers
        , method: 'POST'
      }
    )
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar('Member lodge use request did not save. Please report using the Feedback form.', { variant: 'error' });
        } else {
          response.json().then((result: SignupResultStatus) => {
            if ((result.statusCode === SignupExceptionEnum.MemberSignupInsertDatesOverlap)
              || (result.statusCode === SignupExceptionEnum.MemberSignupUpdateDatesOverlap)
              || (result.statusCode === SignupExceptionEnum.LodgeNightsDatesOverlap)) {
              enqueueSnackbar(result.message, { variant: 'warning' });
            } else {
              fetchSignups();
              // Flag change occurred
              props.hasChanged();
              // Pass to parent for check on min arrival date for off-season
              props.arrivalDateChanged(new Date(signup.arrivalDate));
              // Set trackng flag
              dispatch(setMemberSaved(true));
              enqueueSnackbar(result.message, { variant: 'success' });
            }
          });
        }

        setDrawerVisible(false);
      })
      .catch(err => {
        enqueueSnackbar('Member lodge use request did not save. Please report using the Feedback form.', { variant: 'error' });
        setErrors(err);
        setDrawerVisible(false);
      });

  }

  React.useEffect(() => {
    if (memberCancelled) {
      fetchSignups();
      // Reset memberCancelled in SignupsCanclled
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCancelled]);

  React.useEffect(() => {
    if (memberSaved) {
      fetchSignups();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberSaved]);

  React.useEffect(() => {
    if (notSignedUpAdded) {
      fetchSignups();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notSignedUpAdded]);

  React.useEffect(() => {
    fetchSignups();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.signupID]);

  // #endregion

  return (
    <>
      <Grid container item sx={styles.sectionHeading}>
        <Typography variant="button">Member Requests</Typography>
      </Grid>
      <DataGrid
        autoHeight
        columns={columns()}
        density="compact"
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        getRowId={(row: MemberSignup) => row.memberSignupID} // Unique ID for each row
        hideFooter={true}
        loading={isLoading}
        rows={memberSignups}
        sx={styles.root}
      />

      <Drawer variant="temporary"
        anchor={isMobile ? 'bottom' : 'right'}
        open={drawerVisible}
        onClose={handleDrawerClose}
      >
        <div style={styles.editDrawer}>
          <MemberSignupEdit
            memberSignup={memberEdit}
            handleClose={handleDrawerClose}
            handleUpdate={handleUpdate}
          />
        </div>
      </Drawer>
      <MemberSignupEditAbandonDialog
        show={abandonDialogOpen}
        handleAbandon={handleEditAbandon}
        handleContinue={handleEditContinue} />
      <MemberSignupCancelDialog
        show={cancelDialogOpen}
        ms={memberCancel}
        handleAbandon={handleCancelAbandon}
        handleCancel={handleCancelMember} />
    </>
  )

}

export default MemberSignupsList;