// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import { useAuth0 } from '@auth0/auth0-react';
// #endregion

const Home: React.FC = () => {

  // Auth0 hooks
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      margin: theme.spacing(5, 0, 0, 0)
    },
    loginButton: {
      margin: theme.spacing(4, 0, 0, 0)
    },
  };

  // #endregion

  // #region Event Handlers

  /*
* Event handler for Login element
*/
  const handleLogin = (): void => {
    loginWithRedirect();
  }

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item justifyContent="center">
        <img src="images/msc_logo_150x150.jpg" width="150" height="150" alt="Montclair Ski Club" />
      </Grid>
      <Grid container item justifyContent="center">
        <Typography gutterBottom variant="h5">Montclair Ski Club</Typography>
      </Grid>
      <Grid container item justifyContent="center" >
        <Typography variant="body1" color="textSecondary">Since 1947</Typography>
      </Grid>
      {!isAuthenticated &&
        <Grid container justifyContent="center">
          <Grid item lg={2}>
            <Button variant="contained" color="primary" fullWidth sx={styles.loginButton} onClick={handleLogin}>Login</Button>
          </Grid>
        </Grid>
      }
    </Box>
  );
}

export default Home;

