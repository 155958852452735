// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material//Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

// Redux
import { useDispatch } from "react-redux";
import {
  setCopyright,
  setReleaseDate,
  setVersion
} from "../../store/app/actions";
import {
  setGuestRequestTime,
  setOffSeasonStartDate
} from "../../store/signups/actions";

// Components
import BetaAccess from '../misc/BetaAccess';
import Admin from '../admin/Admin';
import Home from '../misc/Home';
import Membership from '../membership/Membership';
import Offline from '../misc/Offline';
import Resources from '../resources/Resources';
import Signups from '../signups/Signups';
import Starting from '../misc/Starting';
import TopBar from './TopBar';

import {
  API_URL,
  AppMode,
  COMPONENT_ADMIN,
  COMPONENT_MEMBERSHIP,
  COMPONENT_RESOURCES,
  COMPONENT_SIGNUPS,
  RELEASES_KEY
} from '../global';

import {
  HTTPHeaders
} from '../interfaces';

import {
  StartupOptions,
  ReleasesIndicator
} from '../models';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';
import { useLocalStorage } from '../hooks/UseLocalStorage';

// Components
import ReleasesDialog from '../misc/ReleasesDialog';

// #endregion

enum ApplicationMode {
  Unauthenticated = 1,
  Starting = 2,
  BetaOnly = 3,
  Offline = 4,
  Online = 5
}

const Layout: React.FC = () => {

  // Auth0 hooks
  const { getAccessToken, isAuthenticated, isBetaTester } = useAuth0User();
  const dispatch = useDispatch();
  const location = useLocation();

  const [errors, setErrors] = React.useState<boolean>(false);
  const [mode, setMode] = React.useState<ApplicationMode>(isAuthenticated ? ApplicationMode.Starting : ApplicationMode.Unauthenticated);
  const [startupOptions, setStartupOptions] = React.useState<StartupOptions>(new StartupOptions());
  const [releasesLS, setReleasesLS] = useLocalStorage(RELEASES_KEY, new ReleasesIndicator());
  const [releasesOpen, setReleasesOpen] = React.useState<boolean>(false);

  // #region Styles

  const styles = {
    root: {
      flexGrow: 1,
      height: '100%',
    },
    contentContainer: {
      flexGrow: 1,
      height: '100%',
    },
  };

  // #endregion

  // #region Methods

  /*
   * Configure start mode
   */
  const startupConfig = (options: StartupOptions) => {

    // Store in state
    setStartupOptions(options);

    if (options.applicationOffline) {
      setMode(ApplicationMode.Offline);
    } else if (options.applicationMode === AppMode.Beta && !isBetaTester()) {
      setMode(ApplicationMode.BetaOnly);
    } else {
      setMode(ApplicationMode.Online);
    }

    // Store in global state
    dispatch(setGuestRequestTime(options.guestRequestTime));
    dispatch(setOffSeasonStartDate(options.offSeasonStartDate));
    dispatch(setCopyright(options.copyright));
    dispatch(setReleaseDate(options.releaseDate));
    dispatch(setVersion(options.version));

    // Configure whether What's New dialog window is displayed
    configureReleaseDialogDisplay(options.version);
  }

  /*
   * Determine active component
   */
  const activeComponent = () => {

    if (!isAuthenticated) {
      return <Home />
    } else {
      if (mode === ApplicationMode.Starting) {
        return <Starting />
      } else if (mode === ApplicationMode.Offline) {
        return <Offline />
      } else if (mode === ApplicationMode.BetaOnly && !isBetaTester()) {
        return <BetaAccess />
      } else if (mode === ApplicationMode.Online) {
        return routeToComponent();
      } else {
        return <Home />
      }
    }

  }

  /*
  *Configure whether releases dialog window is displayed
  */
  const configureReleaseDialogDisplay = (version: string) => {

    // Remove obsolete key
    // DELETE THIS CODE AT SOME POINT
    localStorage.removeItem('whatsnew');

    // New app version or now yet stored, store version # and display Whats New dialog defaults to true
    if (releasesLS.version != version) {
      setReleasesLS({
        version: version,
        display: true
      });
    };

  }

  /*
   * Route to the active component
   */
  const routeToComponent = () => {

    if (location.pathname.includes(COMPONENT_ADMIN)) {
      return adminComponent();
    } else if (location.pathname.includes(COMPONENT_MEMBERSHIP)) {
      return membershipComponent();
    } else if (location.pathname.includes(COMPONENT_RESOURCES)) {
      return resourcesComponent();
    } else if (location.pathname.includes(COMPONENT_SIGNUPS)) {
      return signupsComponent();
    } else {
      return homeComponent();
    }

  }

  /*
   * Admin component element
   */
  const adminComponent = () => {
    return (
      <Box sx={styles.root}>
        <TopBar />
        <Grid container item sx={styles.contentContainer}>
          <Admin />
        </Grid>
      </Box>
    )
  }

  /*
   * Home component element
   */
  const homeComponent = () => {
    return (
      <Box sx={styles.root}>
        <TopBar />
        <Box sx={styles.contentContainer}>
          <Home />
        </Box>
      </Box>
    )
  }

  /*
   * Membership component element
   */
  const membershipComponent = () => {
    return (
      <Box sx={styles.root}>
        <TopBar />
        <Grid container item sx={styles.contentContainer}>
          <Membership />
        </Grid>
      </Box>
    )
  }

  /*
   * Resources component element
   */
  const resourcesComponent = () => {
    return (
      <Box sx={styles.root}>
        <TopBar />
        <Grid container item sx={styles.contentContainer}>
          <Resources />
        </Grid>
      </Box>
    )
  }

  /*
  * Signups component element
  */

  const signupsComponent = () => {
    return (
      <Box sx={styles.root}>
        <TopBar />
        <Grid container item sx={styles.contentContainer}>
          <Signups
            signupsOffline={startupOptions.signupsOffline}
            signupsStartTime={startupOptions.signupsStartTime}
          />
        </Grid>
      </Box>
    )
  }

  /*
  * Fetch member signup for editing
  */
  const fetchStartupOptions = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/admin/startup`);

    await fetch(url.toString(),
      {
        method: 'GET',
        cache: 'no-cache',
        headers: headers,
        mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Startup options not retrieved');
        }
        return response.json()
      })
      .then((opt: StartupOptions) => {
        startupConfig(opt);
      })
      .catch(err => setErrors(err));
  }

  // #endregion

  // #region Event handlers

  /*
  * Handler for releases dialog window
  */
  const handleReleasesClose = (dontShow: boolean) => {
    // Close dialog windows
    setReleasesOpen(false);

    // If now hide, change display=false
    if (dontShow) {
      setReleasesLS({
        ...releasesLS,
        display: false
      });
    }
  }

  React.useEffect(() => {
    if (isAuthenticated && mode === ApplicationMode.Starting) {
      fetchStartupOptions();
    }
    activeComponent();

    if (isAuthenticated && mode == ApplicationMode.Online && releasesLS.display) {
      setReleasesOpen(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // #endregion

  // Active component returned based upon mode etc...
  return <>
    {activeComponent()}
    <ReleasesDialog
      show={releasesOpen}
      handleClose={handleReleasesClose} />
  </>
}

export default Layout;
