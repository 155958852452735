import {
  EMPTY_STRING
} from "../../components/global";

import {
  SIGNUP_CANCELLED,
  SIGNUP_CLEAR_CALENDARFILTER,
  SIGNUP_GUEST_CANCELLED,
  SIGNUP_GUEST_REQUEST_TIME,
  SIGNUP_GUEST_SAVED,
  SIGNUP_MAXDEPARTURE,
  SIGNUP_MEMBER_CANCELLED,
  SIGNUP_MEMBER_SAVED,
  SIGNUP_MINARRIVAL,
  SIGNUP_NOTSIGNEDUP_ADDED,
  SIGNUP_OFFSEASON_STARTDATE
} from "../ActionTypes";

const initialState = {
  clearCalendarFilter: false,
  guestCancelled: false,
  guestRequestTime: 0, //Min
  guestSaved: false,
  maxDeparture: EMPTY_STRING,
  memberCancelled: false,
  memberSaved: false,
  minArrival: EMPTY_STRING,
  notSignedUpAdded: false,
  offSeasonStartDate: EMPTY_STRING,
  signupCancelled: false
};

const signups = (state: any = initialState, action: any) => {

  switch (action.type) {
    case SIGNUP_CANCELLED:
      return {
        ...state,
        signupCancelled: action.payload
      };
    case SIGNUP_CLEAR_CALENDARFILTER:
      return {
        ...state,
        clearCalendarFilter: action.payload
      };
    case SIGNUP_GUEST_CANCELLED:
      return {
        ...state,
        guestCancelled: action.payload
      };
    case SIGNUP_GUEST_REQUEST_TIME:
      return {
        ...state,
        guestRequestTime: action.payload
      };
    case SIGNUP_GUEST_SAVED:
      return {
        ...state,
        guestSaved: action.payload
      };
    case SIGNUP_MAXDEPARTURE:
      return {
        ...state,
        maxDeparture: action.payload
      };
    case SIGNUP_MEMBER_CANCELLED:
      return {
        ...state,
        memberCancelled: action.payload
      };
    case SIGNUP_MEMBER_SAVED:
      return {
        ...state,
        memberSaved: action.payload
      };
    case SIGNUP_MINARRIVAL:
      return {
        ...state,
        minArrival: action.payload
      };
    case SIGNUP_NOTSIGNEDUP_ADDED:
      return {
        ...state,
        notSignedUpAdded: action.payload
      };
    case SIGNUP_OFFSEASON_STARTDATE:
      return {
        ...state,
        offSeasonStartDate: action.payload
      };
    default: {
      return state;
    }
  }

}

export default signups;
