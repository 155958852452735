// #region imports
import React from 'react';

// Material-UI
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { blueGrey, green, red } from '@mui/material/colors';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay
} from '@mui/x-data-grid';
import LiveHelp from '@mui/icons-material/LiveHelp';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders,
  TransferablePass
} from '../../interfaces';

import {
  API_URL,
  NULL_DATETIME_STRING,
  TransferablePassStatus
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region Interfaces

interface TransferablePassesProps {
  refresh: boolean;
}

// #endregion

const TransferablePasses: React.FC<TransferablePassesProps> = (props: TransferablePassesProps) => {

  const { getAccessToken } = useAuth0User();
  const { dateToLocalDateFormatted, dateStringToLocalDateFormatted } = useDateFormatting();
  const { isMobile } = useResponsive();

  const [passes, setPasses] = React.useState<Array<TransferablePass>>([]);
  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      width: '100%'
    },
    denied: {
      color: red[500]
    },
    confirmed: {
      color: green[500]
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    requested: {
      color: blueGrey[500]
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {

    if (isMobile) {
      return [
        {
          field: 'passDate',
          headerName: 'Pass Date',
          width: 125,
          type: 'string',
          align: 'left',
          renderCell: (params: GridCellParams) => (
            renderPassesDate(params)
          )
        },
        {
          field: 'transferablePassStatusID',
          headerName: 'Status',
          width: 75,
          type: 'string',
          align: 'center',
          renderCell: (params: GridCellParams) => (
            renderStatus(params)
          )
        },
        {
          field: 'fullName',
          headerName: 'Name',
          width: 175,
          type: 'string',
          align: 'left',
        }
      ];
    } else {
      return [
        {
          field: 'passDate',
          headerName: 'Pass Date',
          width: 125,
          type: 'string',
          align: 'left',
          renderCell: (params: GridCellParams) => (
            renderPassesDate(params)
          )
        },
        {
          field: 'transferablePassStatusID',
          headerName: 'Status',
          width: 75,
          type: 'string',
          align: 'center',
          renderCell: (params: GridCellParams) => (
            renderStatus(params)
          )
        },
        {
          field: 'fullName',
          headerName: 'Name',
          width: 175,
          type: 'string',
          align: 'left',
        },
        {
          field: 'requested',
          headerName: 'Requested',
          width: 150,
          type: 'string',
          align: 'left',
          renderCell: (params: GridCellParams) => (
            renderDateRequested(params)
          )
        }
      ];
    }

  }

  /*
  * Custom title for datagrid
  */
  function DataGridTitle() {
    return (
      <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="button">Transferable Pass Requests (100 max)</Typography>
      </Box>
    )
  }

  /*
 * Fetch lodge use transferable pass requests
 */
  const fetchPasses = async () => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // Always display passes today+
    const params = new URLSearchParams();
    params.append('startDate', dateToLocalDateFormatted(new Date(), 'yyyy-MM-dd'));

    if (!params.toString()) {
      return;
    }

    const url = new URL(`${API_URL}/v1/passes`);
    url.search = params.toString();

    await fetch(url.toString(),
      {
        method: 'GET',
        cache: 'no-cache',
        headers: headers,
        mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Lodge use passes not retrieved');
        }
        return response.json()
      })
      .then((e: Array<TransferablePass>) => {
        setPasses(e);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
 * Overlay displayed when no data
 */
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No transferable pass requests to display</div>
      </GridOverlay>
    );
  }

  /*
* Render date requested column
*/
  const renderDateRequested = (params: GridCellParams) => {
    return (
      params.value !== NULL_DATETIME_STRING ? dateStringToLocalDateFormatted(params.value, 'MM/dd/yyyy HH:mm:ss') : 'n/a'
    );
  }

  /*
* Render passes date requested column
*/
  const renderPassesDate = (params: GridCellParams) => {
    return (
      params.value !== NULL_DATETIME_STRING ? dateStringToLocalDateFormatted(params.value, 'MM/dd/yyyy') : 'n/a'
    );
  }

  /*
   * Render the cell for transferable pass status
   */
  const renderStatus = (params: GridCellParams) => {

    const id = params.value as number;

    switch (id) {
      case TransferablePassStatus.Requested:
        return (
          <Tooltip title={`Requested`} arrow>
            <LiveHelp fontSize="medium" sx={styles.requested} />
          </Tooltip>
        )
      case TransferablePassStatus.Confirmed:
        return (
          <Tooltip title={`Confirmed`} arrow>
            <CheckCircleIcon fontSize="medium" sx={styles.confirmed} />
          </Tooltip>
        )
      case TransferablePassStatus.Denied:
        return (
          <Tooltip title={`Denied`} arrow>
            <BlockIcon fontSize="medium" sx={styles.denied} />
          </Tooltip>
        )
      default:
        return (<span />)
    }

  }

  // #endregion

  // #region Event handlers

  React.useEffect(() => {
    fetchPasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  // #endregion

  return (
    <>
      <DataGridTitle />
      <DataGrid
        columns={columns()}
        components={{
          NoRowsOverlay: noRowsOverlay
        }}
        density="compact"
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        getRowId={(row) => row.transferablePassID}
        hideFooter={true}
        loading={isLoading}
        rows={passes}
      />
    </>
  );

}

export default TransferablePasses;