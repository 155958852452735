// #region imports

import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

import {
  WaiverStatus
} from '../interfaces';

// #endregion

// #region interfaces

interface WaiverStatusSelectProps {
  disabled: boolean;
  name: string;
  waiverStatusID: number;
  waiverStatus: Array<WaiverStatus>;
  waiverStatusSelected: (waiverStatusID: number) => void;
}

// #endregion

const WaiverStatusSelect: React.FC<WaiverStatusSelectProps> = (props) => {

  // #region Styles

  const styles = {
    selectWidth: {
      flexGrow: 1,
      minWidth: 200,
    },
  };

  // #endregion

  // #region Event handlers

  /*
   * Handles change in waiver status
   */
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    props.waiverStatusSelected(Number(event.target.value));

  };

  // #endregion

  return (
    <TextField
      sx={styles.selectWidth}
      name={props.name}
      label="Waiver Status"
      onChange={handleStatusChange}
      select
      value={props.waiverStatusID.toString()}
    >
      {props.waiverStatus.map((ws: WaiverStatus) => (
        <MenuItem key={ws.waiverStatusID} value={ws.waiverStatusID}>{ws.status}</MenuItem>
      ))}
    </TextField>
  );

}

export default WaiverStatusSelect;