
// #region Deployment

//export const API_URL = 'https://localhost:50061/api';
//export const API_URL = 'https://apimontclairskiclub-beta.azurewebsites.net/api';
export const API_URL = 'https://apimontclairskiclub.azurewebsites.net/api';

// #endregion

// #region Constants

export const AUTH0_MEMBERID_PROPERTY_NAME = 'https://montclairskiclub.com/memberID';
export const AUTH0_ROLES_PROPERTY_NAME = 'https://montclairskiclub.com/roles';
export const AUTH0_ROLE_BETATESTER = 'MSCBetaTester';
export const AUTH0_ROLE_EMAILADMIN = 'MSCEmailAdmin';
export const AUTH0_ROLE_MEMBERSHIPADMIN = 'MSCMembershipAdmin';
export const AUTH0_ROLE_PASSADMIN = 'MSCPassAdmin';
export const AUTH0_ROLE_SIGNUP_COORDINATOR = 'MSCSignupCoordinator';
export const AUTH0_ROLE_SIGNUPADMIN = 'MSCSignupAdmin';
export const AUTH0_ROLE_SYSTEMADMIN = 'MSCSystemAdmin';

export const EMPTY_STRING = '';
export const INVALID_DATE = 'Invalid Date';
export const KEYCODE_RETURN = 'ENTER';
export const MAX_DATE_STRING = '2100-01-01';
export const MIN_DATE_STRING = '1900-01-01';
export const NULL_DATE: Date = new Date('01/01/0001');
export const NULL_DATE_STRING = '01/01/0001';
export const NULL_DATETIME_STRING = '01/01/0001 00:00:00';
export const NULL_DATETIME_TIMEZONE_STRING = '01/01/0001 00:00:00 +00:00';
export const NULL_DATETIME_YEAR = 2001;
export const NULL_ID = -1;

export const COMPONENT_ADMIN = 'admin';
export const COMPONENT_HOME = 'home';
export const COMPONENT_SIGNUPS = 'signups';
export const COMPONENT_MEMBERSHIP = 'membership';
export const COMPONENT_RESOURCES = 'resources';

export const FEEDBACK_FILTER_MAX_INTERVAL = 30;
export const LODGEUSE_FILTER_MAX_INTERVAL = 30;

export const MATERIALUI_DIALOG_REASON_BACKDROPCLICK = 'backdropClick';
export const MATERIALUI_DIALOG_REASON_ESCKEYDOWN = 'escapeKeyDown';

export const MEMBERSTATUS_ACTIVE = 'active';
export const MEMBERSTATUS_LIFE = 'life';

export const RESOURCE_BREADCRUMB_ROOT_ID = '-1';

export const ROUTER_PATH_ADMIN = '/admin';
export const ROUTER_PATH_HOME = '/';
export const ROUTER_PATH_MEMBERSHIP = '/membership';
export const ROUTER_PATH_RESOURCES = '/resources';
export const ROUTER_PATH_SIGNUPS = '/signups';

export const REQUEST_FILTER_MAX_INTERVAL = 9;
export const SIGNUP_MAX_STAY_DAYS = 14;
export const SIGNUP_LIST_MAX_STAY_DAYS = 14;

export const STANDARD_TIME_ZONE = 'America/New_York';

export const RELEASES_KEY = 'https://montclairskiclub.com';

// #endregion

// #region Enums

export enum AppMode {
  Production = 1,
  Beta = 2
}

export enum DateFilterYear {
  FiscalYear = 1,
  RequestYear = 2
}

export enum FeedbackView {
  All = 0,
  BugReport = 1,
  Question = 2,
  FeatureRequest = 3,
  Other = 99
}

export enum GDFileType {
  Folder = 1,
  Excel = 2,
  GoogleDocument = 3,
  GoogleSheet = 4,
  PDF = 5,
  Word = 6,
  Unknown = 99
}

export enum HouseholdMode {
  Edit = 1,
  View = 2
}

export enum LodgeUseAction {
  Pending = 1,
  Confirm = 2,
  Waitlist = 3,
  Denied = 4,
  Cancel = 5,
  CancelLate = 6,
  SendEmail = 7,
  AssignBedroom = 8
}

export enum LodgeUseView {
  HouseholdRequests = 1,
  GuestRequests = 2,
  PostDeadlineRequests = 3,
  Confirmed = 4,
  Waitlist = 5,
  Denied = 6,
  Cancelled = 7,
  CancelledLate = 8,
  //EmailQueue = 9,
  Bedrooms = 10,
  Pending = 11
}

export enum MembersFetchActionType {
  None = 0,
  Filter = 1,
  Search = 2
}

export enum MemberStatusFilterMode {
  Filter = 1,
  Search = 2
}

export enum MemberStatusType {
  Active = 1,
  Inactive = 2,
  InactiveCouple = 3,
  Life = 4,
  Junior = 5,
  ChildofActive = 6,
  Honorary = 7,
  Applicant = 8,
  Associate = 9,
  Deceased = 10,
  NonMember = 11,
  JuniorApplicant = 12,
  PastMember = 13,
  ChildOfInactive = 14,
  InactiveJunior = 15
}

export enum PassesView {
  All = 1,
  Requested = 2,
  Confirmed = 3,
  Denied = 4,
  EmailQueue = 5
}

export enum PhoneTypeID {
  None = -1,
  Mobile = 1,
  Work = 2,
  Home = 3
}

export enum RequestMode {
  OptionSelection = 1,
  MissingRequirements = 2,
  WeekendHoliday = 3,
  NonWeekendHoliday = 4,
  PostDeadline = 5,
  OffSeason = 6
}

export enum SignupCalendarView {
  All = 1,
  Household = 2
}

export enum SignupStatusID {
  Pending = 1,
  Confirmed = 2,
  Waitlist = 3,
  Denied = 4,
  Cancelled = 5,
  CancelledLate = 6,
  Mixed = 7
}

export enum TransferablePassAction {
  Pending = 1,
  Confirm = 2,
  Denied = 3
}

export enum TransferablePassStatus {
  Requested = 1,
  Confirmed = 2,
  Denied = 3
}

// #endregion

// #region Classes

export class SignupExceptionEnum {
  static readonly NewRequestDatesOverlap = 101;
  static readonly MemberSignupInsertDatesOverlap = 102;
  static readonly MemberSignupUpdateDatesOverlap = 103;
  static readonly LodgeNightsDatesOverlap = 104;
}

// #endregion

// #region Methods

/*
 * Format bytes to KB, MB string
 */
export const formatBytes = (bytes: number, decimals = 2) => {

  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/*
 * Formats gender and age
 */
export const formatGenderAge = (gender: string, age: string): string => {
  let ga = gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : 'Non-Binary';
  if (age) {
    ga += ` Age: ${age}`
  }
  return ga;
}

// #endregion

// #region HTTP/fetch

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T>(
  request: RequestInfo
): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(
    request
  );

  try {
    // may error if there is no body
    response.parsedBody = await response.json();
  } catch (ex) { }

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export async function get<T>(
  path: string,
  args: RequestInit = { method: "get" }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
};

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "post", body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
};

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "put", body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
};

// #endregion
