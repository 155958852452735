// #region imports

import React from 'react';

import {
  isValid as isDateValid
} from 'date-fns';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders,
  TransferablePassSignup
} from '../../interfaces';

import {
  Signup,
  SignupEdit
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  NULL_DATE,
  NULL_ID,
} from '../../global';

// Components
import GuestSignupsList from './GuestSignupsList';
import MemberSignupsList from './MemberSignupsList';
import MyLodgeUseRequestEditHelpDialog from './MyLodgeUseRequestEditHelpDialog';
import NotSignedupList from './NotSignedUpList';
import SignupsCancelledList from './SignupsCancelledList';
import SignupCancelDialog from './SignupCancelDialog';
import TransferablePasses from '../TransferablePasses';

// Redux
import { useDispatch, useSelector } from "react-redux";

import {
  setSignupCancelled,
} from "../../../store/signups/actions";

// Auth0 user hooks
import { useAuth0User } from '../../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';
import Passes from '../../admin/passes/Passes';

// #endregion

// #region Interfaces

interface RequestEditProps {
  closeDrawer: () => void;
  signupID: number;
}

// #endregion

const RequestEdit: React.FC<RequestEditProps> = (props: RequestEditProps) => {

  const { getAccessToken, memberID } = useAuth0User();
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useResponsive();

  const [addGuest, setAddGuest] = React.useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState(false);
  const [minArrivalDate, setMinArrivalDate] = React.useState<Date>(NULL_DATE);
  const [needsSave, setNeedsSave] = React.useState<boolean>(false);
  const [offSeason, setOffSeason] = React.useState<boolean>(false);
  const [signup, setSignup] = React.useState<Signup>(new Signup());
  const [transferablePasses, setTransferablePasses] = React.useState<Array<TransferablePassSignup>>([]);

  // Store changed flag and signup ID for unmount
  const passesChanged = React.useRef<boolean>(false);
  const signupChanged = React.useRef<boolean>(false);
  const signupID = React.useRef<number>(NULL_ID);

  // Redux
  const dispatch = useDispatch();
  const guestCancelled: boolean = useSelector((state: any) => state.signups.guestCancelled);
  const guestSaved: boolean = useSelector((state: any) => state.signups.guestSaved);
  const memberCancelled: boolean = useSelector((state: any) => state.signups.memberCancelled);
  const memberSaved: boolean = useSelector((state: any) => state.signups.memberSaved);
  const notSignedUpAdded: boolean = useSelector((state: any) => state.signups.notSignedUpAdded);
  const offSeasonStartDate: Date = useSelector((state: any) => state.signups.offSeasonStartDate);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(2, 2, 2, 2),
      margin: 'auto',
    },
    actionsContainer: {
      margin: theme.spacing(1, 0, 1, 0),
    },
    cancelAction: {
      backgroundColor: red[200],
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: red[500]
      }
    },
    notesContainer: {
      margin: theme.spacing(2, 0, 1, 0),
    },
    passesContainer: {
      margin: theme.spacing(1, 0, 1, 0),
    },
    saveButton: {
      width: 200
    },
    textArea: {
      width: '100%',
    },
  };

  // #endregion

  // #region Methods

  /*
 * Cancel entire signup
 */
  const cancelSignup = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const id = memberID();
    const url = new URL(`${API_URL}/v1/signups/${props.signupID}/cancel/${id}`);

    await fetch(url.toString(),
      {
        body: JSON.stringify(signup)
        , headers: headers
        , method: 'DELETE'
      }
    )
      .then((response) => {
        if (response.ok) {
          // Set tracking flag
          dispatch(setSignupCancelled(true));
          enqueueSnackbar(`Lodge Use request has been cancelled and email sent`, { variant: 'success' });
        } else {
          throw new Error('Lodge use request not cancelled');
        }
      })

    // Refresh MySignus
  }

  /*
   * Check if min arrival date is offseason?
   */
  const checkArrivalOffSeason = (arrival: Date) => {
    // When min arrival date changes check if arrival date is off season
    if (isDateValid(arrival) && (offSeasonStartDate !== undefined)) {
      // Set offseason flag in local state
      const offSeasonDate = new Date(offSeasonStartDate)
      const isOffSeason = (new Date(arrival) >= offSeasonDate);
      setOffSeason(isOffSeason);

      // If now off season and passes exist, remove them
      if (isOffSeason && transferablePasses.length > 0) {
        setTransferablePasses([]);
      }
    }
  }

  /*
   * Fetch signup data for editing
   */
  const fetchSignup = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/${props.signupID}`);

    await fetch(url.toString(),
      {
        method: 'GET'
        , cache: 'no-cache'
        , headers: headers
        , mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Signup not retrieved');
        }
        return response.json()
      })
      .then((signup: Signup) => {
        signupID.current = signup.signupID;
        setSignup(signup);
        setMinArrivalDate(new Date(signup.minArrivalDate)); // Used for off season check.
        setTransferablePasses(signup.transferablePasses); // Tracks changes to passes
      })
      .catch(err => setErrors(err));
  }

  /*
   * Save signup changes
   */
  const saveSignup = async () => {

    const signupEdit = new SignupEdit(
      signup.signupID,
      signup.notes,
      transferablePasses,
      passesChanged.current,
      memberID() // userID
    );

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/update`);

    await fetch(url.toString(),
      {
        body: JSON.stringify(signupEdit),
        headers: headers,
        method: 'POST'
      }
    )
      .then((response) => {
        if (response.ok) {
          // Reload changes
          fetchSignup();
          // Reset notes/passes changed flag
          setNeedsSave(false);
          enqueueSnackbar(`Lodge use request transferable passes and notes changes saved.`, { variant: 'success' });
        } else {
          enqueueSnackbar('Lodge use request note and transferable passes did not save. Please report using the Feedback form.', { variant: 'error' });
        }
      })

  }

  /*
 * Send email for changed signup
 */
  const sendSignupEmail = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/email/signups/updated/${signupID.current}`);

    await fetch(url.toString(), {
      method: 'GET'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar('Lodge use request changes email was not sent. Please report using the Feedback form.', { variant: 'error' });
        }
      })
      .catch(error => {
        enqueueSnackbar('Lodge use request changes email was not sent. Please report using the Feedback form.', { variant: 'error' });
      })
  }

  // #endregion

  // #region Event handlers

  /*
   * Handle change in member arrival date
   */
  const handleArrivalDateChanged = (arrival: Date) => {
    // Check if new arrival date is offseason
    checkArrivalOffSeason(arrival);
  }

  /*
   * Event handler for signup cancellation dialog - cancel signup
   */
  const handleCancel = () => {
    cancelSignup();
    setCancelDialogOpen(false);
  };

  /*
   * Event handler for signup cancellation dialog - abandon cancellation
   */
  const handleCancelAbandon = () => {
    setCancelDialogOpen(false);
  };

  /*
   * Event handler for cancel dialog closing
   */
  const handleCancelDialog = () => {
    // Reset tracking flag
    dispatch(setSignupCancelled(false));
    setCancelDialogOpen(true);
  };

  /*
   * Handles has changed flag for sending email
   */
  const handleHasChanged = () => {
    // Flag that data has changed
    if (!signupChanged.current) {
      signupChanged.current = true;
    }
  }

  /*
 * Handler for help dialog window
 */
  const handleHelpClose = () => {
    setHelpOpen(false);
  }

  /*
 * Handler for change in note text
 */
  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSignup(
      {
        ...signup,
        notes: event.target.value
      }
    );

    // Flag that data has changed, send email
    if (!signupChanged.current) {
      signupChanged.current = true;
    }
    // Note and passes need save
    if (!needsSave) {
      setNeedsSave(true);
    }
  }

  /*
* Handles change in transferrable passes
*/
  const handlePassesChange = (passes: Array<TransferablePassSignup>) => {

    // Assign housdhold if not present
    passes.forEach(p => p.householdID === NULL_ID ? p.householdID = signup.householdID : p.householdID);

    // Replace changed passes
    setTransferablePasses(passes);

    // Flag that data has changed
    if (!passesChanged.current) {
      passesChanged.current = true;
    }
    // Note and passes need save
    if (!needsSave) {
      setNeedsSave(true);
    }

  };

  React.useEffect(() => {
    if (guestCancelled) {
      fetchSignup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestCancelled]);

  React.useEffect(() => {
    if (guestSaved) {
      fetchSignup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestSaved]);

  React.useEffect(() => {
    if (memberCancelled) {
      fetchSignup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCancelled]);

  React.useEffect(() => {
    if (memberSaved) {
      fetchSignup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberSaved]);

  React.useEffect(() => {
    if (notSignedUpAdded) {
      fetchSignup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notSignedUpAdded]);

  React.useEffect(() => {

    // Another request selected for editing
    if (props.signupID !== NULL_ID) {
      // Previous edit changed
      if (signupChanged.current) {
        sendSignupEmail();
        // Reset changed flag
        signupChanged.current = false;
        setNeedsSave(false);
      }

      fetchSignup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.signupID]);

  React.useEffect(() => {
    // Check if min arrival is offseason
    checkArrivalOffSeason(minArrivalDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minArrivalDate]);

  React.useEffect(() => {

    // Fired on component unmount.
    return () => {
      if (signupChanged.current) {
        sendSignupEmail();
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return <>
    <Box sx={styles.root}>
      <Grid container item alignItems="center" spacing={1}>
        {isMobile &&
          <Grid item>
            <IconButton onClick={props.closeDrawer} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        }
        <Grid item>
          <Typography variant="h6">{`Future Request #${signup.referenceNumber}`}</Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Request edit help">
            <IconButton onClick={() => setHelpOpen(true)} size="large">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item >
          <Tooltip title="Add a guest to the request" arrow>
            <Button size="small" color="primary" variant="outlined" onClick={() => setAddGuest(true)}>Add Guest</Button>
          </Tooltip>
        </Grid>
        <Grid item >
          <Tooltip title="Cancel the entire request" arrow>
            <Button size="small" variant="contained" sx={styles.cancelAction} onClick={handleCancelDialog}>Cancel Request</Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container item>
        <Typography variant="body1" gutterBottom>{`${signup.minArrivalDate} to ${signup.maxDepartureDate} Nights: ${signup.nights} ${signup.priorityRequest ? '(Priority Request)' : EMPTY_STRING}`}</Typography>
      </Grid>
      <Divider />
      {!offSeason &&
        <Grid container item xs={12} md={8} sx={styles.passesContainer}>
          <Typography variant="subtitle2" gutterBottom>Transferable Pass Requests</Typography>
          <TransferablePasses
            handlePassesChange={handlePassesChange}
            minArrivalDate={signup.minArrivalDate}
            maxDepartureDate={signup.maxDepartureDate}
            passes={transferablePasses}
          />
        </Grid>
      }
      <Grid container item xs={12} md={8} sx={styles.notesContainer}>
        <Typography variant="subtitle2" gutterBottom>Notes</Typography>
        <TextareaAutosize
          style={styles.textArea}
          maxRows={10}
          minRows={5}
          onChange={handleNotesChange}
          placeholder="Enter additional lodge use request info such as No Dinner, Need Ride, etc. "
          value={signup.notes}
        />
      </Grid>
      <Grid container item xs={12} md={8} justifyContent="center" spacing={2} sx={styles.actionsContainer}>
        <Grid item>
          <Tooltip title="Save signup transferable passes and notes" arrow>
            <span>
              <Button size="medium" color="primary" variant="contained" disabled={!needsSave} sx={styles.saveButton} onClick={saveSignup}>Save</Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <form noValidate autoComplete="off">
        <MemberSignupsList
          arrivalDateChanged={handleArrivalDateChanged}
          hasChanged={handleHasChanged}
          signupID={signup.signupID}
        />
        <GuestSignupsList
          addGuest={addGuest}
          hasChanged={handleHasChanged}
          setAddGuest={setAddGuest}
          signupID={signup.signupID}
        />
        <NotSignedupList
          hasChanged={handleHasChanged}
          signupID={signup.signupID}
        />
        <SignupsCancelledList
          signupID={signup.signupID}
        />
      </form>
    </Box >
    <MyLodgeUseRequestEditHelpDialog
      show={helpOpen}
      handleClose={handleHelpClose} />
    <SignupCancelDialog
      show={cancelDialogOpen}
      handleAbandon={handleCancelAbandon}
      handleCancel={handleCancel} />
  </ >;

}

export default RequestEdit;