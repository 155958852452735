import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  TransferablePassStatusCount
} from '../../models';

// #region interfaces

interface SummaryProps {
  statusTime: string;
  statusCounts: Array<TransferablePassStatusCount>;
}

// #endregion

const Summary: React.FC<SummaryProps> = (props) => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(0, 2, 0, 2),
    },
  };

  // #endregion

  // #region Methods
  // #endregion

  // #region Event handlers
  // #endregion

  return (
    <Box sx={styles.root}>
      {props.statusTime &&
        <>
          <Grid container item spacing={1}>
            <Grid item>
            <Typography variant="button">{`Status: ${props.statusTime}`}</Typography>
            </Grid >
          </Grid >
          <Grid container item spacing={1}>
            <Grid item xs={4}>
              <Grid container item>
                {props.statusCounts.map(c => (
                  <Grid container item key={c.transferablePassStatusID}>
                    <Grid item xs={4}>
                      <Typography variant="caption">{c.status}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="caption">{c.statusCount}</Typography>
                    </Grid>
                  </Grid>
                )
                )}
              </Grid >
            </Grid >
          </Grid >
        </>
      }
      {!props.statusTime &&
        <Grid container item spacing={1}>
          <Typography variant="body1">Select dates to view summary</Typography>
        </Grid >
      }
    </Box >
  );

}

export default Summary;