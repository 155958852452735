// #region imports
import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

// Styles
import { useTheme } from '@mui/material/styles';

import {
  Bedroom
} from '../../interfaces';

// #endregion

// #region interfaces

interface BedroomSelectProps {
  bedroomID: number;
  bedrooms: Array<Bedroom>;
  bedroomSelected: (bedroomID: number) => void;
  disabled: boolean;
}

// #endregion

const BedroomSelect: React.FC<BedroomSelectProps> = (props) => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    selectWidth: {
      minWidth: 200
    },
  };

  // #endregion

  // #region Event handlers

  /*
   * Handles change in bedroom
   */
  const handleBedroomChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    props.bedroomSelected(Number(event.target.value));

  };

  // #endregion

  return (
    <TextField
      sx={styles.selectWidth}
      label="Bedroom"
      onChange={handleBedroomChange}
      select
      value={props.bedroomID.toString()}
    >
      {props.bedrooms.map((bd: Bedroom) => (
        <MenuItem key={bd.bedroomID} value={bd.bedroomID}>{bd.bedroomName}</MenuItem>
      ))}
    </TextField>
  );

}

export default BedroomSelect;