// #region Imports

import React from 'react';

// Material-UI
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import PhoneTypeIcon from './PhoneTypeIcon';
import Typography from '@mui/material/Typography';

import { Member } from '../interfaces';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// #endregion

// #region Interfaces

interface MemberListContactInfoProps {
  member: Member;
}

// #endregion

const MemberListContactInfo: React.FC<MemberListContactInfoProps> = (props) => {

  const { isSystemAdmin } = useAuth0User();

  return (
    <>
      <Grid container item>
        {!isSystemAdmin() &&
          <Typography variant="button">{`${props.member.firstName} ${props.member.lastName}`}</Typography>
        }
        {isSystemAdmin() &&
          <Typography variant="button">{`${props.member.firstName} ${props.member.lastName} (MID: ${props.member.memberID}/HID: ${props.member.household.householdID})`}</Typography>
        }
      </Grid>
      {props.member.email &&
        <Grid container item>
          <Typography variant="body1">{props.member.email}</Typography>
        </Grid>
      }
      {props.member.primaryPhone &&
        <Grid container item>
          <Link href={'tel:' + props.member.primaryPhone} variant="body1" underline="none">{props.member.primaryPhone}</Link><span>&nbsp;</span><PhoneTypeIcon phoneType={props.member.primaryPhoneType} />
        </Grid>
      }
      {props.member.secondaryPhone &&
        <Grid container item>
          <Link href={'tel:' + props.member.secondaryPhone} variant="body1" underline="none">{props.member.secondaryPhone}</Link><span>&nbsp;</span><PhoneTypeIcon phoneType={props.member.secondaryPhoneType} />
        </Grid>
      }
    </ >
  );
}

export default MemberListContactInfo;
