// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridSelectionModel
} from '@mui/x-data-grid';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders,
  BedroomPerson
} from '../../interfaces';

import {
  DateRange
} from '../../models';

import {
  API_URL,
  NULL_DATETIME_STRING
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface UnassignedPeopleProps {
  clear: boolean;
  dateRange: DateRange;
  refresh: boolean;
}

// #endregion

const UnassignedPeople: React.FC<UnassignedPeopleProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();

  const [errors, setErrors] = React.useState(false);
  const [people, setPeople] = React.useState<Array<BedroomPerson>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(1,0,0,0),
      height: 500
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'fullName',
        headerName: 'Name',
        width: 175,
        type: 'string',
        align: 'left',
      },
      {
        field: 'gender',
        headerName: 'Gender',
        width: 75,
        type: 'string',
        align: 'left',
      },
      {
        field: 'arrivalDate',
        headerName: 'Arrival',
        width: 100,
        type: 'string',
        align: 'left',
        renderCell: (params: GridCellParams) => (
          renderDate(params)
        )
      },
      {
        field: 'departureDate',
        headerName: 'Departure',
        width: 100,
        type: 'string',
        align: 'left',
        renderCell: (params: GridCellParams) => (
          renderDate(params)
        )
      },
    ];

  }

  /*
* Fetch people for bedroom assignments based upon date range
*/
  const fetchPeople = async () => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const params = new URLSearchParams();
    if (props.dateRange !== undefined) {
      if (props.dateRange.startDate) {
        params.append('StartDate', props.dateRange.startDate);
      }
      if (props.dateRange.endDate) {
        params.append('EndDate', props.dateRange.endDate);
      }
    }

    if (!params.toString()) {
      return;
    }

    const url = new URL(`${API_URL}/v1/lodgeuse/bedrooms/people`);
    url.search = params.toString();

    await fetch(url.toString(),
      {
        method: 'GET',
        cache: 'no-cache',
        headers: headers,
        mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Lodge use passes not retrieved');
        }
        return response.json()
      })
      .then((p: Array<BedroomPerson>) => {
        setPeople(p);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
* Overlay displayed when no data
*/
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No people for assignment</div>
      </GridOverlay>
    );
  }

  /*
* Render arrival/departure column
*/
  const renderDate = (params: GridCellParams) => {
    return (
      params.value !== NULL_DATETIME_STRING ? dateStringToLocalDateFormatted(params.value, 'MM/dd/yyyy') : 'n/a'
    );
  }

  // #endregion

  // #region Event handlers

  /*
 * Handle row selection
 */
  const handleSelection = (ids: Array<number>) => {
    //setSelected(s);
  }

  React.useEffect(() => {
    setPeople([]);
  }, [props.clear]);

  React.useEffect(() => {
    fetchPeople();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  React.useEffect(() => {
    // Refresh passes list
    fetchPeople();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return (
    <Box sx={styles.root}>
      <DataGrid
        checkboxSelection={true}
        columns={columns()}
        components={{
          NoRowsOverlay: noRowsOverlay
        }}
        density="compact"
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        getRowId={(row) => row.memberSignupID !== 0 ? row.memberSignupID : row.guestSignupID}
        hideFooter={true}
        loading={isLoading}
        rows={people}
        onSelectionModelChange={(m: GridSelectionModel) => handleSelection(m as Array<number>)}
      />
    </Box >
  )

}

export default UnassignedPeople;