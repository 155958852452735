// #region Imports

import React from 'react';
import { isValid as isDateValid } from 'date-fns';

// Material-UI
import AppBar from '@mui/material/AppBar';
import ApprovalIcon from '@mui/icons-material/Approval';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  DateRange,
  HTTPHeaders,
  LodgeUseCancellation
} from '../../interfaces';

import {
  API_URL,
  EMPTY_STRING,
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface GuestsProps {
  clear: boolean;
  dateRange: DateRange
  refresh: boolean;
}

// #endregion

const Cancellations: React.FC<GuestsProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { dateFormatted, dateStringToLocalDateFormatted, dateToLocalDateFormatted } = useDateFormatting();

  const [cancellations, setCancellations] = React.useState<Array<LodgeUseCancellation>>([]);
  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      height: '100%',
      padding: theme.spacing(2),
    },
    appBar: {
      margin: theme.spacing(0, 0, 1, 0)
    },
    emailQueueStatus: {
      flexGrow: 1,
      textAlign: 'right'
    },
    gridContainer: {
      flexGrow: 1,
      height: '100%'
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    toolbarItem: {
      borderRight: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'referenceNumber',
        headerName: '#',
        width: 85,
        type: 'string',
        align: 'left',
      },
      {
        field: 'fullName',
        headerName: 'Name',
        width: 200,
        type: 'string',
        align: 'left',
      },
      {
        field: 'minArrivalDate',
        headerName: 'Arrival',
        width: 100,
        type: 'string',
        align: 'left',
        valueGetter: renderArrivalDate
      },
      {
        field: 'maxDepartureDate',
        headerName: 'Departure',
        width: 100,
        type: 'number',
        align: 'left',
        valueGetter: renderDepartureDate
      },
      {
        field: 'cancelDate',
        headerName: 'Cancelled',
        width: 175,
        type: 'string',
        align: 'left',
        valueGetter: renderCancelDate
      }
    ];

  }

  /*
* Fetch lodge use request cancellations
*/
  const fetchCancellations = async () => {

    if (!isDateValid(new Date(props.dateRange.startDate)) || !isDateValid(new Date(props.dateRange.endDate))) {
      return;
    }

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // Always display passes today+
    const params = new URLSearchParams();
    params.append('endDate', dateToLocalDateFormatted(new Date(props.dateRange.endDate), 'yyyy-MM-dd'));
    params.append('startDate', dateToLocalDateFormatted(new Date(props.dateRange.startDate), 'yyyy-MM-dd'));

    if (!params.toString()) {
      return;
    }

    const url = new URL(`${API_URL}/v1/lodgeuse/cancellations`);
    url.search = params.toString();

    await fetch(url.toString(),
      {
        method: 'GET',
        cache: 'no-cache',
        headers: headers,
        mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Lodge use cancellations not retrieved');
        }
        return response.json()
      })
      .then((c: Array<LodgeUseCancellation>) => {
        setCancellations(c);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
* Overlay displayed when no data
*/
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No cancelled requests to display</div>
      </GridOverlay>
    );
  }

  /*
   * Render arrival date
   */
  const renderArrivalDate = (params: GridValueGetterParams) => {
    return `${dateFormatted(new Date(params.row.minArrivalDate))}`;
  }

  /*
   * Render arrival date
   */
  const renderCancelDate = (params: GridValueGetterParams) => {
    return `${dateStringToLocalDateFormatted(params.row.cancelDate, 'MM/dd/yyyy HH:mm:ss')}`;
  }

  /*
   * Render arrival date
   */
  const renderDepartureDate = (params: GridValueGetterParams) => {
    return `${dateFormatted(new Date(params.row.maxDepartureDate))}`;
  }

  // #endregion

  // #region Event handlers

  React.useEffect(() => {
    setCancellations([]);
  }, [props.clear]);

  React.useEffect(() => {
    fetchCancellations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange.startDate, props.dateRange.endDate]);

  React.useEffect(() => {
    fetchCancellations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  // #endregion

  return (
    <div style={styles.root}>
      <Box sx={styles.gridContainer}>
        <DataGrid
          columns={columns()}
          components={{
            NoRowsOverlay: noRowsOverlay
          }}
          density="compact"
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          getRowId={(row) => row.signupID}
          hideFooter={true}
          loading={isLoading}
          rows={cancellations}
        />
      </Box>
    </div >);

}

export default Cancellations;