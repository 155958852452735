import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import EmailForm from './EmailForm';
import Filter from './Filter';
import Summary from './Summary';
import TransferablePasses from './TransferablePasses';

import {
  HTTPHeaders,
  TransferablePassStatusCount
} from "../../interfaces";

import {
  DateRange
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  PassesView
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

const Passes: React.FC = () => {

  const { getAccessToken } = useAuth0User();
  const { isMobile } = useResponsive();
  const { timeStamp } = useDateFormatting();
  const { enqueueSnackbar } = useSnackbar();

  const [clear, setClear] = React.useState<boolean>(false);
  const [dateRange, setDateRange] = React.useState<DateRange>(new DateRange());
  const [disableClear, setDisableClear] = React.useState<boolean>(true);
  const [disableRefresh, setDisableRefresh] = React.useState<boolean>(true);
  const [emailIDs, setEmailIDs] = React.useState<Array<number>>([]);
  const [emailDrawerVisible, setEmailDrawerVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState(false);
  const [passStatusCounts, setPassStatusCounts] = React.useState<Array<TransferablePassStatusCount>>([]);
  const [refreshPasses, setRefreshPasses] = React.useState<boolean>(true);
  const [statusTime, setStatusTime] = React.useState<string>(EMPTY_STRING);
  const [view, setView] = React.useState<PassesView>(PassesView.All);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      height: '100%',
    },
    contentContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      flexGrow: 1,
      height: 500
    },
    emailDrawer: {
      flex: 1,
      padding: theme.spacing(2)
    },
    filterContainer: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    toolsContainer: {
      flexGrow: 1,
      padding: theme.spacing(1, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
 * Fetch status counts
 */
  const fetchStatusCounts = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const params = new URLSearchParams();
    params.append('startDate', dateRange.startDate);
    params.append('endDate', dateRange.endDate);

    const url = new URL(`${API_URL}/v1/passes/statuscounts`);
    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Transferable pass status counts not retrieved');
        }
        return response.json()
      })
      .then((sc: Array<TransferablePassStatusCount>) => {
        setPassStatusCounts(sc);
        setStatusTime(timeStamp());
      })
      .catch(err => {
        setErrors(err);
      });
  }

  // #endregion

  // #region Event handlers

  /*
  * Handle clear of components
  */
  const handleClear = () => {
    setView(PassesView.All);
    setDateRange(new DateRange());
    setStatusTime(EMPTY_STRING);

    setClear(clear => !clear);
  }

  /*
* Handle closing of email drawer
*/
  const handleEmailDrawerClose = () => {
    setEmailDrawerVisible(false);
  };

  /*
   * Handle changes to filter
   */
  const handleFilterChanged = (range: DateRange) => {
    setDateRange(range);
  }

  /*
  * Handle actions when pass count changes
  */
  const handlePassesCount = (passes: number) => {
    if (passes > 0) {
      setDisableClear(false);
      setDisableRefresh(false);
    } else {
      setDisableClear(true);
      setDisableRefresh(true);
    }
  }

  /*
   * Handler refresh of passes list
   */
  const handleRefresh = () => {
    if (dateRange.startDate && dateRange.endDate) {
      setRefreshPasses(refreshPasses => !refreshPasses);
      fetchStatusCounts();
    }
  }

  /*
  * Set view of data (All, requested, etc.)
  */
  const handleViewChanged = (newView: PassesView) => {

    if (view !== newView) {
      setView(newView);
    }

  }

  React.useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      setRefreshPasses(refreshPasses => !refreshPasses);
      fetchStatusCounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.startDate, dateRange.endDate]);

  // #endregion

  return (
    <>
      <Box sx={styles.root}>
        <Grid container spacing={0} sx={styles.toolsContainer}>
          <Grid item xs={6} sx={styles.filterContainer}>
            <Filter
              clear={clear}
              disableClear={disableClear}
              disableFilter={false}
              disableRefresh={disableRefresh}
              dateRange={dateRange}
              filterChanged={handleFilterChanged}
              handleClear={handleClear}
              handleRefresh={handleRefresh}
              handleView={handleViewChanged}
            />
          </Grid>
          <Grid item xs={6}>
            <Summary
              statusTime={statusTime}
              statusCounts={passStatusCounts}
            />
          </Grid>
        </Grid>
        <Grid container sx={styles.contentContainer}>
          {((view === PassesView.All) || (view === PassesView.Requested) || (view === PassesView.Confirmed) || (view === PassesView.Denied)) &&
            <TransferablePasses
              dateRange={dateRange}
              handlePassesCount={handlePassesCount}
              refresh={refreshPasses}
              view={view}
            />
          }
        </Grid>
      </Box>
      <Drawer
        variant="temporary"
        anchor={isMobile ? 'bottom' : 'right'}
        open={emailDrawerVisible}
        onClose={handleEmailDrawerClose}
      >
        <Box sx={styles.emailDrawer}>
          <EmailForm
            ids={emailIDs}
          />
        </Box>
      </Drawer>
    </>
  );

}

export default Passes;