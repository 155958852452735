// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// #endregion

// #region interfaces

interface EmailFormHelpDialogProps {
  show: boolean;
  handleClose: () => void;
}

// #endregion

const EmailFormHelpDialog: React.FC<EmailFormHelpDialogProps> = (props) => {

  // #region Styles
  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
    >
      <DialogTitle>Lodge Use Emails</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>{`Lodge use emails are for sending custom emails to one or more members. The email is sent to the selected member(s) in the lodge use list as indicated at the top of the form.`}</Typography>
        <Typography variant="body1" paragraph>{`Note: Guests are not included in lodge use emails because their contact information is not collected. Non-members that are part of a household will receive emails if they have an email address.`}</Typography>
        <Typography variant="button" paragraph>Sending an Email</Typography>
        <Typography variant="body1" paragraph>{`All lodge use emails are sent from the signups@ and any replies will be to that email address.`}</Typography>
        <Typography variant="button" paragraph>Attachments</Typography>
        <Typography variant="body1" paragraph>{`One or more attachments up to 10 MB each can be included in an email using the Add Attachment button to attach a file. Attachments need to be added individually, and are currently limited to PDF files.`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default EmailFormHelpDialog;