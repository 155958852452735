import React from 'react';

import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { amber, blue, blueGrey, green, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  RequestCalendarSummary,
  SignupNote
} from '../../models';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #region interfaces

interface CalendarSummaryProps {
  statusTime: string;
  summary: RequestCalendarSummary;
}

// #endregion

const CalendarSummary: React.FC<CalendarSummaryProps> = (props) => {

  const { dateStringToLocalDateFormatted } = useDateFormatting();

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(0, 2, 0, 2),
    },
    buttonContainer: {
      margin: theme.spacing(0, 0, 1, 0),
    },
    confirmed: {
      color: green[500]
    },
    pending: {
      color: blueGrey[500]
    },
    waitlist: {
      color: amber[500]
    },
    deined: {
      color: red[500]
    },
    cancelled: {
      color: blue[500]
    },
    cancelledlate: {
      color: red[500]
    },
    notesContainer: {
      border: `1px solid ${theme.palette.divider}`,
      flexGrow: 1,
      height: 100,
      width: '100%',
      padding: theme.spacing(1, 1, 1, 1)
    },
    note: {
      flexGrow: 1,
      padding: theme.spacing(0, 0, 0, 0)
    },
    notesDivider: {
      margin: theme.spacing(0, 0, 1, 0)
    },
  };

  // #endregion

  // #region Event handles
  // #endregion

  return (
    <Box sx={styles.root}>
      {props.statusTime &&
        <Grid container item spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="button">{`Status: ${props.statusTime}`}</Typography>
          </Grid >
          {(props.summary.deadline && props.summary.deadline.deadline) &&
            <Grid item>
              <Typography variant="button">{`Deadline: ${dateStringToLocalDateFormatted(props.summary.deadline.deadline)}`}</Typography>
            </Grid >
          }
        </Grid >
      }
      {!props.statusTime &&
        <Grid container item spacing={1}>
          <Typography variant="button">Awaiting date selection</Typography>
        </Grid >
      }
      <Grid container spacing={1} item>
        <Grid item xs={3}>
          <Grid container item spacing={1}>
            <Grid item>
              <InfoIcon fontSize="medium" sx={styles.pending} />
            </Grid>
            <Grid item>
              <Typography variant="overline">Pending</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <CheckCircleIcon fontSize="medium" sx={styles.confirmed} />
            </Grid>
            <Grid item>
              <Typography variant="overline">Confirmed</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <PanToolIcon fontSize="medium" sx={styles.waitlist} />
            </Grid>
            <Grid item>
              <Typography variant="overline">Waitlisted</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <BlockIcon fontSize="medium" sx={styles.deined} />
            </Grid>
            <Grid item>
              <Typography variant="overline">Denied</Typography>
            </Grid>
          </Grid>
        </Grid >
        <Grid item xs={9}>
          {props.summary.notes.length > 0 &&
            <Box overflow="auto" sx={styles.notesContainer}>
              {props.summary.notes.map((n: SignupNote) => (
                <Box key={n.signupID} sx={styles.note}>
                  <Grid container item>
                    <Typography variant="caption">{`${n.createdBy} - ${dateStringToLocalDateFormatted(n.updated)}`}</Typography>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item>
                      <Typography variant="caption">{`Arrive: ${n.minArrivalDate}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">{`Depart: ${n.maxDepartureDate}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">{`Nights: ${n.nights}`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Typography variant="caption" style={{ overflowWrap: 'break-word' }}>{n.notes}</Typography>
                  </Grid>
                  <Divider sx={styles.notesDivider} />
                </Box>
              ))}
            </Box>
          }
        </Grid>
      </Grid>
    </Box >
  );

}

export default CalendarSummary;