// #region Imports

import React from 'react';
import { formatDistanceToNow } from 'date-fns';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { EMPTY_STRING } from '../global';

// Styles
import { useTheme } from '@mui/material/styles';

// UTC to local date formatting
import { useDateFormatting } from '../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface SignupsOfflineProps {
  signupsStartTime: string;
}

// #endregion

const SignupsOffline: React.FC<SignupsOfflineProps> = (props: SignupsOfflineProps) => {

  const { dateStringToLocalDateFormatted } = useDateFormatting();

  const [startTime, setStartTime] = React.useState<string>(EMPTY_STRING);

  const starts = React.useRef<Date>();
  const interval = React.useRef<number>();

  // #region Styles

  const theme = useTheme();
  const styles = {
      root: {
        flexGrow: 1,
        margin: theme.spacing(5, 0, 0, 0),
      },
      message: {
        margin: theme.spacing(4, 0, 0, 0)
      },
    };

  // #endregion

  // #region Methods

  /*
   * Time until signups start
   */
  const timeUntilStart = () => {
    if (starts.current) {
      setStartTime(formatDistanceToNow(starts.current));
    }
  }

  // #endregion

  // #region Event handlers

  React.useEffect(() => {
    if (props.signupsStartTime) {
      starts.current = new Date(props.signupsStartTime);
      // Display start time
      timeUntilStart();

      interval.current = window.setInterval(timeUntilStart, 60000);
    }

    return () => {
      if (interval) {
        clearInterval(interval.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item justifyContent="center" sx={styles.message} >
        <Typography variant="h5" paragraph>{`Signups will be available ${dateStringToLocalDateFormatted(props.signupsStartTime)}`} </Typography>
      </Grid>
      <Grid container item justifyContent="center" sx={styles.message} >
        <Typography variant="h6" paragraph>{`${startTime} until signups open for the ski season`}</Typography>
      </Grid>
      <Grid container item justifyContent="center" sx={styles.message} >
        <Typography variant="button" paragraph>{`Please refresh your browser after the start time to access signups`}</Typography>
      </Grid>
    </Box>
  );
}

export default SignupsOffline;

