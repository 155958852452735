// App
export const APP_COPYRIGHT = 'APP_COPYRIGHT';
export const APP_RELEASE_DATE = 'APP_RELEASE_DATE';
export const APP_VERSION = 'APP_VERSION';

// Membership
export const HOUSEHOLD_EDIT_SAVED = 'HOUSEHOLD_EDIT_SAVED';
export const MEMBER_EDIT_SAVED = 'MEMBER_EDIT_SAVED';
export const MEMBERSHIP_SEARCHTEXT_CLEAR = 'MEMBERSHIP_SEARCHTEXT_CLEAR';
export const MEMBERSHIP_SEARCHTEXT_SET = 'MEMBERSHIP_SEARCHTEXT_SET';

// Signups
export const SIGNUP_GUEST_REQUEST_TIME = 'SIGNUP_GUEST_REQUEST_TIME';
export const SIGNUP_OFFSEASON_STARTDATE = 'SHARED_OFFSEASON_STARTDATE';

export const SIGNUP_CANCELLED = 'SIGNUP_CANCELLED';
export const SIGNUP_CLEAR_CALENDARFILTER = 'SIGNUP_CLEAR_CALENDARFILTER';

export const SIGNUP_MINARRIVAL = 'SIGNUP_MINARRIVAL';
export const SIGNUP_MAXDEPARTURE = 'SIGNUP_MAXDEPARTURE';

export const SIGNUP_GUEST_CANCELLED = 'SIGNUP_GUEST_CANCELLED';
export const SIGNUP_GUEST_SAVED = 'SIGNUP_GUEST_SAVED';
export const SIGNUP_MEMBER_CANCELLED = 'SIGNUP_MEMBER_CANCELLED';
export const SIGNUP_MEMBER_SAVED = 'SIGNUP_MEMBER_SAVED';
export const SIGNUP_NOTSIGNEDUP_ADDED = 'SIGNUP_NOTSIGNEDUP_ADDED';