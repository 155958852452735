import React from 'react';
import { SnackbarProvider } from 'notistack';

// Material-UI
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';

// Auth0 hooks
import { useAuth0 } from '@auth0/auth0-react';

import Routing from './routing';

//declare module '@mui/styles/defaultTheme' {
//  // eslint-disable-next-line @typescript-eslint/no-empty-interface
//  interface DefaultTheme extends Theme { }
//}

const App: React.FC = () => {

  // #region Startup sequence

  // Material-UI theme (default)
  const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      }
    },
  });

  // Auth0 hooks
  const { isLoading, error } = useAuth0();

  // Wait until Auth0 loads...
  if (isLoading) {
    return <div style={{ textAlign: 'center', margin: '150px,0px,0px,0px' }}>Checking authentication...</div>;
  }

  // Check if Auth0 initializes
  if (error) {
    return <div>MSC website initialization error: {error.message}. Please close and reopen your browser and try again, or refresh using SHIFT+F5.</div>;
  }

  // #endregion


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Routing />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );


}

export default App;
