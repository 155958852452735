// #region Imports
import React from 'react';

// Material-UI
import Grid from '@mui/material/Grid';

// Components
import {
  GDFile
} from "../interfaces";

import {
  ResourceBreadcrumb
} from "../models";

import PDFViewer from './PDFViewer';
import ResourcesBreadcrumbs from './ResourcesBreadcrumbs';
import ResourceList from './ResourceList';

// #endregion

const Resources: React.FC = () => {

  const [resourceBreadcumbs, setResourceBreadcumbs] = React.useState<Array<ResourceBreadcrumb>>([new ResourceBreadcrumb('-1', 'Resources')]);
  const [selectedBreadcrumb, setSelectedBreadcumb] = React.useState<ResourceBreadcrumb>(new ResourceBreadcrumb('-1', 'Resources'));
  const [selectedFile, setSelectedFile] = React.useState<GDFile>();

  // #region Styles

  const styles = {
    root: {
      flexGrow: 1
    },
  };

  // #endregion

  // #region Event Handlers

  /*
   * Handle selection of a breadcrumb
   */
  const handleBreadcumbSelected = (rbc: ResourceBreadcrumb) => {

    setSelectedBreadcumb(rbc);

    // Remove breadcrumbs
    const i = resourceBreadcumbs.findIndex((r: ResourceBreadcrumb) => { return r.id === rbc.id });
    if (i === 0) {
      setResourceBreadcumbs(resourceBreadcumbs => resourceBreadcumbs.slice(0, 1));
    } else {
      setResourceBreadcumbs(resourceBreadcumbs => resourceBreadcumbs.slice(0, -i));
    }
  }

  /*
 * Handle viewing PDF file
 */
  const handleFileView = (file: GDFile) => {
    setSelectedFile(file);
  }

  /*
   * Handle selection of a resource (file)
   */
  const handleResourceSelected = (resource: GDFile) => {
    const rbc: ResourceBreadcrumb = new ResourceBreadcrumb(resource.id, resource.name);
    setSelectedBreadcumb(rbc);
    setResourceBreadcumbs(resourceBreadcumbs => [...resourceBreadcumbs, rbc]);
  }

  // #endregion

  return (
    <>
      <Grid container sx={styles.root}>
        <Grid container item>
          <ResourcesBreadcrumbs resourceBreadcumbs={resourceBreadcumbs} breadcrumbSelected={handleBreadcumbSelected} />
        </Grid>
        <Grid container item>
          <ResourceList resourceSelected={handleResourceSelected} selectedBreadcrumb={selectedBreadcrumb} viewFile={handleFileView} />
        </Grid>
      </Grid>
      {selectedFile &&
        <PDFViewer file={selectedFile} />
      }
    </>
  );
}

export default Resources;