// #region imports
import React from 'react';

// Material-UI
import AppBar from '@mui/material/AppBar';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { blueGrey, green } from '@mui/material/colors';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridSelectionModel
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import LiveHelp from '@mui/icons-material/LiveHelp';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  DateRange,
  HTTPHeaders,
  TransferablePass
} from '../../interfaces';

import {
  TransferablePassStatusChange
} from '../../models';

import {
  API_URL,
  TransferablePassAction,
  NULL_DATETIME_STRING,
  PassesView,
  TransferablePassStatus
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region interfaces

interface TransferablePassesProps {
  dateRange: DateRange;
  handlePassesCount: (passes: number) => void;
  refresh: boolean;
  view: PassesView;
}

// #endregion

const TransferablePasses: React.FC<TransferablePassesProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();
  const { enqueueSnackbar } = useSnackbar();

  const [action, setAction] = React.useState<TransferablePassAction>(TransferablePassAction.Confirm);
  const [passes, setPasses] = React.useState<Array<TransferablePass>>([]);
  const [passesFiltered, setPassesFiltered] = React.useState<Array<TransferablePass>>([]);
  const [passesSelected, setPassesSelected] = React.useState<Array<number>>([]);
  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      height: '100%',
      padding: theme.spacing(2),
    },
    appBar: {
      margin: theme.spacing(1, 0, 1, 0)
    },
    confirmed: {
      color: green[500]
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    requested: {
      color: blueGrey[500]
    },
    toolbarItem: {
      marginRight: theme.spacing(2),
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'passDate',
        headerName: 'Pass Date',
        width: 150,
        type: 'string',
        align: 'left',
        renderCell: (params: GridCellParams) => (
          renderPassDate(params)
        )
      },
      {
        field: 'requested',
        headerName: 'Requested',
        width: 150,
        type: 'string',
        align: 'left',
        renderCell: (params: GridCellParams) => (
          renderDate(params)
        )
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        type: 'string',
        align: 'left',
      },
      {
        field: 'fullName',
        headerName: 'Household',
        width: 175,
        type: 'string',
        align: 'left',
      },
      {
        field: 'updated',
        headerName: 'Updated',
        width: 150,
        type: 'string',
        align: 'left',
        renderCell: (params: GridCellParams) => (
          renderDate(params)
        )
      },
    ];

  }

  /*
 * Fetch transferable pass requests
 */
  const fetchPasses = async () => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // Always display passes today+
    const params = new URLSearchParams();
    params.append('startDate', props.dateRange.startDate);
    params.append('endDate', props.dateRange.endDate);
    params.append('view', props.view.toString());

    if (!params.toString()) {
      return;
    }

    const url = new URL(`${API_URL}/v1/passes`);
    url.search = params.toString();

    await fetch(url.toString(),
      {
        method: 'GET',
        cache: 'no-cache',
        headers: headers,
        mode: 'cors'
      })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Lodge use passes not retrieved');
        }
        return response.json()
      })
      .then((e: Array<TransferablePass>) => {
        setPasses(e);
        setIsLoading(false);
      })
      .catch(err => {
        setErrors(err);
        setIsLoading(false);
      });
  }

  /*
 * Filter to subset of transferable passes based upon view
 */
  const filterPasses = () => {

    switch (props.view) {
      case PassesView.All:
        setPassesFiltered(passes.filter(p => p));
        break;
      case PassesView.Confirmed:
        setPassesFiltered(passes.filter(p => p.transferablePassStatusID === 2));
        break;
      case PassesView.Denied:
        setPassesFiltered(passes.filter(p => p.transferablePassStatusID === 3));
        break;
      case PassesView.Requested:
        setPassesFiltered(passes.filter(p => p.transferablePassStatusID === 1));
        break;
    }

  }

  /*
 * Overlay displayed when no data
 */
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No transferable pass requests to display</div>
      </GridOverlay>
    );
  }

  /*
* Render date column
*/
  const renderDate = (params: GridCellParams) => {
    return (
      params.value !== NULL_DATETIME_STRING ? dateStringToLocalDateFormatted(params.value, 'MM/dd/yyyy HH:mm:ss') : 'n/a'
    );
  }

  /*
* Render pass date column
*/
  const renderPassDate = (params: GridCellParams) => {
    return (
      params.value !== NULL_DATETIME_STRING ? dateStringToLocalDateFormatted(params.value, 'MM/dd/yyyy') : 'n/a'
    );
  }

  /*
 * Action to change transferable pass status
 */
  const updateStatus = async () => {

    // Process selected passes
    const statusChange = new TransferablePassStatusChange();
    statusChange.ids = passesSelected;
    statusChange.statusID = action;

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/passes/updatestatus`);

    await fetch(url.toString(), {
      body: JSON.stringify(statusChange),
      headers: headers,
      method: 'POST'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Transferable pass status update error');
        }
        fetchPasses();
        // Clear selections
        setPassesSelected([]);
        setAction(TransferablePassAction.Confirm)
        enqueueSnackbar('Status updated for selected transferable passes', { variant: 'success' });
      })
      .catch(err => {
        setErrors(err);
      });

  }

  // #endregion

  // #region Event handlers

  /*
   * Handles toolbar action
   */
  const handleActionChanged = (event: React.MouseEvent<HTMLElement>, a: TransferablePassAction) => {
    if (a !== null) {
      setAction(a);
    }
  };

  /*
   * Handle selected guest requests
   */
  const handleCheckboxSelected = (ids: Array<number>) => {
    setPassesSelected(ids);
  }

  /*
   * Handles toolbar action status change
   */
  const handleStatusChange = () => {
    updateStatus();
  };

  React.useEffect(() => {
    if (props.dateRange.startDate && props.dateRange.endDate) {
      fetchPasses();
    } else {
      setPasses([]);
      setPassesFiltered([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.dateRange)]);

  React.useEffect(() => {
    if (props.dateRange.startDate && props.dateRange.endDate) {
      fetchPasses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  React.useEffect(() => {
    filterPasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.view]);

  React.useEffect(() => {
    filterPasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(passes)]);

  React.useEffect(() => {
    props.handlePassesCount(passesFiltered.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(passesFiltered)]);

  // #endregion

  return (
    <div style={styles.root}>
      <AppBar position="static" color="inherit" sx={styles.appBar}>
        <Toolbar>
          <ToggleButtonGroup
            disabled={passesFiltered.length === 0}
            value={action}
            exclusive
            onChange={handleActionChanged}
          >
            <ToggleButton value={TransferablePassStatus.Denied}>
              <Tooltip title="Deny">
                <BlockIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={TransferablePassStatus.Requested}>
              <Tooltip title="Requested">
                <LiveHelp />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={TransferablePassStatus.Confirmed}>
              <Tooltip title="Confirmed">
                <CheckCircleIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title="Approve the status change for selected passes">
            <span>
              <IconButton
                disabled={(passesFiltered.length === 0)}
                onClick={handleStatusChange}
                size="large"
                sx={styles.toolbarItem}
              >
                <PlaylistPlayIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box sx={styles.root}>
        <DataGrid
          checkboxSelection={true}
          columns={columns()}
          components={{
            NoRowsOverlay: noRowsOverlay
          }}
          density="compact"
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          getRowId={(row) => row.transferablePassID}
          hideFooter={true}
          loading={isLoading}
          rows={passesFiltered}
          onSelectionModelChange={(m: GridSelectionModel) => handleCheckboxSelected(m as Array<number>)}
          selectionModel={passesSelected}
        />
      </Box>
    </div>
  );

}

export default TransferablePasses;