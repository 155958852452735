// #region imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  API_URL,
  EMPTY_STRING,
  MemberStatusFilterMode
} from '../global';

import {
  HTTPHeaders,
  MemberStatus,
  MemberStatusCount
} from '../interfaces';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';
// Responsive hooks
import { useResponsive } from '../hooks/UseResponsive';

// #endregion

// #region interfaces

interface MemberStatusFilterProps {
  applyFilter: (filter: Array<number>) => void;
  closeFilterDrawer?: () => void;
  filterMessage: string;
  filterMode: MemberStatusFilterMode;
  statusCounts: Array<MemberStatusCount>;
  title: string;
}

// #endregion

const MemberStatusFilter: React.FC<MemberStatusFilterProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { isMobile } = useResponsive();

  const [errors, setErrors] = React.useState(false);
  const [showWorking, setShowWorking] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<Array<MemberStatus>>([]);
  const [statusFilter, setStatusFilter] = React.useState<Array<number>>([]);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1
    },
    actions: {
      padding: theme.spacing(3, 0, 1, 0)
    },
    legend: {
      marginBottom: theme.spacing(1, 1, 1, 1)
    },
    filterSearchText: {
      padding: theme.spacing(1, 0, 1, 0),
    }
  };

  // #endregion

  // #region Methods

  /*
   * Clear all filter selections
   */
  const clearAll = () => {
    setStatusFilter([]);
  }

  /*
   * Select all filters
   */
  const selectAll = () => {
    const all = status.map(s => { return s.memberStatusID });
    setStatusFilter(all);
  }

  /*
* Fetch member status types
*/
  const fetchStatus = async () => {

    setShowWorking(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/members/status`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          setShowWorking(false);
          throw new Error('Member statuses not retrieved');
        }
        return response.json()
      })
      .then((status: Array<MemberStatus>) => {
        setStatus(status);
        setShowWorking(false);
      }
      )
      .catch(err => {
        setErrors(err);
        setShowWorking(false);
      });
  }

  /*
   * Get status count for a status type
   */
  const getStatusCount = (id: number): string => {

    const sc = props.statusCounts.find((sc: MemberStatusCount) => sc.memberStatusID === id);
    if (sc) {
      return ` (${sc.statusCount.toString()})`;
    } else {
      return EMPTY_STRING;
    }

  }

  // #endregion

  // #region Event handlers

  /*
   * Status swtich changed
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const id = parseInt(event.target.name);

    let ss = [];
    if (event.target.checked) {
      // Add new selected status
      ss = [...statusFilter, id];
    } else {
      ss = statusFilter.filter(s => s !== id);
    }

    setStatusFilter(ss);

  };

  React.useEffect(() => {
    fetchStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Apply filter to parent (membership or email)
    props.applyFilter(statusFilter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item alignItems="center">
        {isMobile &&
          <Grid item>
            <IconButton onClick={props.closeFilterDrawer} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        }
        <Grid item>
          <Typography variant="h6">{props.title}</Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Typography variant="caption">{props.filterMode === MemberStatusFilterMode.Filter ? 'Membership Categories' : 'Membership Categories: Search Results'}</Typography>
      </Grid>
      <Grid container item sx={styles.root}>
        {showWorking &&
          <Grid container item justifyContent="center">
            <CircularProgress />
          </Grid>
        }
        {!showWorking &&
          <FormControl component="fieldset">
            <FormGroup>
              {status.map((s: MemberStatus) => (
                <FormControlLabel
                  key={s.memberStatusID}
                  control={
                    <Switch
                      checked={statusFilter.includes(s.memberStatusID)}
                      color="primary"
                      size="small"
                      onChange={handleChange}
                      name={s.memberStatusID.toString()} />
                  }
                  label={`${s.status}${getStatusCount(s.memberStatusID)}`}
                />
              ))}
            </FormGroup>
          </FormControl>
        }
      </Grid>
      <Grid container item justifyContent="center" spacing={3} sx={styles.actions}>
        <Grid item>
          <Button size="small" variant="outlined" onClick={clearAll}>Clear</Button>
        </Grid>
        <Grid item>
          <Button size="small" variant="outlined" onClick={selectAll}>Select All</Button>
        </Grid>
      </Grid>
      {
        props.filterMessage &&
        <FormHelperText sx={styles.filterSearchText}>{props.filterMessage}</FormHelperText>
      }
    </Box >
  );

}

export default MemberStatusFilter;