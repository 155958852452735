// #region imports

import React from 'react';

// Material-UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

// #region interfaces

interface NonWeekendHolidayWarningDialogProps {
  handleClose: () => void;
  show: boolean;
}

// #endregion


const NonWeekendHolidayWarningDialog: React.FC<NonWeekendHolidayWarningDialogProps> = (props) => {

  const { isMobile } = useResponsive();

  // #region Styles
  // #endregion

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
    >
      <DialogTitle>Non-Weekend/Non-Holiday Restrictions</DialogTitle>
      <DialogContent>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>Arrival and departure dates have been cleared because the dates overlay weekend/holiday dates. Only non-weekend/holiday dates can be selected in the Non-Weekend/Holiday mode.</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} paragraph>If you want to request a weekend/holiday, Cancel this request and then select the Weekend/Holiday option.</Typography>
     </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

}

export default NonWeekendHolidayWarningDialog;