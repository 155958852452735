import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div>
      <h1>404</h1>
      <h3>Page not found</h3>
    </div>
  );
}

export default NotFound;