// #region imports

import React from 'react';
import {
  format
} from 'date-fns';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

import {
  PriorityPeriod
} from '../models';

import {
  HTTPHeaders
} from '../interfaces';

import {
  API_URL
}
  from '../global';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// #endregion

// #region Interfaces

interface PriorityPeriodListProps {
  disabled: boolean;
  endDate?: string;
  reset: boolean;
  startDate?: string;
  handlePriorityPeriodChange: (period: PriorityPeriod) => void;
}

// #endregion

const PriorityPeriodList: React.FC<PriorityPeriodListProps> = (props: PriorityPeriodListProps) => {

  const { getAccessToken } = useAuth0User();

  const [errors, setErrors] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [priorityPeriods, setPriorityPeriods] = React.useState<Array<PriorityPeriod>>([]);
  const [period, setPeriod] = React.useState<PriorityPeriod | undefined>();

  // #region Styles

  const styles = {
    selectWidth: {
      minWidth: 365,
    },
  };

  // #endregion

  // #region Methods

  /*
   * Get list of priority periods
   */
  const fetchPeriods = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const params = new URLSearchParams();

    if (props.startDate) {
      params.append('startDate', props.startDate);
    } else {
      // Otherwise today forward
      const today: Date = new Date();
      params.append('startDate', format(today, 'MM/d/y'));
    }

    if (props.endDate) {
      params.append('endDate', props.endDate);
    }

    const url = new URL(`${API_URL}/v1/signups/priorityperiods`);
    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Priority periods were not retrieved.');
        }
        return response.json()
      })
      .then((periods: Array<PriorityPeriod>) => {
        setPriorityPeriods(periods);
        setLoading(false);
      })
      .catch(err => setErrors(err));
  }

  /*
 * Reset selected priority period
 */
  const resetSelectedPeriod = () => {
    setPeriod(undefined);
  }

  // #endregion

  // #region Event handlers

  /*
   * Handler for change in selected signup weekend
   */
  const handlePriorityPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPeriod = priorityPeriods.find((p: PriorityPeriod) => {
      return (p.priorityPeriodID === Number(event.target.value));
    });
    if (selectedPeriod) {
      setPeriod(selectedPeriod);
      props.handlePriorityPeriodChange(selectedPeriod);
    }
  };

  React.useEffect(() => {
    resetSelectedPeriod();
  }, [props.reset]);

  React.useEffect(() => {
    fetchPeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return (
    <TextField
      select
      label='Weekend/Holiday Dates'
      sx={styles.selectWidth}
      disabled={props.disabled || loading}
      onChange={handlePriorityPeriodChange}
      value={period ? period.priorityPeriodID.toString() : '0'}
    >
      <MenuItem key={0} value={0}>Select dates to fill arrival/departure</MenuItem>
      {priorityPeriods.map((period: PriorityPeriod) => (
        <MenuItem key={period.priorityPeriodID} value={period.priorityPeriodID}>{period.arrivalDate + ' to ' + period.departureDate + (period.description ? ' ' + period.description : '')}</MenuItem>
      ))}
    </TextField>
  );

}

export default PriorityPeriodList;