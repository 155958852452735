// #region Imports

import React from 'react';

// Material-UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Household } from '../interfaces';

// #endregion

// #region Interfaces

interface MemberListHouseholdProps {
  household: Household;
}

// #endregion

const MemberListHousehold: React.FC<MemberListHouseholdProps> = (props) => {

  return (
    <>
      {props.household.address1 &&
        <Grid container item>
          <Typography variant="body1">{props.household.address1}</Typography>
        </Grid>
      }
      {props.household.address2 &&
        <Grid container item>
          <Typography variant="body1">{props.household.address2}</Typography>
        </Grid>
      }
      {(props.household.city || props.household.stateRegion || props.household.postalCode) &&
        <Grid container item>
          <Typography variant="body1">{`${props.household.city} ${props.household.stateRegion} ${props.household.postalCode}`}</Typography>
        </Grid>
      }
      {props.household.country &&
        <Grid container item>
          <Typography variant="body1">{props.household.country}</Typography>
        </Grid>
      }
    </ >
  );
}

export default MemberListHousehold;
