// #region Imports

import React from 'react';

// Material-UI
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';

// Styles
import { alpha, useTheme } from '@mui/material/styles';

import {
  EMPTY_STRING,
  KEYCODE_RETURN
} from '../global';

// #endregion

// #region Interfaces

interface MemberListSearchProps {
  handleSearch: (searchText: string) => void;
  handleSearchClear: () => void;
}

// #endregion

const MemberListSearch: React.FC<MemberListSearchProps> = (props) => {

  const [searchText, setSearchText] = React.useState<string | null>(EMPTY_STRING);

  // #region Styles

  const theme = useTheme();
  const styles = {
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1),
      // vertical padding + font size from searchIcon
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      flexGrow: 1,
      backgroundColor: alpha(theme.palette.primary.main, 0.10),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
      },
    },
    search: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      marginLeft: 0,
      padding: theme.spacing(1),
      flex: 1,
    },
  };

  // #endregion

  // #region Event Handlers

  /*
   * Initiate search for RETURN key
   */
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (searchText && searchText.length >= 2 && (event.code.toUpperCase() === KEYCODE_RETURN)) {
      props.handleSearch(searchText);
    }
  }

  /*
   * Update search text and invoide handler if criteria met
   */
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.trim());
  };

  React.useEffect(() => {
    // Min 3 chars to initiate search
    if (searchText) {
      if (searchText.length >= 3) {
        props.handleSearch(searchText);
      }
    } else {
      props.handleSearchClear();
    }
  }, [searchText]);

  // #endregion

  return (
    <div style={styles.search}>
      <InputBase
        autoFocus
        placeholder="Enter name"
        classes={{
          root: styles.inputRoot.color,
          input: styles.inputInput.toString(),
        }}
        inputProps={{ 'aria-label': 'search', 'type': 'search' }}
        value={searchText}
        onChange={handleSearch}
        onKeyUp={handleKeyUp}
        endAdornment={
          < InputAdornment position="end">
            <SearchIcon /><PeopleIcon />
          </InputAdornment>
        }
      />
    </div>
  );
}

export default MemberListSearch;
