// #region Imports

import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import {
  API_URL
  , EMPTY_STRING
} from '../global';

import {
  HTTPHeaders
} from "../interfaces";

import {
  Household
} from '../models';

// Auth0 user hooks 
import { useAuth0User } from '../hooks/UseAuth0User';

// UTC to local date formatting
import { useDateFormatting } from '../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface HouseholdViewProps {
  memberID: number;
}

// #endregion

const HouseholdView: React.FC<HouseholdViewProps> = (props) => {

  const { getAccessToken } = useAuth0User();
  const { dateStringToLocalDateFormatted } = useDateFormatting();

  const [errors, setErrors] = React.useState(false);
  const [household, setHousehold] = React.useState<Household>(new Household());

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flex: 1
    },
    rowSpacing: {
      marginTop: theme.spacing(1)
    },
    updated: {
      marginTop: theme.spacing(2)
    }
  };

  // #endregion

  // #region Methods

  /*
* Fetch household and members
*/
  const fetchHousehold = async (id: number) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(`${API_URL}/v1/households/${id}`, {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Household not saved');
        }
        return response.json()
      })
      .then((h: Household) => {
        setHousehold(h);
      })
      .catch(err => setErrors(err));

  }

  // #endregion

  // #region Event handlers

  React.useEffect(() => {
    if (props.memberID > 0) {
      fetchHousehold(props.memberID);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberID]);

  // #endregion

  return (
    <>
      {household.isDeceased &&
        <Box sx={styles.root}>
          <Typography variant="body1">No household information, all members deceased</Typography>
        </Box>
      }
      {!household.isDeceased &&
        <Box sx={styles.root}>
          {household.address1 &&
            <Grid container item lg={12} sx={styles.rowSpacing}>
              <Typography variant="body1">{household.address1}</Typography>
            </Grid>
          }
          {household.address2 &&
            <Grid container item lg={12} sx={styles.rowSpacing} >
              <Typography variant="body1">{household.address2}</Typography>
            </Grid>
          }
          {(household.city || household.stateRegion || household.postalCode) &&
            <Grid container item lg={12} sx={styles.rowSpacing}>
              <Typography variant="body1">{`${household.city} ${household.stateRegion} ${household.postalCode}`}</Typography>
            </Grid>
          }
          {household.country &&
            <Grid container item lg={12} sx={styles.rowSpacing}>
              <Typography variant="body1">{household.country}</Typography>
            </Grid>
          }
          <Grid container item lg={12} sx={styles.updated}>
            <Typography variant="overline">Updated: {dateStringToLocalDateFormatted(household.updated)} {household.updatedBy ? household.updatedBy : EMPTY_STRING}</Typography>
          </Grid>
        </Box>
      }
    </>
  );
}

export default HouseholdView;
