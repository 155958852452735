// #region imports

import React from 'react';
import { format } from 'date-fns';

// Material-UI
import { blueGrey, green } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography'

// Styles
import { useTheme } from '@mui/material/styles';

import {
  TransferablePassSignup
} from '../models';

import {
  EMPTY_STRING
} from '../global';

// #endregion

// #region interfaces

interface TransferablePassRequestProps {
  handlePassChange(pass: TransferablePassSignup): void;
  pass: TransferablePassSignup
}

// #endregion

const TransferablePassRequest: React.FC<TransferablePassRequestProps> = (props) => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      height: '100%',
      padding: theme.spacing(2),
    },
    confirmed: {
      color: green[500]
    },
    requested: {
      color: blueGrey[500]
    },
  };

  // #endregion

  // #region Methods

  /*
 * Render transferable pass date and status icon 
 */
  const renderDate = () => {
    const d = new Date(props.pass.passDate);
    const dateFormatted = format(d, 'M/d/yyyy EEEEEE');
    return <Typography variant="body2">{dateFormatted}</Typography>
  }

  // #endregion

  // #region Event handlers

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    // # of passes selected
    const numberPasses = parseInt((event.target as HTMLInputElement).value);

    // Updated pass data to parent component
    const pass: TransferablePassSignup = new TransferablePassSignup();
    pass.passDate = props.pass.passDate;
    pass.passes = numberPasses;
    pass.status = props.pass.status;
    props.handlePassChange(pass);
  };

  // #endregion

  return (
    <Grid container alignItems="center" spacing={2} >
      <Grid item xs={3}>
        {renderDate()}
      </Grid>
      <Grid item>
        <RadioGroup
          onChange={handleChange}
          row
          value={props.pass.passes}
        >
          <FormControlLabel value="0" control={<Radio />} label=<Typography variant="body2">None</Typography> />
          <FormControlLabel value="1" control={<Radio />} label=<Typography variant="body2">1</Typography> />
          <FormControlLabel value="2" control={<Radio />} label=<Typography variant="body2">2</Typography> />
        </RadioGroup>
      </Grid>
      <Grid item>
        <Typography variant="body2">{props.pass.status ? '(' + props.pass.status + ')' : EMPTY_STRING}</Typography>
      </Grid>
    </Grid>
  );

}

export default TransferablePassRequest;